import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { UserService } from '../../services/user.service';
import { Usuario } from '../../interfaces/transaccion.interface';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, SortDirection } from '@angular/material/sort';
import { Sort } from '@angular/material/sort';
import { BalanceSearchFilter } from 'src/app/shared/enums/balance-search-filter.enum';

@Component({
  selector: 'app-users-balance-search',
  templateUrl: './users-balance-search.component.html',
  styleUrls: ['./users-balance-search.component.sass']
})
export class UsersBalanceSearchComponent implements OnInit {
  selectedFilter: string = BalanceSearchFilter.ALL;

  filterDescriptions: { [key: string]: string } = {
    [BalanceSearchFilter.ALL]: 'Todos',
    [BalanceSearchFilter.DOCUMENT]: 'Documento',
    [BalanceSearchFilter.EMAIL]: 'Email',
    [BalanceSearchFilter.ID]: 'ID',
  };
  filters: string[] = [<string>BalanceSearchFilter.ALL, <string>BalanceSearchFilter.DOCUMENT, <string>BalanceSearchFilter.EMAIL, <string>BalanceSearchFilter.ID];
  searchValue: string = '';
  range = new FormGroup({
    start: new FormControl<Date | null>(new Date(), [Validators.required]),
    end: new FormControl<Date | null>(new Date(), [Validators.required]),
  });
  users: Usuario[] = [];
  dataSource: MatTableDataSource<any> | undefined;
  constructor(private spinner: SpinnerService, private userService: UserService){

  }
  ngOnInit(): void {
    this.search();

  }

  getFilterDescription(value: string){
    return this.filterDescriptions[value];
  }
  @ViewChild(MatSort) sort: MatSort = new MatSort();

  async search(){
    const ref = this.spinner.start();
    let searchQuery = this.searchValue;
    if(this.selectedFilter === BalanceSearchFilter.DOCUMENT){
      searchQuery = searchQuery.replaceAll('.', '').replaceAll('-', '');
    }
    this.users = await this.userService.searchUsers(this.searchValue || BalanceSearchFilter.ALL, this.selectedFilter.toUpperCase() );
    this.dataSource = new MatTableDataSource(this.users);
    console.log(this.users);
    this.spinner.stop(ref);
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  sortData(sort: Sort) {
    const data = this.users.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource( data);
    }

    this.users = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      console.log(sort.active);
      switch (sort.active) {

        case 'idUsuario':
          return this.compare(a.usuarioId, b.usuarioId, isAsc);
        case 'nombre':
          return this.compare(a.nombre, b.nombre, isAsc);
        case 'documento':
          return this.compare(a.numeroId, b.numeroId, isAsc);
        case 'email':
          return this.compare(a.email, b.email, isAsc);
        default:
          return 0;
      }
    });
    this.dataSource = new MatTableDataSource( this.users);
  }


  displayedColumns: string[] = [

    'idUsuario',
    'nombre',
    'documento',
    'email',
    'acciones'
  ];

  columns = [
    {
      columnDef: 'idUsuario',
      header: 'Id',
      cell: (element: any) => `${element.usuarioId}`,
      cellStyle: { 'left': '0','text-align':'left', 'font-weight': 'bold', 'min-width':'60px', 'width':'80px'},
      headerStyle: { 'left': '0','text-align':'left', 'font-weight': 'bold', 'min-width':'60px', 'width':'80px'},
    },
    {
      columnDef: 'nombre',
      header: 'Nombre',
      cell: (element: any) => `${element.nombre} ${element.apellidos} `,
      cellStyle: { 'left': '0','text-align':'left', 'min-width':'60px', 'width':'200px'},
      headerStyle: { 'left': '0','text-align':'left', 'font-weight': 'bold', 'min-width':'60px', 'width':'200px'},
    },
    {
      columnDef: 'documento',
      header: 'Documento',
      cell: (element: any) => `${element.numeroId}  `,
      cellStyle: { 'left': '0','text-align':'left', 'min-width':'120px', 'width':'200px'},
      headerStyle: { 'left': '0','text-align':'left', 'font-weight': 'bold', 'min-width':'120px', 'width':'200px'},
    },
    {
      columnDef: 'email',
      header: 'Email',
      cell: (element: any) => `${element.email}  `,
      cellStyle: { 'left': '0','text-align':'left', 'min-width':'60px', 'width':'250px'},
      headerStyle: { 'left': '0','text-align':'left', 'font-weight': 'bold', 'min-width':'60px', 'width':'250px'},
    },

  ];
}
