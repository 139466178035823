<div class="m-4 flex flex-col justify-center">
  <h1 class="m-4 text-xl font-bold text-center">Segmentación de usuarios</h1>

  <div class="flex flex-row gap-5 align-middle mb-4 justify-center flex-wrap" style="max-width: 800px; margin: 0 auto;">

      <mat-form-field class="w-[250px] sm:w-[220px]">
        <mat-label>Tipo de búsqueda</mat-label>
        <mat-select required [(ngModel)]="selectedFilter">
          <mat-option>Seleccione un filtro</mat-option>
          <mat-option *ngFor="let filter of filters" [value]="filter">
            {{ filterDescriptions[filter]}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="w-[250px] sm:w-[220px]" *ngIf="selectedFilter === 'USER_SEGMENTATION'">
        <mat-label>Tipo de usuario</mat-label>
        <mat-select required [(ngModel)]="userSegmentationFilter">
          <mat-option>Seleccione un tipo de usuario</mat-option>
          <mat-option *ngFor="let filter of filtersSegmentation" [value]="filter">
            {{ filterSegmentationDescription[filter]}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    <div >
      <mat-form-field *ngIf="selectedFilter !== 'USER_SEGMENTATION'">
        <mat-label>Valor de búsqueda</mat-label>
        <input matInput type="text" [(ngModel)]="searchValue" />

      </mat-form-field>

      <button *ngIf="searchValue"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="searchValue = ''"
      >
        <mat-icon>close</mat-icon>
      </button>

    </div>
      <button mat-raised-button color="primary" (click)="search()" class="mt-[1px] h-[56px] button-search">
        Buscar
      <mat-icon> search </mat-icon>
    </button>

  </div>
  <div class="flex flex-col w-full items-center">
    <h2> <b>Precio Spot:</b> {{spotPrice | currency: '$'}} </h2>
    <h2> <b>Tasa USDT:</b> {{usdtRate | currency: '$'}} </h2>

  </div>
  <div class="my-4 flex flex-col overflow-x-auto max-w-[100%]" >

    <div class="table-container" >
      <table mat-table [dataSource]="dataSource" (matSortChange)="sortData($event)" class="w-full" *ngIf="dataSource" matSort>

        <ng-container
          *ngFor="let column of columns"
          [matColumnDef]="column.columnDef"
        >
          <th mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          style="background-color: #CEE9C2; text-align:left"
          [ngStyle]="column.headerStyle"
          >
          {{column.header}}
        </th>
          <td mat-cell
          *matCellDef="let row"
          style="text-align:left"
          [ngStyle]="column.cellStyle"
          >
          {{column.cell(row)}}
        </td>
        </ng-container>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="montoMinimoTasa">
          <th mat-header-cell *matHeaderCellDef aria-label="row actions" style="background-color: #CEE9C2; text-align: center">Monto mínimo tasa</th>
          <td mat-cell *matCellDef="let element" >
            <span class="flex flex-row align-middle">
            <input matInput type="text" [(ngModel)]="element.minimumTresholdUsdt" appOnlynumber matInputCommified [disabled]="element.segmentacionId === 1" />
              <button mat-icon-button color="primary" aria-label="ver" (click)="updateMinAmount(element)" *ngIf="element.segmentacionId !== 1">
                <mat-icon>save</mat-icon>
              </button>
            </span>

          </td>
        </ng-container>
        <ng-container matColumnDef="acciones" stickyEnd>
          <th mat-header-cell *matHeaderCellDef aria-label="row actions" style="background-color: #CEE9C2; text-align: center">Acciones</th>
          <td mat-cell *matCellDef="let element" >
            <span class="flex flex-row align-middle">
              <button mat-icon-button color="primary" aria-label="ver" (click)="openUserDialog(element)">
                <mat-icon>settings</mat-icon>
              </button>
              <button mat-icon-button color="primary" aria-label="ver" (click)="openAssignPoints(element)" *ngIf="element.segmentacionId !== 1">
                <mat-icon>swap_vert</mat-icon>
              </button>
            </span>

          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>


