import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Transaccion } from '../interfaces/transaccion.interface';
import { GetTransactionsType } from 'src/app/shared/enums/get-transactions-type';
import { AssignTransactionsDTO } from '../interfaces/assign-transactions.dto';
import { AcceptTransactionDTO } from '../interfaces/accept-transaction.dto';
import { RejectTransactionDTO } from '../interfaces/reject-transaction.dto';
import { EditTransactionDTO } from '../interfaces/edit-transaction.dto';
import { TransactionUserDTO } from '../interfaces/transaction-user.dto';
import { getQueryString } from 'src/app/shared/utils/misc.util';
import { query } from '@angular/animations';

@Injectable({
  providedIn: 'root'
})
export class TransaccionService {
  API_URL = environment.apiGatewayURL;

  constructor(private http: HttpClient) {}

  saveTransaccion(transaccionDTO: any): Promise<any>{
    const serviceURL = `${this.API_URL}transaccion`;
    return firstValueFrom(this.http.post<any>(serviceURL, transaccionDTO));
  }

  getAllTransactions(type: GetTransactionsType, bankType?: string): Promise<Transaccion[]>{
    let queryParams = `?type=${type}${bankType ? '&bankType='+bankType: ''}`;
    const serviceURL = `${this.API_URL}transaccion${queryParams}`;
    return firstValueFrom(this.http.get<Transaccion[]>(serviceURL));
  }
  getAllTransactionsVes(type: GetTransactionsType, currentPage: number, pageSize: number, startDate: string, endDate: string): Promise<any>{
    let queryParams = `?type=${type}&startDate=${startDate}&endDate=${endDate}&page=${currentPage}&pageSize=${pageSize}`;
    const serviceURL = `${this.API_URL}transaccion${queryParams}`;
    return firstValueFrom(this.http.get<any>(serviceURL));
  }

  getAllWithdrawTransactions(status: string, currentPage: number, pageSize: number): Promise<any>{
    let queryParams = `?transactionType=RETIRO&status=`+status;
    const serviceURL = `${this.API_URL}transaccion${queryParams}&page=${currentPage}&pageSize=${pageSize}`;
    return firstValueFrom(this.http.get<any>(serviceURL));
  }

  getTransaccionCliente(currentPage: number, pageSize: number,idCliente: string): Promise<any>{
    const serviceURL = `${this.API_URL}transaccion/${idCliente}?page=${currentPage}&pageSize=${pageSize}`;
    return firstValueFrom(this.http.get<any[]>(serviceURL));
  }

  getTransaccionClienteByDate(idCliente: string, startDate: string, endDate: string): Promise<TransactionUserDTO[]>{
    const serviceURL = `${this.API_URL}transaccion/${idCliente}?startDate=${startDate}&endDate=${endDate}`;
    return firstValueFrom(this.http.get<TransactionUserDTO[]>(serviceURL));
  }
  asignTransactions(asignTransactions: AssignTransactionsDTO): Promise<{mensaje:string}>{
    const serviceURL = `${this.API_URL}transaccion/assign-transactions`;
    return firstValueFrom(this.http.post<{mensaje:string}>(serviceURL, asignTransactions));
  }

  getCompletedCount(): Promise<{any: any}>{
    const serviceURL = `${this.API_URL}transaccion/completed-count`;
    return firstValueFrom(this.http.get<any>(serviceURL));
  }

  getPendingCount(): Promise<{any: any}>{
    const serviceURL = `${this.API_URL}transaccion/pending-count`;
    return firstValueFrom(this.http.get<any>(serviceURL));
  }

  acceptTransaction(acceptTransactionDTO: AcceptTransactionDTO):Promise<{mensaje:string}>{
    const serviceURL = `${this.API_URL}transaccion/accept-transaction`;
    return firstValueFrom(this.http.post<{mensaje:string}>(serviceURL, acceptTransactionDTO));
  }

  rejectTransaction(rejectTransactionDTO: RejectTransactionDTO):Promise<{mensaje:string}>{
    const serviceURL = `${this.API_URL}transaccion/reject-transaction`;
    return firstValueFrom(this.http.post<{mensaje:string}>(serviceURL, rejectTransactionDTO));
  }
  editTransaction(editTransactionDTO: EditTransactionDTO):Promise<{mensaje:string}>{
    const serviceURL = `${this.API_URL}transaccion/edit-transaction`;
    return firstValueFrom(this.http.post<{mensaje:string}>(serviceURL, editTransactionDTO));
  }
  changeAssignationStatus(payload: any){
   const serviceURL = `${this.API_URL}transaccion/change-transaction-assignation`;
   return firstValueFrom(this.http.post(serviceURL, payload));
  }

  setPurchaseValue(payload: any){
    const serviceURL = `${this.API_URL}transaccion/set-purchase-value`;
    return firstValueFrom(this.http.post(serviceURL, payload));
  }

  assignUnassignedTrx(cartola: any): Promise<any>{
    const serviceURL = `${this.API_URL}transaccion/assignUnassigned`;
    return firstValueFrom(this.http.post<any>(serviceURL, cartola));
  }

   reportTransactions(date:string){
    const serviceURL = `${this.API_URL}reportTransactions?type=reportTransactions`;
    const body = {
      fechaReporte: date
    };
    return firstValueFrom(this.http.post(serviceURL, body));
   }

   reportCreditLine(){
    const serviceURL = `${this.API_URL}reportTransactions?type=reportCreditLine`;
    const body = {
    };
    return firstValueFrom(this.http.post(serviceURL, body));
   }
}
