import * as moment from "moment-timezone";
import { Monedas } from "src/app/shared/enums/monedas";
import { obtenerMonedas } from "src/app/shared/utils/obtenerMonedas";
import { CurrencyPipe } from '@angular/common';


export function getColumns(currencyPipe: CurrencyPipe) {
  return [
    {
      columnDef: 'codigoTransferencia',
      header: 'Referencia',
      cell: (element: any) => `${element.codigoTransferencia}`,
      cellStyle: { 'left': '0','text-align':'left','min-width':'120px', 'width':'140px'},
      headerStyle: { 'left': '0','text-align':'left', 'min-width':'120px', 'width':'140px'},
    },
    {
      columnDef: 'bancoOrigen',
      header: 'Banco Origen',
      cell: (element: any) => `${element.bancoOrigen }`,
      cellStyle: { 'left': '0','text-align':'left','min-width':'140px', 'width':'150px'},
      headerStyle: { 'left': '0','text-align':'left', 'min-width':'140px', 'width':'150px'},
    },
    {
      columnDef: 'tipoCuentaOrigen',
      header: 'Tipo cuenta origen',
      cell: (element: any) => `${element.tipoCuentaOrigen }`,
      cellStyle: { 'left': '0','text-align':'left','min-width':'140px', 'width':'150px'},
      headerStyle: { 'left': '0','text-align':'left', 'min-width':'140px', 'width':'150px'},
    },
    {
      columnDef: 'numeroCuentaOrigen',
      header: 'Cuenta origen',
      cell: (element: any) => `${element.numeroCuentaOrigen }`,
      cellStyle: { 'left': '0','text-align':'left','min-width':'140px', 'width':'150px'},
      headerStyle: { 'left': '0','text-align':'left', 'min-width':'140px', 'width':'150px'},
    },
    {
      columnDef: 'numeroCuentaDestino',
      header: 'Cuenta destino',
      cell: (element: any) => `${element.cuentaCorriente }`,
      cellStyle: { 'left': '0','text-align':'left','min-width':'140px', 'width':'150px'},
      headerStyle: { 'left': '0','text-align':'left', 'min-width':'140px', 'width':'150px'},
    },
    {
      columnDef: 'rutOrigen',
      header: 'Rut origen',
      cell: (element: any) => `${element.rutOrigen  }`,
      cellStyle: { 'left': '0','text-align':'left','min-width':'140px', 'width':'150px'},
      headerStyle: { 'left': '0','text-align':'left', 'min-width':'140px', 'width':'150px'},
    },

    {
      columnDef: 'nombreOrigen',
      header: 'Nombre origen',
      cell: (element: any) => `${element.nombreOrigen}`,
      cellStyle: { 'left': '0','text-align':'left','min-width':'140px', 'width':'150px'},
      headerStyle: { 'left': '0','text-align':'left', 'min-width':'140px', 'width':'150px'},
    },
    {
      columnDef: 'fechaContable',
      header: 'Fecha contable',
      cell: (element: any) => `${element.fechaContable}`,
      cellStyle: { 'left': '0','text-align':'left','min-width':'140px', 'width':'150px'},
      headerStyle: { 'left': '0','text-align':'left', 'min-width':'140px', 'width':'150px'},
    },
    {
      columnDef: 'hora',
      header: 'Hora',
      cell: (element: any) => `${element.hora} `,
      cellStyle: { 'left': '0','text-align':'left','min-width':'140px', 'width':'150px'},
      headerStyle: { 'left': '0','text-align':'left', 'min-width':'140px', 'width':'150px'},
    },

    {
      columnDef: 'moSaldoCargo',
      header: 'Cargo',
      cell: (element: any) => `${currencyPipe.transform(element.moSaldoCargo, '$')}`,
      cellStyle: { 'left': '0','text-align':'left','min-width':'140px', 'width':'150px'},
      headerStyle: { 'left': '0','text-align':'left', 'min-width':'140px', 'width':'150px'},
    },
    {
      columnDef: 'moSaldoAbono',
      header: 'Abono',
      cell: (element: any) => `${currencyPipe.transform(element.moSaldoAbono, '$')}`,
      cellStyle: { 'left': '0','text-align':'left','min-width':'140px', 'width':'150px'},
      headerStyle: { 'left': '0','text-align':'left', 'min-width':'140px', 'width':'150px'},
    },
  ]
}

export const  columns = [
  {
    columnDef: 'codigoTransferencia',
    header: 'ID',
    cell: (element: any) => `${element.codigoTransferencia}`,
  },
  {
    columnDef: 'bancoOrigen',
    header: 'Banco Origen',
    cell: (element: any) => `${element.bancoOrigen }`,
  },
  {
    columnDef: 'tipoCuentaOrigen',
    header: 'Tipo cuenta origen',
    cell: (element: any) => `${element.tipoCuentaOrigen }`,
  },
  {
    columnDef: 'rutOrigen',
    header: 'Rut origen',
    cell: (element: any) => `${element.rutOrigen  }`,
  },

  {
    columnDef: 'nombreOrigen',
    header: 'Nombre origen',
    cell: (element: any) => `${element.nombreOrigen}`,
  },
  {
    columnDef: 'fechaContable',
    header: 'Fecha contable',
    cell: (element: any) => `${element.fechaContable}`,
  },
  {
    columnDef: 'hora',
    header: 'Hora',
    cell: (element: any) => `${element.hora} `,
  },

  {
    columnDef: 'moSaldoCargo',
    header: 'Cargo',
    cell: (element: any) => `${element.moSaldoCargo}`,
  },
  {
    columnDef: 'moSaldoAbono',
    header: 'Abono',
    cell: (element: any) => `${element.moSaldoAbono} `,
  },


];

export const  displayedColumns: string[] = [
  'seleccion',
  'id',
  'ruta',
  'userId',
  'cuentaBeneficiarioId',
  'cuentaTrabajo',
  'beneficiarioDNI',
  'bancoDestino',
  'beneficiarioTipoCuenta',
  'numeroCuenta',
  'totalMonedaDestino',

  'acciones',

];
