<div class="m-4 flex flex-col justify-center">
  <h1 class="m-4 text-xl font-bold text-center">Historial de transacciones de transacción manual</h1>
  <div class="col self-center m-4 text-center">
      <mat-paginator #paginator
                class="demo-paginator self-left"
                (page)="onPageChange($event)"
                [length]="totalItems"
                [pageSize]="pageSize"
                [disabled]="false"
                [showFirstLastButtons]="true"
                [pageSizeOptions]="[5, 10, 25, 50, 100]"
                [hidePageSize]="false"
                [pageIndex]="currentPage-1"
                aria-label="Select page">
    </mat-paginator>

    <button class="text-center" mat-raised-button color="primary" aria-label="Descargar" title="Descargar"
      (click)='printHistorial()'>
      <mat-icon>print</mat-icon>
    </button>
  </div>
  <div class="col self-center m-4 max-h-[450px] overflow-auto">
    <table mat-table [dataSource]="dataSource" class="table-container overflow-y-auto" *ngIf="dataSource" matSort>
      <ng-container
        *ngFor="let column of columns"
        [matColumnDef]="column.columnDef"
      >
        <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        style="background-color: #CEE9C2;"
        [ngStyle]="column.headerStyle"
        >
          {{column.header}}
        </mat-header-cell>
        <mat-cell
        *matCellDef="let row"
        [ngStyle]="column.cellStyle"

        >
          {{ column.cell(row) }}
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </table>
  </div>
</div>
