import { Component, Input,Output, OnDestroy, OnInit, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { CartolaService } from 'src/app/dashboard/services/cartola.service';
import { MultipleExchangeService } from 'src/app/dashboard/services/multiple-exchange.service';
import { TipoPago } from 'src/app/shared/enums/tipo-pago.enum';

@Component({
  selector: 'app-payment-instruction',
  templateUrl: './payment-instruction.component.html',
  styleUrls: ['./payment-instruction.component.css']
})
export class PaymentInstructionComponent implements OnInit, OnDestroy {
  value= `Banco de credito e inversiones (BCI)
  Cuenta corriente
  Parra Aldazoro spa
  27780767
  Rut: 76.661.819-7`;
  valueSecondAccount = `
  Banco de credito e inversiones (BCI)
  Cuenta corriente
  Aguacate Investments SPA
  46590234
  Rut 77258223-4`;
  @Input() valorATransferir: any | undefined;
  @Output() medioPagoSeleccionado: EventEmitter<string> = new EventEmitter();
  @Input() multiple: boolean | undefined;
  subscriptions: Subscription = new Subscription();
  usuarioID: number | undefined;
  medioPago: string | undefined = undefined;
  mediosPago: string[] = [TipoPago.TRANSFERENCIA, TipoPago.WALLET];
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  selectType(){
    this.medioPagoSeleccionado.emit(this.medioPago);
  }
  initFormPago() {
    console.log('entra aqui');
    this.subscriptions.add(
      this.store.select('auth').subscribe(async ({ user }) => {
        this.usuarioID = user?.usuarioId;
        if(this.usuarioID){
          this.saldoUsuario = (await this.cartolaService.getSaldoCliente(String(this.usuarioID))).balancePermitido;
          this.medioPagoSeleccionado.emit(this.medioPago);
        }
      })
    );
  }
  saldoUsuario= 0;

  constructor(private cartolaService: CartolaService,    private store: Store<AppState>, private multipleExchange: MultipleExchangeService
    ) {
      console.log(this.valorATransferir);
      this.initFormPago();
      if(this.multiple === true){
        this.multipleExchange.currentData$.subscribe(res=>{
          //this.valorATransferir = res.map
        })

      }
     }

  ngOnInit() {

  }

}
