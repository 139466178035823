import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { UserService } from '../../services/user.service';
import { Usuario } from '../../interfaces/transaccion.interface';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, SortDirection } from '@angular/material/sort';
import { Sort } from '@angular/material/sort';
import { BalanceSearchFilter } from 'src/app/shared/enums/balance-search-filter.enum';
import { SegmentacionEnum } from 'src/app/shared/enums/segmentacion.enum';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { DialogSegmentationComponent } from './dialog-segmentation/dialog-segmentation.component';
import { User } from 'src/app/shared/models/user.model';
import { BinanceService } from '../../services/binance.service';
import { ParameterService } from '../../services/parameter.service';
import {
  ParameterTypeEnum,
  ParameterValueEnum,
} from 'src/app/shared/enums/parameter-type.enum';
import { ParametrosEnum } from 'src/app/shared/enums/parameters.enum';
import { CurrencyPipe } from '@angular/common';
import { DialogAssignPointsComponent } from './dialog-assign-points/dialog-assign-points.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-segmentation',
  templateUrl: './user-segmentation.component.html',
  template: `<p>user-segmentation works!</p>`,
  styleUrls: ['./user-segmentation.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class UserSegmentationComponent {
  selectedFilter: string = BalanceSearchFilter.ALL;
  userSegmentationFilter: number = SegmentacionEnum.RETAIL;
  selection = new SelectionModel<any>(true, []);
  usdtRate = 0;
  spotPrice = 0;
  filterDescriptions: { [key: string]: string } = {
    [BalanceSearchFilter.ALL]: 'Todos',
    [BalanceSearchFilter.DOCUMENT]: 'Documento',
    [BalanceSearchFilter.EMAIL]: 'Email',
    [BalanceSearchFilter.ID]: 'ID',
    [BalanceSearchFilter.USER_SEGMENTATION]: 'Segmentación de usuario',
  };

  filterSegmentationDescription: { [key: string]: string } = {
    [SegmentacionEnum.RETAIL]: 'Retail',
    [SegmentacionEnum.MAYORISTA]: 'Mayorista',
    [SegmentacionEnum.SUPERMAYORISTA]: 'Super Mayorista',
  };
  filtersSegmentation: number[] = [
    SegmentacionEnum.RETAIL,
    SegmentacionEnum.MAYORISTA,
    SegmentacionEnum.SUPERMAYORISTA,
  ];
  filters: string[] = [
    <string>BalanceSearchFilter.ALL,
    <string>BalanceSearchFilter.DOCUMENT,
    <string>BalanceSearchFilter.USER_SEGMENTATION,
    <string>BalanceSearchFilter.EMAIL,
    <string>BalanceSearchFilter.ID,
  ];
  searchValue: string = '';
  range = new FormGroup({
    start: new FormControl<Date | null>(new Date(), [Validators.required]),
    end: new FormControl<Date | null>(new Date(), [Validators.required]),
  });
  users: Usuario[] = [];
  dataSource: MatTableDataSource<any> | undefined;
  constructor(
    private currency: CurrencyPipe,
    private spinner: SpinnerService,
    private userService: UserService,
    private matDialog: MatDialog,
    private binanceService: BinanceService,
    private parameterService: ParameterService
  ) {}
  ngOnInit(): void {
    this.search();
    this.loadConversionData();
  }

  async loadConversionData() {
    this.usdtRate = await this.binanceService
      .getConversionData()
      .then((res) => +res.tasaUsdtClp);
    this.spotPrice = await this.parameterService
      .getParameter(ParametrosEnum.PRECIO_SPOT)
      .then((res) => +res.valor);
    this.search();
  }

  openUserDialog(row: User) {
    const data =
      this.selection.selected.length > 0 ? this.selection.selected : [row];
    this.matDialog
      .open(DialogSegmentationComponent, {
        data: data,
        height: '400px',
        width: '600px',
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.search();
        }
      });
  }

  openAssignPoints(row: User) {
    const data =
      this.selection.selected.length > 0 ? this.selection.selected : [row];
    this.matDialog
      .open(DialogAssignPointsComponent, {
        data: data,
        height: '300px',
        width: '600px',
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.search();
        }
      });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource?.data.length || 0;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    if (this.dataSource) {
      this.dataSource.data.forEach((row) => this.selection.select(row));
    }
  }

  getFilterDescription(value: string) {
    return this.filterDescriptions[value];
  }
  @ViewChild(MatSort) sort: MatSort = new MatSort();

  async search() {
    this.selection.clear();
    const ref = this.spinner.start();
    let searchQuery = this.searchValue;
    if (this.selectedFilter === BalanceSearchFilter.DOCUMENT) {
      searchQuery = searchQuery.replaceAll('.', '').replaceAll('-', '');
    }
    const filter =
      this.selectedFilter === BalanceSearchFilter.USER_SEGMENTATION
        ? String(this.userSegmentationFilter)
        : this.searchValue || BalanceSearchFilter.ALL;
    this.users = await this.userService.searchUsers(
      filter,
      this.selectedFilter.toUpperCase()
    );
    this.dataSource = new MatTableDataSource(this.users);
    this.spinner.stop(ref);
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  sortData(sort: Sort) {
    const data = this.users.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource(data);
    }

    this.users = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'idUsuario':
          return this.compare(a.usuarioId, b.usuarioId, isAsc);
        case 'nombre':
          return this.compare(a.nombre, b.nombre, isAsc);
        case 'documento':
          return this.compare(a.numeroId, b.numeroId, isAsc);
        case 'email':
          return this.compare(a.email, b.email, isAsc);
        default:
          return 0;
      }
    });
    this.dataSource = new MatTableDataSource(this.users);
  }

  displayedColumns: string[] = [
    'select',
    'idUsuario',
    'nombre',
    'email',
    'tipoSegmentacion',
    'puntosSpot',
    'tasaUsuario',
    'montoMinimoTasa',
    'acciones',
  ];

  columns = [
    {
      columnDef: 'idUsuario',
      header: 'Id',
      cell: (element: any) => `${element.usuarioId}`,
      cellStyle: {
        left: '0',
        'text-align': 'left',
        'font-weight': 'bold',
        'min-width': '60px',
        width: '80px',
      },
      headerStyle: {
        left: '0',
        'text-align': 'left',
        'font-weight': 'bold',
        'min-width': '60px',
        width: '80px',
      },
    },
    {
      columnDef: 'nombre',
      header: 'Nombre',
      cell: (element: any) => `${element.nombre} ${element.apellidos} `,
      cellStyle: {
        left: '0',
        'text-align': 'left',
        'min-width': '60px',
        width: '200px',
      },
      headerStyle: {
        left: '0',
        'text-align': 'left',
        'font-weight': 'bold',
        'min-width': '60px',
        width: '200px',
      },
    },
    {
      columnDef: 'documento',
      header: 'Documento',
      cell: (element: any) => `${element.numeroId}  `,
      cellStyle: {
        left: '0',
        'text-align': 'left',
        'min-width': '120px',
        width: '200px',
      },
      headerStyle: {
        left: '0',
        'text-align': 'left',
        'font-weight': 'bold',
        'min-width': '120px',
        width: '200px',
      },
    },
    {
      columnDef: 'email',
      header: 'Email',
      cell: (element: any) => `${element.email}  `,
      cellStyle: {
        left: '0',
        'text-align': 'left',
        'min-width': '60px',
        width: '250px',
      },
      headerStyle: {
        left: '0',
        'text-align': 'left',
        'font-weight': 'bold',
        'min-width': '60px',
        width: '250px',
      },
    },
    {
      columnDef: 'puntosSpot',
      header: 'Puntos Spot',
      cell: (element: any) => `${element.segmentacionUsdtRate}  `,
      cellStyle: {
        left: '0',
        'text-align': 'left',
        'min-width': '60px',
        width: '250px',
      },
      headerStyle: {
        left: '0',
        'text-align': 'left',
        'font-weight': 'bold',
        'min-width': '60px',
        width: '250px',
      },
    },
    {
      columnDef: 'tipoSegmentacion',
      header: 'Tipo Usuario',
      cell: (element: any) =>
        `${this.getTipoUsuario(element.segmentacionId)}  `,
      cellStyle: {
        left: '0',
        'text-align': 'left',
        'min-width': '60px',
        width: '250px',
      },
      headerStyle: {
        left: '0',
        'text-align': 'left',
        'font-weight': 'bold',
        'min-width': '60px',
        width: '250px',
      },
    },
    {
      columnDef: 'tasaUsuario',
      header: 'Tasa Usuario',
      cell: (element: any) =>
        `${this.currency.transform(this.getTasaUsuario(element), '$')}  `,
      cellStyle: {
        left: '0',
        'text-align': 'left',
        'min-width': '60px',
        width: '250px',
      },
      headerStyle: {
        left: '0',
        'text-align': 'left',
        'font-weight': 'bold',
        'min-width': '60px',
        width: '250px',
      },
    },
  ];

  updateMinAmount(user: Usuario) {
    Swal.fire({
      title: '¿Desea actualizar el monto mínimo de la tasa?',
      showCancelButton: true,
      confirmButtonText: 'Actualizar',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        const ref = this.spinner.start();
        return this.userService
          .updateUserSegmentation([user.usuarioId], undefined, undefined, user.minimumTresholdUsdt)
          .then((res) => {
            this.spinner.stop(ref);
            this.search();
          })
          .catch((err: any) => {
            this.spinner.stop(ref);
            Swal.showValidationMessage(`Error al actualizar el monto mínimo`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading,
    });
  }

  getTasaUsuario(usuario: Usuario) {
    switch (usuario.segmentacionId) {
      case SegmentacionEnum.RETAIL:
        return this.usdtRate;
      case SegmentacionEnum.MAYORISTA:
        return +this.spotPrice + +usuario.segmentacionUsdtRate;
      case SegmentacionEnum.SUPERMAYORISTA:
        return +this.spotPrice + +usuario.segmentacionUsdtRate;
      default:
        return this.usdtRate;
    }
  }

  getTipoUsuario(tipoUsuario: number) {
    switch (tipoUsuario) {
      case SegmentacionEnum.RETAIL:
        return 'Retail';
      case SegmentacionEnum.MAYORISTA:
        return 'Mayorista';
      case SegmentacionEnum.SUPERMAYORISTA:
        return 'Super Mayorista';
      default:
        return 'Desconocido';
    }
  }
}
