import { Component, OnDestroy } from '@angular/core';
import { BinanceService } from '../../services/binance.service';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-rates',
  templateUrl: './rates.component.html',
  styleUrls: ['./rates.component.sass']
})
export class RatesComponent implements OnDestroy {
  currentRates: any;
  currentValues: any;
  private subscription: Subscription = new Subscription();
  private intervalToRefreshValue = interval(60000);
  constructor(private binanceService: BinanceService, ){
    this.subscription.add(
      this.intervalToRefreshValue.subscribe(()=>{
        this.getCurrentValues();
        this.getCurrentRates();
      })
    );
    this.getCurrentRates();
    this.getCurrentValues();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  async getCurrentRates(){
    this.currentRates = await this.binanceService.getCurrentRates();
  }
  async getCurrentValues(){
    this.currentValues = await this.binanceService.getConversionData();
  }
}
