<div class="m-4 overflow-x-auto text-center">
    <h1 class="m-4 text-2xl font-bold"> Cuentas Activas </h1>
    <div class="mt-8 table-container overflow-auto max-w-[1000px] mx-auto">
        <mat-table [dataSource]="dataSource" *ngIf="dataSource">
            <ng-container
            *ngFor="let column of columns"
            [matColumnDef]="column.columnDef"
            >
                <mat-header-cell
                *matHeaderCellDef
                style="background-color: #CEE9C2;"
                [ngStyle]="column.headerStyle"
                >
                    {{column.header}}
                </mat-header-cell>

                <mat-cell
                *matCellDef="let row"
                [ngStyle]="column.cellStyle"
                >
                    {{column.cell(row)}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="acciones" stickyEnd>
                <mat-header-cell
                    *matHeaderCellDef
                    aria-label="row actions"
                    class="text-center font-bold min-w-[150px] align-center"
                    style="background-color: #CEE9C2; max-width:200px;"
                >
                    Disponibilidad
                </mat-header-cell>
                <mat-cell *matCellDef="let element" style="text-align: center; max-width:200px; min-width:150px;">
                    <mat-slide-toggle
                    [checked]="element.disponible === true"
                    (change)="updateAccountStatus(element.id, $event.checked ? true : false)"
                    color="primary">
                    {{ element.disponible === true ? 'Disponible' : 'No disponible' }}
                    </mat-slide-toggle>
                </mat-cell>
                </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
    </div>
  </div>

