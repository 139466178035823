import { Component, EventEmitter, Output, Input, OnInit  } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddBeneficiaryComponent } from '../add-beneficiary/add-beneficiary.component';
import { RecipientCriptoService } from 'src/app/dashboard/services/recipient-cripto.service';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { BeneficiarioCriptomoneda } from 'src/app/dashboard/interfaces/beneficiario-criptomoneda.interface';

@Component({
  selector: 'app-beneficiary',
  templateUrl: './beneficiary.component.html',
  styleUrls: ['./beneficiary.component.sass']
})
export class BeneficiaryComponent implements OnInit{

  public subscription: Subscription = new Subscription();
  public user: any;
  public searchTxt: string = '';
  public wordFilter: string = '';

  constructor(
    private dialog: MatDialog,
    private recipientcriptoservice: RecipientCriptoService,
    private store: Store<AppState>,
  ){}

  ngOnInit(): void {
    this.subscription.add(
      this.store.select('auth').subscribe(({ user }: any) => {
        this.user = user;
        this.getBeneficiarios();
      })
    );
  }

  @Output()
  public changeStep = new EventEmitter<number>();

  @Output()
  public beneficiarioOutput = new EventEmitter<BeneficiarioCriptomoneda>();

  @Output()
  public goBack = new EventEmitter<any>();

  public beneficiarioEscogido:BeneficiarioCriptomoneda | undefined = undefined;

  public listaBeneficiarios:BeneficiarioCriptomoneda[] = [];
  public listaBeneficiariosFiltrados:BeneficiarioCriptomoneda[] = [];

  openCreateDialog(){

    const dialogRef = this.dialog.open(AddBeneficiaryComponent, {
      data: {
        userID: this.user?.usuarioId,
        type: 'CREATE',
      },
      disableClose: true,
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(async result => {
      if(result == true ){
        this.getBeneficiarios();
      }
    })

  }

  clearInput() {
    this.searchTxt = '';
  }

  elegir( beneficiario: BeneficiarioCriptomoneda ):void{
    if(this.beneficiarioEscogido === beneficiario ){
      this.beneficiarioEscogido = undefined;
      return;
    }
    this.beneficiarioEscogido = beneficiario;
  }

  emitChangeStep( ): void{
    this.changeStep.emit( 3 );
    this.beneficiarioOutput.emit( this.beneficiarioEscogido );
  }

  async getBeneficiarios(): Promise<void>{
    this.listaBeneficiarios = await this.recipientcriptoservice.getByUser(this.user?.usuarioId);
    this.listaBeneficiariosFiltrados = this.listaBeneficiarios;
  }

  buscar(event: KeyboardEvent): void {
    var searchWord = this.searchTxt.toLowerCase();
    if(searchWord === '') {
      this.listaBeneficiariosFiltrados = this.listaBeneficiarios;
      return;
    }
    this.listaBeneficiariosFiltrados = this.listaBeneficiarios.filter( beneficiario => {
      let nombreCompleto = beneficiario.beneficiario_nombre + " " + beneficiario.beneficiario_apellido;
      return nombreCompleto.toLowerCase().includes(searchWord.toLowerCase());
    }
    )
  }

  goToBack(): void {
    this.goBack.emit();
  }

}
