import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';1

@Component({
  selector: 'app-dialog-beneficiary-zelle',
  templateUrl: './dialog-beneficiary-zelle.component.html',
  styleUrls: ['./dialog-beneficiary-zelle.component.sass']
})
export class DialogBeneficiaryZelleComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogBeneficiaryZelleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ){
  }

  onNoClick(){
    this.dialogRef.close()
  }
}
