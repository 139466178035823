<div class="container_change">
  <app-zelle-change [zelle_buy_rate]="zelle_buy_rate" [zelle_sell_rate]="zelle_sell_rate" [showBuy]="false" class="zelleChange"></app-zelle-change>
    <div class="text-center">
      <h2><b>Saldo actual: </b> ${{saldoActual | currency:'':''}} CLP</h2>
      <mat-error *ngIf="lineaCreditoBloqueada">Su linea de credito se encuentra actualmente bloqueada</mat-error>
      <div *ngIf="lineaCredito!>0&&!lineaCreditoBloqueada">
      <h2><b>Saldo contable: </b> ${{saldoContable | currency:'':''}} CLP</h2>
      <h2><b>Sobregiro disponible: </b> ${{sobregiroDisponible | currency:'':''}} CLP</h2>

      </div>
      <h2 class="font-bold">Horario de operación: 9:00 - 17:00 </h2>
      <h3 class="text-red-700">Los pagos en bolívares y Zelle estarán disponibles hasta hoy a las 5 p.m. y se reactivarán el lunes 23/09/24. Los demás servicios de la plataforma funcionarán con normalidad durante el feriado.</h3>
    </div>
    <div class="mt-10">
      <div class="grid grid-cols-1 send-input">
        <input
          type="text"
          [(ngModel)]="valorClp"
          (keyup)="transformCurrency('clp')"
          placeholder="$"
          pattern="[0-9]*"
          (keypress)="soloNumeros($event)"
          maxlength="8"
          >
        <span class="button-inline flex flex-row items-center">
          <button *ngIf="valorClp!=''" class="close-btn hover:border-gray-700/50 border-transparent border-1"(click)="clearValAvailableCLP()">✕</button>
          <button class="max-btn hover:bg-aguacate-highlight" (click)="maxValAvailableCLP()">MÁX.</button>
        </span>
        <span>Tu envias CLP</span>
        <mat-error *ngIf="lineaCredito === null  && clpCurrency > saldoActual">El monto de envio excede el saldo actual</mat-error>
        <mat-error *ngIf="lineaCredito! > 0 && clpCurrency > saldoContable!">El monto de envio excede el saldo disponible</mat-error>

      </div>
      <img src="assets/img/transaccion.png" class="mb-5">
      <div class="grid grid-cols-1">
        <input
          type="text"
          [(ngModel)]="valorZelle"
          (keyup)="transformCurrency('zelle')"
          placeholder="$"
          pattern="[0-9]*"
          (keypress)="soloNumeros($event)"
          maxlength="8"
        >
        <span>Tu recibes USD Zelle</span>
        <mat-error *ngIf="minError">El monto mínimo para enviar es de {{minValue}} USDT</mat-error>
        <mat-error *ngIf="maxError">El monto maximo para enviar es de {{maxValue}} USDT</mat-error>
      </div>
    </div>
  </div>
  <div class="text-center">
    <button [disabled]="clpCurrency === 0 || zelleCurrency === 0 || (lineaCredito === null  && clpCurrency > saldoActual) || (lineaCredito! > 0 && clpCurrency > saldoContable!) || lineaCreditoBloqueada" (click)="emitChangeStep()" mat-raised-button color="primary" class="mt-10 p-5 text-lg">
      Comprar
    </button>
  </div>
