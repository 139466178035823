import { Component,OnInit,Input,Output,EventEmitter } from '@angular/core';


@Component({
  selector: 'app-balance-change',
  templateUrl: './balance-change.component.html',
  styleUrls: ['./balance-change.component.sass']
})
export class BalanceChangeComponent implements OnInit{

  constructor() {}

  @Input()
  public saldoActual:number = 0;

  @Input()
  public usdt_clp_tax: number = 0;

  @Output()
  public changeStep = new EventEmitter<number>();

  public hasLoaded:boolean =  true;

  ngOnInit(): void {}

  emitChangeStep(): void{
    this.changeStep.emit( 1 )
  }

}
