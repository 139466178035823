<ng-container *ngIf="hasAccounts">
  <mat-stepper (selectionChange)="onStepChange($event)" #stepper class="pt-4 py-4" [orientation]="isVertical ? 'vertical': 'horizontal'" [linear]="true">
    <mat-step [completed]="stepPass2 && (montoRetiro !== 0)">
      <ng-template matStepLabel>Calcula tu retiro</ng-template>
      <div class="w-full md:w-1/2 mx-auto mt-10">
        <app-calculator-withdraw #calculatorComponent (montoRetiroEmit)="assignMontoRetiro($event)" (changeStep)="nextStep($event)" [saldoActual]="saldoActual"></app-calculator-withdraw>
      </div>
    </mat-step>

    <mat-step [completed]="stepPass3 && withdrawAccountsStepComponent!.accountSelected != undefined">
      <ng-template matStepLabel>Elige tu cuenta de retiro</ng-template>
      <app-withdraw-accounts-step
        #beneficiarioComponent
        (changeStep)="nextStep($event)"
        (beneficiarioOutput)="assignAccount($event)"
        (goBack)="backStep()"></app-withdraw-accounts-step>
    </mat-step>
    
    <mat-step [completed]="stepPass4">
      <ng-template matStepLabel>Resumen de retiro</ng-template>
      <app-withdraw-summary-order
        [montoRetiro]="montoRetiro"
        [account]="accountSelected"
        [usuarioId]="userId"
        (goBack)="backStep()"
        ></app-withdraw-summary-order>
    </mat-step>
  </mat-stepper>
  
</ng-container>

