import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';
import { DatePipe } from '@angular/common';
import { CuentaRetiro } from '../interfaces/cuenta-retiro.interface';

@Injectable({
  providedIn: 'root'
})
export class WithdrawAccountService {

  API_URL = environment.apiGatewayURL;

  constructor(private http: HttpClient, private datePipe: DatePipe) {}

  saveWithdrawAccount(withdrawAccount: CuentaRetiro, userId?: string): Promise<CuentaRetiro> {
    const query = userId ? `?userId=${userId}` : '';
    const serviceURL = `${this.API_URL}account/withdrawal-account${query}`;
    return firstValueFrom(this.http.post<CuentaRetiro>(serviceURL,withdrawAccount));
  }

  getWithdrawAccounts(): Promise<CuentaRetiro[]>{
    const serviceURL = `${this.API_URL}account/withdrawal-account`;
    return firstValueFrom(this.http.get<CuentaRetiro[]>(serviceURL));
  }

  inactivateAccount(cuentaId: number): Promise<any>{
    const serviceURL = `${this.API_URL}account/withdrawal-account/${cuentaId}`;
    return firstValueFrom(this.http.delete(serviceURL,));
  }

}
