<div class="p-6 space-y-4 md:space-y-6 sm:p-8">
  <div class=" flex flex-col items-center">
    <h1 class="text-xl font-bold">
      Abonos / débitos
    </h1>
  </div>
  <form
    (ngSubmit)="guardarBeneficiario()"
    *ngIf="formCuenta"
    class="space-y-4 md:space-y-6"
    [formGroup]="formCuenta"
  >
  <p><mat-checkbox formControlName="interna">Interna</mat-checkbox></p>
    <div class="grid grid-cols-1 gap-4">
      <mat-form-field class="col-span-1">
        <mat-label>Monto</mat-label>
        <input
          type="text"
          matInput
          appOnlynumber
          formControlName="monto"
          placeholder="Ingrese el monto"
        />
         <mat-error
          *ngIf="
            formCuenta.get('monto')?.invalid &&
            (formCuenta.get('monto')?.dirty ||
              formCuenta.get('monto')?.touched)
          "
        >
          Campo obligatorio
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-span-1">
        <mat-label>Precio compra</mat-label>
        <input
          appOnlynumber
          type="text"
          matInput
          formControlName="precioCompra"
          placeholder="Ingrese el valor"
        />
      </mat-form-field>
      <mat-form-field class="col-span-1">
        <mat-label>Tipo de transaccion</mat-label>
        <mat-select required formControlName="tipoTransaccion">
          <mat-option>Seleccione uno</mat-option>
          <mat-option
            *ngFor="let tipo of tiposTransaccion"
            [value]="tipo"
          >
            {{ tipo | titlecase }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-span-1">
        <mat-label>Tipo de abono/débito</mat-label>
        <mat-select required formControlName="tipoAbonoDebito">
          <mat-option>Seleccione uno</mat-option>
          <mat-option
            *ngFor="let tipo of tipos"
            [value]="tipo"
          >
            {{ tipo | titlecase }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>País</mat-label>
        <mat-select
          required
          formControlName="pais"
          (selectionChange)="getBanks()"
        >
          <mat-option>Selecione país</mat-option>
          <mat-option
            *ngFor="let pais of paisesActivos"
            [value]="pais.codigoPais"
          >
            {{ pais.nombre | titlecase }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Cuenta</mat-label>
        <mat-select
          required
          formControlName="cuenta"

        >
          <mat-option>Selecione cuenta</mat-option>
          <mat-option
            *ngFor="let cuenta of cuentasTrabajo"
            [value]="cuenta.cuenta_trabajo_id"
          >
            {{ cuenta.nombre  | titlecase }}  {{ cuenta.apellidos  | titlecase }} {{ cuenta.banco?.nombre}} {{cuenta.numeroCuenta}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-span-1">
        <mat-label>Comentarios</mat-label>
        <input
          type="text"
          matInput
          formControlName="comentario"
          placeholder="Ingrese un comentario"
        />
        <mat-error *ngIf="this.formCuenta.get('comentario')!.hasError('maxlength')">
          Máximo 120 caracteres
       </mat-error>
      </mat-form-field>
    </div>


    <div class=" flex flex-row gap-4 items-center justify-center">
      <button mat-raised-button type="submit" color="primary">Guardar</button>
    </div>
  </form>
</div>
