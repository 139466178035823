<div class="m-4 flex flex-col overflow-x-auto">
  <h1 class="md:m-4 text-xl font-bold text-left md:text-center  justify-start md:justify-center">Movimientos de Usuario
  </h1>
  <div class="grid grid-cols-1 md:grid-cols-2 items-center">
    <div class="md:ml-10  text-left self-start">
      <h2>
        Nombre: {{userInfo?.nombre}} {{userInfo?.apellidos}}
      </h2>
      <h2>
        Id: {{userInfo?.usuarioId}}
      </h2>
      <h2>Documento: {{userInfo?.tipoId}} - {{formatDocument()}} </h2>
      <h2>Email: {{userInfo?.email}} </h2>
      <h2>Saldo: {{this.saldoUsuario | currency: ''}} </h2>
      <div *ngIf="lineaCreditoDisponible">
        <h2><em>Linea de credito disponible</em></h2>
        <h2>Saldo contable: ${{saldoContable | currency:'':''}} CLP</h2>
        <h2>Sobregiro disponible: ${{sobregiroDisponible | currency:'':''}} CLP</h2>
      </div>
      <div *ngIf="lineaCreditoBloqueada">
        <h2><em>Linea de credito bloqueada</em></h2>
        <h2>Saldo contable: ${{saldoContable | currency:'':''}} CLP</h2>
        <h2>Sobregiro bloqueado: ${{sobregiroDisponible | currency:'':''}} CLP</h2>
      </div>
    </div>
    <div
      class="md:w-4/5 w-full self-start flex flex-row flex-wrap gap-5 md:ml-10 md:items-center justify-start lg:flex-nowrap md:justify-around md:align-middle mt-4">
      <mat-form-field class="w-1/2 search-date">
        <mat-label>Ingresa un rango de fechas</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input matStartDate formControlName="start" placeholder="Start date">
          <input matEndDate formControlName="end" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Fecha fin invalida</mat-error>
      </mat-form-field>
      <button mat-raised-button color="primary" (click)="search()"
        class="self-start mt-[1px] h-[56px]  md:w-2/6 button-search">
        Buscar
        <mat-icon> search </mat-icon>
      </button>
      <button mat-raised-button color="primary" (click)="downloadFile()"
        class="self-start mt-[1px] h-[56px]  md:w-2/6 button-search">
        Descargar
        <mat-icon> download </mat-icon>
      </button>
    </div>
  </div>
  <div class="flex flex-row flex-wrap justify-start my-8 md:m-4 no-wrap gap-4" *ngIf="this.validUser">
    <button mat-fab extended color="secondary" matTooltip="Agregar Saldo" aria-label="Agregar Saldo" (click)="agregar()"
      class="text-aguacate-green h-[56px] w-1/5 md:w-[160px] button-search leading-6">
      <span class="hidden md:block">Agregar Saldo </span>
      <mat-icon class="">add</mat-icon>
      <mat-icon class=""> attach_money </mat-icon>
    </button>
    <button mat-fab extended color="secondary" matTooltip="Debitar Saldo" aria-label="Debitar Saldo" (click)="debitar()"
      class="text-aguacate-green h-[56px] w-1/5 md:w-[160px] button-search leading-6">
      <span class="hidden md:block"> Debitar Saldo </span>
      <mat-icon class=""> arrow_downward </mat-icon>
      <mat-icon class=""> attach_money </mat-icon>
    </button>
    <button mat-fab extended color="secondary" (click)="quitar()" aria-label="Quitar Saldo" matTooltip="Quitar Saldo"
      class="text-aguacate-green h-[56px] w-1/5 md:w-[160px] button-search leading-6">
      <span class="hidden md:block">Quitar Saldo </span>
      <mat-icon class=""> clear </mat-icon>
      <mat-icon class=""> attach_money </mat-icon>
    </button>
    <button mat-fab extended color="secondary" (click)="desactivarLineaCredito()" aria-label="Borrar linea de credito"
      matTooltip="Borrar linea de credito"
      class="text-aguacate-green h-[56px] w-1/5 md:w-[195px] button-search leading-6" *ngIf="lineaCreditoDisponible">
      <span class="hidden md:block max-w-[110]">Borrar linea de credito</span>
      <mat-icon class=""> clear </mat-icon>
      <mat-icon class=""> request_quote </mat-icon>
    </button>
    <button mat-fab extended color="secondary" (click)="guardarLineaCredito()" aria-label="linea de credito"
      matTooltip="Modificar linea de credito"
      class="text-aguacate-green h-[56px] w-1/5 md:w-[195px] button-search leading-6">
      <span class="hidden md:block max-w-[110]">linea de credito</span>
      <mat-icon class="" *ngIf="lineaCreditoDisponible"> edit </mat-icon>
      <mat-icon class="" *ngIf="!lineaCreditoDisponible"> add </mat-icon>
      <mat-icon class=""> request_quote </mat-icon>
    </button>
  </div>
</div>
<div class="overflow-auto">
  <table mat-table *ngIf="dataSource" [dataSource]="dataSource" multiTemplateDataRows class="mt-4">
    <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
      <th mat-header-cell *matHeaderCellDef style="text-align:left">{{column.header}}</th>
      <td mat-cell *matCellDef="let row" style="text-align:left">{{column.cell(row)}}</td>
    </ng-container>
    <ng-container matColumnDef="detalle">
      <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button aria-label="detalle row"
          (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
          <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
        <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <mat-card class="w-full">
            <mat-card-content class="ml-2">
              <h3 class=" mb-2 text-lg font-bold"> Detalle del movimiento: </h3>
              <table *ngIf="element.motivo === 'FONDEO WALLET'">
                <p *ngIf="!element.remitente"> Fondeo manual</p>
                <ng-container *ngIf="element.remitente">
                  <tr>
                    <td style="width: 200px;"><span class="font-bold">Fecha de transferencia:</span></td>
                    <td>{{element.fecha_recepcion}}</td>
                  </tr>
                  <tr>
                    <td style="width: 200px;"><span class="font-bold">Hora de transferencia:</span></td>
                    <td>{{element.hora_recepcion}}</td>
                  </tr>
                  <tr>
                    <td style="width: 200px;"><span class="font-bold">Nombre origen:</span></td>
                    <td>{{element.remitente}}</td>
                  </tr>
                  <tr>
                    <td style="width: 200px;"><span class="font-bold">RUT origen:</span></td>
                    <td>{{element.rut_origen}}</td>
                  </tr>
                  <tr>
                    <td style="width: 200px;"><span class="font-bold">Cuenta origen:</span></td>
                    <td>{{element.numeroCuenta}}</td>
                  </tr>
                  <tr>
                    <td style="width: 200px;"><span class="font-bold">Codigo de transferencia:</span></td>
                    <td>{{element.codigo_transferencia}}</td>
                  </tr>
                  <ng-container *ngIf="element.cuentaDestino">
                    <tr>
                      <td style="width: 200px;"><span class="font-bold">Cuenta destino:</span></td>
                      <td>{{element.cuentaDestino === "27780767"? "Parra Aldazoro SpA":"Aguacate Investments SpA"}}</td>
                    </tr>
                  </ng-container>
                </ng-container>
              </table>
              <table *ngIf="element.tipoTransaccion === 'CRIPTO'">
                <tr>
                  <td style="width: 200px;"><span class="font-bold">Beneficiario:</span></td>
                  <td>{{element.beneficiario}}</td>
                </tr>
                <tr>
                  <td style="width: 200px;"><span class="font-bold">Tasa de negociación:</span></td>
                  <td>{{element.tn}}</td>
                </tr>
                <tr>
                  <td style="width: 200px;"><span class="font-bold">Monto CLP:</span></td>
                  <td>${{element.monedaOrigen | number:'1.0-2' }}</td>
                </tr>
                <tr>
                  <td style="width: 200px;"><span class="font-bold">Monto USDT:</span></td>
                  <td>${{element.monedaDestino | number:'1.0-2' }}</td>
                </tr>
                <tr>
                  <td style="width: 200px;"><span class="font-bold">Wallet TRC20:</span></td>
                  <td>{{element.walletTRC20}}</td>
                </tr>
                <tr>
                  <td style="width: 200px;"><span class="font-bold">Estado:</span></td>
                  <td>{{element.estadoActual}}</td>
                </tr>
              </table>
              <table *ngIf="element.tipoTransaccion === 'ZELLE'">
                <tr>
                  <td style="width: 200px;"><span class="font-bold">Beneficiario:</span></td>
                  <td>{{element.beneficiarioZelle}}</td>
                </tr>
                <tr>
                  <td style="width: 200px;"><span class="font-bold">Tasa de negociación:</span></td>
                  <td>{{element.tn}}</td>
                </tr>
                <tr>
                  <td style="width: 200px;"><span class="font-bold">Monto CLP:</span></td>
                  <td>${{element.monedaOrigen | number:'1.0-2' }}</td>
                </tr>
                <tr>
                  <td style="width: 200px;"><span class="font-bold">Monto USD:</span></td>
                  <td>${{element.monedaDestino | number:'1.0-2' }}</td>
                </tr>
                <tr>
                  <td style="width: 200px;"><span class="font-bold">Estado:</span></td>
                  <td>{{element.estadoActual}}</td>
                </tr>
              </table>
              <table *ngIf="element.tipoTransaccion === 'FIAT'">
                <tr>
                  <td style="width: 200px;"><span class="font-bold">Beneficiario:</span></td>
                  <td>{{element.beneficiario}}</td>
                </tr>
                <tr>
                  <td style="width: 200px;"><span class="font-bold">Banco:</span></td>
                  <td>{{element.banco}}</td>
                </tr>
                <tr>
                  <td style="width: 200px;"><span class="font-bold">Tasa de negociación:</span></td>
                  <td>{{element.tn}}</td>
                </tr>
                <tr>
                  <td style="width: 200px;"><span class="font-bold">Monto CLP:</span></td>
                  <td>${{element.monedaOrigen | number:'1.0-2' }}</td>
                </tr>
                <tr>
                  <td style="width: 200px;"><span class="font-bold">Monto VES:</span></td>
                  <td>${{element.monedaDestino | number:'1.0-2' }}</td>
                </tr>
                <tr>
                  <td style="width: 200px;"><span class="font-bold">Estado:</span></td>
                  <td>{{element.estadoActual}}</td>
                </tr>
              </table>
              <table *ngIf="element.tipoTransaccion === 'RETIRO'">
                <tr>
                  <td style="width: 200px;"><span class="font-bold">Alias:</span></td>
                  <td>{{element.aliasRetiro}}</td>
                </tr>
                <tr>
                  <td style="width: 200px;"><span class="font-bold">Numero de cuenta:</span></td>
                  <td>{{element.numeroCuentaRetiro}}</td>
                </tr>
                <tr>
                  <td style="width: 200px;"><span class="font-bold">Banco:</span></td>
                  <td>{{element.banco}}</td>
                </tr>
                <tr>
                  <td style="width: 200px;"><span class="font-bold">Monto:</span></td>
                  <td>${{element.monedaDestino | number:'1.0-2' }}</td>
                </tr>
                <tr>
                  <td style="width: 200px;"><span class="font-bold">Estado:</span></td>
                  <td>{{element.estadoActual}}</td>
                </tr>
              </table>
              <table *ngIf="element.motivo.includes('Reversión')">
                <tr>
                  <td style="width: 200px;"><span class="font-bold">Motivo:</span></td>
                  <td>{{element.motivo}}</td>
                </tr>
                <tr>
                  <td style="width: 200px;"><span class="font-bold">Monto:</span></td>
                  <td>${{element.monedaOrigen | number:'1.0-2'}}</td>
                </tr>
              </table>
              <table *ngIf="element.motivo === 'AJUSTE DE BALANCE'">
                <tr>
                  <td style="width: 200px;"><span class="font-bold">Motivo:</span></td>
                  <td>{{element.motivo}}</td>
                </tr>
                <tr>
                  <td style="width: 200px;"><span class="font-bold">Monto:</span></td>
                  <td>${{element.monedaOrigen | number:'1.0-2'}}</td>
                </tr>
                <tr>
                  <td style="width: 200px;"><span class="font-bold">Estado:</span></td>
                  <td>{{element.estadoActual}}</td>
                </tr>
              </table>
            </mat-card-content>
          </mat-card>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
</div>