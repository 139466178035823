<mat-card class="mat-elevation-z0 p-5">
  <div class="flex flex-col md:flex-row gap-5 justify-center items-center ">
    <img [src]="imgUrl" width="60px">
     <div class="flex flex-col items-left">
      <span class="text-xl font-bold" *ngIf="rateOrigin">{{rateOrigin | number : '1.2-6' }}</span>
       <span class="text-xl font-bold">{{rateDestination | number : '1.2-5' }}</span>
      <span class="text-base">{{bank}}</span>
     </div>
  </div>
</mat-card>
