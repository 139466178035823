import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ComentarioCompliance } from 'src/app/dashboard/interfaces/comentario-compliance';
import { UserService } from 'src/app/dashboard/services/user.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import Swal from 'sweetalert2';
import { ComplianceDialogComponent } from '../../compliance-user/compliance-dialog/compliance-dialog.component';
import { PagadorService } from 'src/app/dashboard/services/pagador.service';

@Component({
  selector: 'app-reject-compliance-pagador',
  templateUrl: './reject-compliance-pagador.component.html',
  styleUrls: ['./reject-compliance-pagador.component.sass']
})
export class RejectCompliancePagadorComponent {
  formCompliance: FormGroup;
  comentariosCompliance: ComentarioCompliance[] = [];
  constructor(
  public dialogRef: MatDialogRef<RejectCompliancePagadorComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any, private fb: FormBuilder,   private pagadorService: PagadorService,  private spinner: SpinnerService,
    private dialog: MatDialog) {
      this.comentariosCompliance = data.comentariosCompliance;
      this.comentariosCompliance = this.comentariosCompliance.filter(res=> res.comentarioID !==0)
      this.formCompliance = this.fb.group({
        comentarioCompliance: new FormControl(
          null,
          [Validators.required]
        ),
    })
  }

    onNoClick(){
      this.dialogRef.close()
    }

    rechazarUsuario(){
      if(this.formCompliance.get('comentarioCompliance')?.invalid)return;

      const ref = this.spinner.start();
      this.pagadorService
      .updateUserCompliance(
        this.data.pagadorId,
        this.data.userEmail,
       {comentarioCompliance: this.formCompliance.get('comentarioCompliance')?.value,
       estadoCompliance: 1}
      )
      .subscribe(
        (res) => {
          Swal.fire({
            icon: 'success',
            title: 'Compliance actualizado',
            text: 'Se ha rechazado el pagador',
            showCancelButton: false,
            showDenyButton: false,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#1e5a3f',

          });
          this.spinner.stop(ref);
          this.dialogRef.close(true);
        },
        (err) => {
          this.spinner.stop(ref);
          this.dialogRef.close(false);

        }


      );
    }
}
