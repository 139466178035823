<div class="p-6 space-y-4 md:space-y-6 sm:p-8 text-center">
    <div class="container flex flex-col items-center">
      <h1 class="text-xl font-bold">
        Actualizar Tasa negociación USDT Zelle
      </h1>
    </div>
    <form
      *ngIf="formCuenta"
      class="space-y-4 md:space-y-6 overflow-y-auto"
      [formGroup]="formCuenta"
    >
    <div class="grid grid-cols-1 gap-4 text-center">
    <p>USDT Aguacate * % de venta = Tasa de venta</p>
    <p>{{data.usdtClpTax}} * {{data.porCompZelle}} = {{data.zelleSellValue | number:'1.0-0'}}</p>
      <mat-form-field>
        <mat-label>Porcentaje de Venta</mat-label>
        <input
          type="text"
          appOnlynumber
          matInput
          formControlName="porcVenta"
        />
        <mat-error *ngIf="formCuenta.get('porcVenta')?.errors?.['greaterThanZero'] === true">Debe ser mayor que 0</mat-error>
        <mat-error *ngIf="formCuenta.get('porcVenta')?.hasError('maxlength')">Debe tener menos de 9 caracteres</mat-error>
      </mat-form-field>
  
    </div>
    <div class="grid grid-cols-1 gap-4 text-center">
        <p>USDT spot - Descuento de compra = Tasa de compra</p>
        <p>{{data.spotPrice}} - {{data.descVentaZelle}} = {{data.zelleBuyValue | number:'1.0-0'}}</p>
          <mat-form-field>
            <mat-label>Descuento de compra</mat-label>
            <input
              type="text"
              appOnlynumber
              matInput
              formControlName="descCompra"
            />
            <mat-error *ngIf="formCuenta.get('descCompra')?.errors?.['greaterThanZero'] === true">Debe ser mayor que 0</mat-error>
            <mat-error *ngIf="formCuenta.get('descCompra')?.hasError('maxlength')">Debe tener menos de 9 caracteres</mat-error>
          </mat-form-field>
      
        </div>
      <div class="container flex flex-col items-center">
        <button
          (click)="actualizarTasas()"
          type="button"
          class="w-1/2 text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        >
          Actualizar
        </button>
        <button
          (click)="close()"
          type="button"
          class="w-1/2 text-white bg-red-700 hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-4"
        >
          Cancelar
        </button>
      </div>
    </form>
  </div>
  