<h1 class="text-2xl font-bold mx-14 my-7" *ngIf="!showOnlyUSDT">Formas de actualización</h1>
<div class="mx-14">
  <ng-container  *ngIf="!showOnlyUSDT">
    <h2 class="text-lg font-bold mb-4">Tasa USDT</h2>
    <mat-button-toggle-group [(ngModel)]="usdtType" (change)="change('ACTUALIZACION_USDT',usdtType)" aria-label="Favorite Color">
      <mat-button-toggle value="AUTOMATICO">Automática</mat-button-toggle>
      <mat-button-toggle value="MANUAL">Manual</mat-button-toggle>
    </mat-button-toggle-group>
  </ng-container>

  <div [class.max-w-full]="showOnlyUSDT" class="rounded-[40px] border-4 shadow-2xl border-lime-900 max-w-[220px] mt-8 text-center">
    <p class="text-2xl font-bold mt-10 my-4 mx-4">{{ autoRate | number:'1.2-2' }} USDT</p>
    <p class="text-md italic mb-4 mx-4">Tasa Automatica</p>
    <button  mat-fab extended color="secondary"  matTooltip="Refrescar Tasa" aria-label="Refrescar Tasa"  (click)="getAutoRate()" class="text-aguacate-green h-[56px] w-1/5 md:w-[160px] button-search leading-6 mb-4">
      <span class="hidden md:block">Refrescar</span>
      <mat-icon class="">refresh</mat-icon>
    </button>
</div>
</div>
<ng-container  *ngIf="!showOnlyUSDT">
  <div class="mx-14 mt-4">
    <h2 class="text-lg font-bold mb-4">Tasa Fiat</h2>
    <mat-button-toggle-group [(ngModel)]="fiatType" (change)="change('ACTUALIZACION_VES',fiatType)" aria-label="Favorite Color">
      <mat-button-toggle value="AUTOMATICO">Automática</mat-button-toggle>
      <mat-button-toggle value="MANUAL">Manual</mat-button-toggle>
    </mat-button-toggle-group>

  </div>
</ng-container>

