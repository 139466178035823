import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ParameterService } from '../../services/parameter.service';
import { ParameterTypeEnum } from 'src/app/shared/enums/parameter-type.enum';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { AutomaticRateService } from '../../services/automatic-rate.service';

@Component({
  selector: 'app-rate-update-type',
  templateUrl: './rate-update-type.component.html',
  styleUrls: ['./rate-update-type.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class RateUpdateTypeComponent {
  usdtType = '';
  fiatType = '';
  autoRate = 0;

  @Input() showOnlyUSDT = false;
  constructor(
    private parameterService: ParameterService,
    private spinnerService: SpinnerService,
    private automaticRate: AutomaticRateService
    ){
    this.getUsdtValue();
    this.getFiatValue();
    this.getAutoRate();
  }

  async getUsdtValue(){
    const ref = this.spinnerService.start();
    const parameter = await this.parameterService.getParameter(ParameterTypeEnum.ACTUALIZACION_USDT);
    this.usdtType = parameter.valor;
    this.spinnerService.stop(ref);
  }
  async getFiatValue(){
    const parameter = await this.parameterService.getParameter(ParameterTypeEnum.ACTUALIZACION_VES);
    console.log(parameter);
    this.fiatType = parameter.valor;
  }

  async change(type: string, value: string){
    const spinner = this.spinnerService.start();
    await this.parameterService.saveParameter(type, value);
    this.spinnerService.stop(spinner);
    }

  async getAutoRate(){
    const ref = this.spinnerService.start();
    const rate = await this.automaticRate.getAutomaticRate();
    this.autoRate = rate.tasa;
    this.spinnerService.stop(ref);
  }

 }
