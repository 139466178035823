import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class BalanceAdjustmentService {
  API_URL = environment.apiGatewayURL;
  constructor(private http: HttpClient) { }

  async addBalance(body: any){
    const serviceURL = `${this.API_URL}balance/add-balance`
    return firstValueFrom(this.http.post(serviceURL, body));
  }

  async debitBalance(body: any){
    const serviceURL = `${this.API_URL}balance/debit-balance`
    return firstValueFrom(this.http.post(serviceURL, body));
  }

  async debitAllBalance(body: any){
    const serviceURL = `${this.API_URL}balance/debit-all-balance`
    return firstValueFrom(this.http.post(serviceURL, body));
  }
}
