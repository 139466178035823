import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import { BinanceService } from 'src/app/dashboard/services/binance.service';
import { ParameterService } from 'src/app/dashboard/services/parameter.service';
import { ParameterTypeEnum } from 'src/app/shared/enums/parameter-type.enum';
import { ParametrosEnum } from 'src/app/shared/enums/parameters.enum';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-set-zelle-rate',
  templateUrl: './set-zelle-rate.component.html',
  styleUrls: ['./set-zelle-rate.component.sass']
})
export class SetZelleRateComponent {
  formCuenta: FormGroup | undefined;
  email: string  = '';
  constructor(
    private fb: FormBuilder,
    private auth: CognitoService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private binanceService: BinanceService,
    private dialogRef: MatDialogRef<SetZelleRateComponent>,
    private spinner: SpinnerService,
    private parameterService: ParameterService,
  ) {
    this.initFormCuenta();
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }
  close(){
    this.dialogRef.close();
  }

  async initFormCuenta() {
    const cognitoUser = await this.auth.getUser();
    this.email = cognitoUser.attributes['email'];
    this.formCuenta = this.fb.group({
      porcVenta: new FormControl( this.data.porCompZelle, [Validators.required, Validators.min(0), Validators.maxLength(9)]),
      descCompra: new FormControl( this.data.descVentaZelle , [Validators.required, Validators.min(0), Validators.maxLength(9)]),
    });
  }

  async actualizarTasas(){
    if(this.formCuenta?.invalid)return;

    if( !this.formCuenta?.get('porcVenta')?.value ){
      console.log(this.formCuenta?.get('porcVenta')?.errors);
      this.formCuenta?.get('porcVenta')?.setErrors({greaterThanZero: true});
      return;
    }
    if( !this.formCuenta?.get('descCompra')?.value ){
      console.log(this.formCuenta?.get('descCompra')?.errors);
      this.formCuenta?.get('descCompra')?.setErrors({greaterThanZero: true});
      return;
    }
    let ref = this.spinner.start();
    try{
      await this.parameterService.saveParameter(ParametrosEnum.PORC_VENTA_ZELLE, this.formCuenta?.get('porcVenta')?.value);
      await this.parameterService.saveParameter(ParametrosEnum.DESCUENTO_COM_ZELLE, this.formCuenta?.get('descCompra')?.value);

      this.spinner.stop(ref);
      Swal.fire('Tasas actualizadas','Se han actualizado los valores', 'success').then(()=>{
        this.dialogRef.close(true);
      });
    }catch(err: any){
      console.log(
        'update error', err.error.message);
      this.spinner.stop(ref);
      if(err.error && err.error.message === 'EXCEEDS_DIFF'){
        Swal.fire('Error','La tasa nueva excede la diferencia del 0.8% respecto a la anterior', 'error').then(()=>{
          this.dialogRef.close(false);
        });
      }else{
        Swal.fire('Error','No se ha podido actualizar la tasa', 'error').then(()=>{
          this.dialogRef.close(false);
        });
      }

    }
  }
}
