import { Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef} from '@angular/material/dialog';
import { SpinnerComponent } from '../components/spinner/spinner.component';
@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  constructor(private dialog: MatDialog) {

  }

  start(message?: string | undefined): MatDialogRef<SpinnerComponent> {

      const dialogRef = this.dialog.open(SpinnerComponent,{
          maxWidth: '600px',
          width: '20vw',
          minWidth: '300px',
          disableClose: true ,
          data: message == ''|| message == undefined ? "Cargando..." : message
      });
      return dialogRef;
    };

  stop(ref:MatDialogRef<SpinnerComponent>){
      ref.close();
  }
}
