import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rate-value-card',
  templateUrl: './rate-value-card.component.html',
  styleUrls: ['./rate-value-card.component.sass']
})
export class RateValueCardComponent {
  @Input() rateOrigin: string | undefined;
  @Input() rateDestination: string | undefined;
  @Input() bank: string | undefined;
  @Input() imgUrl: string | undefined;
}
