<div class="m-8">
    <div class="flex flex-col justify-between items-center">
      <div class="mb-10">
        <h1 class="text-xl font-bold">Resumen del retiro</h1>
      </div>
    </div>
    <div class="containerInfo w-full md:w-1/2 mx-auto">
      <p><span style="font-weight: bold;">Tu retiras:</span> {{ montoRetiro | currency:'':'' }} CLP</p>
      <p><b>Titular</b> - {{ this.account?.alias }}<br></p>
      <p><b>Banco</b> - {{ this.account?.banco }}<br>
      </p>
      <p><b>Tipo de cuenta</b> - {{ this.account?.tipoCuenta }}<br>
      </p>
      <p><b>Numero de cuenta</b> - {{ this.account?.numeroCuenta }}<br>
      </p>
    </div>
    <div class="flex flex-row justify-center items-center mt-10 mx-auto">
      <button
      mat-raised-button color="primary"
      class="w-40 py-7 md:w-30 mb-10 my-4 p-5"
      type="button"
      (click)="goToBack()">
      Volver
      </button>
      <button
        mat-raised-button color="primary"
        class="w-40 py-7 md:w-60 mb-10 my-4 ml-10 p-5"
        type="button"
        (click)="confirmarEnvio()">
        Retirar
      </button>
    </div>
  </div>
  