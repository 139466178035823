import { Component,OnInit } from '@angular/core';
import { RecipientCriptoService } from '../../services/recipient-cripto.service';
import { TransferenciasUSDT } from '../../interfaces/transferencia-usdt.interface';
import { MatDialog } from '@angular/material/dialog';
import { RejectTransactionModalComponent } from '../assigned-transactions/reject-transaction-modal/reject-transaction-modal.component';
import {Clipboard} from '@angular/cdk/clipboard';
import Swal from 'sweetalert2';
import { EditCriptoTransactionComponent } from './edit-cripto-transaction/edit-cripto-transaction.component';
import { SendTransactionaCriptoModalComponent } from './send-transactiona-cripto-modal/send-transactiona-cripto-modal.component';
import { getColumns,displayedColumns } from './table-transaction-cripto-colums';
import { CurrencyPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from 'src/app/shared/services/spinner.service';

@Component({
  selector: 'app-assigned-transactions-cripto',
  templateUrl: './assigned-transactions-cripto.component.html',
  styleUrls: ['./assigned-transactions-cripto.component.sass']
})
export class AssignedTransactionsCriptoComponent implements OnInit{

  public transactionCripto: TransferenciasUSDT[] = [];
  displayedColumns = displayedColumns;
  columns = getColumns(this.currencyPipe);
  filteredTransactionCripto: TransferenciasUSDT[] = [];
  backTransactionCripto: TransferenciasUSDT[] = [];
  filterValue: string = '';
  public currentPage = 1;
  public pageSize = 20;
  public totalItems = 0;
  public totalPages = this.totalItems/this.pageSize;


  constructor(
    private recipientcriptoservice:RecipientCriptoService,
    private dialog: MatDialog,
    private clipboard: Clipboard,
    private currencyPipe:CurrencyPipe,
    private spinner: SpinnerService,
    ){}

  async ngOnInit() {
    await this.loadTransactions();
  }

  async loadTransactions(): Promise<void> {
      const ref = this.spinner.start();
      const data = await this.recipientcriptoservice.getTransactionCripto(this.currentPage,this.pageSize);
      this.transactionCripto = data.query; 
      this.backTransactionCripto = data.query;
      this.totalItems = +data.count;
      this.totalPages = Math.ceil (this.totalItems/this.pageSize);
      this.spinner.stop(ref);
  }

  async reload ():Promise<void>{
    this.currentPage = 1;
    this.loadTransactions();
  }
  onPageChange(event: any) {
    console.log(event);
    this.pageSize=event.pageSize;
    this.currentPage = event.pageIndex+1;
    this.loadTransactions();
  }

  applyFilter() {
    if(this.filterValue===''){
      this.transactionCripto=this.backTransactionCripto;
    }
    this.filteredTransactionCripto = this.transactionCripto.filter(transaction => {
      const transactionValues = Object.values(transaction);
      const lowerCaseFilter = this.filterValue.toLowerCase();
      return transactionValues.some(value => {
        if (typeof value === 'string') {
          return value.toLowerCase().includes(lowerCaseFilter);
        }
        return false;
      });
    });
    this.transactionCripto = this.filteredTransactionCripto;
  }

  private transaccionesIguales(transacciones1: TransferenciasUSDT[], transacciones2: TransferenciasUSDT[]): boolean {
    if (transacciones1.length !== transacciones2.length) {
      return false; // Si las longitudes son diferentes, las listas no son iguales
    }
    const transacciones1Ids = transacciones1.map((t) => t.idTransferencia);
    const transacciones2Ids = transacciones2.map((t) => t.idTransferencia);

    // Compara los identificadores de las transacciones
    for (const id of transacciones1Ids) {
      if (!transacciones2Ids.includes(id)) {
        return false; // Si un identificador en transacciones1 no está en transacciones2, no son iguales
      }
    }
    return true; // Si todas las comparaciones fueron iguales, las listas son iguales
  }

  openRejectTransactionModal(row: any) {
    console.log(row);
    const dialogRef = this.dialog.open(RejectTransactionModalComponent, {
      data: row,
      maxHeight: '70%',
      maxWidth: '826px',
      minWidth: '800px',
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if(result === true){
        this.loadTransactions();
      }
    });
  }

  openSendTransactionModal(row: any) {
    const dialogRef = this.dialog.open(SendTransactionaCriptoModalComponent, {
      data: row,
      maxHeight: '70%',
      maxWidth: '826px',
      minWidth: '800px',
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if(result === true){
        this.loadTransactions();
      }
    });
  }

  openEditModal(row: any){
    const dialogRef = this.dialog.open(EditCriptoTransactionComponent, {
      data: row,
      maxHeight: '70%',
      maxWidth: '826px',
      minWidth: '800px',
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if(result === true){
        this.loadTransactions();
      }
    });
  }

  copy(element: TransferenciasUSDT){

    var text = '';

    if(element.estadoActual === 'POR_PROCESAR'){
      text =
      `Beneficiario: ${element.beneficiario}\nRemitente: ${element.remitente}\nFecha Inicio: ${element.fechaInicio}\nTasa Negociación: ${element.tn}\nMonto CLP: ${element.montoCLP}\nMonto USDT: ${element.montoUSDT}\nWallet TRC20: ${element.walletTRC20}`
    }else{
      text =
      `Beneficiario: ${element.beneficiario}\nRemitente: ${element.remitente}\nFecha Inicio: ${element.fechaInicio}\nFecha Termino: ${element.fechaTermino}\nTasa Negociación: ${element.tn}\nMonto CLP: ${element.montoCLP}\nMonto USDT: ${element.montoUSDT}\nWallet TRC20: ${element.walletTRC20}`
    }
    this.clipboard.copy(text);
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    })

    Toast.fire({
      icon: 'success',
      title: 'Transferencia copiada al portapapeles'
    })


  }

}
