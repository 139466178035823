import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { Banco } from 'src/app/dashboard/interfaces/banco';
import { Pais } from 'src/app/dashboard/interfaces/country.interface';
import { CuentaTrabajo } from 'src/app/dashboard/interfaces/cuenta-trabajo.interface';
import { TipoCuenta } from 'src/app/dashboard/interfaces/tipo-cuenta.interface';
import { AccountTypeService } from 'src/app/dashboard/services/account-type.service';
import { BanksService } from 'src/app/dashboard/services/banks.service';
import { CountriesService } from 'src/app/dashboard/services/countries.service';
import { WorkingAccountService } from 'src/app/dashboard/services/working-account.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-working-account',
  templateUrl: './add-working-account.component.html',
  styleUrls: ['./add-working-account.component.sass']
})
export class AddWorkingAccountComponent {
  formCuenta: FormGroup | undefined;
  paisesActivos: Pais[] = [];
  tiposID = ['CI', 'PASAPORTE', 'RUT'];
  tiposCuentas: TipoCuenta[] = [];
  bancos: Banco[] = [];
  usuarioID: number | undefined;
  subscriptions: Subscription = new Subscription();
  esUpdate = false;
  constructor(
    private fb: FormBuilder,
    private countriesService: CountriesService,
    private bancoService: BanksService,
    private tipoCuentaService: AccountTypeService,
    private workingAccountService: WorkingAccountService,
    private spinner: SpinnerService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AddWorkingAccountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.esUpdate =  this.data.type === 'UPDATE';
    this.initFormCuenta();
    console.log(data);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async initFormCuenta() {
    this.formCuenta = this.fb.group({
      pais: new FormControl( this.esUpdate ? this.data.cuenta.banco.codigoPais : '', [Validators.required]),
      nombre: new FormControl(this.esUpdate ? this.data.cuenta.nombre : '', [Validators.required]),
      apellidos: new FormControl(this.esUpdate ? this.data.cuenta.apellidos : '', [Validators.required]),
      tipoID: new FormControl(this.esUpdate ? this.data.cuenta.tipoID : '', [Validators.required]),
      numeroID: new FormControl(this.esUpdate ? this.data.cuenta.numeroID: '', [Validators.required]),
      banco: new FormControl(this.esUpdate ? this.data.cuenta.banco :'', [Validators.required]),
      tipoCuenta: new FormControl(this.esUpdate ? this.data.cuenta.tipoCuentaID :'', [Validators.required]),
      numeroCuenta: new FormControl(this.esUpdate ? this.data.cuenta.numeroCuenta :'', [
        Validators.required,
        Validators.maxLength(20),
        Validators.pattern(/^[0-9]*$/),
      ]),
    });
    this.initLists();
  }

  async initLists() {
    this.paisesActivos = await this.countriesService.getActiveCountries();

    if(this.esUpdate){
      [this.bancos, this.tiposCuentas] = await Promise.all([
        this.bancoService.getBanksByCountry(this.data.cuenta.banco.codigoPais),
        this.tipoCuentaService.getAccountTypes(this.data.cuenta.banco.codigoPais),
      ]);
      console.log(this.data.cuenta.banco);
      const bancoIndex = this.bancos.findIndex((elem: any): boolean => (elem.bancoID === this.data.cuenta.banco.bancoID));
      this.formCuenta?.get('banco')?.patchValue(this.bancos[bancoIndex]);
    }
  }
  async getBanks() {
    const paisSeleccionado = this.formCuenta?.get('pais')?.value;
    if (!paisSeleccionado) return;
    [this.bancos, this.tiposCuentas] = await Promise.all([
      this.bancoService.getBanksByCountry(paisSeleccionado),
      this.tipoCuentaService.getAccountTypes(paisSeleccionado),
    ]);
  }
  ngOnInit() {}

  async guardarCuentaTrabajo() {
    let mensajeExito = 'Cuenta de trabajo creada';
    if (this.formCuenta?.invalid) return;
    console.log(this.formCuenta?.value);
    const formValue = this.formCuenta?.value;
    const cuenta: CuentaTrabajo = {
      ...formValue,
      bancoID: formValue.banco.bancoID,
      tipoCuentaID: formValue.tipoCuenta

    };
    if(this.esUpdate){
       cuenta['cuenta_trabajo_id'] = this.data.cuenta.cuenta_trabajo_id;
        delete cuenta.banco;
        delete cuenta.tipoCuenta;
        mensajeExito ='Cuenta de trabajo actualizada';
    }
    console.log(cuenta);
    const ref = this.spinner.start();
    try {
      const resultado = await this.workingAccountService.saveWorkingAccount(
        cuenta
      );
      console.log(resultado);

      this.snackBar.open(mensajeExito, 'Ok', {duration: 3000});
      this.dialogRef.close(true);
    } catch (error: any) {
      if(error.status === 400){
        Swal.fire({
          icon: 'warning',
          title: error.error.title,
          text: error.error.message,
          showCancelButton: false,
          showDenyButton: false,
          confirmButtonText: 'Entendido',
          confirmButtonColor: '#1e5a3f',

        });
      }
      console.log(error);
    }
    this.spinner.stop(ref);
  }
}
