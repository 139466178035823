import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { BinanceService } from '../../services/binance.service';
import { CartolaService } from '../../services/cartola.service';
import { ActionsService } from 'src/app/shared/services/actions.service';
import { ParameterService } from '../../services/parameter.service';
import { ParametrosEnum } from 'src/app/shared/enums/parameters.enum';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
@Component({
  selector: 'app-zelle-home',
  templateUrl: './zelle-home.component.html',
  styleUrls: ['./zelle-home.component.sass'],

})
export class ZelleHomeComponent implements OnInit, OnDestroy{

  constructor(
    private cartolaService: CartolaService,
    private store: Store<AppState>,
    private binanceService: BinanceService,
    private actionService: ActionsService,
    private parameterService: ParameterService,
    private spinner: SpinnerService,
  ){
    this.getCurrentValues();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @ViewChild('stepper') stepper: MatStepper | undefined;
  private intervalToRefreshValue = interval(60000);
  public subscription: Subscription = new Subscription();
  public user: any;
  public saldoActual = 0;
  public sobregiroDisponible :number|undefined=0;
  public saldoContable :number|undefined= 0;
  public lineaCreditoBloqueada:boolean|undefined=false;
  public lineaCredito:number|undefined=0;
  public hasLoaded:boolean =  false;
  public zelleSellValue: number = 0;
  public zelleBuyValue: number = 0;
  public usdt_clp_tax: number = 0;
  public porCompZelle: number = 0;
  public descVentaZelle: number = 0;
  public spotPrice: number = 0;


  ngOnInit(): void {
    this.subscription.add(
      this.intervalToRefreshValue.subscribe(()=>this.getCurrentValues())
    );
    this.subscription.add(
      this.actionService.subjectTasaUSDT.subscribe(res=>{
        this.getCurrentValues();
      })
    )
    this.subscription.add(
      this.store.select('auth').subscribe(({ user }: any) => {
        this.user = user;
        this.obtenerSaldo();
      })
    );
  }

  async obtenerSaldo(){
    if(this.user?.usuarioId){
      const {saldo,lineaCreditoId,balancePermitido,lineaBloqueada,sobregiro} = await this.cartolaService.getSaldoCliente(this.user.usuarioId);
      this.saldoActual = saldo;
      this.sobregiroDisponible=sobregiro;
      this.saldoContable = balancePermitido;
      this.lineaCreditoBloqueada=lineaBloqueada;
      this.lineaCredito=lineaCreditoId;
      this.hasLoaded = true;
    }
  }

  async getCurrentValues(){
    const ref = this.spinner.start()
    const currentValues = await this.binanceService.getConversionData();
    this.usdt_clp_tax = currentValues.tasaUsdtClp;
    await this.parameterService.getParameter(ParametrosEnum.DESCUENTO_COM_ZELLE).then((value) => {
      this.descVentaZelle = value.valor;
    }
    );
    await this.parameterService.getParameter(ParametrosEnum.PRECIO_SPOT).then((value) => {
      this.spotPrice = value.valor;
    }
    );
    await this.parameterService.getParameter(ParametrosEnum.PORC_VENTA_ZELLE).then((value) => {
      this.porCompZelle = value.valor;
    }
    );
    this.zelleSellValue=this.usdt_clp_tax*this.porCompZelle;
    this.zelleBuyValue=this.spotPrice-this.descVentaZelle;
    this.spinner.stop(ref);
  }

  redirectToExternalLink(): void {
    window.open('https://wa.me/56965474761?text=Hola,%20quiero%20vender%20zelle%20con%20ustedes', '_blank');
  }
}
