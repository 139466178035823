import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import { User } from 'src/app/shared/models/user.model';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { Country } from '../../interfaces/country.interface';
import { UpdateContactData } from '../../interfaces/update-contact-data';
import { CountriesService } from '../../services/countries.service';
import { UserService } from '../../services/user.service';
@Component({
  selector: 'app-edit-user-data',
  templateUrl: './edit-user-data.component.html',
  styleUrls: ['./edit-user-data.component.css']
})
export class EditUserDataComponent implements OnInit, OnDestroy {
  datosForm: FormGroup | undefined;
  userData: User | undefined;
  subscription: Subscription = new Subscription();
  userSession: string = '';
  tiposID = ['CI', 'PASAPORTE', 'RUT'];
  paises: Country[]= [];
  sexos = ['Masculino', 'Femenino'];
  constructor (private dialogRef: MatDialogRef<EditUserDataComponent>,
    @Inject(MAT_DIALOG_DATA) data: any, private cognito: CognitoService, private fb: FormBuilder, private countryService: CountriesService, private userService: UserService, private spinner: SpinnerService){
    this.initUserForm();
    this.userData = data;
    this.datosForm = this.fb.group({
      nombre: new FormControl({value: this.userData?.nombre, disabled: true }),
      apellido: new FormControl({value: this.userData?.apellidos, disabled: true }),
      tipoId: new FormControl({value: this.userData?.tipoId, disabled: true }),
      numeroId: new FormControl({value: this.userData?.numeroId, disabled: true }),
      nacionalidad: new FormControl({value: this.userData?.nacionalidad, disabled: true }),
      sexo: new FormControl({value: this.userData?.sexo === 'M' ? 'Masculino': 'Femenino', disabled: true }),
      fechaNacimiento: new FormControl({value: this.userData?.fechaNacimiento, disabled: true }),
      ciudadResidencia: new FormControl({value: this.userData?.ciudadResidencia, disabled: true }, [Validators.required]),
      paisResidencia: new FormControl({value: this.userData?.paisResidencia, disabled: true }, [Validators.required]),
      prefijoTelefono: new FormControl({value: this.userData?.prefijoTelefono ? this.userData?.prefijoTelefono : "+56", disabled: false }, [Validators.required]),
      telefono: new FormControl({value: this.userData?.telefono, disabled: false }, [Validators.required]),
      direccion: new FormControl(this.userData?.direccion, [Validators.required]),
      profesion: new FormControl({value: this.userData?.profesion, disabled: true}),
      recibeFactura: new FormControl({value: this.userData?.recibeFactura, disabled: false}, [Validators.required]),
    });

    this.countryService.getCountryList().subscribe( countryList => {
      this.paises = countryList;
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  async initUserForm(){
    const userInSession = await this.cognito.getUser();
    this.userSession = userInSession.attributes['email'];
    this.subscription.add(this.userService.getUserData(this.userSession).subscribe(({user}: any)=>{
      this.userData = user;

    }))
  }
  ngOnInit(): void {

  }

  enviarDatos(){
    if( !this.datosForm?.valid) return;
    const spinnerRef = this.spinner.start();
    const userData: UpdateContactData = this.datosForm.value;
    userData.email = this.userSession;
    this.userService.updateUserData(userData).subscribe(({updated}: any)=>{

      this.spinner.stop(spinnerRef);
      this.dialogRef.close(true);
    }, error => {
      this.spinner.stop(spinnerRef);
      this.dialogRef.close(false);
    })
  }

  close() {
    this.dialogRef.close();
}

}
