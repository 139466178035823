<div class="px-16 py-10  rounded-3xl uploader-status flex flex-col items-center justify-center align-middle">


  <ul class="text-lg">
    <li>
      <b>{{data.fechaCreacion | date: 'medium'}}</b>
    </li>
    <div *ngIf="data.motivo !== 'FONDEO WALLET'; else pagador">
      <b *ngIf="data.beneficiario">Beneficiario:</b> {{data.beneficiario}}
    </div>
    <ng-template #pagador>
      <div *ngIf="data.pagador != 'Aguacate Investment'"><b>Pagador:</b> {{data.beneficiario}}</div>
    </ng-template>
    <li *ngIf="data.motivo !== 'FONDEO WALLET' && data.motivo !== 'AJUSTE DE BALANCE' && data.tipoTransaccion !== 'RETIRO'">
      <b>Banco del Beneficiario:</b> {{data.motivo}}
    </li>
    <li *ngIf="data.tipoTransaccion==='RETIRO'">
      <b>Cuenta de Retiro: </b> {{data.aliasRetiro}}
    </li>
    <li *ngIf="data.numeroCuenta != 'Abono Manual' && data.numeroCuenta">
      <b>Cuenta del Beneficiario:</b> {{data.numeroCuenta}}
    </li>
    <li *ngIf="data.codigo_transferencia != null">
      <b>Código transferencia:</b> {{data.codigo_transferencia}}
    </li>
    <li *ngIf="data.motivo != 'CRIPTOMONEDA' && data.pais_origen !== null && data.tipoTransaccion !== 'RETIRO'">
      <b>País Origen:</b> {{ data.pais_origen }}
    </li>
    <li *ngIf="data.motivo != 'CRIPTOMONEDA' && data.pais_destino !== null && data.tipoTransaccion !== 'RETIRO' && data.pais_destino!='ZELLE'">
      <b>País Destino:</b> {{data.pais_destino}}
    </li>
    <li *ngIf="data.tn">
      <b>Tasa Negociación:</b> {{data.tn | number : '1.2-4'}}
    </li>
    <li *ngIf="data.tipoTransaccion !== 'RETIRO'">
      <b>Monto del envío:</b> ${{data.monedaOrigen | currency:'':'' }}
    </li>
    <li *ngIf="data.monedaDestino != null && data.tipoTransaccion !== 'RETIRO'">
      <b>Monto destino:</b> ${{data.monedaDestino | currency:'':'' }}
    </li>
    <li *ngIf="data.tipoTransaccion==='RETIRO'">
      <b>Banco: </b> {{data.banco}}
    </li>
    <li *ngIf="data.tipoTransaccion==='RETIRO'">
      <b>Numero de Cuenta: </b> {{data.numeroCuentaRetiro}}
    </li>
    <li *ngIf="data.monedaDestino != null && data.tipoTransaccion === 'RETIRO'">
      <b>Monto de retiro:</b> {{data.monedaDestino | currency: '$' : 'symbol' : '4.2-2' }}
    </li>
    <li *ngIf="data.monedaDestino != null && data.tipoTransaccion === 'RETIRO'">
      <b>Moneda destino:</b> ${{data.tipoMonedaDestino}}
    </li>
    <li>
      <b>Estado:</b> {{data.estadoActual}}
    </li>
  </ul>
  <button type="button" (click)="onNoClick()"
    class="mt-4 w-1/2 font-semibold text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 text-lg rounded-lg px-5 py-2.5 text-center">
    Cerrar
  </button>
</div>
