import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Banco } from '../interfaces/banco';

@Injectable({
  providedIn: 'root',
})
export class BanksService {
  API_URL = environment.apiGatewayURL;

  constructor(private http: HttpClient) {}

  getBanksByCountry(countryCode: string): Promise<Banco[]> {
    const serviceURL = `${this.API_URL}general-types/banks/${countryCode}`;
    return firstValueFrom(this.http.get<Banco[]>(serviceURL));
  }
}
