import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { CuentaBeneficiario } from '../../interfaces/cuenta-beneficiario.interface';
import { AccountRecipientService } from '../../services/account-recipient.service';
import AddAccountComponent from '../account-recipient/components/add-account/add-account.component';
import { BeneficiarioCriptomoneda } from '../../interfaces/beneficiario-criptomoneda.interface';
import { RecipientCriptoService } from '../../services/recipient-cripto.service';
import { AddAccountCriptoComponent } from './components/add-account-cripto/add-account-cripto.component';
import { AddBeneficiaryComponent } from '../calculator-cryto/components/add-beneficiary/add-beneficiary.component';

@Component({
  selector: 'app-recipient-cripto',
  templateUrl: './recipient-cripto.component.html',
  styleUrls: ['./recipient-cripto.component.sass']
})
export class RecipientCriptoComponent  implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  usuarioID: number | undefined;
  listaCuentas: BeneficiarioCriptomoneda[] = [];
  @Input() seleccionable = false;
  @Output() seleccion: EventEmitter<BeneficiarioCriptomoneda> = new EventEmitter();
  constructor(
    private store: Store<AppState>,
    private accountRecipientService: RecipientCriptoService,
    private dialog: MatDialog
  ) {
    this.initFormCuenta();
  }



  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initFormCuenta() {
    this.subscriptions.add(
      this.store.select('auth').subscribe(async ({ user }) => {
        this.usuarioID = user?.usuarioId;
        if(this.usuarioID){
          this.listaCuentas = await this.accountRecipientService.getByUser(this.usuarioID);
        }
      })
    );

  }
  ngOnInit() {}

  async reloadAccount(){
    if(this.usuarioID){
      this.listaCuentas = await this.accountRecipientService.getByUser(this.usuarioID);
    }
  }

  openCreateDialog(cuenta?: BeneficiarioCriptomoneda):void{
    var data_:any = {
      data: {
        userID: this.usuarioID
      },
      disableClose: true,
      width: '600px'
    }
    var dialogRef;
    if(!cuenta){
      dialogRef = this.dialog.open(AddBeneficiaryComponent, data_);
    }else{
      data_['data'].type = 'UPDATE';
      data_['data'].beneficiario = cuenta;
      dialogRef = this.dialog.open(AddBeneficiaryComponent, data_);
    }
    dialogRef.afterClosed().subscribe(async result => {
      if(result == true && this.usuarioID){
        this.listaCuentas = await this.accountRecipientService.getByUser(this.usuarioID);
      }
    })
  }

  onSeleccion(cuenta:BeneficiarioCriptomoneda ){
    this.seleccion.emit(cuenta);
  }

}
