<div class="container_change">
    <app-withdraw-balance-view [actualBalance]="saldoActual"></app-withdraw-balance-view>
    <div class="mt-10">
      <div class="grid grid-cols-1 send-input">
        <input
          type="text"
          [(ngModel)]="valorARetiro"
          (keyup)="balanceLeft()"
          placeholder="$"
          pattern="[0-9]*"
          (keypress)="soloNumeros($event)"
          maxlength="8"
          >
        <span class="button-inline flex flex-row items-center">
          <button *ngIf="valorARetiro!=''" class="close-btn hover:border-gray-700/50 border-transparent border-1"(click)="clearValAvailableCLP()">✕</button> 
          <button class="max-btn hover:bg-aguacate-highlight" (click)="maxValAvailableCLP()">MÁX.</button> 
        </span>
        <span>Monto de retiro</span>
        <mat-error *ngIf="valorRetiro>saldoActual">El monto de retiro excede el balance actual</mat-error>
      </div>
      <div class="grid grid-cols-1">
        <input
          type="text"
          [(ngModel)]="valorRestante"
          placeholder="$"
          pattern="[0-9]*"
          (keypress)="soloNumeros($event)"
          maxlength="8"
          readonly
        >
        <span>Balance restante</span>
      </div>
    </div>
  </div>
  <div class="text-center">
    <button [disabled]="valorRetiro === 0 || valorRetiro>saldoActual" (click)="emitChangeStep()" mat-raised-button color="primary" class="mt-10 p-5 text-lg">
      Retirar
    </button>
  </div>