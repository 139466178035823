<div class="w-full flex flex-col  justify-center items-center" >
  <h2 class="font-bold text-lg my-5">Medio de pago</h2>
  <div class="px-8 py-4 w-11/12 bg-aguacate-bg rounded-lg " >
    <h1 class="font-extrabold text-lg text-center">Transferencia electrónica</h1>
    <div class="flex flex-col justify-between items-start">

      <div class="w-full items-center" >

        <p class="my-4 text-center">Realizar el depósito a una de las siguientes cuentas:</p>
    <div class="flex flex-wrap w-full gap-8 mb-8 justify-center">
      <ng-container *ngFor="let item of data" >
        <div *ngIf="item.disponible===true" class="rounded-md border border-lime-900 flex flex-col justify-self-center w-[350px] m-4">
          <p class="my-5 mx-10">
            {{ item.banco }}<br>
            Cuenta {{ item.tipoCuenta}}<br>
            {{ item.cuenta }}<br>
            Número de cuenta: {{ item.numeroCuenta }}<br>
            Rut: {{ this.formatRut(item.rutemisor) }}<br>
          </p>
          <button mat-raised-button color="primary" class="m-4 shadow max-h-md" (click)="copyData(item)">
            <mat-icon>content_copy</mat-icon> Copiar datos
          </button>
        </div>
      </ng-container>
      
    </div>
  </div>

  </div>
</div>
<p class="mt-4 text-center"><mat-icon>warning</mat-icon> Recuerda que el pago debe estar a tu nombre, si el pago proviene de un tercero, no será válido <br> Al hacer tu recarga estará disponible en tu wallet en un lapso máximo de 10 min.</p>

</div>
