import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { RecipientCriptoService } from '../../services/recipient-cripto.service';
import { TransferenciasUSDT } from '../../interfaces/transferencia-usdt.interface';
import { MatSort } from '@angular/material/sort';
import { CurrencyPipe } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-manual-transaction-history-dialog',
  templateUrl: './manual-transaction-history-dialog.component.html',
  styleUrls: ['./manual-transaction-history-dialog.component.sass']
})
export class ManualTransactionHistoryDialogComponent {

  dataSource : MatTableDataSource<any> | undefined;
  transacciones: TransferenciasUSDT[] = [];
  public currentPage = 1;
  public pageSize = 5;
  public totalItems = 0;
  public totalPages = this.totalItems/this.pageSize;

  @ViewChild('stepper') stepper: MatStepper | undefined;
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator) paginator!: MatPaginator;


  constructor(
    private recipientcriptoservice:RecipientCriptoService,
    private currencyPipe:CurrencyPipe,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.loadTransactions();
  };

  async loadTransactions(){
    this.transacciones = await this.recipientcriptoservice.getTransactionCriptoManualTransactions(this.data.id);
    this.dataSource = new MatTableDataSource(this.transacciones);
    this.dataSource.paginator = this.paginator;
    this.totalItems = this.transacciones.length;
    this.totalPages = Math.ceil (this.totalItems/this.pageSize);
  }

  columns = [
    { 
      columnDef: 'idTransferencia',
      header: 'ID',
      cell: (element: any) => `${element.idTransferencia}`,
      headerStyle: {'font-weight': 'bold','max-width':'100px','min-width':'80px'},
      cellStyle: { 'text-align':'center','max-width':'100px','min-width':'80px'},
    },
    { 
      columnDef: 'fechaInicio',
      header: 'Fecha Inicio',
      cell: (element: any) => `${this.formatDate(new Date(element.fechaInicio))}`,
      headerStyle: {'font-weight': 'bold','max-width':'200px','min-width':'100px'},
      cellStyle: { 'text-align':'center','max-width':'200px','min-width':'100px'},
    },
    { 
      columnDef: 'fechaFondeoWallet',
      header: 'Fecha Fondeo Wallet',
      cell: (element: any) => `${this.formatDate(element.fechaFondeoWallet)}`,
      headerStyle: {'font-weight': 'bold','max-width':'200px','min-width':'100px'},
      cellStyle: { 'text-align':'center','max-width':'200px','min-width':'100px'},
    },
    { 
      columnDef: 'montoCLP',
      header: 'Monto CLP',
      cell: (element: any) => `${this.currencyPipe.transform(element.montoCLP, 'USD', 'symbol-narrow', '1.2-2', 'en-US')}`,
      headerStyle: {'font-weight': 'bold','max-width':'150px','min-width':'100px'},
      cellStyle: { 'text-align':'center','max-width':'150px','min-width':'100px'},
    },
    { 
      columnDef: 'montoUSDT',
      header: 'Monto USDT',
      cell: (element: any) => `${this.currencyPipe.transform(element.montoUSDT, 'USD', 'symbol-narrow', '1.2-2', 'en-US')}`,
      headerStyle: {'font-weight': 'bold','max-width':'150px','min-width':'100px'},
      cellStyle: { 'text-align':'center','max-width':'150px','min-width':'100px'},
    },
    { 
      columnDef: 'remitente',
      header: 'Remitente',
      cell: (element: any) => `${element.remitente}`,
      headerStyle: {'font-weight': 'bold','max-width':'150px','min-width':'100px'},
      cellStyle: { 'text-align':'center','max-width':'150px','min-width':'100px'},
    },
    { 
      columnDef: 'estadoActual',
      header: 'Estado Actual',
      cell: (element: any) => `${element.estadoActual.replace(/_/g, ' ')}`,
      headerStyle: {'font-weight': 'bold','max-width':'220px','min-width':'100px'},
      cellStyle: { 'text-align':'center','max-width':'220px','min-width':'100px'},
    },
    { 
      columnDef: 'tn',
      header: 'Tasa',
      cell: (element: any) => `${element.tn}`,
      headerStyle: {'font-weight': 'bold','max-width':'150px','min-width':'100px'},
      cellStyle: { 'text-align':'center','max-width':'150px','min-width':'100px'},
    },
    { 
      columnDef: 'walletTRC20',
      header: 'Wallet',
      cell: (element: any) => `${element.walletTRC20}`,
      headerStyle: {'font-weight': 'bold','max-width':'300px','min-width':'150px', 'white-space':'normal','word-wrap': 'break-word','overflow-wrap': 'anywhere' },
      cellStyle: { 'text-align':'center','max-width':'300px','min-width':'150px', 'white-space':'normal','word-wrap': 'break-word','overflow-wrap': 'anywhere' },
    },
  ];

  printHistorial() {
    const headers = [
      "ID",
      "Fecha Inicio",
      "Fecha Fondeo Wallet",
      "Monto CLP",
      "Monto USDT",
      "Remitente",
      "Estado Actual",
      "Tasa",
      "Wallet"
  ];

  const dataToExport = this.transacciones.map(tx => ({
      ID: tx.idTransferencia,
      'Fecha Inicio': this.formatDate(new Date(tx.fechaInicio)),
      'Fecha Fondeo Wallet': this.formatDate(tx.fechaFondeoWallet),
      'Monto CLP': tx.montoCLP,
      'Monto USDT': tx.montoUSDT,
      'Remitente': tx.remitente,
      'Estado Actual': tx.estadoActual.replace(/_/g, ' '),
      'Tasa': tx.tn,
      'Wallet': tx.walletTRC20
  }));

  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport);

  XLSX.utils.sheet_add_aoa(ws, [headers], { origin: "A1" });

  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Transactions');

  const wbout: ArrayBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  saveAs(new Blob([wbout], { type: 'application/octet-stream' }), this.data.id+'.xlsx');
  }

  formatDate(dateString: Date) {
    const date = new Date(dateString);
    
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

  onPageChange(event: any) {
    console.log(event);
    this.pageSize=event.pageSize;
    this.currentPage = event.pageIndex+1;
    this.loadTransactions();
  }
  
  displayedColumns: string[] = this.columns.map(column => column.columnDef);
    
  filterTransactionsByWallet(transactions: TransferenciasUSDT[], id: string): TransferenciasUSDT[] {
    return transactions.filter(transaction => transaction.walletTRC20 === id);
  }
}