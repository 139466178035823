import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import { User } from 'src/app/shared/models/user.model';
import { UserService } from '../../services/user.service';
import { EditUserDataComponent } from '../edit-user-data/edit-user-data.component';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.sass']
})
export class UserProfileComponent implements OnInit {

  userData: User | undefined;
  subscription: Subscription = new Subscription();
  userSession: string = '';
  constructor(private _snackBar: MatSnackBar, private userService: UserService, private cognito: CognitoService, private userProfileDialog: MatDialog) {
    this.initUserForm();
  }

  openDialog(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.maxHeight = '90vh';
    dialogConfig.maxWidth= '100vw'
    dialogConfig.data = this.userData;

    this.userProfileDialog.open(EditUserDataComponent, dialogConfig).afterClosed().subscribe(res=>{
      if(res === true){
        this._snackBar.open('Datos actualizados', 'Ok');
        this.fecthUserData();
        return;
      }
      else if(res === false){
        this._snackBar.open('Hubo un error al actualizar los datos', 'Ok');
      }

    });
  }
  async initUserForm(){
    const userInSession = await this.cognito.getUser();
    this.userSession = userInSession.attributes['email'];
    this.fecthUserData();
  }
  fecthUserData(){
    this.subscription.add(this.userService.getUserData(this.userSession).subscribe(({user}: any)=>{
      this.userData = user;
    }));
  }
  ngOnInit() {
  }

}
