import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, catchError, from, switchMap } from 'rxjs';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiRestInterceptor implements HttpInterceptor {

  constructor( private cognito: CognitoService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.get("skip"))
    {
      let request_without_skip = request.clone({ headers: request.headers.delete('skip','true') });
      return next.handle(request_without_skip);
    }
    if(request.url.includes(environment.s3.bucket)){
      return next.handle(request);
    }

    return from(this.cognito.getSession())
        .pipe(
            switchMap((auth: any) => { // switchMap() is used instead of map().
                let jwt = auth.idToken.jwtToken;
                let with_auth_request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${jwt}`
                    }
                });
                return next.handle(with_auth_request);
            })
        );

}
}
