import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BinanceService } from '../../services/binance.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BanksService } from '../../services/banks.service';
import { Banco } from '../../interfaces/banco';
import { MultipleExchangeService } from '../../services/multiple-exchange.service';
import {Subscription} from 'rxjs';
import { ActionsService } from 'src/app/shared/services/actions.service';
@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.css']
})
export class CalculatorComponent implements OnInit, OnDestroy {

  @Input()multiple: boolean = false;
  @Output() continuarEmitter: EventEmitter<any> = new EventEmitter();

  calculatorForm: FormGroup | undefined;
  currentRates: any;
  currentRateMinimo: number = 0;
  currentRateMaximo: number = 0;
  selectedRate: number = 0;
  currentValues: any;
  bancos: Banco[] = [];
  subscription: Subscription  = new Subscription();
  constructor(
    private actionService: ActionsService,
    private binanceService: BinanceService,
    private bancoService: BanksService,
    private multipleExchangeService: MultipleExchangeService
    ) {

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async ngOnInit() {
    this.subscription.add(
      this.actionService.subjectTasaFiat.subscribe(async res=> {
        console.log('Tasas actualizadas', 'Calculadora')
        await this.getCurrentRates();
        await this.onBankSelection();
        this.changeValue('origen');
      })
    )
    this.bancos  = (await this.bancoService.getBanksByCountry('VE')).filter((res=> res.codigoBanco !== 'BINANCE'));
    await  this.getCurrentRates();
    this.calculatorForm = new FormGroup({
      origen: new FormControl('CL'),
      destino: new FormControl('VE'),
      envia: new FormControl(0),
      banco: new FormControl(this.bancos[0], [Validators.required]),
      recibe: new FormControl(0, [Validators.min( +this.currentValues.limiteInferior)])
    });
    this.emitMultipleData();
    this.onBankSelection();
    this.cleanCalculator();
  }

  cleanCalculator(){
      this.subscription.add(this.multipleExchangeService.currentStatusCalculator$.subscribe(res=>{
        if(res === true ){
          this.calculatorForm?.reset();
          this.calculatorForm?.get('origen')?.patchValue('CL');
          this.calculatorForm?.get('destino')?.patchValue('VE');
        }

      }));
  }
  emitMultipleData(){
    if(this.multiple){
      this.subscription.add(this.calculatorForm?.valueChanges.subscribe(
        val => {
         if(this.calculatorForm?.valid){
          this.continuarEmitter.emit(this.calculatorForm?.value);
         }
        }
      ));
    }
  }

  async emitChangesMultiple(){

  }

  onInputChange(event: any) {
    let value = event.target.value.trim();
    if (value.startsWith('0')) {
      value = value.substring(1);
    }
    event.target.value = value;
  }

  async getCurrentRates(){
    this.currentRates = await this.binanceService.getCurrentRates();
    this.currentValues = await this.binanceService.getConversionData();
    console.log(this.currentRates);
    console.log(this.currentValues);
  }

  changeValue(campo: string){
    if(campo==='origen'){
      const valorDestino = Number(this.calculatorForm?.get('envia')?.value);
      const valorReferencia = valorDestino * (+this.currentRateMinimo);
      if(valorReferencia < +this.currentValues.limiteSuperior){
        const vesValue = valorDestino * (+this.currentRateMinimo);
        this.selectedRate = this.currentRateMinimo;
        this.calculatorForm?.get('recibe')?.patchValue(Math.round(vesValue));
        this.calculatorForm?.get('recibe')?.markAsTouched();
      }
      if(valorReferencia>= +this.currentValues.limiteSuperior){
        const vesValue = valorDestino * (+this.currentRateMaximo);
        this.selectedRate = this.currentRateMaximo;
        this.calculatorForm?.get('recibe')?.patchValue(Math.round(vesValue));
        this.calculatorForm?.get('recibe')?.markAsTouched();
      }
    }
    if(campo==='destino'){
      const currentValue= Number(this.calculatorForm?.get('recibe')?.value);
      if( currentValue < +this.currentValues.limiteSuperior){
        const clpValue = currentValue/ (+this.currentRateMinimo);
        this.selectedRate = this.currentRateMinimo;
        this.calculatorForm?.get('envia')?.setValue(Math.round(clpValue));
        this.calculatorForm?.get('envia')?.markAsTouched();
      }
      if(currentValue >= this.currentValues.limiteSuperior){
        const clpValue = currentValue/ (+this.currentRateMaximo);
        this.selectedRate = this.currentRateMaximo;
        this.calculatorForm?.get('envia')?.setValue(Math.round(clpValue));
        this.calculatorForm?.get('envia')?.markAsTouched();
      }
    }
    this.continuar();
  }

  async getBanks() {
    const paisSeleccionado = this.calculatorForm?.get('destino')?.value;
    if (!paisSeleccionado) return;

  }



  onBankSelection(){
    const bancoSeleccionado = this.calculatorForm?.get('banco')?.value;
    if(!bancoSeleccionado){
      this.calculatorForm?.get('banco')?.setValue(this.bancos[0]);
    }
    let bancoNombre = ( this.calculatorForm?.get('banco')?.value.nombre).toLowerCase();
    let firstLetter = bancoNombre.charAt(0).toUpperCase();
    const nombreUpperCase = firstLetter + bancoNombre.slice(1);
    this.currentRateMaximo = this.currentRates.maximo[`tasa${nombreUpperCase}`];
    this.currentRateMinimo = this.currentRates.minimo[`tasa${nombreUpperCase}`];
    this.changeValue('origen');
    this.continuar();

  }

  continuar(){
    if(this.calculatorForm?.invalid){
      this.continuarEmitter.emit(undefined);
      return;
    };
    const calculatorData = {
      ...this.calculatorForm?.value,
      tasa: this.selectedRate,
      valueId: this.currentRates.valoresCambioId,
      tasaId: this.currentRates.tasaId,
    }
    console.log(calculatorData);
    this.continuarEmitter.emit(calculatorData);
  }

}
