import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CuentaTrabajo } from 'src/app/dashboard/interfaces/cuenta-trabajo.interface';
import { WorkingAccountService } from 'src/app/dashboard/services/working-account.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';

@Component({
  selector: 'app-working-account-card',
  templateUrl: './working-account-card.component.html',
  styleUrls: ['./working-account-card.component.sass']
})
export class WorkingAccountCardComponent {
  @Input() cuenta: CuentaTrabajo | undefined;
  @Output() deleteAccount: EventEmitter<any> = new EventEmitter();
  @Output() actualizarCuenta: EventEmitter<CuentaTrabajo> = new EventEmitter();
  constructor(private workingAccountService: WorkingAccountService, private spinner: SpinnerService, private snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  async inactivarCuenta(){
    if( !(this.cuenta && this.cuenta.cuenta_trabajo_id))return;
    const spinnerRef  = this.spinner.start();
    try {
      await this.workingAccountService.inactivateAccount(this.cuenta.cuenta_trabajo_id);
      this.snackBar.open('Cuenta eliminada correctamente', 'Ok', {
        duration: 3000
      });
      this.deleteAccount.emit();
    } catch (error) {
      console.log(error);
    }
    this.spinner.stop(spinnerRef);

  }

  actualizar(){
    this.actualizarCuenta.emit(this.cuenta);
  }

}
