<div class="m-4 flex-col">
  <h1 class="m-4 text-xl font-bold text-center">{{ getTitle() }}</h1>
  <div>
    <div class="flex flex-row gap-5 items-center justify-around align-middle mb-4" style="max-width: 800px; margin: 0 auto;">
      <button mat-raised-button color="primary" (click)="onCrearTransaccionClick()" class="mt-[1px] h-[56px] w-4/5" [disabled]="viewType === 'crear Transaccion'">Crear Transacción</button>
      <button mat-raised-button color="primary" (click)="onHistorialTransaccionesClick()" class="mt-[1px] h-[56px] w-4/5" [disabled]="viewType === 'historial Transacciones'">Historial de Transacciones</button>
      <!-- <button mat-raised-button color="primary" (click)="onTransaccionesPendientesClick()" class="mt-[1px] h-[56px] w-4/5" [disabled]="viewType === 'transacciones Pendientes'">Transacciones Pendientes</button> -->
    </div>
    <div class="flex flex-row gap-5 items-center justify-around align-middle mb-4" style="max-width: 1000px; margin: 0 auto;" *ngIf="viewType === 'historial Transacciones'">
      <button mat-raised-button color="primary" (click)="onOrdenesAbiertasClick()" class="mt-[1px] h-[56px] w-4/5" [disabled]="historialFilter === 'ABIERTAS'">Ordenes Abiertas</button>
      <button mat-raised-button color="primary" (click)="onOrdenesCerradasClick()" class="mt-[1px] h-[56px] w-4/5"[disabled]="historialFilter === 'CERRADAS'">Ordenes Cerradas</button>
      <button mat-raised-button color="primary" (click)="onOrdenesTodasClick()" class="mt-[1px] h-[56px] w-4/5"[disabled]="historialFilter === 'TODAS'">Todas las Ordenes</button>
    </div>
    <div class="flex flex-row gap-5 items-center justify-around align-middle mb-4" style="max-width: 1000px; margin: 0 auto;" *ngIf="viewType === 'historial Transacciones'">
        <form [formGroup]="range" (ngSubmit)="onDateFilterChange()" class="form-container" *ngIf="range">
          <div class="mt-[1px] h-[56px] w-5/5">
            <mat-form-field class="search-date">
              <mat-label>Ingresa un rango de fechas</mat-label>
              <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="Start date">
                <input matEndDate formControlName="end" placeholder="End date">
              </mat-date-range-input>
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>

              <mat-error *ngIf="range.controls['start'].hasError('matStartDateInvalid')">Invalid start date</mat-error>
              <mat-error *ngIf="range.controls['end'].hasError('matEndDateInvalid')">Fecha fin invalida</mat-error>
            </mat-form-field>
          </div>
          <div class="mt-[1px] h-[56px] w-5/5">
            <mat-select formControlName="fechaTypeSelected" placeholder="Seleccione fecha para filtrar">
              <mat-option *ngFor="let filter of filtersDates" [value]="filter">
                {{ filtersDate[filter] }}
              </mat-option>
            </mat-select>
          </div>
          <button mat-raised-button color="primary" class="mt-[1px] h-[56px] w-4/5">
            Buscar
          <mat-icon> search </mat-icon>
          </button>
        </form>
    </div>
  </div>
  <div class="my-4 self-center" *ngIf="viewType==='crear Transaccion'" >
    <div class="flex flex-col items-center justify-around align-middle" style="z-index: 0;">
      <div class="create-container gap-5 px-10 mb-4 flex-row text-center " style="max-width: 800px; margin: 0 auto;">
        <mat-form-field class="w-full">
          <mat-label>Tipo de búsqueda</mat-label>
          <mat-select required [(ngModel)]="selectedFilter">
            <mat-option>Seleccione un filtro</mat-option>
            <mat-option *ngFor="let filter of filters" [value]="filter">
              {{ filterDescriptions[filter]}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="w-full">
          <mat-label>Valor de búsqueda</mat-label>
          <input matInput type="text" [(ngModel)]="searchValue" />

          <button *ngIf="searchValue"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="searchValue = ''"
          >
            <mat-icon>close</mat-icon>
          </button>

        </mat-form-field>
          <button mat-raised-button color="primary" (click)="search()" class="mt-[1px] h-[56px] w-2/5">
            Buscar
          <mat-icon> search </mat-icon>
        </button>

      </div>
      <div class="flex overflow-x-auto max-w-[100%]">
        <table
        mat-table
        [dataSource]="dataSource"
        (matSortChange)="sortData($event)"
        matSort
        *ngIf="dataSource"
        >
          <ng-container
            *ngFor="let column of columns"
            [matColumnDef]="column.columnDef"
          >
            <mat-header-cell
              mat-sort-header
              *matHeaderCellDef
              style="text-align:left"
              [ngStyle]="column.headerStyle"
            >
              {{column.header}}
            </mat-header-cell>

            <mat-cell
              *matCellDef="let row"
              [ngStyle]="column.cellStyle"
              style="text-align:left"
            >
              {{column.cell(row)}}
            </mat-cell>

          </ng-container>

          <ng-container matColumnDef="acciones" stickyEnd>
            <mat-header-cell *matHeaderCellDef aria-label="row actions" style="font-weight: bold;background-color: #CEE9C2" class="text-center font-bold min-w-[120px] max-w-[200px]">Crear Transacción</mat-header-cell>
            <mat-cell *matCellDef="let element"  class="text-center font-bold min-w-[120px] max-w-[200px] justify-evenly">
              <button mat-icon-button color="primary" aria-label="ver" (click)="openCreateFormDialog(element.usuarioId, element.nombre + ' ' + element.apellidos)">
                <mat-icon>note_add</mat-icon>
              </button>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </table>
      </div>
    </div>
  </div>

  <div *ngIf="viewType==='historial Transacciones'" class="row items-center self-center" style="z-index: 0;">
    <mat-paginator #paginator
               class="demo-paginator self-left"
               (page)="onPageChange($event)"
               [length]="totalItems"
               [pageSize]="pageSize"
               [disabled]="false"
               [showFirstLastButtons]="true"
               [pageSizeOptions]="[5, 10, 25, 50, 100]"
               [hidePageSize]="false"
               [pageIndex]="currentPage-1"
               aria-label="Select page">
  </mat-paginator>
    <div class="flex flex-row overflow-x-auto">
      <table mat-table [dataSource]="dataSourceHistorial" matSort (matSortChange)="sortDataHistory($event)" *ngIf="dataSourceHistorial">
        <ng-container
          *ngFor="let column of columnsHistorial"
          [matColumnDef]="column.columnDef"
        >
          <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          style="background-color: #CEE9C2;"
          [ngStyle]="column.headerStyle"
          [class.sticky-column]="column.isSticky"
          >
            {{column.header}}
          </mat-header-cell>
          <mat-cell
          *matCellDef="let row"
          [ngStyle]="column.cellStyle"
          [class.sticky-column]="column.isSticky"
          >
            <ng-container *ngIf="column.columnDef === 'estado' && column.iconColor; else otherColumns">
              <button mat-raised-button [style.background-color]="column.iconColor(row)" class="max-w-full text-white m-1 mat-elevation-z0">
              {{column.cell(row)}}
              </button>
            </ng-container>
            <ng-template #otherColumns>
              {{ column.cell(row) }}
            </ng-template>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="acciones" stickyEnd>
          <mat-header-cell
          *matHeaderCellDef
          aria-label="row actions"
          class="text-center font-bold min-w-[200px] align-center"
          style="background-color: #CEE9C2; max-width:220px;"
          >
            Acciones
          </mat-header-cell>
          <mat-cell *matCellDef="let element" style="text-align: center;  max-width:220px; min-width:200px;">
            <button mat-icon-button class="material-icons color_green" aria-label="Cerrar" [disabled]="element.estado === 'ABIERTA'"(click)="updateManualTransactionStatus(element, 'ABIERTA')">
              <mat-icon>lock_open</mat-icon>
            </button>
            <button mat-icon-button class="material-icons color_red" aria-label="Abrir" [disabled]="element.estado === 'CERRADA'"(click)="updateManualTransactionStatus(element, 'CERRADA')">
              <mat-icon>lock</mat-icon>
            </button>
            <button mat-icon-button class="material-icons color_red" aria-label="Abrir" [disabled]="ValidateTransaction(element)"(click)="RemoveUnnecesaryTransaction(element)">
              <mat-icon>delete</mat-icon>
            </button>
            <button mat-icon-button color="primary" aria-label="registro" (click)="openHistoryFormDialog(element.id, element.walletTrx)">
              <mat-icon>search</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumnsHistorial"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsHistorial;"></mat-row>
      </table>
    </div>
  </div>
</div>
