import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerComponent } from 'src/app/shared/components/spinner/spinner.component';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { CustomValidators } from 'src/app/shared/utils/custom.validator';
import { CognitoError } from '../../interfaces/cognitoError.interface';
import { CognitoService, IUser } from '../../services/cognito.service';

@Component({
  selector: 'app-mail-validation',
  templateUrl: './mail-validation.component.html',
  styleUrls: ['./mail-validation.component.sass']
})
export class MailValidationComponent {
  isConfirm: boolean=false;
  confirmationCode: string= '';
  currentMail: string = '';
  signUpError: CognitoError | undefined;
  verificationError: CognitoError | undefined;
  constructor(private router: Router,
              private cognitoService: CognitoService,private route: ActivatedRoute, private spinner: SpinnerService) {
                this.currentMail = this.route.snapshot.paramMap.get('email') || '';
                this.confirmationCode = this.route.snapshot.paramMap.get('code') || '';
                if(!this.currentMail || !this.confirmationCode){
                  this.router.navigate(['/auth/login']);
                }
              }



  public confirmSignUp(): void {
    let spinnerRef = this.spinner.start();
    this.cognitoService.confirmSignUp(this.currentMail, this.confirmationCode )
    .then(() => {
      this.spinner.stop(spinnerRef);
        this.router.navigate(['/auth/login']);
      //this.router.navigate(['/auth/login']);
    }).catch((err) => {
      this.verificationError= err;
      this.spinner.stop(spinnerRef);
    });
  }
}
