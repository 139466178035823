import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { Banco } from 'src/app/dashboard/interfaces/banco';
import { Pais } from 'src/app/dashboard/interfaces/country.interface';
import { CuentaBeneficiario } from 'src/app/dashboard/interfaces/cuenta-beneficiario.interface';
import { TipoCuenta } from 'src/app/dashboard/interfaces/tipo-cuenta.interface';
import { AccountRecipientService } from 'src/app/dashboard/services/account-recipient.service';
import { AccountTypeService } from 'src/app/dashboard/services/account-type.service';
import { BanksService } from 'src/app/dashboard/services/banks.service';
import { CountriesService } from 'src/app/dashboard/services/countries.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.sass'],
})
export default class AddAccountComponent implements OnInit, OnDestroy {
  formCuenta: FormGroup | undefined;
  paisesActivos: Pais[] = [];
  tiposID = ['CI', 'PASAPORTE', 'RUT'];
  tiposCuentas: TipoCuenta[] = [];
  bancos: Banco[] = [];
  usuarioID: number | undefined;
  subscriptions: Subscription = new Subscription();
  esUpdate = false;
  constructor(
    private fb: FormBuilder,
    private countriesService: CountriesService,
    private bancoService: BanksService,
    private tipoCuentaService: AccountTypeService,
    private accountRecipientService: AccountRecipientService,
    private store: Store<AppState>,
    private spinner: SpinnerService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AddAccountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.esUpdate =  this.data.type === 'UPDATE';
    this.initFormCuenta();
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async initFormCuenta() {
    this.subscriptions.add(
      this.store.select('auth').subscribe(({ user }) => {
        this.usuarioID = user?.usuarioId;
      })
    );


    this.formCuenta = this.fb.group({
      pais: new FormControl( this.esUpdate ? this.data.cuenta.banco.codigoPais : '', [Validators.required]),
      nombre: new FormControl(this.esUpdate ? this.data.cuenta.nombre : '', [Validators.required,  Validators.maxLength(40)]),
      apellidos: new FormControl(this.esUpdate ? this.data.cuenta.apellidos : '', [Validators.required,  Validators.maxLength(40)]),
      tipoID: new FormControl(this.esUpdate ? this.data.cuenta.tipoID : '', [Validators.required]),
      numeroID: new FormControl(this.esUpdate ? this.data.cuenta.numeroID: '', [Validators.required, Validators.maxLength(15), Validators.minLength(7)]),
      banco: new FormControl(this.esUpdate ? this.data.cuenta.banco :'', [Validators.required]),
      tipoCuenta: new FormControl(this.esUpdate ? this.data.cuenta.tipoCuentaID :'', [Validators.required]),
      numeroCuenta: new FormControl(this.esUpdate ? this.data.cuenta.numeroCuenta :'', [
        Validators.required,
        Validators.maxLength(20),
        Validators.minLength(20),
        Validators.pattern(/^[0-9]*$/),
      ]),
    });
    this.initLists();
  }

  async initLists() {
    this.paisesActivos = await this.countriesService.getActiveCountries();

    if(this.esUpdate){
      [this.bancos, this.tiposCuentas] = await Promise.all([
        (await this.bancoService.getBanksByCountry(this.data.cuenta.banco.codigoPais)).filter(res=> res.codigoBanco !== 'BINANCE'),
        this.tipoCuentaService.getAccountTypes(this.data.cuenta.banco.codigoPais),
      ]);
      const bancoIndex = this.bancos.findIndex((elem: any): boolean => (elem.bancoID === this.data.cuenta.banco.bancoID));
      this.formCuenta?.get('banco')?.patchValue(this.bancos[bancoIndex]);
    }
  }
  async getBanks() {
    const paisSeleccionado = this.formCuenta?.get('pais')?.value;
    if (!paisSeleccionado) return;
    [this.bancos, this.tiposCuentas] = await Promise.all([
      (await this.bancoService.getBanksByCountry(paisSeleccionado)).filter(res=> res.codigoBanco !== 'BINANCE'),
      this.tipoCuentaService.getAccountTypes(paisSeleccionado),
    ]);
    this.formCuenta?.get('banco')?.setValue('');
    this.formCuenta?.get('tipoCuenta')?.setValue('');
  }
  ngOnInit() {}

  async guardarBeneficiario() {
    if (this.formCuenta?.invalid) return;
    const formValue = this.formCuenta?.value;
    const cuenta: CuentaBeneficiario = {
      ...formValue,
      bancoID: formValue.banco.bancoID,
      tipoCuentaID: formValue.tipoCuenta,
      usuarioID: this.usuarioID,

    };
    if(this.esUpdate){
       cuenta['cuentaBeneficiarioID'] = this.data.cuenta.cuentaBeneficiarioID;
        delete cuenta.banco;
        delete cuenta.tipoCuenta;
    }
    const ref = this.spinner.start();
    try {
      const resultado = await this.accountRecipientService.saveRecipientAccount(
        cuenta
      );
      if(this.esUpdate){
        Swal.fire({
          icon: 'success',
          title: 'Beneficiario editado',
          text: 'Su Beneficiario fue editado con éxito',
          showCancelButton: false,
          showDenyButton: false,
          confirmButtonText: 'Entendido',
          confirmButtonColor: '#1e5a3f',

        });
      }else{
        Swal.fire({
          icon: 'success',
          title: 'Beneficiario creado',
          text: 'Su Beneficiario fue registrado con éxito',
          showCancelButton: false,
          showDenyButton: false,
          confirmButtonText: 'Entendido',
          confirmButtonColor: '#1e5a3f',

        });
      }


      this.dialogRef.close(true);
    } catch (error) {
    }
    this.spinner.stop(ref);
  }

  cancel(){
    this.dialogRef.close();
  }
}
