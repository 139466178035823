<mat-stepper #stepper class="pt-4 py-4" [linear]="true">
  <mat-step [completed]="multipleExchangeService.transactionList.length > 0">
    <ng-template matStepLabel>Calcula tu cambio</ng-template>
    <app-recipient-select (seleccion)="onCuentaSeleccionada($event)"></app-recipient-select>
    <div class="flex flex-row justify-center items-center w-full">
    <div class="bg-aguacate-bg rounded-lg flex flex-col justify-center items-center w-full md:w-8/12">
    <app-calculator (continuarEmitter)="addDataCalculator($event)" class="w-full" [multiple]="true"></app-calculator>

      <button
      mat-raised-button color="primary"
      class="w-40 md:w-80 mb-10 my-4"
      type="button"
      (click)="addTransferencia()"
       >
      Continuar
      </button>
    </div>
  </div>
    <hr>
    <h1 class="text-lg font-bold">Lista de remesas</h1>
    <app-orders-list class="w-full md:w-8/12"></app-orders-list>
    <div class=" flex flex-row gap-4 justify-center items-center my-7">
      <button routerLink="/wallet/client/remittance"
      type="button"
      class=" text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
    >
     Cancelar
    </button>
       <button
        type="button"
        matStepperNext
        class=" text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
      >
       Continuar
      </button>
  </div>
  </mat-step>

  <mat-step >
    <ng-template matStepLabel>Realiza tu pago</ng-template>
    <app-payment-instructions-multiple [valorATransferir]="50000" [multiple]="true"></app-payment-instructions-multiple>
    <div class="w-full flex flex-row gap-4 justify-center items-center my-7">


      <button
      type="button"
      matStepperPrevious
      class=" text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
    >
     Regresar
    </button>
       <button
        type="button"
        (click)="confirmarEnvio()"
        class=" text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
      >
       Realizar envío
      </button>
  </div>
  </mat-step>

</mat-stepper>
