<div>
    <h1 class="m-4 text-xl font-bold text-center ">Facturas Exentas</h1>
    <div class="grid grid-cols-1">
        <div class="flex flex-col md:flex-row gap-5 sm:justify-center mb-4 w-full ">
            <mat-form-field class="w-[260px] mx-auto sm:max-w-sm lg:m-4">
                <mat-label>Ingresa un rango de fechas</mat-label>
                <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                    <input matStartDate formControlName="start" placeholder="Start date">
                    <input matEndDate formControlName="end" placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>

                <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Fecha fin invalida</mat-error>
            </mat-form-field>
            <form [formGroup]="formEmisor" *ngIf="formEmisor" class="mx-auto w-[260px] sm:w-[250px] lg:m-4">
                <mat-form-field>
                    <mat-label>Emisor</mat-label>
                    <mat-select [formGroup]="formEmisor" formControlName="emisor" required *ngIf="formEmisor">
                      <mat-option>Seleccione Emisor</mat-option>
                      <mat-option *ngFor="let emisor of emisores" [value]="emisor.rutemisor">
                        {{ emisor.cuenta }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
            </form>
            <button mat-raised-button color="primary" (click)="search()"
                class="h-[56px] button-search w-[200px] mt-0 mx-auto sm:max-w-xs lg:m-4">
                Buscar
                <mat-icon> search </mat-icon>
            </button>
        </div>
    </div>
    <div class="m-4 flex overflow-x-auto">
        <div class="justify-center mx-auto">
            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="shadow-lg" *ngIf="dataSource">
                <ng-container *ngFor="let column of columns; let first = first; let last = last;" [matColumnDef]="column.columnDef">
                    <mat-header-cell *matHeaderCellDef mat-sort-header style="background-color: #CEE9C2;"
                        [ngStyle]="column.headerStyle" [class.sticky-column]="column.isSticky">
                        {{column.header}}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" [ngStyle]="column.cellStyle" [class.sticky-column]="column.isSticky">
                        {{ column.cell(row) }}
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </table>
        </div>
    </div>
</div>
