import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { CuentaTrabajo } from 'src/app/dashboard/interfaces/cuenta-trabajo.interface';
import { Transaccion } from 'src/app/dashboard/interfaces/transaccion.interface';
import { WorkingAccountService } from 'src/app/dashboard/services/working-account.service';
import { columns, displayedColumns } from '../table-transactions-columns';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { TransaccionService } from 'src/app/dashboard/services/transaccion.service';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import { AssignTransactionsDTO } from 'src/app/dashboard/interfaces/assign-transactions.dto';
import Swal from 'sweetalert2';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AcceptTransactionDTO } from 'src/app/dashboard/interfaces/accept-transaction.dto';

@Component({
  selector: 'app-send-transaction-withdraw-modal-component',
  templateUrl: './send-transaction-withdraw-modal.component.html',
  styleUrls: ['./send-transaction-withdraw-modal.component.sass']
})
export class SendTransactionWithdrawModalComponent {
  datosForm: FormGroup | undefined;
  tiposID = ['CI', 'PASAPORTE', 'RUT'];
  transaction: any | undefined;
  constructor(
    private auth: CognitoService,
    private readonly transactionService: TransaccionService,
    private fb: FormBuilder,
    private spinner: SpinnerService, private workingAccountService: WorkingAccountService,
    public dialogRef: MatDialogRef<SendTransactionWithdrawModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any ){
      //nombre del beneficiario, DNI, Numero de cuenta.
      // public dialogRef: MatDialogRef<EditTransactionModalComponent>,
    //@Inject(MAT_DIALOG_DATA) public data: any
     this.transaction = data;
     this.initForm();
  }

  initForm(){
    this.datosForm = this.fb.group({
      referencia: new FormControl(''),

    });
  }

  async acceptTransaction(){
    if(this.datosForm?.invalid || !this.transaction?.id)return;
    const spinnerRef = this.spinner.start();

    const payload: AcceptTransactionDTO = {
      transactionID: this.transaction?.id,
      referencia: this.datosForm?.get('referencia')?.value,
    }
    try{
      const result =  await this.transactionService.acceptTransaction(payload);
      Swal.fire({
        icon: 'success',
        title: 'Transacción pagada',
        text: 'la transacción se ha pagado exitosamente',
        showCancelButton: false,
        showDenyButton: false,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#1e5a3f',

      });
      this.dialogRef.close(true);
    }catch(error: any){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.error.mensaje,
        showCancelButton: false,
        showDenyButton: false,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#1e5a3f',

      });
      this.dialogRef.close(true);
    }

    this.spinner.stop(spinnerRef);
  }

  close(){
    this.dialogRef.close();
  }
}
