import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class RecipientZelleService {
  API_URL = environment.apiGatewayURL;

  constructor(private http: HttpClient) {}

  saveRecipientAccount(recipientAccount: any): Promise<any> {
    const serviceURL = `${this.API_URL}recipient/zelle`;
    return firstValueFrom(this.http.post<any>(serviceURL,recipientAccount));
  }
  getByUser(userId: number): Promise<any[]>{
    const serviceURL = `${this.API_URL}recipient/zelle/${userId}`;
    return firstValueFrom(this.http.get<any[]>(serviceURL));
  }
  inactivateAccount(accountId: string): Promise<any>{
    const serviceURL = `${this.API_URL}recipient/zelle`;
    const body = { cuentaId: accountId };
    return firstValueFrom(this.http.put<any>(serviceURL, body));
  }

  getTransactionZelle(currentPage: number, pageSize: number): Promise<any>{
    const serviceURL = `${this.API_URL}transaccion/zelle?page=${currentPage}&pageSize=${pageSize}`;
    return firstValueFrom(this.http.get<any[]>(serviceURL));
  }

  getTransactionCriptoManualTransactions(id: string): Promise<any>{
    const serviceURL = `${this.API_URL}transaccion/manual-related?id=${id}`;
    return firstValueFrom(this.http.get<any[]>(serviceURL));
  }
}
