import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-money-order',
  templateUrl: './money-order.component.html',
  styleUrls: ['./money-order.component.css']
})
export class MoneyOrderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
