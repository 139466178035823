import { Component, EventEmitter, Output, OnInit  } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { CuentaRetiro } from 'src/app/dashboard/interfaces/cuenta-retiro.interface';
import { AddWithdrawAccountsComponent } from '../../../add-withdraw-accounts/add-withdraw-accounts.component';
import { WithdrawAccountService } from 'src/app/dashboard/services/withdraw-account.service';

@Component({
  selector: 'app-withdraw-accounts-step',
  templateUrl: './withdraw-accounts-step.component.html',
  styleUrls: ['./withdraw-accounts-step.component.sass']
})
export class WithdrawAccountsStepComponent implements OnInit{
  public subscription: Subscription = new Subscription();
  public user: any;
  public searchTxt: string = '';
  public wordFilter: string = '';

  constructor(
    private dialog: MatDialog,
    private withdrawAccountService: WithdrawAccountService,
    private store: Store<AppState>,
  ){}

  ngOnInit(): void {
    this.subscription.add(
      this.store.select('auth').subscribe(({ user }: any) => {
        this.user = user;
        this.getBeneficiarios();
      })
    );
  }

  @Output()
  public changeStep = new EventEmitter<number>();

  @Output()
  public beneficiarioOutput = new EventEmitter<CuentaRetiro>();

  @Output()
  public goBack = new EventEmitter<any>();

  public accountSelected:CuentaRetiro | undefined = undefined;

  public accountsList:CuentaRetiro[] = [];
  public accountsListFiltered:CuentaRetiro[] = [];

  openCreateDialog(cuenta?: CuentaRetiro):void{
    const dialogRef = this.dialog.open(AddWithdrawAccountsComponent, {
      data: {
        type: cuenta? 'UPDATE': 'CREATE',
        cuenta
      },
      height: '70%'
    });
    dialogRef.afterClosed().subscribe(async result => {
      if(result == true){
        await this.getBeneficiarios();
      }
    })
  }

  clearInput() {
    this.searchTxt = '';
  }

  elegir( account: CuentaRetiro ):void{
    if(this.accountSelected === account ){
      this.accountSelected = undefined;
      return;
    }
    this.accountSelected = account;
  }

  emitChangeStep( ): void{
    this.changeStep.emit( 3 );
    this.beneficiarioOutput.emit( this.accountSelected );
  }

  async getBeneficiarios(): Promise<void>{
    this.accountsList = await this.withdrawAccountService.getWithdrawAccounts();
    this.accountsListFiltered = this.accountsList;
  }

  buscar(event: KeyboardEvent): void {
    var searchWord = this.searchTxt.toLowerCase();
    if(searchWord === '') {
      this.accountsListFiltered = this.accountsList;
      return;
    }
    this.accountsListFiltered = this.accountsList.filter( beneficiario => {
      let alias = beneficiario.alias;
      return alias.toLowerCase().includes(searchWord.toLowerCase());
    }
    )
  }

  goToBack(): void {
    this.goBack.emit();
  }
}
