import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import { BinanceService } from 'src/app/dashboard/services/binance.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-set-usdt-rate',
  templateUrl: './set-usdt-rate.component.html',
  styleUrls: ['./set-usdt-rate.component.sass']
})
export class SetUsdtRateComponent {
  formCuenta: FormGroup | undefined;
  email: string  = '';
  constructor(
    private fb: FormBuilder,
    private auth: CognitoService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private binanceService: BinanceService,
    private dialogRef: MatDialogRef<SetUsdtRateComponent>,
    private spinner: SpinnerService,
  ) {
    this.initFormCuenta();
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }


  async initFormCuenta() {
    const cognitoUser = await this.auth.getUser();
    this.email = cognitoUser.attributes['email'];
    this.formCuenta = this.fb.group({
      tasaCLPUSDT: new FormControl( 0, [Validators.required, Validators.min(0), Validators.maxLength(9)]),
    });
  }

  async actualizarTasas(){
    console.log(this.formCuenta?.get('tasaCLPUSDT')?.errors);
    if(this.formCuenta?.invalid)return;

    if( !this.formCuenta?.get('tasaCLPUSDT')?.value){
      console.log(this.formCuenta?.get('tasaCLPUSDT')?.errors);
      this.formCuenta?.get('tasaCLPUSDT')?.setErrors({greaterThanZero: true});
      return;
    }
    const tasa = this.formCuenta?.get('tasaCLPUSDT')?.value;
    let ref = this.spinner.start();
    try{
      await this.binanceService.updateRateCLPUSDT(tasa, this.email);
      this.spinner.stop(ref);
      Swal.fire('Tasa actualizada','Se han actualizado los valores', 'success').then(()=>{
        this.dialogRef.close(true);
      });
    }catch(err: any){
      console.log(
        'update error', err.error.message);
      this.spinner.stop(ref);
      if(err.error && err.error.message === 'EXCEEDS_DIFF'){
        Swal.fire('Error','La tasa nueva excede la diferencia del 0.8% respecto a la anterior', 'error').then(()=>{
          this.dialogRef.close(false);
        });
      }else{
        Swal.fire('Error','No se ha podido actualizar la tasa', 'error').then(()=>{
          this.dialogRef.close(false);
        });
      }

    }
  }
}
