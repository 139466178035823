import { Component, NgModule } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CartolaService } from '../../services/cartola.service';
import { int } from 'aws-sdk/clients/datapipeline';
import { SpinnerService } from 'src/app/shared/services/spinner.service';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.sass'],
})

export class AccountsComponent {
  dataSource: MatTableDataSource<any> | undefined;

  constructor(private cartolaService: CartolaService, private spinner: SpinnerService) {
    let spinnerRef = this.spinner.start();
    this.loadData();
    this.spinner.stop(spinnerRef);
  }

  displayedColumns: string[] = [
    'id',
    'cuenta',
    'banco',
    'numeroCuenta',
    'rutemisor',
    'tipoCuenta',
    'acciones'
  ];
  
  columns = [
    {
      columnDef: 'id',
      header: 'Id',
      cell: (element: any) => `${element.id}`,
      headerStyle: { 'left': '0','text-align':'left', 'font-weight': 'bold', 'width':'80px', 'max-width':'80px'},
      cellStyle: { 'left': '0','text-align':'left', 'font-weight': 'bold', 'width':'80px', 'max-width':'80px'},
    },{
      columnDef: 'banco',
      header: 'Banco',
      cell: (element: any) => `${element.banco}`,
      headerStyle: { 'left': '0','text-align':'left', 'font-weight': 'bold', 'width':'140px', 'min-width':'200px'},
      cellStyle: { 'left': '0','text-align':'left', 'width':'140px', 'min-width':'200px'},
    },
    {
      columnDef: 'cuenta',
      header: 'Cuenta',
      cell: (element: any) => `${element.cuenta}`,
      headerStyle: { 'text-align':'left', 'font-weight': 'bold', 'min-width':'140px'},
      cellStyle: { 'text-align':'left','min-width':'140px'},
    },
    {
      columnDef: 'numeroCuenta',
      header: 'Numero de Cuenta',
      cell: (element: any) => `${element.numeroCuenta}`,
      headerStyle: { 'text-align':'left', 'font-weight': 'bold', 'min-width':'120px'},
      cellStyle: { 'text-align':'left','min-width':'120px'},
    },
    {
      columnDef: 'tipoCuenta',
      header: 'Tipo de Cuenta',
      cell: (element: any) => `${this.formatRut(element.tipoCuenta)}`,
      headerStyle: { 'text-align':'left', 'font-weight': 'bold', 'min-width':'120px'},
      cellStyle: { 'text-align':'left','min-width':'120px'},
    },
    {
      columnDef: 'rutemisor',
      header: 'Rut',
      cell: (element: any) => `${this.formatRut(element.rutemisor)}`,
      headerStyle: { 'text-align':'left', 'font-weight': 'bold', 'min-width':'135px'},
      cellStyle: { 'text-align':'left','min-width':'135px'},
    },

  ];

  async loadData(): Promise<void> {
    const accounts = await this.cartolaService.getEmisores();
    this.dataSource = new MatTableDataSource(accounts);
  }

  async updateAccountStatus(id: number, estado: boolean){
    await this.cartolaService.setEstadoEmisor(id, estado);
    this.loadData();
  }

  formatRut(rut: string): string {
    if (!rut) return '';
    
    const rutDigits = rut.replace(/[^\d]/g, ''); 
    const rutLength = rutDigits.length;
  
    if (rutLength < 3) return rut;
  
    const lastDigit = rutDigits.charAt(rutLength - 1);
    const mainPart = rutDigits.substring(0, rutLength - 1);
  
    let formattedRut = mainPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  
    formattedRut = formattedRut + '-' + lastDigit;
  
    return formattedRut;
  }

}

