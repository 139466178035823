<h1 class="text-2xl font-bold mx-14 my-7">Valores actuales</h1>

<div *ngIf="currentValues && currentRates">
  <h1 class="text-xl font-bold mx-14 my-7">
    Tasa USD-CLP {{ currentRates.tasaCLP | number : "1.2-2" }}
  </h1>
  <h1 class="text-xl font-bold mx-14 my-7">
    Porcentaje comisión: {{ currentRates.comision | number : "1.2-5" }}
  </h1>

  <button
    (click)="actualizarTasa()"
    type="button"
    class="mx-14 mb-4 text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
  >
    Actualizar tasas
  </button>

  <button
  (click)="copiarTasa()"
  type="button"
  class="mb-4 text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
>
  Copiar tasa
</button>
  <h1 class="text-xl font-bold mx-14 my-7">
    Mínimo {{ currentValues.limiteInferior | currency:'':'' }} BS
  </h1>
  <div class="grid xl:grid-cols-4 sm:grid-cols-2 gap-4 mx-14">
    <app-rate-value-card
      [bank]="'Banesco'"
      [rateOrigin]="currentRates.valoresVES.valorBanesco"
      [imgUrl]="'assets/img/bancos/banesco.png'"
      [rateDestination]="currentRates.minimo.tasaBanesco"
    ></app-rate-value-card>
    <app-rate-value-card
      [bank]="'Mercantil'"
      [rateOrigin]="currentRates.valoresVES.valorMercantil"
      [imgUrl]="'assets/img/bancos/mercantil.png'"
      [rateDestination]="currentRates.minimo.tasaMercantil"
    ></app-rate-value-card>
    <app-rate-value-card
      [bank]="'Provincial'"
      [rateOrigin]="currentRates.valoresVES.valorProvincial"
      [imgUrl]="'assets/img/bancos/provincial.png'"
      [rateDestination]="currentRates.minimo.tasaProvincial"
    ></app-rate-value-card>
    <app-rate-value-card
      [bank]="'Venezuela'"
      [rateOrigin]="currentRates.valoresVES.valorVenezuela"
      [imgUrl]="'assets/img/bancos/venezuela.png'"
      [rateDestination]="currentRates.minimo.tasaVenezuela"
    ></app-rate-value-card>
  </div>

  <h1 class="text-xl font-bold mx-14 my-7">
    Mínimo {{ currentValues.limiteSuperior }} BS
  </h1>
  <div class="grid xl:grid-cols-4 sm:grid-cols-2 gap-4 mx-14">
    <app-rate-value-card
      [bank]="'Banesco'"
      [rateOrigin]="currentRates.valoresVES.valorBanesco"
      [imgUrl]="'assets/img/bancos/banesco.png'"
      [rateDestination]="currentRates.maximo.tasaBanesco"
    ></app-rate-value-card>
    <app-rate-value-card
      [bank]="'Mercantil'"
      [rateOrigin]="currentRates.valoresVES.valorMercantil"
      [imgUrl]="'assets/img/bancos/mercantil.png'"
      [rateDestination]="currentRates.maximo.tasaMercantil"
    ></app-rate-value-card>
    <app-rate-value-card
      [bank]="'Provincial'"
      [rateOrigin]="currentRates.valoresVES.valorProvincial"
      [imgUrl]="'assets/img/bancos/provincial.png'"
      [rateDestination]="currentRates.maximo.tasaProvincial"
    ></app-rate-value-card>
    <app-rate-value-card
      [bank]="'Venezuela'"
      [rateOrigin]="currentRates.valoresVES.valorVenezuela"
      [imgUrl]="'assets/img/bancos/venezuela.png'"
      [rateDestination]="currentRates.maximo.tasaVenezuela"
    ></app-rate-value-card>
  </div>
</div>
