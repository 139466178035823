<div style="display: flex; justify-content: center; ">
  <div class="w-full md:w-9/12 mt-4">
      <h1 class="font-semibold text-xl text-center mb-4">Modificar parámetros para tasa automática USDT</h1>
      <form [formGroup]="ratesForm" style="display: flex; flex-direction: column;">
        <mat-form-field>
          <mat-label >Valor Solvencia (ValueSL)</mat-label>
          <input matInput formControlName="valueSL" appOnlynumber matInputCommified type="text" placeholder="Value SL">
          <span matTextPrefix>CLP  &nbsp; &nbsp; </span>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Valor liquidez (ValueLQ)</mat-label>
          <input matInput formControlName="valueLQ"  appOnlynumber matInputCommified type="text" placeholder="Value LQ">
          <span matTextPrefix>USDT  &nbsp;</span>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Descuento 1</mat-label>
          <input matInput formControlName="discount1"appOnlynumber [allowNegative]="true"  type="text" placeholder="Discount 1">
          <span matTextPrefix>USDT &nbsp;</span>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Descuento 2</mat-label>
          <input matInput formControlName="discount2"  appOnlynumber [allowNegative]="true"  type="text" placeholder="Discount 2">
          <span matTextPrefix>USDT &nbsp;</span>
        </mat-form-field>
        <div class="flex flex-row justify-center gap-10">
          <button mat-raised-button color="primary" (click)="showConfirm()">Actualizar</button>
          <button mat-raised-button  (click)="reset()">Restablecer</button>
        </div>
      </form>

  </div>
</div>
<div class="flex flex-col w-full justify-center items-center">
  <app-rate-update-type [showOnlyUSDT]="true" class="w-full md:w-9/12"></app-rate-update-type>
</div>

