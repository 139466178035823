import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CuentaBeneficiario } from '../interfaces/cuenta-beneficiario.interface';
import { CuentaTrabajo } from '../interfaces/cuenta-trabajo.interface';
import { firstValueFrom } from 'rxjs';
import { SaveBalanceCajeroDTO } from '../interfaces/save-balance-cajero.dto';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class WorkingAccountService {
  API_URL = environment.apiGatewayURL;

  constructor(private http: HttpClient, private datePipe: DatePipe) {}

  saveWorkingAccount(workingAccount: CuentaTrabajo): Promise<CuentaTrabajo> {
    const serviceURL = `${this.API_URL}account/working-accounts`;
    return firstValueFrom(this.http.post<CuentaTrabajo>(serviceURL,workingAccount));
  }


  saveBalanceCajero(body: SaveBalanceCajeroDTO): Promise<any> {
    const serviceURL = `${this.API_URL}cashier-balance`;
    return firstValueFrom(this.http.post<any>(serviceURL,body));
  }
  getWorkingAccounts(countryCode?: string[]): Promise<CuentaTrabajo[]>{
    const serviceURL = `${this.API_URL}account/working-accounts${countryCode ? '?countries=' + countryCode : ''}`;
    return firstValueFrom(this.http.get<CuentaTrabajo[]>(serviceURL));
  }

  inactivateAccount(accountId: number): Promise<any>{
    const serviceURL = `${this.API_URL}account/working-accounts/${accountId}`;
    return firstValueFrom(this.http.put<any>(serviceURL, {}));
  }

  getBalancesCajero(accountId: number, startDate: Date, endDate: Date){
    const dates = [this.datePipe.transform(startDate, 'YYYY-MM-dd'),this.datePipe.transform(endDate, 'YYYY-MM-dd') ];
    const serviceURL = `${this.API_URL}cashier-balance/${accountId}?startDate=${dates[0]}&endDate=${dates[1]}`;
    return firstValueFrom(this.http.get<any[]>(serviceURL));
  }

  getStatusCajero(countryCode: string[]): Promise<any[]>{
    const countries = countryCode ? '?countries=' + countryCode.join(',') : '';
    const serviceURL = `${this.API_URL}cashier-balance/status?countryCodeList=${ countries }`;
    return firstValueFrom(this.http.get<any[]>(serviceURL));
  }

}
