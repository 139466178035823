<div class="flex flex-col gap-4 p-4">
  <h1 class="font-bold text-2xl self-start mt-4 ml-4">{{this.view==='historial'? 'Historial de Transacciones' : 'Transacciones alto valor'}}</h1>
  <div class="flex flex-row gap-5 items-start align-middle mb-4" style="max-width: 800px;" *ngIf="viewInfo">
    <button mat-raised-button color="primary" class="h-12 flex-1 max-w-xs" (click)="onHistorialClick()" [disabled]="this.view === 'historial'">Historial de Transacciones</button>
    <button mat-raised-button color="primary"  class="h-12 flex-1 max-w-xs" (click)="onListaManualesClick()" [disabled]="this.view === 'lista manuales'">Transacciones de alto valor</button>
  </div>
  <div>
    <mat-paginator #paginator
               class="demo-paginator self-left"
               (page)="onPageChange($event)"
               [length]="totalItems"
               [pageSize]="pageSize"
               [disabled]="false"
               [showFirstLastButtons]="true"
               [pageSizeOptions]="[5, 10, 25, 50, 100]"
               [hidePageSize]="false"
               [pageIndex]="currentPage-1"
               aria-label="Select page">
  </mat-paginator>
  </div>

  <div *ngIf="this.view==='historial'" class="flex flex-col gap-4 p-4">
    <app-transaction-card *ngFor="let transaccion of listaTransacciones" [transaccion]="transaccion"></app-transaction-card>
  </div>

  <div class="mx-4 mb-4" style="z-index: 0;" *ngIf="this.view==='lista manuales'">
    <div class="flex flex-row overflow-x-auto">
      <table mat-table [dataSource]="dataSourceHistorial" matSort (matSortChange)="sortDataHistory($event)" *ngIf="dataSourceHistorial">
        <ng-container
          *ngFor="let column of columnsHistorial; let first = first; let last = last;"
          [matColumnDef]="column.columnDef"
        >
          <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          style="background-color: #CEE9C2;"
          [ngStyle]="column.headerStyle"
          >
            {{column.header}}
          </mat-header-cell>
          <mat-cell
          *matCellDef="let row"
          [ngStyle]="column.cellStyle"
          >
            <ng-container *ngIf="column.columnDef === 'estado' && column.iconColor; else otherColumns">
              <mat-icon [style.color]="column.iconColor(row)" [matTooltip]="column.val(row)">{{ column.cell(row) }}</mat-icon>
            </ng-container>
            <ng-template #otherColumns>
              {{ column.cell(row) }}
            </ng-template>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumnsHistorial"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsHistorial;"></mat-row>
      </table>
    </div>
  </div>
</div>
