import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { CartolaService } from '../../services/cartola.service';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { BinanceService } from '../../services/binance.service';
import { MatStepper } from '@angular/material/stepper';
import { BeneficiarioCriptomoneda } from '../../interfaces/beneficiario-criptomoneda.interface';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { BeneficiaryComponent } from './components/beneficiary/beneficiary.component';
import { ActionsService } from 'src/app/shared/services/actions.service';

import Swal from 'sweetalert2';
import { CalculatorCryptoComponent } from './components/calculator-crypto/calculator.component';
import { HoursOperationService } from '../../services/hours-operation.service';
import { Router } from '@angular/router';
import { AutomaticRateService } from '../../services/automatic-rate.service';
import { ParameterService } from '../../services/parameter.service';
import { ParameterTypeEnum, ParameterValueEnum } from 'src/app/shared/enums/parameter-type.enum';
import { ParametrosEnum } from 'src/app/shared/enums/parameters.enum';


@Component({
  selector: 'app-calculator-cryto',
  templateUrl: './calculator-cryto.component.html',
  styleUrls: ['./calculator-cryto.component.sass']
})
export class CalculatorCrytoComponent implements OnInit {

  isVertical = false;
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    if (window.innerWidth <= 768) {
      this.isVertical = true;
    } else {
      this.isVertical = false;
    }
  }
  constructor(
    private cartolaService: CartolaService,
    private store: Store<AppState>,
    private binanceService: BinanceService,
    private actionService: ActionsService,
    private hourOperations: HoursOperationService,
    private router: Router,
    private automaticRateService: AutomaticRateService,
    private parameterService: ParameterService
  ) {
    this.getCurrentValues();
    this.validarEstado();
  }


  @ViewChild('beneficiarioComponent') beneficiaryComponent: BeneficiaryComponent | undefined;
  @ViewChild('stepper') stepper: MatStepper | undefined;
  @ViewChild('calculatorComponent') calculatorComponent: CalculatorCryptoComponent | undefined;

  public subscription: Subscription = new Subscription();
  public user: any;
  public saldoActual = 0;
  public sobregiroDisponible: number | undefined = 0;
  public saldoContable: number | undefined = 0;
  public lineaCreditoBloqueada: boolean | undefined = false;
  public lineaCredito: number | undefined = 0;
  public hasLoaded: boolean = false;
  public usdt_clp_tax: number = 0;
  public aguacate_usdt_rate: number = 0;
  public stepPass2: boolean = false;
  public stepPass3: boolean = false;
  public stepPass4: boolean = false;
  public clpCurrency: number = 0;
  public usdCurrency: number = 0;
  public beneficiario: BeneficiarioCriptomoneda | undefined;
  public valorCambioId: number = 0;
  public currentValues: any;
  public aguacateUsdtRate: number = 0;
  public rateValues: any;
  public precioSpot: number = 0;
  public delta: number = 0;
  estadoOperaciones = true;
  estadoStopOperacion = true;

  ngOnInit(): void {
    this.onWindowResize();
    this.subscription.add(
      this.store.select('auth').subscribe(({ user }: any) => {
        this.user = user;
        if (!user || !user.segmentacionId) {
        } else {
          this.getCurrentValues();
        }
        this.obtenerSaldo();
      })
    );
    this.subscription.add(
      this.actionService.subjectTasaUSDT.subscribe(res => {
        this.getCurrentValues();
        if (this.stepper && this.stepper.selectedIndex !== 0) {
          Swal.fire('Tasas actualizadas', 'Se han actualizado las tasas, será regresado al primer paso', 'warning').then(() => { });
          this.stepper.selectedIndex = 0;
        }
      })
    )

  }


  async validarEstado() {
    this.estadoOperaciones = await this.hourOperations.getEstadoOperacion('USDT');
    this.estadoStopOperacion = await this.hourOperations.getEstadoOperacion('USDT_STOP');
    const date = new Date().getDay();
    const message = [6, 7].includes(date) ? 'Hemos cerrado operaciones, muchas gracias por preferirnos, Nos vemos el Lunes a las 9:00 am' :
      'Hemos cerrado operaciones, muchas gracias por preferirnos, Nos vemos Mañana a las 9:00 am'
    if (!this.estadoOperaciones) {
      Swal.fire(
        {
          confirmButtonColor: '#1e5a3f',
          title: 'Operación cerrada',
          text: message,
        }
      );
      this.router.navigate(['/wallet']);
    }
    if (!this.estadoStopOperacion) {
      Swal.fire(
        {
          confirmButtonColor: '#1e5a3f',
          title: 'Operación Pausada',
          text: 'Volvemos en 10 min',
        }
      );
      this.router.navigate(['/wallet']);
    }
  }

  async obtenerSaldo() {
    if (this.user?.usuarioId) {
      const { saldo, lineaCreditoId, balancePermitido, lineaBloqueada, sobregiro } = await this.cartolaService.getSaldoCliente(this.user.usuarioId);
      this.saldoActual = saldo;
      this.sobregiroDisponible = sobregiro;
      this.saldoContable = balancePermitido;
      this.lineaCreditoBloqueada = lineaBloqueada;
      this.lineaCredito = lineaCreditoId;
    }
  }

  async getCurrentValues() {
    this.rateValues = await this.automaticRateService.getUsdtRateForUser();
    this.currentValues = this.rateValues.valoresCambio;
    this.aguacateUsdtRate = this.currentValues.tasaUsdtClp;
    this.precioSpot = +this.rateValues.spot.valor;
    this.delta = Math.round(+this.rateValues.delta * 100) / 100;
    if (this.user?.segmentacionId === 1) {
      this.usdt_clp_tax = this.calculateUsdtClpRate();
    } else {
      if(this.user?.minimumTresholdUsdt < this.clpCurrency){
        this.usdt_clp_tax = +this.user?.segmentacionUsdtRate + +this.precioSpot;
      } else {
        this.usdt_clp_tax = this.calculateUsdtClpRate();
      }
    }
    this.valorCambioId = this.currentValues.id;
  }

  calculateUsdtClpRate(): number {
    if (this.clpCurrency < 5000000) {
      return this.aguacateUsdtRate;
    }
    if (this.clpCurrency <= 10000000) {
      return this.precioSpot + (0.97 * this.delta);
    }
    if (this.clpCurrency <= 15000000) {
      return this.precioSpot + (0.94 * this.delta);
    }
    if (this.clpCurrency <= 20000000) {
      return this.precioSpot + (0.91 * this.delta);
    }
    if (this.clpCurrency <= 25000000) {
      return this.precioSpot + (0.88 * this.delta);
    }
    if (this.clpCurrency <= 30000000) {
      return this.precioSpot + (0.85 * this.delta);
    }
    if (this.clpCurrency <= 35000000) {
      return this.precioSpot + (0.82 * this.delta);
    }
    if (this.clpCurrency <= 40000000) {
      return this.precioSpot + (0.79 * this.delta);
    }
    if (this.clpCurrency > 40000000) {
      return this.precioSpot + (0.76 * this.delta);
    }
    return this.aguacateUsdtRate;

  }

  async returngetCurrentTax() {
    const currentValues = await this.binanceService.getConversionData();
    const precioSpotActual = await this.parameterService.getParameter(ParametrosEnum.PRECIO_SPOT);
    if(currentValues.tasaUsdtClp != this.aguacateUsdtRate && this.aguacateUsdtRate != 0){
    //if (((currentValues.tasaUsdtClp < (this.aguacateUsdtRate + 0.2)) && this.aguacateUsdtRate != 0) || ((precioSpotActual < this.precioSpot + 0.2) && this.precioSpot != 0)) {
      this.getCurrentValues();
      if (this.stepper && this.stepper.selectedIndex !== 0) {
        Swal.fire('Tasas actualizadas', 'Se han actualizado las tasas, será regresado al primer paso', 'warning').then(() => { });
        this.stepper.selectedIndex = 0;
        setTimeout(() => this.calculatorComponent!.transformCurrency('clp'), 1000);
      }
    }
  }

  async validateGetCurrentValues() {
    const currentValues = await this.binanceService.getConversionData();
    let last_tax = this.usdt_clp_tax;
    if (last_tax != currentValues.tasaUsdtClp) {

    }
    this.usdt_clp_tax = currentValues.tasaUsdtClp;
    this.valorCambioId = currentValues.id;
  }


  nextStep(step: number): void {
    switch (step) {
      case 2: {
        this.stepPass2 = true
        break
      }
      case 3: {
        this.stepPass3 = true
        break
      }
    }
    setTimeout(() => {
      if (this.stepper) {
        this.stepper.next();
      }
    }, 300);
  }

  backStep(): void {
    setTimeout(() => {
      if (this.stepper) {
        this.stepper.previous();
      }
    }, 300);
  }

  assignClp(clp: number) {
    this.clpCurrency = clp;
    this.getCurrentValues();
    if (this.clpCurrency == 0) this.stepPass2 = false;
  }

  assignUsd(usd: number) {
    this.usdCurrency = usd;
    if (this.usdCurrency == 0) this.stepPass2 = false;
  }

  assignBeneficiario(beneficiario: BeneficiarioCriptomoneda) {
    this.beneficiario = beneficiario;
  }

  onStepChange(event: StepperSelectionEvent): void {
    // const newIndex = event.selectedIndex;
    this.beneficiaryComponent!.clearInput();
    this.beneficiaryComponent!.beneficiarioEscogido = undefined;
    this.beneficiaryComponent!.listaBeneficiariosFiltrados = this.beneficiaryComponent!.listaBeneficiarios;
    this.stepPass3 = false;
    this.returngetCurrentTax();
  }




}


