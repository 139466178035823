<div class="text-center mt-4">
    <h1><b>Crear Transacción Manual</b></h1>
</div>
<div class="col manual self-center overflow-hidden m-5">
    <form [formGroup]="createForm" (ngSubmit)="onSubmit()" class="form-container" *ngIf="createForm">
        <div class="grid grid-cols-2 gap-4">
            <div class="form-field">
                <mat-form-field class="w-full">
                <mat-label>Usuario Id</mat-label>
                <input matInput formControlName="usuarioId"/>
                </mat-form-field>
            </div>
            <div class="form-field">
                <mat-form-field class="w-full">
                <mat-label>Total Destino</mat-label>
                <input matInput formControlName="totalDest" appOnlynumber placeholder="Total Destino"/>
                </mat-form-field>
            </div>
        </div>
    
        <div class="grid grid-cols-2 gap-4">
            <div class="form-field">
                <mat-form-field class="w-full">
                <mat-label>Tasa</mat-label>
                <input matInput formControlName="tasa" appOnlynumber placeholder="Tasa" (change)="loadDestinoOnTasaChange()" required/>
                </mat-form-field>
            </div>
            <div class="form-field w-full mat-form-field">
                <mat-form-field class="w-full">
                    <mat-label>Moneda Destino</mat-label>
                    <mat-select required formControlName="monedaDestino">
                    <mat-option>Seleccione una moneda</mat-option>
                    <mat-option *ngFor="let filter of filters" [value]="filter" >
                        {{ selectedCurren[filter]}}
                    </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    
        <div class="grid grid-cols-2 gap-4">
            <div class="form-field">
                <mat-form-field class="w-full">
                    <mat-label>Moneda Local</mat-label>
                    <mat-select required formControlName="monedaLocal" required>
                    <mat-option>Seleccione una moneda</mat-option>
                    <mat-option *ngFor="let filter of filtersLocal" [value]="filter">
                        {{ selectedCurren[filter]}}
                    </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-field">
                <mat-form-field class="w-full">
                <mat-label>Monto Promesa</mat-label>
                <input matInputCommified matInput formControlName="totalEnvio" placeholder="Monto Promesa" appOnlynumber (change)="loadDestino()"/>
                </mat-form-field>
            </div>
        </div>
    
        <div class="grid grid-cols-1 gap-4">
            <div class="form-field">
                <mat-form-field class="w-full">
                <mat-label>Wallet TRX</mat-label>
                <input matInput formControlName="walletTrx" placeholder="Wallet TRX" required/>
                </mat-form-field>
            </div>
        </div>
    
        <div class="gap-4 w-full flex flex-row items-center align-middle justify-center">
        <button type="submit" mat-raised-button color="primary" [disabled]="createForm.invalid">Crear transacción</button>
        </div>
    </form>
</div>
<div class="my-4 text-center">
    <button type="submit" mat-raised-button color="primary" (click)="closeDialog()">Cancelar</button>
</div>