
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { Banco } from 'src/app/dashboard/interfaces/banco';
import { Pais } from 'src/app/dashboard/interfaces/country.interface';
import { CuentaBeneficiario } from 'src/app/dashboard/interfaces/cuenta-beneficiario.interface';
import { TipoCuenta } from 'src/app/dashboard/interfaces/tipo-cuenta.interface';
import { AccountRecipientService } from 'src/app/dashboard/services/account-recipient.service';
import { AccountTypeService } from 'src/app/dashboard/services/account-type.service';
import { BanksService } from 'src/app/dashboard/services/banks.service';
import { CountriesService } from 'src/app/dashboard/services/countries.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import Swal from 'sweetalert2';
import { WorkingAccountService } from '../../services/working-account.service';
import { CuentaTrabajo } from '../../interfaces/cuenta-trabajo.interface';
import { SaveBalanceCajeroDTO } from '../../interfaces/save-balance-cajero.dto';
import { CognitoService } from 'src/app/auth/services/cognito.service';

@Component({
  selector: 'app-transaction-cashier',
  templateUrl: './transaction-cashier.component.html',
  styleUrls: ['./transaction-cashier.component.sass']
})
export class TransactionCashierComponent {
  formCuenta: FormGroup = new FormGroup({});
  paisesActivos: Pais[] = [];
  tipos = ['TRABAJO'];
  tiposTransaccion = ['ABONO', 'DEBITO'];
  bancos: Banco[] = [];
  usuarioID: number | undefined;
  cuentasTrabajo: CuentaTrabajo[] = [];
  subscriptions: Subscription = new Subscription();
  esUpdate = false;
  email = '';
  constructor(
    private fb: FormBuilder,
    private countriesService: CountriesService,
    private bancoService: BanksService,
    private tipoCuentaService: AccountTypeService,
    private accountRecipientService: AccountRecipientService,
    private store: Store<AppState>,
    private spinner: SpinnerService,
    private workingAccountService: WorkingAccountService,
    private auth: CognitoService
  ) {

    this.initFormCuenta();
    this.formCuenta = this.fb.group({
      monto: new FormControl(0, [Validators.required,   Validators.pattern(/^[0-9]*$/),]),
      tipoTransaccion: new FormControl('', [Validators.required,  Validators.maxLength(40)]),
      tipoAbonoDebito: new FormControl( '', [Validators.required,  Validators.maxLength(40)]),
      pais: new FormControl( '', [Validators.required]),
      cuenta: new FormControl('', [Validators.required]),
      comentario: new FormControl('', [Validators.maxLength(120)]),
      interna:  new FormControl(false, [Validators.required]),
      precioCompra: new FormControl(0, [Validators.pattern(/^[0-9]*$/),]),
    });
  }



  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async initFormCuenta() {
    this.subscriptions.add(
      this.store.select('auth').subscribe(({ user }) => {
        this.usuarioID = user?.usuarioId;
      })
    );



    this.initLists();
  }

  async initLists() {
    this.paisesActivos = await this.countriesService.getActiveCountries();


  }
  async getBanks() {
    const paisSeleccionado = this.formCuenta?.get('pais')?.value;
    if(!paisSeleccionado) return;
    this.cuentasTrabajo = (await this.workingAccountService.getWorkingAccounts([paisSeleccionado])).filter(res=>
      res.banco?.nombre !== 'BINANCE'
    );

    // const paisSeleccionado = this.formCuenta?.get('pais')?.value;
    // if (!paisSeleccionado) return;
    // [this.bancos, this.tiposCuentas] = await Promise.all([
    //   (await this.bancoService.getBanksByCountry(paisSeleccionado)).filter(res=> res.codigoBanco !== 'BINANCE'),
    //   this.tipoCuentaService.getAccountTypes(paisSeleccionado),
    // ]);
  }
  ngOnInit() {}

  getFormValidationErrors() {
    Object.keys(this.formCuenta.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.formCuenta.controls[key].errors ||  {}


      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
         console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }
  async guardarBeneficiario() {
    this.getFormValidationErrors();
    console.log(this.formCuenta?.invalid);
    console.log(this.formCuenta?.errors);
    if (this.formCuenta?.invalid) return;

    const user = await this.auth.getAuthUser();
    console.log(user);
    console.log(this.formCuenta?.value);
    console.log()
    const formValue = this.formCuenta?.value;
    const balanceCajero : SaveBalanceCajeroDTO = {
      monto: formValue.monto,
      tipoTransaccion: formValue.tipoTransaccion,
      tipo: formValue.tipoAbonoDebito,
      cuentaTrabajoID: formValue.cuenta,
      precioCompra: formValue.precioCompra,
      comentario: formValue.comentario,
      email: user.attributes.email,
      interno: formValue.interna

    }
    const ref = this.spinner.start();
    try {
      const resultado = await this.workingAccountService.saveBalanceCajero(
        balanceCajero
      );
    //  console.log(resultado);
      this.spinner.stop(ref);
      this.formCuenta.reset({
        monto: 0,
        tipoTransaccion: '',
        tipoAbonoDebito:'',
        pais: '',
        cuenta: '',
        comentario: '',
        interna:  false,
        precioCompra:0,
      });
        Swal.fire({
          icon: 'success',
          title: `${balanceCajero.tipoTransaccion} registrado`,
          text: 'Su transacción fue registrada   con éxito',
          showCancelButton: false,
          showDenyButton: false,
          confirmButtonText: 'Entendido',
          confirmButtonColor: '#1e5a3f',

        });
      } catch (error) {
      console.log(error);
    this.spinner.stop(ref);
  }}}
