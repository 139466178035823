import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { Pagador } from 'src/app/dashboard/interfaces/pagador';
import { SpinnerService } from 'src/app/shared/services/spinner.service';

@Component({
  selector: 'app-payer',
  templateUrl: './payer.component.html',
  styleUrls: ['./payer.component.css']
})
export class PayerComponent implements OnInit {
  @Input() cuenta: Pagador | undefined;
  @Output() deleteAccount: EventEmitter<any> = new EventEmitter();
  @Output() actualizarCuenta: EventEmitter<Pagador> = new EventEmitter();
  constructor(private spinner: SpinnerService, private snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  async inactivarCuenta(){
   // if( !(this.cuenta && this.cuenta.cuentaBeneficiarioID))return;
    const spinnerRef  = this.spinner.start();
    try {
    //  await this.accountRecipientService.inactivateAccount(this.cuenta.cuentaBeneficiarioID);
      this.snackBar.open('Cuenta eliminada correctamente', 'Ok', {
        duration: 3000
      });
      this.deleteAccount.emit();
    } catch (error) {
      console.log(error);
    }
    this.spinner.stop(spinnerRef);
   
  }

  actualizar(){
    this.actualizarCuenta.emit(this.cuenta);
  }

}
