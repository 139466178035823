import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { MatTableDataSource } from '@angular/material/table';
import { formatDate } from 'src/app/shared/utils/date.util';
import { CartolaService } from '../../services/cartola.service';
import { Sort } from '@angular/material/sort';
import { UtcToLocalTimePipe } from 'src/app/shared/pipes/utc-to-local-time.pipe';
import { UtcToLocalTimeFormat } from 'src/app/shared/pipes/utc-converter.service';

@Component({
  selector: 'app-billing-record',
  templateUrl: './billing-record.component.html',
  styleUrls: ['./billing-record.component.sass']
})
export class BillingRecordComponent {
  emisores: any[] = [];
  range = new FormGroup({
    start: new FormControl<Date | null>(new Date(), [Validators.required]),
    end: new FormControl<Date | null>(new Date(), [Validators.required]),
  });
  dataSource: MatTableDataSource<any> | undefined;
  facturas: any | undefined;
  formEmisor: FormGroup | undefined;

  constructor(
    private fb: FormBuilder,
    private cartolaService: CartolaService,
    private spinnerService: SpinnerService,
    private datePipe:UtcToLocalTimePipe,
    private currencyPipe:CurrencyPipe
    ) {
    const ref = this.spinnerService.start();
    this.formEmisor = this.fb.group({
      emisor: new FormControl( "", [Validators.required]),
    });
    this.getEmisores();
    this.search();
    this.spinnerService.stop(ref);
  }
  async getEmisores(){
    this.emisores = await this.cartolaService.getEmisores();
    this.emisores = this.emisores.concat([{ cuenta: 'Todos', rutemisor: 'TODOS' }]);
  }
  async search() {
    const emisor = this.formEmisor?.get('emisor')?.value;
    const ref = this.spinnerService.start();
    let facturas = await this.cartolaService.getBillingRecords(formatDate(this.range.controls['start'].value), formatDate(this.range.controls['end'].value),emisor?emisor:'TODOS');
    facturas = facturas.filter((factura: any) => {
      return (
        factura.doc_fiscal_id !== null &&
        factura.tipo_doc_fiscal !== null &&
        factura.fecha_emision !== null
      );
    });
    this.dataSource = new MatTableDataSource(facturas);
    this.spinnerService.stop(ref);
  }

  displayedColumns: string[] = [
    'cartola_id',
    'codigo_transferencia',
    'rut_origen',
    'email',
    'nombre_origen',
    'mo_saldo_abono',
    'numero_id',
    'doc_fiscal_id',
    'tipo_doc_fiscal',
    'emisor',
    'fecha_emision'
  ];

  columns = [
    {
      columnDef: 'cartola_id',
      header: 'Id',
      cell: (element: any) => `${element.cartola_id}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'110px','min-width':'100px'},
      cellStyle: { 'text-align':'center','max-width':'110px','min-width':'100px'},
      isSticky: true,
    },
    {
      columnDef: 'codigo_transferencia',
      header: 'Codigo Transferencia',
      cell: (element: any) => `${element.codigo_transferencia}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'200px','min-width':'200px'},
      cellStyle: { 'text-align':'center','max-width':'200px','min-width':'200px'},
    },
    {
      columnDef: 'rut_origen',
      header: 'Rut Origen',
      cell: (element: any) => `${element.rut_origen}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'150px','min-width':'150px'},
      cellStyle: { 'text-align':'left','max-width':'150px','min-width':'150px'},
    },
    {
      columnDef: 'email',
      header: 'Email',
      cell: (element: any) => `${element.email}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'220px','min-width':'180px'},
      cellStyle: { 'text-align':'left', 'max-width':'220px','min-width':'180px'},
    },
    {
      columnDef: 'nombre_origen',
      header: 'Nombre Origen',
      cell: (element: any) => `${element.nombre_origen}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'200px','min-width':'200px'},
      cellStyle: { 'text-align':'center','max-width':'200px','min-width':'200px'},
    },
    {
      columnDef: 'mo_saldo_abono',
      header: 'Monto Saldo Abono',
      cell: (element: any) => `${this.currencyPipe.transform(element.mo_saldo_abono, 'USD', 'symbol-narrow', '1.2-2', 'en-US')}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'150px'},
      cellStyle: { 'text-align':'center','max-width':'150px'},
    },
    {
      columnDef: 'doc_fiscal_id',
      header: 'Id Documento Fiscal',
      cell: (element: any) => `${element.doc_fiscal_id}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'150px'},
      cellStyle: { 'text-align':'center','max-width':'150px'},
    },
    {
      columnDef: 'tipo_doc_fiscal',
      header: 'Tipo Documento Fiscal',
      cell: (element: any) => `${element.tipo_doc_fiscal}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'160px','min-width':'140px'},
      cellStyle: { 'text-align':'left','max-width':'160px','min-width':'140px'},
    },
    {
      columnDef: 'fecha_emision',
      header: 'Fecha de Emision',
      cell: (element: any) => `${this.datePipe.transform(element.fecha_emision,UtcToLocalTimeFormat.SHORT)}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'150px','min-width':'150px'},
      cellStyle: { 'text-align':'center','max-width':'150px','min-width':'150px'},
    },
    {
      columnDef: 'numero_id',
      header: 'Numero Id',
      cell: (element: any) => `${element.numero_id}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'300px','min-width':'260px'},
      cellStyle: { 'text-align':'left','max-width':'300px','min-width':'260px'},
    },
    {
      columnDef: 'emisor',
      header: 'Emisor',
      cell: (element: any) => `${element.emisor}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'250px','min-width':'220px'},
      cellStyle: { 'text-align':'left','max-width':'250px','min-width':'220px'},
    },
  ];
  
  sortData(sort: Sort) {
    const data = this.facturas.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource(data);
    }

    this.facturas = data.sort((a: { cartola_id: number; }, b: { cartola_id: string; }) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'cartola_id':
          return this.compare(a.cartola_id, b.cartola_id, isAsc);
        default:
          return 0;
      }
    });
    this.dataSource = new MatTableDataSource( this.facturas);
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

}
