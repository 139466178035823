<div class="m-4 w-full  overflow-x-auto" >
  <h1 class="m-4 text-2xl font-bold"> Pagadores </h1>
  <table mat-table [dataSource]="dataSource" class="w-10/12 m-4 " *ngIf="dataSource; else noPayers">
    <ng-container
      *ngFor="let column of columns"
      [matColumnDef]="column.columnDef"
    >
      <th mat-header-cell
      *matHeaderCellDef
      class="text-left font-bold min-w-[100px]"
      style="background-color: #CEE9C2;">
        {{column.header}}
      </th>
      <td mat-cell *matCellDef="let row">{{column.cell(row)}}</td>
    </ng-container>
    <ng-container matColumnDef="fecha_registro">
      <th mat-header-cell
      *matHeaderCellDef
      aria-label="row fecha"
      class="text-left font-bold min-w-[100px]"
      style="background-color: #CEE9C2;"
      >
        Fecha registro
      </th>
      <td mat-cell *matCellDef="let element">
        {{element.created_at | date: 'medium' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="acciones">
      <th mat-header-cell
      *matHeaderCellDef
      aria-label="row actions"
      class="text-left font-bold min-w-[80px]"
      style="background-color: #CEE9C2;">
        Acciones
      </th>
      <td mat-cell *matCellDef="let element">
        <button *ngIf="!isAdminView" mat-icon-button color="primary" aria-label="editar" [routerLink]="['/wallet/client/edit-payer',element.pagadorId]">
          <mat-icon>edit</mat-icon>
        </button>
        <button *ngIf="!isAdminView" mat-icon-button color="warn" aria-label="eliminar" (click)="eliminar(element.pagadorId)">
          <mat-icon>delete</mat-icon>
        </button>
        <button *ngIf="isAdminView" mat-icon-button color="primary" aria-label="ver pagador" (click)="openPagadorData(element)">
          <mat-icon>visibility</mat-icon>
        </button>
      </td>
    </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <ng-template #noPayers>
    No hay pagadores para mostrar
  </ng-template>
</div>
