import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogBeneficiariosComponent } from 'src/app/dashboard/components/account-recipient/components/account-list/dialog-beneficiarios/dialog-beneficiarios.component';
import { CuentaBeneficiario } from 'src/app/dashboard/interfaces/cuenta-beneficiario.interface';
import { AccountRecipientService } from 'src/app/dashboard/services/account-recipient.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  @Input() cuenta: CuentaBeneficiario | undefined;
  @Output() deleteAccount: EventEmitter<any> = new EventEmitter();
  @Output() actualizarCuenta: EventEmitter<CuentaBeneficiario> = new EventEmitter();
  constructor(
    private accountRecipientService: AccountRecipientService,
    private spinner: SpinnerService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog) { }

  ngOnInit() {
  }

  async verCuenta(){
    this.dialog.open(DialogBeneficiariosComponent, {
      data: this.cuenta,
      disableClose: true,
    });
  }

  async inactivarCuenta(){
    if( !(this.cuenta && this.cuenta.cuentaBeneficiarioID))return;
    const resullt = await Swal.fire({
      title: '¿Está seguro de que desea desvincular esta cuenta?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: `No`,
      confirmButtonColor: '#1e5a3f',
    });
    if(resullt.isDenied || !resullt.isConfirmed){
      return;
    }
    const spinnerRef  = this.spinner.start();
    try {
      await this.accountRecipientService.inactivateAccount(this.cuenta.cuentaBeneficiarioID);
      this.snackBar.open('Cuenta eliminada correctamente', 'Ok', {
        duration: 3000
      });
      this.deleteAccount.emit();
    } catch (error) {

    }
    this.spinner.stop(spinnerRef);

  }

  actualizar(){
    this.actualizarCuenta.emit(this.cuenta);
  }

}
