<div class="text-center mt-4">
    <h1><b>{{data.lineaCredito?.lineaCredito?'Editar':'Crear'}} Linea de Credito</b></h1>
</div>
<div class="col manual self-center overflow-hidden m-5 text-justify">
    <form [formGroup]="createForm" (ngSubmit)="onSubmit()" class="form-container px-4" *ngIf="createForm">
        <h2><b>Usuario ID: </b>{{this.data.user}}</h2>
        <h2><b>Usuario: </b>{{this.data.userEmail}}</h2>
        <h2><b>Operador: </b>{{this.data.operator}}</h2>
        <h2 *ngIf="this.data.lineaCredito?.lineaCredito">Monto actual linea de credito: {{this.data.lineaCredito?.lineaCredito}}</h2>
        <div class="form-field mt-2">
            <mat-form-field class="w-full">
            <mat-label>{{this.data.lineaCredito?.lineaCredito?'Editar ':''}}Monto</mat-label>
            <input
            matInputCommified
            matInput
            formControlName="lineaCredito"
            placeholder="Monto"
            appOnlynumber
            pattern="[0-9]*"/>
            </mat-form-field>
        </div>
        <div class="gap-4 w-full flex flex-row items-center align-middle justify-center mt-4">
        <button type="submit" mat-raised-button color="primary" [disabled]="createForm.invalid">Crear Linea de credito</button>
        </div>
    </form>
</div>
<div class="my-4 text-center">
    <button type="submit" mat-raised-button color="primary" (click)="closeDialog()">Cancelar</button>
</div>