<div class="p-6 space-y-4 md:space-y-6 sm:p-8">
    <div class="container flex flex-col items-center">
      <h1 class="text-xl font-bold">
        {{ data.type === "UPDATE" ? "Actualizar cuenta de retiro" : "Registrar cuenta de retiro" }}
      </h1>
    </div>
    <form
      *ngIf="formCuenta"
      class="space-y-4 md:space-y-6"
      [formGroup]="formCuenta"
    >
      <div class="grid grid-cols-1 gap-4">
        <mat-form-field>
          <mat-label>País</mat-label>
          <mat-select
            required
            formControlName="pais"
            (selectionChange)="getBanks()"
          >
            <mat-option>Selecione país</mat-option>
            <mat-option
              *ngFor="let pais of paisesActivos"
              [value]="pais.codigoPais"
            >
              {{ pais.nombre | titlecase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="grid grid-cols-1 gap-4">
        <mat-form-field>
          <mat-label>Nombre y apellido</mat-label>
          <input
            type="text"
            matInput
            formControlName="alias"
            placeholder="Ingresa Nombre y Apellido del titular"
          />
        </mat-form-field>
      </div>
      <div class="grid grid-cols-2 gap-4">
        <mat-form-field>
          <mat-label>Tipo documento</mat-label>
          <mat-select required formControlName="tipoId">
            <mat-option>Tipo documento</mat-option>
            <mat-option *ngFor="let tipo of tiposID" [value]="tipo">
              {{ tipo }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Numero identificación</mat-label>
          <input
            type="text"
            matInput
            formControlName="numeroId"
            placeholder="Ingresa tu documento"
          />
        </mat-form-field>
      </div>
      <div class="grid grid-cols-2 gap-4">
        <mat-form-field>
          <mat-label>Banco</mat-label>
          <mat-select required formControlName="banco">
            <mat-option>Selecione el banco</mat-option>
            <mat-option *ngFor="let banco of bancos" [value]="banco">
              {{ banco.nombre | titlecase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Código</mat-label>
          <input
            type="text"
            matInput
            [disabled]="true"
            [value]="formCuenta.get('banco')?.value?.codigoBanco ?? ''"
            placeholder="Ingresa tu nombre"
          />
        </mat-form-field>
      </div>
      <div class="grid grid-cols-3 gap-4">
        <mat-form-field class="col-span-1">
          <mat-label>Tipo de cuenta</mat-label>
          <mat-select required formControlName="tipoCuentaId">
            <mat-option>Tipo de cuenta</mat-option>
            <mat-option
              *ngFor="let tipo of tiposCuentas"
              [value]="tipo.tipoCuentaID"
            >
              {{ tipo.nombre | titlecase }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-span-2">
          <mat-label>Número de cuenta</mat-label>
          <input
            type="text"
            matInput
            formControlName="numeroCuenta"
            placeholder="Ej. 00000 0000 00000 00000"
          />
          <mat-error
            *ngIf="
              formCuenta.get('numeroCuenta')?.invalid &&
              (formCuenta.get('numeroCuenta')?.dirty ||
                formCuenta.get('numeroCuenta')?.touched)
            "
          >
          La cuenta no debe tener más de 20 dígitos y no puede contener caracteres especiales
          </mat-error>
        </mat-form-field>
      </div>
  
      <div class="container flex flex-col items-center">
        <button
          type="button"
          (click)="guardarCuentaRetiro()"
          class="w-1/2 text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        >
          Guardar
        </button>
      </div>
    </form>
  </div>
  