import { Component, OnInit } from '@angular/core';
import { TransaccionService } from '../../services/transaccion.service';
import { Transaccion } from '../../interfaces/transaccion.interface';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { getColumns, columns, displayedColumns } from './table-transactions-completed';
import { GetTransactionsType } from 'src/app/shared/enums/get-transactions-type';
import { CurrencyPipe } from '@angular/common';
import Swal from 'sweetalert2';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { formatDate } from 'src/app/shared/utils/date.util';

@Component({
  selector: 'app-transactions-ves-completed',
  templateUrl: './transactions-ves-completed.component.html',
  styleUrls: ['./transactions-ves-completed.component.sass']
})
export class TransactionsVesCompletedComponent implements OnInit {
  transacciones: Transaccion[] = [];
  seleccionados: number = 0;
  dataSource: MatTableDataSource<any> | undefined;
  subscription: Subscription = new Subscription();
  columns = getColumns(this.currencyPipe);
  displayedColumns = displayedColumns;
  range = new FormGroup({
    start: new FormControl<Date | null>(new Date(), [Validators.required]),
    end: new FormControl<Date | null>(new Date(), [Validators.required]),
  });

  currentPage = 1;
  pageSize = 25;
  totalItems = 0;
  totalPages = this.totalItems/this.pageSize;

  private intervalId: any;
  constructor(
    private transactionService: TransaccionService,
    private dialog: MatDialog,
    private currencyPipe: CurrencyPipe,
    private spinnerService: SpinnerService
  ) { }
  async ngOnInit() {
    await this.loadTransactions();
    this.intervalId = setInterval(() => {
      this.loadTransactions();
    }, 60000)
  }

  async loadTransactions() {
    if(this.transacciones?.length !== 0){
      const transaccion_old: Transaccion[] = this.transacciones,
      data = await this.transactionService.getAllTransactionsVes(GetTransactionsType.COMPLETED, this.currentPage, this.pageSize,formatDate(this.range.controls['start'].value), formatDate(this.range.controls['end'].value) );
      this.totalItems = +data.count;
      this.totalPages = Math.ceil (this.totalItems/this.pageSize);
      const transaccion_new: Transaccion[] = data.query;
      if (!this.transaccionesIguales(transaccion_old, transaccion_new)) {
        this.transacciones = transaccion_new;
      }
    }else{
      const data = await this.transactionService.getAllTransactionsVes(GetTransactionsType.COMPLETED, this.currentPage, this.pageSize,formatDate(this.range.controls['start'].value), formatDate(this.range.controls['end'].value) );
      this.transacciones = data.query;
    }
    this.dataSource = new MatTableDataSource(this.transacciones);
  }


  private transaccionesIguales(transacciones1: Transaccion[], transacciones2: Transaccion[]): boolean {
    if (transacciones1?.length !== transacciones2?.length) {
      return false;
    }
    const transacciones1Ids = transacciones1?.map((t) => t.id);
    const transacciones2Ids = transacciones2?.map((t) => t.id);
    if(transacciones1Ids){
      for (const id of transacciones1Ids) {
        if (!transacciones2Ids.includes(id)) {
          return false;
        }
      }
    }
    
    return true;
  }

  change() {
    this.seleccionados =
      this.dataSource?.data.filter((value) => value.seleccionado).length || 0;

  }

  onPageChange(event: any) {
    console.log(event);
    this.pageSize=event.pageSize;
    this.currentPage = event.pageIndex+1;
    this.loadTransactions();
  }

  limpiarSeleccion() {
    const data =
      this.dataSource?.data.map((value) => {
        return { ...value, seleccionado: false };
      }) || [];

    if (this.dataSource && this.dataSource.data) {
      this.dataSource.data = data;
    }
    this.change();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    clearInterval(this.intervalId);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (this.dataSource) {
      this.dataSource.filter = filterValue.trim().toUpperCase();
    }
  }

  public doFilter = (event: any) => {
    const element = event.currentTarget as HTMLInputElement;
    const value = element.value;
    if (this.dataSource)
      this.dataSource.filter = value.trim().toLocaleLowerCase();
  };
  //TPDP

  returnSelectedTransactions(row: Transaccion) {
    const selected =
      this.dataSource?.data?.filter((x) => x.seleccionado && x.id !== row.id) ||
      [];
    selected.push(row);
    return selected;
  }
}