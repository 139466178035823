    <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
      <div class="container flex flex-col items-center">
        <h1 class="text-2xl font-bold"> Editar datos </h1>
      </div>
      <form class="space-y-4 md:space-y-6" [formGroup]="datosForm" *ngIf="datosForm" (ngSubmit)="enviarDatos()">
        <div class="grid grid-cols-2 gap-4">
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input type="text" matInput formControlName="nombre"
                   placeholder="Ingresa tu nombre">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Apellidos</mat-label>
            <input type="text" matInput formControlName="apellido"
                   placeholder="Ingresa tu apellido">
          </mat-form-field>
        </div>

        <div class="grid grid-cols-2 gap-4">
          <mat-form-field >
            <mat-label >Tipo documento</mat-label>
            <mat-select required formControlName="tipoId">

              <mat-option >Tipo documento</mat-option>
              <mat-option *ngFor="let tipoID of tiposID" [value]="tipoID">
                {{tipoID}}
              </mat-option>
            </mat-select>

          </mat-form-field>
          <mat-form-field>
            <mat-label>Numero identificación</mat-label>
            <input type="text" matInput formControlName="numeroId"
                   placeholder="Ingresa tu documento">
          </mat-form-field>
        </div>
        <div class="grid grid-cols-2 gap-4">
          <mat-form-field >
            <mat-label >Nacionalidad</mat-label>
            <mat-select required formControlName="nacionalidad">

              <mat-option >Selecciona un país</mat-option>
              <mat-option *ngFor="let pais of paises" [value]="pais.ALPHA2" class="flex flex-row">
                <div [class]="'bg-' + pais.ALPHA2.toLowerCase() "></div>
                <span>{{pais.NAME | titlecase}}</span>
              </mat-option>
            </mat-select>

          </mat-form-field>
          <mat-form-field class="col-span-1">
            <mat-label>Profesión</mat-label>
            <input type="text" matInput formControlName="profesion"
                   placeholder="Ej. Ingeniero">
          </mat-form-field>
        </div>

        <div class="grid grid-cols-3 gap-4">
         <mat-form-field >
            <mat-label >Sexo</mat-label>
            <mat-select required formControlName="sexo">

              <mat-option >Selecciona una opción</mat-option>
              <mat-option *ngFor="let sexo of sexos" [value]="sexo" class="flex flex-row">
                <span> {{sexo}}</span>
              </mat-option>
            </mat-select>
         </mat-form-field>
         <mat-form-field class="col-span-2" appearance="fill">

          <input matInput placeholder="Fecha de nacimiento" [matDatepicker]="picker" formControlName="fechaNacimiento">

          <mat-datepicker-toggle   matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker ></mat-datepicker>
        </mat-form-field>

        </div>


        <div class="grid grid-cols-1 gap-4">
          <mat-form-field >
            <mat-label >País de residencia</mat-label>
            <mat-select required formControlName="paisResidencia">

              <mat-option >Selecciona un país</mat-option>
              <mat-option *ngFor="let pais of paises" [value]="pais.ALPHA2" class="flex flex-row">
                <div [class]="'bg-' + pais.ALPHA2.toLowerCase() "></div>
                <span>{{pais.NAME | titlecase}}</span>
              </mat-option>
            </mat-select>

          </mat-form-field>

        </div>
        <div class="grid grid-cols-1 gap-4">
          <mat-form-field class="col-span-2">
            <mat-label>Ciudad de residencia</mat-label>
            <input type="text" matInput formControlName="ciudadResidencia"
                   placeholder="Ej. Santiago">
          </mat-form-field>

        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
          <mat-form-field >
            <mat-label >Código</mat-label>
            <mat-select required formControlName="prefijoTelefono" >

              <mat-option >Selecciona un país</mat-option>
              <mat-option *ngFor="let pais of paises" [value]="pais.DIAL" class="flex flex-row">
                <div [class]="'bg-' + pais.ALPHA2.toLowerCase() "></div>
                <span> {{pais.DIAL}} - {{pais.NAME | titlecase}}  </span>
              </mat-option>
            </mat-select>

          </mat-form-field>
          <mat-form-field class="md:col-span-2">
          <mat-label>Teléfono</mat-label>
          <input type="text" matInput formControlName="telefono"
                 placeholder="Ingresa tu Teléfono">
        </mat-form-field>

        </div>
        <div class="grid grid-cols-1 gap-4">
          <mat-form-field class="col-span-1">
            <mat-label>Dirección</mat-label>
            <input type="text" matInput formControlName="direccion"
                   placeholder="Ej. Santiago">
          </mat-form-field>


        </div>
        <div class="grid grid-cols-1 gap-4">
          <mat-form-field class="col-span-1">
            <mat-label>Facturas</mat-label>
            <mat-select required matInput formControlName="recibeFactura">
              <mat-option value="1">Facturas Activadas</mat-option>
              <mat-option value="0">Facturas Desactivadas</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="container flex flex-row items-center align-middle justify-center gap-7">
          <button mat-raised-button (click)="close()">Cancelar</button>
          <button mat-raised-button color="primary" type="submit">Actualizar</button>
        </div>
      </form>
    </div>
