import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Transaccion } from 'src/app/dashboard/interfaces/transaccion.interface';
import { WorkingAccountService } from 'src/app/dashboard/services/working-account.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { TransaccionService } from 'src/app/dashboard/services/transaccion.service';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import Swal from 'sweetalert2';
import { FormBuilder, FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { AcceptTransactionDTO } from 'src/app/dashboard/interfaces/accept-transaction.dto';
import { S3 } from 'aws-sdk';
import { environment } from 'src/environments/environment';
import { Buffer } from 'buffer';
@Component({
  selector: 'app-send-transaction-modal',
  templateUrl: './send-transaction-modal.component.html',
  styleUrls: ['./send-transaction-modal.component.sass']
})
export class SendTransactionModalComponent {
  datosForm: FormGroup | undefined;
  tiposID = ['CI', 'PASAPORTE', 'RUT'];
  transaction: Transaccion | undefined;
  contentType = 'image/jpeg';
  fileExt = 'jpeg';
  comprobante: any;
  constructor(
    private auth: CognitoService,
    private readonly transactionService: TransaccionService,
    private fb: UntypedFormBuilder,
    private spinner: SpinnerService, private workingAccountService: WorkingAccountService,
    public dialogRef: MatDialogRef<SendTransactionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any ){
      console.log(data);
      //nombre del beneficiario, DNI, Numero de cuenta.
      // public dialogRef: MatDialogRef<EditTransactionModalComponent>,
    //@Inject(MAT_DIALOG_DATA) public data: any
     this.transaction = data;
     this.initForm();
  }

  initForm(){
    this.datosForm = this.fb.group({
      referencia: new FormControl(''),
      photo: []

    });
  }

  setFileData(event: Event): void {
    const eventTarget: HTMLInputElement | null = event.target as HTMLInputElement | null;
    if (eventTarget?.files?.[0]) {
      const file: File = eventTarget.files[0];
      this.contentType = file.type;
      const filename = file.name;
      this.fileExt = filename.split('.').pop() || '';
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.datosForm?.get('photo')?.setValue(reader.result as string);
        console.log( this.datosForm?.get('photo')?.value);
        console.log(this.contentType);
      });

      reader.readAsDataURL(file);
    }
  }

  uploadBase64Image(base64Image: string): Promise<any> {
    console.log(base64Image);
    const buffer = Buffer.from(base64Image.replace(/^data:image\/\w+;base64,/, ''), 'base64');
    console.log(buffer);
    console.log(base64Image);
    this.comprobante = `comprobantes/${new Date().getTime()}.${this.fileExt}`;
    const s3 = new S3({

          accessKeyId: environment.s3.accessKeyId,
          secretAccessKey: environment.s3.secretAccessKey,
    });
    const params = {
          Bucket: environment.s3.bucket,
          Key: this.comprobante,
          Body: buffer,
          ContentEncoding: 'base64',
          ContentType: this.contentType
        };

    return s3.upload(params).promise();
  }

  async acceptTransaction(){
    if(this.datosForm?.invalid || !this.transaction?.id)return;
    const spinnerRef = this.spinner.start();
    console.log(this.transaction.precio_compra);
    const payload: AcceptTransactionDTO = {
      transactionID: this.transaction?.id,
      referencia: this.datosForm?.get('referencia')?.value,
      precioCompra: this.transaction.precio_compra,
    }
    try{
      if(this.datosForm?.get('photo')?.value){
        const response = await this.uploadBase64Image(this.datosForm?.get('photo')?.value);
        console.log(response);
        payload.comprobante = this.comprobante;
      }
      await this.transactionService.acceptTransaction(payload);

      Swal.fire({
        icon: 'success',
        title: 'Transacción pagada',
        text: 'la transacción se ha pagado exitosamente',
        showCancelButton: false,
        showDenyButton: false,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#1e5a3f',

      });
      this.dialogRef.close(true);
    }catch(error: any){
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.error.mensaje,
        showCancelButton: false,
        showDenyButton: false,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#1e5a3f',

      });
      this.dialogRef.close(true);
    }

    this.spinner.stop(spinnerRef);
  }

  close(){
    this.dialogRef.close();
  }
}
