import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-zelle-change',
  templateUrl: './zelle-change.component.html',
  styleUrls: ['./zelle-change.component.sass']
})
export class ZelleChangeComponent implements OnInit {

  @Input()
  public zelle_buy_rate: number = 0;
  @Input()
  public zelle_sell_rate: number = 0;
  @Input()
  public showBuy: boolean = true;
  @Input()
  public invert: boolean = false;
  public hasLoaded: boolean = true;

  constructor() { 

  }

  ngOnInit(): void { }

}
