import { ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ParameterService } from '../../services/parameter.service';
import { ParametrosEnum } from 'src/app/shared/enums/parameters.enum';
import Swal from 'sweetalert2';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { BinanceRateComponent } from '../admin-rate/binance-rate/binance-rate.component';

@Component({
  selector: 'app-set-rates-parameters-bolivares',
  templateUrl: './set-rates-parameters-bolivares.component.html',
  styleUrls: ['./set-rates-parameters-bolivares.component.sass'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SetRatesParametersBolivaresComponent {

  @ViewChild(BinanceRateComponent) binanceRateComponent!: BinanceRateComponent;

  valorSolvenciaFiat: number = 0;
  valorLiquidezFiat: number = 0;
  valorDescuentoFiat: number = 0;
  ratesFiatForm = new FormGroup({
    valorSolvenciaFiat: new FormControl(),
    valorLiquidezFiat: new FormControl(),
    valorDescuentoFiat: new FormControl(),
  });

  constructor(private parametersService: ParameterService, private spinner: SpinnerService) {
    this.parametersService.getParameter(ParametrosEnum.VALOR_SOLVENCIA_FIAT).then((value) => {
      this.valorSolvenciaFiat = value.valor;
      this.ratesFiatForm.controls['valorSolvenciaFiat'].setValue(value.valor);
    }
    );
    this.parametersService.getParameter(ParametrosEnum.VALOR_LIQUIDEZ_FIAT).then((value) => {
      this.valorLiquidezFiat = value.valor;
      this.ratesFiatForm.controls['valorLiquidezFiat'].setValue(+value.valor);
    }
    );
    this.parametersService.getParameter(ParametrosEnum.DESCUENTO_FIAT).then((value) => {
      this.valorDescuentoFiat = value.valor;
      this.ratesFiatForm.controls['valorDescuentoFiat'].setValue(+value.valor);
    }
    );
   }
  

  reset(){
    this.ratesFiatForm.controls['valorSolvenciaFiat'].setValue(this.valorSolvenciaFiat);
    this.ratesFiatForm.controls['valorLiquidezFiat'].setValue(this.valorLiquidezFiat);
    this.ratesFiatForm.controls['valorDescuentoFiat'].setValue(this.valorDescuentoFiat);
    this.reloadData();
  }

  reloadData(): void {
    this.binanceRateComponent.initDatos();
  }

  showConfirm(){
    Swal.fire({
      title: '¿Está seguro de guardar los parámetros?',
      showCancelButton: true,
      confirmButtonText: `Guardar`,
      cancelButtonText: `Cancelar`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const ref = this.spinner.start();
        await this.setParameters();
        this.spinner.stop(ref);
        Swal.fire('Guardado\n Actualizando tasas', '', 'success');
      }
    });
  }
  async setParameters(){

    const valorSolvenciaFiat = this.ratesFiatForm.controls['valorSolvenciaFiat'].value;
    const valorLiquidezFiat = this.ratesFiatForm.controls['valorLiquidezFiat'].value;
    const valorDescuentoFiat = this.ratesFiatForm.controls['valorDescuentoFiat'].value;

    await Promise.all([
      this.parametersService.saveParameter(ParametrosEnum.VALOR_SOLVENCIA_FIAT, valorSolvenciaFiat),
      this.parametersService.saveParameter(ParametrosEnum.VALOR_LIQUIDEZ_FIAT, valorLiquidezFiat),
      this.parametersService.saveParameter(ParametrosEnum.DESCUENTO_FIAT, valorDescuentoFiat),
      this.reloadData(),

    ]);
    this.valorLiquidezFiat = valorLiquidezFiat;
    this.valorSolvenciaFiat = valorSolvenciaFiat;
    this.valorDescuentoFiat = valorDescuentoFiat;
  }
}
