<div class="px-16 py-10  rounded-3xl uploader-status flex flex-col items-center justify-center align-middle">


  <ul class="text-lg">
    <li>
      <b>Cédula del Beneficiario:</b> {{data.numeroID}}
    </li>
    <li>
      <b>Nombres del beneficiario:</b> {{data.nombre}}
    </li>
    <li>
      <b>Apellidos del beneficiario:</b> {{data.apellidos}}
    </li>
    <li>
      <b>Banco del Beneficiario:</b> {{data.banco?.nombre}}
    </li>
    <li>
      <b>Tipo de cuenta:</b> {{data.tipoCuenta?.nombre }}
    </li>
    <li>
      <b>Cuenta del Beneficiario:</b> {{data.numeroCuenta}}
    </li>
  </ul>
  <button type="button" (click)="onNoClick()"
    class="mt-4 w-1/2 font-semibold text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 text-lg rounded-lg px-5 py-2.5 text-center">
    Cerrar
  </button>
</div>


