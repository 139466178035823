<div class="w-full flex flex-col  justify-center items-center">
  <h2 class="font-bold text-xl my-5">Validación</h2>
  <div class="px-6 py-8 w-full md:w-8/12 bg-aguacate-bg rounded-lg " >
    <div class="flex flex-col justify-between items-left gap-4" *ngIf="valorATransferir && beneficiario">
      <h1 class="font-extrabold text-lg">Datos del envío</h1>
      <p ><span class="font-bold">Enviarás a:</span> {{beneficiario.nombre }} {{beneficiario.apellidos}}</p>
      <p ><span class="font-bold"> Tipo de pago: </span> {{tipoPago}}</p>
      <p ><span class="font-bold">Cuenta bancaria:</span> {{beneficiario.numeroCuenta }} {{beneficiario.banco.nombre}}</p>
      <p ><span class="font-bold">Tu envías:</span> ${{valorATransferir.envia | number : '1.2-2'}} CLP</p>
      <p ><span class="font-bold">Tu beneficiario recibe:</span> ${{valorATransferir.recibe | number : '1.2-2'}} VES</p>
      <p ><span class="font-bold">Tasa:</span> {{valorATransferir.tasa | number : '1.5-5' }} </p>
      <p ><span class="font-bold">Tiempo estimado:</span> 1 hora</p>



  </div>
</div>
</div>
