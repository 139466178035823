import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { CuentaBeneficiario } from '../../interfaces/cuenta-beneficiario.interface';
import { AccountRecipientService } from '../../services/account-recipient.service';
import AddAccountComponent from './components/add-account/add-account.component';

@Component({
  selector: 'app-account-recipient',
  templateUrl: './account-recipient.component.html',
  styleUrls: ['./account-recipient.component.sass'],
})
export class AccountRecipientComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  usuarioID: number | undefined;
  listaCuentas: CuentaBeneficiario[] = [];
  @Input() seleccionable = false;
  @Output() seleccion: EventEmitter<CuentaBeneficiario> = new EventEmitter();
  constructor(
    private store: Store<AppState>,
    private accountRecipientService: AccountRecipientService,
    private dialog: MatDialog
  ) {
    this.initFormCuenta();
  }



  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initFormCuenta() {
    this.subscriptions.add(
      this.store.select('auth').subscribe(async ({ user }) => {
        this.usuarioID = user?.usuarioId;
        if(this.usuarioID){
          this.listaCuentas = await this.accountRecipientService.getByUser(this.usuarioID);
        }
      })
    );

  }
  ngOnInit() {}

  async reloadAccount(){
    if(this.usuarioID){
      this.listaCuentas = await this.accountRecipientService.getByUser(this.usuarioID);
    }
  }

  openCreateDialog(cuenta?: CuentaBeneficiario):void{
    const dialogRef = this.dialog.open(AddAccountComponent, {
      data: {
        type: cuenta? 'UPDATE': 'CREATE',
        cuenta,
      },
      disableClose: true,
      height: '70%'
    });
    dialogRef.afterClosed().subscribe(async result => {
      if(result == true && this.usuarioID){
        this.listaCuentas = await this.accountRecipientService.getByUser(this.usuarioID);
      }
    })
  }

  onSeleccion(cuenta: CuentaBeneficiario){
    this.seleccion.emit(cuenta);
  }

}
