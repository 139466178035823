import { Component,Output,EventEmitter,Input } from '@angular/core';
import { ActionsService } from 'src/app/shared/services/actions.service';

@Component({
  selector: 'app-calculator-withdraw',
  templateUrl: './calculator-withdraw.component.html',
  styleUrls: ['./calculator-withdraw.component.sass']
})
export class CalculatorWithdrawComponent {
  public minError = false;
  public valorRetiro: number = 0
  public valorBalanceFinal: number = 0;
  @Input()
  public usdt_clp_tax:number = 0;

  @Input()
  public saldoActual:number = 0;

  @Output()
  public changeStep = new EventEmitter<number>();

  @Output()
  public montoRetiroEmit = new EventEmitter<number>();

  constructor(
    private actionService: ActionsService,
  ){
    this.valorBalanceFinal=this.saldoActual;
    this.valorRestante= this.saldoActual.toLocaleString('en-US');
    this.actionService.subjectTasaUSDT.subscribe(
      (res)=>{
        // setTimeout(()=> this.transformCurrency('clp'), 1000)
      }
    )
  }

  get valorARetiro(): string{
    return this.valorRetiro === 0 ? '' : this.valorRetiro.toLocaleString('en-US');
  }

  set valorARetiro(val: string) {
    if (val === '') {
      this.valorRetiro = 0;
    } else {
      this.valorRetiro = parseFloat(val.replace(/,/g, ''));
    }

  }

  get valorRestante(): string{
    return this.valorBalanceFinal.toLocaleString('en-US');
  }
  set valorRestante(val: string) {
    if (val === '') {
      this.valorBalanceFinal = 0;
    } else {
      this.valorBalanceFinal = parseFloat(val.replace(/,/g, ''));
    }

  }

  emitChangeStep( ): void{
    this.changeStep.emit( 2 );
    this.montoRetiroEmit.emit( this.valorRetiro );
  }

  maxValAvailableCLP( ): void{
    if(this.saldoActual>0){
      this.valorARetiro=this.saldoActual.toString();
      this.valorRetiro=this.saldoActual;
      this.balanceLeft();
    }
  }

  clearValAvailableCLP(): void{
    this.valorARetiro="0";
    this.valorRestante="0";
    this.valorRetiro=0;
    this.valorBalanceFinal=this.saldoActual;
  }

  balanceLeft():void{
    this.valorBalanceFinal=this.saldoActual-this.valorRetiro;
    this.valorRestante=this.valorBalanceFinal.toLocaleString('en-US');
  }

  

  soloNumeros(event: KeyboardEvent) {
    const char = event.key;
    if (!char.match(/[0-9]/)) {
      event.preventDefault();
    }
  }
}
