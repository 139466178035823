import { Action, createAction, props } from '@ngrx/store';
import { User } from '../shared/models/user.model';
import { CuentaBeneficiario } from './interfaces/cuenta-beneficiario.interface';

export const SET_RECIPIENT = '[Dashboard] Set recipient';
export const UNSET_RECIPIENT = '[Dashboard] Unset recipient';
export const SET_EXCHANGE_DATA = '[Dashboard] set exchange data';
export const UNSET_EXCHANGE_DATA = '[Dashboard] Unset exchange data';
export const SET_INIT_DATE = '[Dashboard] set init date';
export const setRecipient = createAction(SET_RECIPIENT, props<{beneficiario: CuentaBeneficiario}>())
export const unsetRecipient = createAction(UNSET_RECIPIENT);
export const setExchangeData = createAction(SET_EXCHANGE_DATA, props<{origen: string, destino: string,
  dineroOrigen: number, dineroDestino: number}>());
export const unsetExchangeData = createAction(UNSET_EXCHANGE_DATA);
export const setInitDate = createAction(SET_INIT_DATE);
