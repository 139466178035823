import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ActionsService {
  public subjectSaldo: Subject<boolean> = new Subject();
  public subjectAssignedTransaction: Subject<boolean> = new Subject();
  public subjectTasaFiat: Subject<boolean> = new Subject();
  public subjectTasaUSDT: Subject<boolean> = new Subject();

  constructor() { }
  emitirAccionRecargarSaldo(){
    this.subjectSaldo.next(true);
  }
  emitirAccionTasaFiat(){
    this.subjectTasaFiat.next(true);
  }
  emitirAccionTasaUSDT(){
    this.subjectTasaUSDT.next(true);
  }
  emitirRecargarTransaccionesAsignadas(){
    this.subjectAssignedTransaction.next(true);
  }
}
