
<mat-stepper (selectionChange)="onStepChange($event)" #stepper class="pt-4 py-4" [orientation]="isVertical ? 'vertical': 'horizontal'" [linear]="true">
  <mat-step [completed]="stepPass2 && (clpCurrency !=0 && usdCurrency != 0)">
    <ng-template matStepLabel>Calcula tu cambio</ng-template>
    <div class="w-full md:w-1/2 mx-auto mt-10">
      <app-calculator-crypto 
      #calculatorComponent 
      (clpCurrencyEmit)="assignClp($event)" 
      (usdCurrencyEmit)="assignUsd($event)" 
      (changeStep)="nextStep($event)" 
      [usdt_clp_tax]="usdt_clp_tax" 
      [saldoActual]="saldoActual"
      [sobregiroDisponible]="sobregiroDisponible" 
      [saldoContable]="saldoContable" 
      [lineaCreditoBloqueada]="lineaCreditoBloqueada" 
      [lineaCredito]="lineaCredito"
      >
      
    </app-calculator-crypto>
    </div>
  </mat-step>
  <mat-step [completed]="stepPass3 && beneficiaryComponent!.beneficiarioEscogido != undefined">
    <ng-template matStepLabel>Elige tu beneficiario</ng-template>
    <app-beneficiary
      #beneficiarioComponent
      (changeStep)="nextStep($event)"
      (beneficiarioOutput)="assignBeneficiario($event)"
      (goBack)="backStep()"></app-beneficiary>
  </mat-step>
  <mat-step [completed]="stepPass4">
    <ng-template matStepLabel>Resumen de tu orden</ng-template>
    <app-summary-order
      [clpCurrency]="clpCurrency"
      [usdCurrency]="usdCurrency"
      [usdt_clp_tax]="usdt_clp_tax"
      [beneficiario]="beneficiario"
      [valorCambioId]="valorCambioId"
      (goBack)="backStep()"
      ></app-summary-order>
  </mat-step>
</mat-stepper>
