<div class="m-8">
  <div class="flex flex-col justify-between items-center">
    <div class="mb-10">
      <h1 class="text-xl font-bold">Resumen de Orden</h1>
    </div>
  </div>
  <div class="containerInfo w-full md:w-1/2 mx-auto">
    <p><span style="font-weight: bold;">Tu envias:</span> {{ clpCurrency | currency:'':'' }} CLP</p>
    <p><span style="font-weight: bold;">Tu beneficiario recibe:</span> {{ usdCurrency | currency:'':'' }} USDT</p>
    <p><span style="font-weight: bold;">Beneficiario:</span> {{ beneficiario?.beneficiario_nombre }} {{ beneficiario?.beneficiario_apellido }}</p>
    <p class="transaction-detail"><span style="font-weight: bold;">Dirección de Retiro:</span> {{ beneficiario?.beneficiario_medio_recepcion }}</p>
    <p><span style="font-weight: bold;">Tasa de compra USDT/CLP:</span> {{ usdt_clp_tax | number:'1.0-2' }}</p>
  </div>
  <div class="flex flex-row justify-center items-center mt-10 mx-auto">
    <button
    mat-raised-button color="primary"
    class="w-40 py-7 md:w-30 mb-10 my-4 p-5"
    type="button"
    (click)="goToBack()">
    Volver
    </button>
    <button
      mat-raised-button color="primary"
      class="w-40 py-7 md:w-60 mb-10 my-4 ml-10 p-5"
      type="button"
      (click)="confirmarEnvio()">
      Enviar transacción
    </button>
  </div>
</div>
