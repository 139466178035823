import { Component } from '@angular/core';
import { WorkingAccountService } from '../../services/working-account.service';
import { MatTableDataSource } from '@angular/material/table';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { CuentaTrabajo } from '../../interfaces/cuenta-trabajo.interface';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {NgIf, DatePipe, CurrencyPipe} from '@angular/common';
import { UtcToLocalTimePipe } from 'src/app/shared/pipes/utc-to-local-time.pipe';
import { UtcToLocalTimeFormat } from 'src/app/shared/pipes/utc-converter.service';
@Component({
  selector: 'app-balance-cajero',
  templateUrl: './balance-cajero.component.html',
  styleUrls: ['./balance-cajero.component.sass']
})
export class BalanceCajeroComponent {

  constructor(private datePipe: UtcToLocalTimePipe, private workingAccountService: WorkingAccountService, private spinner: SpinnerService, private currencyPipe:CurrencyPipe){
    this.getWorkingAccounts();
  }

  statusCajero:any[] = [];
  countryCodes = ['VE', 'US'];
  dataSource: MatTableDataSource<any> | undefined;

  cuentasTrabajo: CuentaTrabajo[] = [];

  cuentaSeleccionada: number | undefined;
  cuentaTrabajo: CuentaTrabajo | undefined;

  range = new FormGroup({
    start: new FormControl<Date | null>(new Date(), [Validators.required]),
    end: new FormControl<Date | null>(new Date(), [Validators.required]),
  });

  getTipoTransaccion(banco:string, tipoTransaccion: string){
    if(tipoTransaccion === 'DEBITO'){
      if(banco === this.cuentaTrabajo?.banco?.nombre){
        return 'TMB';
      }
      return 'TOB'
    }
    return '';
  }
  async loadData(){
    if(this.cuentaSeleccionada && this.range.valid){
      this.cuentaTrabajo = this.cuentasTrabajo.find(res=> res.cuenta_trabajo_id === this.cuentaSeleccionada);
      const ref = this.spinner.start();
      this.statusCajero = await this.workingAccountService.getBalancesCajero(this.cuentaSeleccionada,
        this.range.controls['start'].value || new Date(), this.range.controls['end'].value || new Date());
      console.log("ESTATUS CJAERO: ",this.statusCajero)
      this.dataSource = new MatTableDataSource(this.statusCajero);
      this.spinner.stop(ref);
    }
  }

  async getWorkingAccounts() {
    this.cuentasTrabajo = (await this.workingAccountService.getWorkingAccounts(this.countryCodes))
  }

  displayedColumns: string[] = [
    'saldo',
    'tipoTransaccion',
    'idTransaccion',
    'montoclp',
    'montobs',
    'precioCompra',
    'beneficiario',
    'banco',
    'cuenta',
    'referencia',
    'fecha',
    'operador',
    'saldo_deudor'
  ];
  columns = [
    {
      columnDef: 'saldo',
      header: 'Saldo',
      cell: (element: any) => `${this.currencyPipe.transform(element.saldo_actual, 'USD', 'symbol-narrow', '1.2-2', 'en-US')}`,
    },
    {
      columnDef: 'tipoTransaccion',
      header: 'Tipo transacción',
      cell: (element: any) => `${element.tipo_transaccion} ${this.getTipoTransaccion(element.banco, element.tipo_transaccion)}`,
    },
    {
      columnDef: 'idTransaccion',
      header: 'Id transacción',
      cell: (element: any) => `${element.transaction_id || ''} `,
    },
    {
      columnDef: 'montoclp',
      header: 'Monto CLP',
      cell: (element: any) => `${this.currencyPipe.transform(element.monto_origen, 'USD', 'symbol-narrow', '1.2-2', 'en-US')}`,
    },
    {
      columnDef: 'montobs',
      header: `Monto Destino`,
      cell: (element: any) => `${this.currencyPipe.transform(element.monto_general, 'USD', 'symbol-narrow', '1.2-2', 'en-US')}`,
    },
    {
      columnDef: 'precioCompra',
      header: 'Precio de compra',
      cell: (element: any) => `${element.precio_compra_usdt }`,

    },
    {
      columnDef: 'fecha',
      header: 'Fecha',
      cell: (element: any) => `${this.datePipe.transform(element.created_at,UtcToLocalTimeFormat.SHORT)}`,
    },
    {
      columnDef: 'beneficiario',
      header: 'Beneficiario',
      cell: (element: any) => `${element.nombre || ''} ${element.apellidos || ''}`,
    },
    {
      columnDef: 'operador',
      header: 'Operador',
      cell: (element: any) => `${element.email_operador?element.email_operador:'N/A'}`,
    },
    {
      columnDef: 'referencia',
      header: 'Referencia',
      cell: (element: any) => `${element.referencia || ''}`,
    },
    {
      columnDef: 'banco',
      header: 'Banco',
      cell: (element: any) => `${element.banco || ''}`,
    },
    {
      columnDef: 'cuenta',
      header: 'Cuenta',
      cell: (element: any) => `${element.numero_cuenta || ''}`,
    },
    {
      columnDef: 'saldo_deudor',
      header: 'Saldo deudor',
      cell: (element: any) => `${element.precio_compra || 0}`,
    },
  ];
}
