import { Action, createAction, props } from '@ngrx/store';
import { User } from '../shared/models/user.model';

export const SET_USER = '[Auth] Set User';
export const UNSET_USER = '[Auth] Unset User';
export const LOAD_USER = '[Auth] Load User';
export const LOAD_USER_EMAIL = '[Auth] Load User with email';
export const SET_ROLE= '[Auth] Set User Role';
export const SET_USERANDROLE = '[Auth] Set User & Role';

export const setUser = createAction(SET_USER, props<{user: User}>())
export const unsetUser = createAction(UNSET_USER);
export const setUserRole = createAction(SET_ROLE, props<{role: string}>());
export const setUserAndRole = createAction(SET_USERANDROLE, props<{user: User, role: string}>())
export const loadUser = createAction(LOAD_USER, props<{email: string, role: string}>())
export const loadUserWithEmail = createAction(LOAD_USER_EMAIL, props<{email: string}>())
