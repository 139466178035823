import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EstadoCompliance } from '../interfaces/estado-compliance';
import { lastValueFrom, map } from 'rxjs';
import { ComentarioCompliance } from '../interfaces/comentario-compliance';

@Injectable({
  providedIn: 'root'
})
export class VariablesComplianceService {
API_URL = environment.apiGatewayURL;
constructor(private http: HttpClient) { }

async getEstadosCompliance(): Promise<EstadoCompliance[]>{
  const serviceURL = `${this.API_URL}estados-compliance`;
  return lastValueFrom (this.http.get<any>(serviceURL).pipe(
    map(({estados}) => estados )
  ));
}

async getComentariosCompliance(): Promise<ComentarioCompliance[]>{
  const serviceURL = `${this.API_URL}comentarios-compliance`;
  return lastValueFrom (this.http.get<any>(serviceURL).pipe(
    map(({comentarios})=> comentarios)
  ));
}
}
