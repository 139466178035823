<div class="m-8">
    <div class="flex flex-col justify-between items-center">
      <div class="mb-10">
        <h1 class="text-xl font-bold">Resumen de Orden</h1>
      </div>
    </div>
    <div class="containerInfo w-full md:w-1/2 mx-auto">
      <p><span style="font-weight: bold;">Tu envias:</span> {{ clpCurrency | currency:'':'' }} CLP</p>
      <p><span style="font-weight: bold;">Tu beneficiario recibe:</span> {{ zelleCurrency | currency:'':'' }} USD</p>
      <p><span style="font-weight: bold;">Beneficiario:</span> {{ beneficiario?.nombre }} </p>
      <p><span style="font-weight: bold;">Banco:</span> {{ beneficiario?.banco?.nombre }} </p>
      <p *ngIf="beneficiario?.banco?.codigoBanco ==='BOFA'"><span style="font-weight: bold;">Número cuenta:</span> {{ beneficiario?.numeroCuenta }} </p>
      <p class="transaction-detail"><span style="font-weight: bold;">Email/Teléfono:</span> {{ beneficiario?.identificador }}</p>
      <p><span style="font-weight: bold;">Tasa de compra USD/CLP:</span> {{ usdt_clp_tax_zelle | number:'1.0-0' }}</p>
    </div>
    <div class="flex flex-row justify-center items-center mt-10 mx-auto">
      <button
      mat-raised-button color="primary"
      class="w-40 py-7 md:w-30 mb-10 my-4 p-5"
      type="button"
      (click)="goToBack()">
      Volver
      </button>
      <button
        mat-raised-button color="primary"
        class="w-40 py-7 md:w-60 mb-10 my-4 ml-10 p-5"
        type="button"
        (click)="confirmarEnvio()">
        Enviar transacción
      </button>
    </div>
  </div>
