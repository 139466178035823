import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { Banco } from 'src/app/dashboard/interfaces/banco';
import { Pais } from 'src/app/dashboard/interfaces/country.interface';
import { CuentaTrabajo } from 'src/app/dashboard/interfaces/cuenta-trabajo.interface';
import { TipoCuenta } from 'src/app/dashboard/interfaces/tipo-cuenta.interface';
import { AccountTypeService } from 'src/app/dashboard/services/account-type.service';
import { BanksService } from 'src/app/dashboard/services/banks.service';
import { CountriesService } from 'src/app/dashboard/services/countries.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import Swal from 'sweetalert2';
import { WithdrawAccountService } from '../../services/withdraw-account.service';
import { CuentaRetiro } from '../../interfaces/cuenta-retiro.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-withdraw-accounts',
  templateUrl: './add-withdraw-accounts.component.html',
  styleUrls: ['./add-withdraw-accounts.component.sass']
})
export class AddWithdrawAccountsComponent {
  formCuenta: FormGroup | undefined;
  paisesActivos: Pais[] = [];
  tiposID = ['CI', 'PASAPORTE', 'RUT'];
  tiposCuentas: TipoCuenta[] = [];
  bancos: Banco[] = [];
  usuarioID: number | undefined;
  subscriptions: Subscription = new Subscription();
  esUpdate = false;
  constructor(
    private fb: FormBuilder,
    private countriesService: CountriesService,
    private bancoService: BanksService,
    private tipoCuentaService: AccountTypeService,
    private withdrawAccountService: WithdrawAccountService,
    private spinner: SpinnerService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AddWithdrawAccountsComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.esUpdate =  this.data.type === 'UPDATE';
    this.initFormCuenta();
    console.log(this.data);
    console.log(this.data.cuenta);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async initFormCuenta() {
    console.log(this.esUpdate)
    this.formCuenta = this.fb.group({
      pais: new FormControl( this.esUpdate ? this.data.cuenta.codigoPais : '', [Validators.required]),
      alias: new FormControl(this.esUpdate ? this.data.cuenta.alias : '', [Validators.required]),
      tipoId: new FormControl(this.esUpdate ? this.data.cuenta.tipoId : '', [Validators.required]),
      numeroId: new FormControl(this.esUpdate ? this.data.cuenta.numeroId: '', [Validators.required]),
      banco: new FormControl(this.esUpdate ? this.data.cuenta.banco :'', [Validators.required]),
      bancoId: new FormControl(this.esUpdate ? this.data.cuenta.bancoId :''),
      tipoCuentaId: new FormControl(this.esUpdate ? this.data.cuenta.tipoCuentaId :'', [Validators.required]),
      numeroCuenta: new FormControl(this.esUpdate ? this.data.cuenta.numeroCuenta :'', [
        Validators.required,
        Validators.maxLength(20),
        Validators.pattern(/^[a-zA-Z0-9]*$/),
      ]),
    });
    this.initLists();
  }

  async initLists() {
    this.paisesActivos = await this.countriesService.getActiveCountries();
    this.removePais('VE');
    if(this.esUpdate){
      [this.bancos, this.tiposCuentas] = await Promise.all([
        this.bancoService.getBanksByCountry(this.data.cuenta.codigoPais),
        this.tipoCuentaService.getAccountTypes(this.data.cuenta.codigoPais),
      ]);
      console.log(this.bancos);
      console.log(this.data.cuenta.bancoId);
      const bancoIndex = this.bancos.find((elem: any): boolean => (elem.bancoID === this.data.cuenta.bancoId));
      console.log(bancoIndex);

      this.formCuenta?.get('banco')?.patchValue(bancoIndex);

    }
  }
  async getBanks() {
    const paisSeleccionado = this.formCuenta?.get('pais')?.value;
    if (!paisSeleccionado) return;
    [this.bancos, this.tiposCuentas] = await Promise.all([
      this.bancoService.getBanksByCountry(paisSeleccionado),
      this.tipoCuentaService.getAccountTypes(paisSeleccionado),
    ]);
  }

  removePais(codigoPais: string): void {
    this.paisesActivos = this.paisesActivos.filter(pais => pais.codigoPais !== codigoPais);
  }
  ngOnInit() {}

  async guardarCuentaRetiro() {
    let mensajeExito = 'Cuenta de retiro creada';
    if (this.formCuenta?.invalid) return;
    const formValue = this.formCuenta?.value;
    const cuenta: CuentaRetiro = {
      ...formValue,
      bancoId: formValue.banco.bancoID,
      tipoCuentaID: formValue.tipoCuenta,
      cuentaId: null,

    };
    if(this.esUpdate){
      console.log('id to update', this.data.cuenta.id);
       cuenta['cuentaId'] = this.data.cuenta.id;
        mensajeExito ='Cuenta de retiro actualizada';
    }
    const usuarioID = this.data.userId;
    console.log('Cuenta to update:', cuenta);
    const ref = this.spinner.start();
    try {
      const resultado = await this.withdrawAccountService.saveWithdrawAccount(
        cuenta, usuarioID
      );
      console.log(resultado);

      this.snackBar.open(mensajeExito, 'Ok', {duration: 3000});
      this.dialogRef.close(true);
      if(!usuarioID){
        this.router.navigate(['/wallet/client/withdraw']);
      }

    } catch (error: any) {
      if(error.status === 400){
        Swal.fire({
          icon: 'warning',
          title: error.error.title,
          text: error.error.message,
          showCancelButton: false,
          showDenyButton: false,
          confirmButtonText: 'Entendido',
          confirmButtonColor: '#1e5a3f',

        });
      }
      console.log(error);
    }
    this.spinner.stop(ref);
  }
}
