import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.guard';
import { AccountRecipientComponent } from './components/account-recipient/account-recipient.component';
import { ComplianceListComponent } from './components/compliance-list/compliance-list.component';
import { ComplianceUserComponent } from './components/compliance-user/compliance-user.component';
import { MultipleMoneyOrderComponent } from './components/money-order/multiple-money-order/multiple-money-order.component';
import { SingleMoneyOrderComponent } from './components/money-order/single-money-order/single-money-order.component';
import { TransactionsComponent } from './components/transactions/transactions.component';
import { UserHomeComponent } from './components/user-home/user-home.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { ValidateUserComponent } from './components/validate-user/validate-user.component';
import { HomeComponent } from './pages/home/home.component';
import { CreatePayerComponent } from './components/payer/create-payer/create-payer.component';
import { ListPayerComponent } from './components/payer/list-payer/list-payer.component';
import { AdminTransactionsComponent } from './components/admin-transactions/admin-transactions.component';
import { OperatorGuard } from '../shared/guards/operator.guard';
import { ClientGuard } from '../shared/guards/client.guard';
import { WorkingAccountComponent } from './components/working-account/working-account.component';
import { AssignedTransactionsComponent } from './components/assigned-transactions/assigned-transactions.component';
import { RejectTransactionModalComponent } from './components/assigned-transactions/reject-transaction-modal/reject-transaction-modal.component';
import { UnassignedTransactionsComponent } from './components/unassigned-transactions/unassigned-transactions.component';
import { ChargeAccountComponent } from './components/charge-account/charge-account.component';
import { TransactionCashierComponent } from './components/transaction-cashier/transaction-cashier.component';
import { BalanceCajeroComponent } from './components/balance-cajero/balance-cajero.component';
import { StatusCajeroComponent } from './components/status-cajero/status-cajero.component';
import { CalculatorCrytoComponent } from './components/calculator-cryto/calculator-cryto.component';
import { RecipientCriptoComponent } from './components/recipient-cripto/recipient-cripto.component';
import { CriptoHomeComponent } from './components/cripto-home/cripto-home.component';
import { CriptoRateComponent } from './components/cripto-rate/cripto-rate.component';
import { ActivateGuard } from '../shared/guards/activate.guard';
import { AssignedTransactionsCriptoComponent } from './components/assigned-transactions-cripto/assigned-transactions-cripto.component';
import { HoursOperationsComponent } from './components/hours-operations/hours-operations.component';
import { UsersBalanceSearchComponent } from './components/users-balance-search/users-balance-search.component';
import { UsersBalanceComponent } from './components/users-balance/users-balance.component';
import { ManualTransactionsComponent } from './components/manual-transactions/manual-transactions.component';
import { RemittanceComponent } from './components/remittance/remittance.component';
import { BillingRecordComponent } from './components/billing-record/billing-record.component';
import { AccountsComponent } from './components/accounts/accounts.component';
import { GenerateCashClosingComponent } from './components/generate-rcash-closing/generate-cash-closing.component';
import { SetRatesParametersComponent } from './components/set-rates-parameters/set-rates-parameters.component';
import { WithdrawAccountsComponent } from './components/withdraw-accounts/withdraw-accounts.component';
import { WithdrawBalanceComponent } from './components/withdraw-balance/withdraw-balance.component';
import { WithdrawTrxListComponent } from './components/withdraw-trx-list/withdraw-trx-list.component';
import { RecipientZelleComponent } from './components/recipient-zelle/recipient-zelle.component';
import { SetRatesParametersBolivaresComponent } from './components/set-rates-parameters-bolivares/set-rates-parameters-bolivares.component';
import { ZelleHomeComponent } from './components/zelle-home/zelle-home.component';
import { CalculatorZelleComponent } from './components/calculator-zelle/calculator-zelle.component';
import { ZelleRateComponent } from './components/zelle-rate/zelle-rate.component';
import { PendingTransactionsZelleComponent } from './components/pending-transactions-zelle/pending-transactions-zelle.component';
import { TransactionsDashboardComponent } from './components/transactions-dashboard/transactions-dashboard.component';
import { WithdrawTrxSentComponent } from './components/withdraw-trx-sent/withdraw-trx-sent.component';
import { TransactionsVesCompletedComponent } from './components/transactions-ves-completed/transactions-ves-completed.component';
import { UserSegmentationComponent } from './components/user-segmentation/user-segmentation.component';

const routes: Routes = [
  {
    path: 'wallet',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        component: UserHomeComponent,
      },

      {
        path: 'admin',
        canActivateChild:[OperatorGuard],
        children: [
          {path: 'compliance-list', component: ComplianceListComponent},
          {path: 'transactions', component: AdminTransactionsComponent},
          {path: 'compliance/:email', component: ComplianceUserComponent},
          {path: 'working-account', component: WorkingAccountComponent},
          {path: 'active-accounts', component: AccountsComponent},
          {path: 'pending-transactions', component: AssignedTransactionsComponent},
          {path: 'pending-transactions-binance', component: AssignedTransactionsComponent},
          {path: 'pending-transactons-cripto', component: AssignedTransactionsCriptoComponent},
          {path: 'unassigned-transaction', component: UnassignedTransactionsComponent},
          {path: 'manual-transaction', component: ManualTransactionsComponent},
          {path: 'modal', component: RejectTransactionModalComponent},
          {path: 'transaction-cashier', component: TransactionCashierComponent},
          {path: 'balance-cajero', component: BalanceCajeroComponent},
          {path: 'status-cajero', component: StatusCajeroComponent},
          {path: 'cripto-rate', component: CriptoRateComponent},
          {path: 'zelle-rate', component: ZelleRateComponent},
          {path: 'hours-operations',component: HoursOperationsComponent},
          {path: 'user-balance', component: UsersBalanceSearchComponent},
          {path: 'user-balance/:userId/:userEmail', component: UsersBalanceComponent},
          {path: 'registerPayer', component: CreatePayerComponent},
          {path: 'billing-record', component: BillingRecordComponent},
          {path: 'cash-closing-report', component: GenerateCashClosingComponent},
          {path: 'set-rates-parameters', component: SetRatesParametersComponent},
          {path: 'set-rates-parameters-bolivares', component: SetRatesParametersBolivaresComponent},
          {path: 'withdraw-transactions', component: WithdrawTrxListComponent},
          {path: 'pending-transactions-zelle', component: PendingTransactionsZelleComponent},
          {path: 'dashboard-operation', component: TransactionsDashboardComponent},
          {path: 'withdraw-transactions-sent', component: WithdrawTrxSentComponent},
          {path: 'completed-ves-transactions', component: TransactionsVesCompletedComponent},
          {path: 'user-segmentation', component: UserSegmentationComponent}
        ]
      },
      {
        path: 'client',
        canActivateChild: [ClientGuard],
        children: [
          { path: 'user-validation', component: ValidateUserComponent },
          { path: 'user-profile', component: UserProfileComponent },
          { path: 'recipients', component: AccountRecipientComponent, canActivate: [ActivateGuard] },
          { path: 'orders', component: TransactionsComponent, canActivate: [ActivateGuard] },
          { path: 'single', component: SingleMoneyOrderComponent, canActivate: [ActivateGuard] },
          { path: 'multiple', component: MultipleMoneyOrderComponent, canActivate: [ActivateGuard] },
          { path: 'registerPayer', component: CreatePayerComponent, canActivate: [ActivateGuard]},
          { path: 'payerList', component: ListPayerComponent, canActivate: [ActivateGuard]},
          { path: 'rechargeAccount', component: ChargeAccountComponent, canActivate: [ActivateGuard]},
          { path: 'edit-payer/:payerId', component: CreatePayerComponent, canActivate: [ActivateGuard] },
          { path: 'send-cripto', component: CalculatorCrytoComponent, canActivate: [ActivateGuard] },
          { path: 'recipient-cripto', component: RecipientCriptoComponent, canActivate: [ActivateGuard]},
          { path: 'recipient-zelle', component: RecipientZelleComponent, canActivate: [ActivateGuard]},
          { path: 'cripto-home', component: CriptoHomeComponent, canActivate: [ActivateGuard]},
          { path: 'remittance', component: RemittanceComponent, canActivate: [ActivateGuard]},
          { path: 'withdraw-accounts', component: WithdrawAccountsComponent, canActivate: [ActivateGuard]},
          { path: 'withdraw', component: WithdrawBalanceComponent, canActivate: [ActivateGuard]},
          { path: 'zelle-home', component: ZelleHomeComponent, canActivate: [ActivateGuard]},
          { path: 'send-zelle', component: CalculatorZelleComponent, canActivate: [ActivateGuard]}
          // { path: 'edit-profile', component: EditUserDataComponent },
          //{ path: 'rates', component: AdminRateComponent },
          //{ path: 'day-rates', component: RatesComponent},
        ]
      },

      { path: '', redirectTo: 'home', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
