<div class="p-6 space-y-4 md:space-y-6 sm:p-8">
    <div class="flex justify-end">
      <button (click)="cancel()" class="button_close text-white p-1"><mat-icon>close</mat-icon></button>
    </div>
    <div class="container flex flex-col items-center">
      <h1 class="text-xl font-bold">
        Registrar
      </h1>
    </div>
    <form [formGroup]="beneficiarioForm!" (ngSubmit)="guardarCuentaZelle()" class="space-y-4 md:space-y-6">
      <div class="grid grid-cols-1 gap-4">
        <select formControlName="bancoDestino" placeholder="Banco destino">
          <option value="" disabled selected>Seleccione banco</option>
          <option *ngFor="let banco of bancos" [value]="banco.codigoBanco">{{banco.nombre}}</option>
        </select>
        <mat-error *ngIf="beneficiarioForm?.get('tipoBeneficiario')?.hasError('required')&&beneficiarioForm?.get('tipoBeneficiario')?.touched">Tipo de beneficiario es requerido</mat-error>
      </div>
      <div class="grid grid-cols-1 gap-4">
        <select formControlName="tipoBeneficiario" placeholder="Tipo de beneficiario" (change)="resetIdentificador()">
          <option value="" disabled selected>Seleccione tipo de beneficiario</option>
          <option value="0">Individuo</option>
          <option value="1">Empresa</option>
        </select>
        <mat-error *ngIf="beneficiarioForm?.get('tipoBeneficiario')?.hasError('required')&&beneficiarioForm?.get('tipoBeneficiario')?.touched">Tipo de beneficiario es requerido</mat-error>
      </div>
      <div class="grid grid-cols-1 gap-4">
        <input
          formControlName="nombreApellido"
          type="text"
          pattern="[a-zA-Z0-9 À-ÿ]*"
          [placeholder]="getPlaceholder()"
          (keypress)="soloLetras($event)">
        <mat-error *ngIf="beneficiarioForm?.get('nombreApellido')?.hasError('required')&&beneficiarioForm?.get('nombreApellido')?.touched">Nombre del beneficiario es requerido</mat-error>
      </div>
      <div class="grid grid-cols-1 gap-4" *ngIf="beneficiarioForm?.get('bancoDestino')?.value === 'ZELLE'">
        <input
          formControlName="identificador"
          (keypress)="identifier($event)"
          (input)="onInput($event)"
          type="text"
          placeholder="Numero de telefono o Email">
        <mat-error *ngIf="beneficiarioForm?.get('identificador')?.hasError('required')&&beneficiarioForm?.get('identificador')?.touched">Identificacion del beneficiario es requerida</mat-error>
        <mat-error *ngIf="beneficiarioForm?.get('identificador')?.touched && beneficiarioForm?.get('identificador')?.hasError('invalidEmail')">
          Debe ser un correo electrónico válido
        </mat-error>
        <mat-error *ngIf="beneficiarioForm?.get('identificador')?.touched && beneficiarioForm?.get('identificador')?.hasError('invalidPhone')">
          Debe ser un número de teléfono válido
        </mat-error>
        <mat-error *ngIf="beneficiarioForm?.get('identificador')?.touched && beneficiarioForm?.get('identificador')?.hasError('minLength')">
          El número de teléfono debe tener al menos 10 dígitos
        </mat-error>
      </div>
      <div class="grid grid-cols-1 gap-4" *ngIf="beneficiarioForm?.get('bancoDestino')?.value !== 'ZELLE'">
        <input
          formControlName="numeroCuenta"
          type="text"
          placeholder="Numero cuenta">
          <mat-error *ngIf="beneficiarioForm?.get('numeroCuenta')?.hasError('required')&&beneficiarioForm?.get('numeroCuenta')?.touched">Campo requerido</mat-error>
          <mat-error *ngIf="(beneficiarioForm?.get('numeroCuenta')?.hasError('minlength') || beneficiarioForm?.get('numeroCuenta')?.hasError('maxlength'))&&beneficiarioForm?.get('numeroCuenta')?.touched">Debe contener de 8 a 12 caracteres</mat-error>
        </div>
      <div class="container flex flex-row gap-4 items-center justify-center">
        <button mat-raised-button type="submit" color="primary" [disabled]="!beneficiarioForm?.valid">{{this.esUpdate?'Actualizar':'Agregar Beneficiario'}}</button>
      </div>
    </form>
  </div>
