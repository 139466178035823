import { Component } from '@angular/core';

import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-carousel-wallet',
  templateUrl: './carousel-wallet.component.html',
  styleUrls: ['./carousel-wallet.component.sass'],
})
export class CarouselWalletComponent {
  photoList = [
    {
      id: '0',
      author: 'Alejandro Escamilla',
      width: 5000,
      height: 3333,
      url: 'https://unsplash.com/photos/yC-Yzbqy7PY',
      download_url: 'https://picsum.photos/id/0/5000/3333',
    },
    {
      id: '1',
      author: 'Alejandro Escamilla',
      width: 5000,
      height: 3333,
      url: 'https://unsplash.com/photos/LNRyGwIJr5c',
      download_url: 'https://picsum.photos/id/1/5000/3333',
    },
    {
      id: '2',
      author: 'Alejandro Escamilla',
      width: 5000,
      height: 3333,
      url: 'https://unsplash.com/photos/N7XodRrbzS0',
      download_url: 'https://picsum.photos/id/2/5000/3333',
    },
    {
      id: '3',
      author: 'Alejandro Escamilla',
      width: 5000,
      height: 3333,
      url: 'https://unsplash.com/photos/Dl6jeyfihLk',
      download_url: 'https://picsum.photos/id/3/5000/3333',
    },
    {
      id: '4',
      author: 'Alejandro Escamilla',
      width: 5000,
      height: 3333,
      url: 'https://unsplash.com/photos/y83Je1OC6Wc',
      download_url: 'https://picsum.photos/id/4/5000/3333',
    },
  ];

  limit: number = 10;
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    center: false,
    dots: true,
    autoHeight: true,
    autoWidth: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
}
