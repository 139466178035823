<div class="m-8">
    <div class="flex flex-row flex-wrap justify-between items-center">
      <h1 class="text-xl font-bold">Cuentas de retiro</h1>
      <button mat-button (click)="openCreateDialog()">
        <mat-icon color="primary" class="add-icon">add_box</mat-icon>
        <span>Agregar cuenta de retiro</span>
      </button>
    </div>
  </div>
  <app-withdraw-account-list [listaCuentas]="listaCuentas" (seleccion)="onSeleccion($event)" (reloadAccountList)="reloadAccount()"[seleccionable]="seleccionable" (actualizarCuenta)="openCreateDialog($event)"></app-withdraw-account-list>
  
