import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SucessDialogComponent } from '../components/sucess-dialog/sucess-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog ) { }
  start(data : { message: string, button: string } ): MatDialogRef<SucessDialogComponent> {

    const dialogRef = this.dialog.open(SucessDialogComponent,{
        maxWidth: '800px',
        width: '40vw',
        minWidth: '400px',
        disableClose: true ,
        data: data
    });
    return dialogRef;
  };

stop(ref:MatDialogRef<SucessDialogComponent>){
    ref.close();
}
acept(ref:MatDialogRef<SucessDialogComponent>){
  ref.close(true);
}
}
