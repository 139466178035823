import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree, createUrlTreeFromSnapshot } from '@angular/router';
import { State, Store } from '@ngrx/store';
import { Observable, Subscription, firstValueFrom,switchMap, from, take, map, of } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { CartolaService } from 'src/app/dashboard/services/cartola.service';
import { ActionsService } from '../services/actions.service';
import { UserRoles } from '../enums/roles-enum';
import { EstadosUsuario } from '../enums/estados-usuario.enum';
import Swal from 'sweetalert2';
import { SpinnerService } from '../services/spinner.service';
import { ValidateLoginService } from './validate-login.service';

@Injectable({
  providedIn: 'root'
})
export class ActivateGuard implements CanActivate {


  isNotNullOrEmpty(object: Object) {
    return object && Object.keys(object).length > 0;
  }

  constructor(private store: Store<AppState>, private spinner: SpinnerService, private validateLogin: ValidateLoginService) {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.getFromStoreOrAPI().pipe(
      switchMap((res) => {
        if(!res.isLogged){
          this.validateLogin.validateLogin();
          return of(true);
        }
        else if(this.isNotNullOrEmpty(res.user) && res.user.estadoComplianceID === 3){
          return of(true);
        }else{
          Swal.fire({
            icon: 'warning',
            title: 'Completa tu validación',
            text: 'Para poder acceder a esta funcionalidad debes completar tu proceso de validación, si ya está en proceso por favor espera a que finalice',
            showCancelButton: false,
            showDenyButton: false,
            confirmButtonText: 'Entendido',
            confirmButtonColor: '#1e5a3f',

          });
          return of(createUrlTreeFromSnapshot(route, ['/wallet/home']));

        }
       }))

  }

  getFromStoreOrAPI(): Observable<any> {

    return this.store

      .select('auth').pipe(take(1));
  }



}
