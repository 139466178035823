<div
  class="overflow-y-auto my-10 h-auto flex flex-row justify-around items-center content-center gap-4 md:mx-4"
>
  <div
    class="w-11/12 md:w-5/6 xl:w-4/6 h-auto bg-slate-200 rounded-lg shadow xs:p-0"
  >
    <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
      <div class="flex flex-col justify-center items-center mt-4">
        <h1 class="text-2xl font-bold">{{ !esEditar ? 'Registrar pagador' : 'Editar pagador'}}</h1>
        <h1 class="text-2xl font-bold">{{ this.tipoUsuario === 0 ? 'Persona' : 'Empresa'}}</h1>
      </div>
      <div class="flex justify-evenly items-center mt-4 space-evenly" *ngIf="!esEditar">
        <button mat-raised-button color="primary" (click)="toggleTipoUsuario('persona')">Persona</button>
        <button mat-raised-button color="primary" (click)="toggleTipoUsuario('empresa')">Empresa</button>
      </div>
      <div class="space-y-4 md:space-y-6" *ngIf="isAdmin">
        <h2 class="text-xl font-bold">{{'ID del usuario: '}} {{this.data?.id}}</h2>
      </div>
      <form class="space-y-4 md:space-y-6" [formGroup]="datosForm" *ngIf="datosForm" (ngSubmit)="enviarDatos()">
        <h2 class="text-xl font-bold">{{ this.tipoUsuario === 0 ? 'Persona' : 'Representante legal'}}</h2>
        <div class="grid grid-cols-2 gap-4">
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input
              type="text"
              matInput
              pattern="[ a-zA-Z]*"
              formControlName="nombre"
              placeholder="Ingresa tu nombre"
            />
            <mat-error *ngIf="this.datosForm.get('nombre')!.hasError('pattern')">
              Sólo caracteres alfábeticos
           </mat-error>
           <mat-error *ngIf="this.datosForm.get('nombre')!.hasError('maxlength')">
            Máximo 40 caracteres
         </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Apellidos</mat-label>
            <input
              type="text"
              matInput
              pattern="[ a-zA-Z]*"
              formControlName="apellidos"
              placeholder="Ingresa tu apellido"
            />
            <mat-error *ngIf="this.datosForm.get('apellidos')!.hasError('pattern')">
              Sólo caracteres alfábeticos
           </mat-error>
           <mat-error *ngIf="this.datosForm.get('apellidos')!.hasError('maxlength')">
            Máximo 40 caracteres
         </mat-error>
          </mat-form-field>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <mat-form-field>
            <mat-label>Tipo documento</mat-label>
            <mat-select required formControlName="tipoId">
              <mat-option>Tipo documento</mat-option>
              <mat-option *ngFor="let tipoID of tiposID" [value]="tipoID">
                {{ tipoID }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Numero identificación</mat-label>
            <input
              type="text"
              matInput
              formControlName="numeroId"
              placeholder="Ingresa tu documento"
              (blur)="checkPayer('numeroId')"
            />
            <mat-error *ngIf="this.datosForm.get('numeroId')!.hasError('maxlength')">
              Máximo 15 caracteres
           </mat-error>
          </mat-form-field>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
          <mat-form-field class="col-span-2 sm:col-span-1">
            <mat-label>Código</mat-label>
            <input type="text"
                   placeholder="Selecciona uno"
                   aria-label="Number"
                   matInput
                   formControlName="prefijoTelefono"
                   [matAutocomplete]="auto" (blur)="checkPrefijo()">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
              <mat-option
              *ngFor="let pais of optionsPrefijoTelefono | async"
              [value]="pais.DIAL"
              class="flex flex-row"
            >
              <div [class]="'bg-' + pais.ALPHA2.toLowerCase()"></div>
              <span> {{ pais.DIAL }} - {{ pais.NAME | titlecase }} </span>
            </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field class="col-span-2">
            <mat-label>Teléfono</mat-label>
            <input
              type="text"
              matInput
              appOnlynumber
              formControlName="telefono"
              placeholder="Ingresa tu Teléfono"
            />
          </mat-form-field>
        </div>
        <div *ngIf="tipoUsuario === 0">
          <div class="grid grid-cols-1 gap-4">
            <mat-form-field>
             <mat-label>Nacionalidad</mat-label>
               <input type="text"
                      placeholder="Selecciona un país"
                      matInput
                      formControlName="nacionalidad"
                      required
                      [matAutocomplete]="autoPaisNacionalidad" (blur)="checkPais('nacionalidad')">
               <mat-autocomplete autoActiveFirstOption #autoPaisNacionalidad="matAutocomplete">
                 <mat-option
                 *ngFor="let pais of optionsNacionalidad | async"
                 [value]="pais.NAME"
                 class="flex flex-row"
               >
                 <div [class]="'bg-' + pais.ALPHA2.toLowerCase()"></div>
                 <span>  {{ pais.NAME | titlecase }} </span>
               </mat-option>
               </mat-autocomplete>
           </mat-form-field>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
            <mat-form-field>
              <mat-label>Sexo</mat-label>
              <mat-select required formControlName="sexo">
                <mat-option>Selecciona una opción</mat-option>
                <mat-option
                  *ngFor="let sexo of sexos"
                  [value]="sexo"
                  class="flex flex-row"
                >
                  <span> {{ sexo }}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="col-span-2" appearance="fill">
              <mat-label>Selecciona tu fecha</mat-label>
              <input
                matInput
                formControlName="fechaNacimiento"
                [matDatepicker]="picker"

                [max]="maxDate"
              />
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="grid grid-cols-1 gap-4">
            <mat-form-field >
              <mat-label>País de residencia</mat-label>
              <input type="text"
                     placeholder="Selecciona un país"
                     aria-label="Number"
                     matInput
                     formControlName="paisResidencia"
                     [matAutocomplete]="autoPais" (blur)="checkPais('paisResidencia')">
              <mat-autocomplete autoActiveFirstOption #autoPais="matAutocomplete">
                <mat-option
                *ngFor="let pais of optionsPaisResidencia | async"
                [value]="pais.NAME"
                class="flex flex-row"
              >
                <div [class]="'bg-' + pais.ALPHA2.toLowerCase()"></div>
                <span>  {{ pais.NAME | titlecase }} </span>
              </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <mat-form-field class="col-span-2">
              <mat-label>Ciudad de residencia</mat-label>
              <input
                type="text"
                matInput
                formControlName="ciudadResidencia"
                placeholder="Ej. Santiago"
              />
            </mat-form-field>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <mat-form-field class="col-span-1">
              <mat-label>Dirección</mat-label>
              <input
                type="text"
                matInput
                formControlName="direccion"
                placeholder="Ej. Santiago"
              />
            </mat-form-field>
            <mat-form-field class="col-span-1">
              <mat-label>Profesión</mat-label>
              <input
                type="text"
                matInput
                formControlName="profesion"
                placeholder="Ej. Ingeniero"
              />
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="esEditar; else imageEditar">
          <mat-card>
            <img mat-card-image class="card-image mt-4" [src]="fotoIdFrontal" [alt]="fotoIdFrontal">
            <mat-card-content class="self-center">
              <div class="file-select border-2 border-lime-700 rounded" id="src-file1" >
                <input
                class="mt-3 cursor-pointer"
                type="file"
                #fileInputFrontal
                (change)="showPreview($event, 'documentoFrontal')"
                accept=".png, .jpg, .jpeg"
                formControlName="documentoFrontal">
              </div>
            </mat-card-content>
            <div *ngIf="this.datosForm.controls['documentoFrontal'].invalid && (this.datosForm.controls['documentoFrontal'].dirty || this.datosForm.controls['documentoFrontal'].touched)" class="error-file alert">
              <mat-error class="error-file" *ngIf="this.datosForm.get('documentoFrontal')!.errors!['maxSize']">
                Máximo 5 MB
              </mat-error>
              <mat-error class="error-file" *ngIf="this.datosForm.get('documentoFrontal')!.errors!['incorrectFormat']">
                Únicamente formatos JPG o PNG
              </mat-error>
            </div>
          </mat-card>
          <mat-card>
            <img mat-card-image class="card-image mt-4" [src]="fotoIdPosterior" [alt]="fotoIdPosterior">
            <mat-card-content class="self-center">
              <div class="file-select border-2 border-lime-700 rounded" id="src-file2" >
                <input
                class="mt-3 cursor-pointer"
                type="file"
                #fileInputPosterior
                (change)="showPreview($event, 'documentoPosterior')"
                accept=".png, .jpg, .jpeg"
                formControlName="documentoPosterior">
              </div>
            </mat-card-content>
            <div *ngIf="this.datosForm.controls['documentoPosterior'].invalid && (this.datosForm.controls['documentoPosterior'].dirty || this.datosForm.controls['documentoPosterior'].touched)" class="error-file alert">
              <mat-error class="error-file" *ngIf="this.datosForm.get('documentoPosterior')!.errors!['maxSize']">
              Máximo 5 MB
              </mat-error>
              <mat-error class="error-file" *ngIf="this.datosForm.get('documentoPosterior')!.errors!['incorrectFormat']">
                Únicamente formatos JPG o PNG
                </mat-error>
            </div>
          </mat-card>
          <div class="grid grid-cols-1 gap-4">
            <h2 class="text-xl font-bold">{{ this.tipoUsuario === 1 ? 'Empresa' : ''}}</h2>
          </div>
          <div *ngIf="tipoUsuario === 1">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <mat-form-field>
                <mat-label>Nombre o Razón Social</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="razonSocial"
                  placeholder="Ingresa la razón social"
                />
                <mat-error
                  *ngIf="this.datosForm.get('razonSocial')!.hasError('maxlength')"
                >
                  Máximo 40 caracteres
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Nombre comercial</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="nombreComercial"
                  placeholder="Ingresa el nombre comercial"
                />

                <mat-error
                  *ngIf="
                    this.datosForm.get('nombreComercial')!.hasError('maxlength')
                  "
                >
                  Máximo 40 caracteres
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Rut Empresa</mat-label>
                <input
                  (blur)="validateRutEmpresa()"
                  type="text"
                  matInput
                  formControlName="rutEmpresa"
                  placeholder="Ingresa tu documento"

                />
                <div
                  *ngIf="
                    this.datosForm.controls['rutEmpresa'].invalid &&
                    (this.datosForm.controls['rutEmpresa'].dirty ||
                      this.datosForm.controls['rutEmpresa'].touched)
                  "
                  class="error-file alert"
                >
                  <mat-error
                    *ngIf="
                      this.datosForm.get('rutEmpresa')!.errors!['incorrectFormat'] ===
                      true
                    "
                  >
                    Ingrese un rut válido
                  </mat-error>
                  <mat-error
                    *ngIf="this.datosForm.get('rutEmpresa')!.hasError('maxlength')"
                  >
                    Máximo 15 caracteres
                  </mat-error>
                </div>
              </mat-form-field>
              <mat-form-field class="col-span-1">
                <mat-label>Domicilio fiscal</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="domicilioFiscal"
                  placeholder="Ej. Santiago"
                />
                <mat-error
                  *ngIf="
                    this.datosForm.get('domicilioFiscal')!.hasError('maxlength')
                  "
                >
                  Máximo 60 caracteres
                </mat-error>
              </mat-form-field>
            </div>
            <div class="grid grid-cols-1 gap-4">
              <mat-card>
                <img mat-card-image *ngIf="!this.pagador?.estatuto?.includes('.pdf')" class="card-image mt-4" [src]="estatuto" [alt]="estatuto">
                <mat-card-content class="self-center">
                  <div class="file-select border-2 border-lime-700 rounded" id="src-file4" >
                    <input
                    class="mt-3 cursor-pointer"
                    type="file"
                    #fileEstatuto
                    (change)="showPreview($event, 'estatuto')"
                    accept="'image/*, .pdf'"
                    formControlName="estatuto">
                  </div>
                </mat-card-content>
                <div *ngIf="this.datosForm.controls['estatuto'].invalid && (this.datosForm.controls['estatuto'].dirty || this.datosForm.controls['estatuto'].touched)" class="error-file alert">
                  <mat-error class="error-file" *ngIf="this.datosForm.get('estatuto')!.errors!['maxSize']">
                  Máximo 5 MB
                  </mat-error>
                  <mat-error class="error-file" *ngIf="this.datosForm.get('estatuto')!.errors!['incorrectFormat']">
                    Únicamente formatos JPG o PNG
                    </mat-error>
                </div>
              </mat-card>
            </div>
            <div class="grid grid-cols-1 gap-4">
              <mat-card>
                <img mat-card-image *ngIf="!this.pagador?.e_rut?.includes('.pdf')" class="card-image mt-4" [src]="erut" [alt]="erut">
                <mat-card-content class="self-center">
                  <div class="file-select border-2 border-lime-700 rounded" id="src-file3" >
                    <input
                    class="mt-3 cursor-pointer"
                    type="file"
                    #fileRutE
                    (change)="showPreview($event, 'e_rut')"
                    accept="'image/*, .pdf'"
                    formControlName="e_rut">
                  </div>
                </mat-card-content>
                <div *ngIf="this.datosForm.controls['e_rut'].invalid && (this.datosForm.controls['e_rut'].dirty || this.datosForm.controls['e_rut'].touched)" class="error-file alert">
                  <mat-error class="error-file" *ngIf="this.datosForm.get('e_rut')!.errors!['maxSize']">
                  Máximo 5 MB
                  </mat-error>
                  <mat-error class="error-file" *ngIf="this.datosForm.get('e_rut')!.errors!['incorrectFormat']">
                    Únicamente formatos JPG o PNG
                    </mat-error>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
        <ng-template #imageEditar>
          <div class="grid grid-cols-1 gap-4">
            <mat-form-field appearance="fill" class="w-full">
              <ngx-mat-file-input
                class="w-full"
                #fileInputFrontal
                formControlName="documentoFrontal"
                [multiple]="false"
                [accept]="'image/*'"
                color="primary"
                placeholder="Adjunte imagen frontal de su documento"
              >
              </ngx-mat-file-input>
              <div *ngIf="this.datosForm.controls['documentoFrontal'].invalid && (this.datosForm.controls['documentoFrontal'].dirty || this.datosForm.controls['documentoFrontal'].touched)" class="error-file alert">
                <mat-error class="error-file" *ngIf="this.datosForm.get('documentoFrontal')!.errors!['maxSize']">
                Máximo 5 MB
                </mat-error>
                <mat-error class="error-file" *ngIf="this.datosForm.get('documentoFrontal')!.errors!['incorrectFormat']">
                  Únicamente formatos JPG o PNG
                </mat-error>
            </div>
            </mat-form-field>
          </div>
          <div class="grid grid-cols-1 gap-4">
            <mat-form-field appearance="fill" class="w-full">
              <ngx-mat-file-input
                [multiple]="false"
                formControlName="documentoPosterior"
                #fileInputPosterior
                [accept]="'image/*'"
                color="primary"
                placeholder="Adjunte imagen posterior de su documento"
              >
              </ngx-mat-file-input>
              <div *ngIf="this.datosForm.controls['documentoPosterior'].invalid && (this.datosForm.controls['documentoPosterior'].dirty || this.datosForm.controls['documentoPosterior'].touched)" class="error-file alert">
                <mat-error class="error-file" *ngIf="this.datosForm.get('documentoPosterior')!.errors!['maxSize']">
                Máximo 5 MB
                </mat-error>
                <mat-error class="error-file" *ngIf="this.datosForm.get('documentoPosterior')!.errors!['incorrectFormat']">
                  Únicamente formatos JPG o PNG
                </mat-error>
            </div>
            </mat-form-field>
          </div>
          <h2 class="text-xl font-bold">{{ this.tipoUsuario === 1 ? 'Empresa' : ''}}</h2>
          <div *ngIf="tipoUsuario === 1">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <mat-form-field>
                <mat-label>Nombre o Razón Social</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="razonSocial"
                  placeholder="Ingresa la razón social"
                />
                <mat-error
                  *ngIf="this.datosForm.get('razonSocial')!.hasError('maxlength')"
                >
                  Máximo 40 caracteres
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Nombre comercial</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="nombreComercial"
                  placeholder="Ingresa el nombre comercial"
                />

                <mat-error
                  *ngIf="
                    this.datosForm.get('nombreComercial')!.hasError('maxlength')
                  "
                >
                  Máximo 40 caracteres
                </mat-error>
              </mat-form-field>
              <mat-form-field>
                <mat-label>Rut Empresa</mat-label>
                <input
                  (blur)="validateRutEmpresa()"
                  type="text"
                  matInput
                  formControlName="rutEmpresa"
                  placeholder="Ingresa tu documento"
                />
                <div
                  *ngIf="
                    this.datosForm.controls['rutEmpresa'].invalid &&
                    (this.datosForm.controls['rutEmpresa'].dirty ||
                      this.datosForm.controls['rutEmpresa'].touched)
                  "
                  class="error-file alert"
                >
                  <mat-error
                    *ngIf="
                      this.datosForm.get('rutEmpresa')!.errors!['incorrectFormat'] ===
                      true
                    "
                  >
                    Ingrese un rut válido
                  </mat-error>
                  <mat-error
                    *ngIf="this.datosForm.get('rutEmpresa')!.hasError('maxlength')"
                  >
                    Máximo 15 caracteres
                  </mat-error>
                </div>
              </mat-form-field>
              <mat-form-field class="col-span-1">
                <mat-label>Domicilio fiscal</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="domicilioFiscal"
                  placeholder="Ej. Santiago"
                />
                <mat-error
                  *ngIf="
                    this.datosForm.get('domicilioFiscal')!.hasError('maxlength')
                  "
                >
                  Máximo 60 caracteres
                </mat-error>
              </mat-form-field>
            </div>
            <div class="grid grid-cols-1 gap-4">
              <mat-form-field appearance="fill" class="w-full">
                <ngx-mat-file-input
                  [multiple]="false"
                  formControlName="estatuto"
                  #fileInputEstatuto
                  [accept]="'image/*, .pdf'"
                  color="primary"
                  (change)="showPreview($event, 'estatuto')"
                  placeholder="Adjunte Estatuto constitutivo de la empresa"
                >
                </ngx-mat-file-input>
                <div
                  *ngIf="
                    this.datosForm.controls['estatuto'].invalid &&
                    (this.datosForm.controls['estatuto'].dirty ||
                      this.datosForm.controls['estatuto'].touched)
                  "
                  class="error-file alert"
                >
                  <mat-error
                    class="error-file"
                    *ngIf="
                      this.datosForm.get('estatuto')!.errors!['maxSize']
                    "
                  >
                    Máximo 5 MB
                  </mat-error>
                  <mat-error
                    class="error-file"
                    *ngIf="
                      this.datosForm.get('estatuto')!.errors![
                        'incorrectFormat'
                      ]
                    "
                  >
                    Únicamente formatos JPG, PNG o PDF
                  </mat-error>
                </div>
              </mat-form-field>
            </div>
            <div class="grid grid-cols-1 gap-4">
              <mat-form-field appearance="fill" class="w-full">
                <ngx-mat-file-input
                  [multiple]="false"
                  formControlName="e_rut"
                  [accept]="'image/*, .pdf'"
                  color="primary"
                  #fileInputERut
                  (change)="showPreview($event, 'e_rut')"
                  placeholder="Adjunte e-RUT de la empresa"
                >
                </ngx-mat-file-input>
                <div
                  *ngIf="
                    this.datosForm.controls['e_rut'].invalid &&
                    (this.datosForm.controls['e_rut'].dirty ||
                      this.datosForm.controls['e_rut'].touched)
                  "
                  class="error-file alert"
                >
                  <mat-error
                    class="error-file"
                    *ngIf="
                      this.datosForm.get('e_rut')!.errors!['maxSize']
                    "
                  >
                    Máximo 5 MB
                  </mat-error>
                  <mat-error
                    class="error-file"
                    *ngIf="
                      this.datosForm.get('e_rut')!.errors![
                        'incorrectFormat'
                      ]
                    "
                  >
                    Únicamente formatos JPG, PNG o PDF
                  </mat-error>
                </div>
              </mat-form-field>
            </div>
          </div>
        </ng-template>

        <div class=" gap-4 w-full flex flex-row items-center align-middle justify-center">
          <button mat-raised-button (click)="cancelar()" type="button">
            Cancelar
          </button>
          <button
            type="submit"
            tabindex="1"
            mat-raised-button
            color="primary"
            [disabled]="!validPayerId"
          >
            Enviar datos
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<div>
  <button class="close"  mat-stroked-button (click)="cancelar()" *ngIf="isAdmin">Cerrar </button>
</div>
