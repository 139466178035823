<div class="w-[800px] px-16 py-10  rounded-3xl uploader-status flex flex-col items-center justify-center align-middle">

  <h2 class="text-2xl font-bold self-start">Editar transacción
  </h2>

  <h3 class="text-lg font-bold my-4  self-start">Id transacción: {{transaccion.id}}</h3>
  <p class="  self-start">Sólo podrá editar nombre del beneficiario, DNI y número de cuenta</p>
    <form [formGroup]="datosForm" class="w-full my-4" *ngIf="datosForm">
      <div class="grid grid-cols-2 gap-4  w-full">
        <mat-form-field>
          <mat-label>Nombre</mat-label>
          <input
            type="text"
            matInput
            formControlName="nombre"
            placeholder="Ingresa tu nombre"
          />
        </mat-form-field>
        <mat-form-field>
          <mat-label>Apellidos</mat-label>
          <input
            type="text"
            matInput
            formControlName="apellido"
            placeholder="Ingresa tu apellido"
          />
        </mat-form-field>
      </div>

      <div class="grid grid-cols-2 gap-4">
        <mat-form-field>
          <mat-label>Tipo documento</mat-label>
          <mat-select required formControlName="tipoID">
            <mat-option>Tipo documento</mat-option>
            <mat-option *ngFor="let tipo of tiposID" [value]="tipo">
              {{ tipo }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Número identificación</mat-label>
          <input
            type="text"
            matInput
            formControlName="numeroID"
            placeholder="Ingresa tu documento"
          />
        </mat-form-field>
      </div>
      <div class="grid grid-cols-1 gap-4">
        <mat-form-field class="col-span-2">
          <mat-label>Número de cuenta</mat-label>
          <input
            type="text"
            matInput
            formControlName="numeroCuenta"
            placeholder="Máximo 20 carácteres"
          />
        </mat-form-field>
      </div>

    </form>

  <div class="flex flex-row justify-center gap-10 w-full mt-10">
    <button type="button" (click)="updateTransaction()" [disabled]="datosForm?.invalid" mat-raised-button color="primary" class="mt-4 w-1/2  text-lg rounded-lg px-5 py-6 text-center"
    >
    Editar transacción
  </button>
    <button type="button" mat-raised-button color="basic" (click)="close()"
      class=" w-1/2 mt-4  text-lg rounded-lg px-5 py-6 text-center">
      Cancelar
    </button>
  </div>

</div>
