import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { Pagador } from 'src/app/dashboard/interfaces/pagador';
import { PagadorService } from 'src/app/dashboard/services/pagador.service';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import Swal from 'sweetalert2';
import { PagadorComplianceComponent } from '../../pagador-compliance/pagador-compliance.component';

@Component({
  selector: 'app-list-payer',
  templateUrl: './list-payer.component.html',
  styleUrls: ['./list-payer.component.css']
})
export class ListPayerComponent implements OnInit {
  usuarioID: number | undefined;
  @Output() reloadAccountList: EventEmitter<any> = new EventEmitter();
  @Output() actualizarCuenta: EventEmitter<Pagador> = new EventEmitter();
  @Output() seleccion: EventEmitter<Pagador> = new EventEmitter();
  subscriptions: Subscription = new Subscription();
  @Input() listaCuentas: Pagador[] = [];
  @Input() set setPagadores(pagadores:Pagador[]){
    this.isAdminView= true;
    this.dataSource = new MatTableDataSource(pagadores);
  }
  isAdminView = false;
  @Input() seleccionable: boolean = false;
  cuentaSeleccionada: Pagador | undefined;

  displayedColumns: string[] = [

    'tipoUsuario',
    'nombre',
    'razon_social',
    'tipoId',
    'numeroId',
    'paisResidencia',
    'nacionalidad',
    'estado_compliance',
    'fecha_registro',
    'acciones',
  ];

  columns = [
    {
      columnDef: 'tipoUsuario',
      header: 'Tipo',
      cell: (element: any) => `${element.tipoUsuarioId===1? 'Empresa' : 'Persona'}`,
    },
    {
      columnDef: 'paisResidencia',
      header: 'Pais',
      cell: (element: any) => `${element.tipoUsuarioId===1? 'No aplica' : element.paisResidencia}`,
    },
    {
      columnDef: 'nacionalidad',
      header: 'Nacionalidad',
      cell: (element: any) => `${element.tipoUsuarioId===1? 'No aplica' : element.nacionalidad}`,
    },
    {
      columnDef: 'tipoId',
      header: 'Tipo ID',
      cell: (element: any) => `${element.tipoUsuarioId===1? 'RUT EMPRESA' : element.tipoId}`,
    },
    {
      columnDef: 'numeroId',
      header: 'Documento',
      cell: (element: any) => `${element.tipoUsuarioId===1? element.rutEmpresa : element.numeroId}`,
    },
    {
      columnDef: 'nombre',
      header: 'Nombre',
      cell: (element: any) => `${element.tipoUsuarioId===1? element.nombreComercial : element.nombre + ' ' + element.apellidos}`,
    },
    {
      columnDef: 'razon_social',
      header: 'Razon social',
      cell: (element: any) => `${element.tipoUsuarioId===1? element.razonSocial : 'No aplica'}`,
    },
    {
      columnDef: 'estado_compliance',
      header: 'Estado compliance',
      cell: (element: any) => `${element.estadoCompliance.descripcion}`,
    },


  ];
  dataSource: MatTableDataSource<any> | undefined;

    constructor(
    private store: Store<AppState>,
    private pagadorService: PagadorService,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private spinnerService: SpinnerService
  ) {
    this.initFormCuenta();
  }



  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initFormCuenta() {
    this.subscriptions.add(
      this.store.select('auth').subscribe(async ({ user }) => {
        this.usuarioID = user?.usuarioId;
        if(this.usuarioID){
          this.listaCuentas = await this.pagadorService.getPagadoresByUser(this.usuarioID);
          this.dataSource = new MatTableDataSource(this.listaCuentas);
          console.log(this.listaCuentas);
        }
      })
    );

  }
  ngOnInit() {}

  async reloadAccount(){
    if(this.usuarioID){
      this.listaCuentas = await this.pagadorService.getPagadoresByUser(this.usuarioID);
      this.dataSource = new MatTableDataSource(this.listaCuentas);

    }
  }

  async eliminar(pagadorId: string){
    console.log(pagadorId);
    const resultado = await  Swal.fire({
      text: '¿Está seguro de que desea desvincular esta cuenta?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: `No`,
      confirmButtonColor: '#1e5a3f',

    });
    if(resultado.isConfirmed){
      const ref =  this.spinnerService.start();
      try {

      const result = await this.pagadorService.inactivatePagador(pagadorId);
      console.log(result);
      this.reloadAccount();
      Swal.fire({
        icon: 'success',
        title: 'Pagador eliminado',
        text: 'El pagador ha sido eliminado exitosamente',
        showCancelButton: false,
        showDenyButton: false,
        confirmButtonText: 'Entendido',
        confirmButtonColor: '#1e5a3f',

      });
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'No se puede eliminar el pagador',
          text: 'Ha ocurrido un error al intentar eliminar el pagador',
          showCancelButton: false,
          showDenyButton: false,
          confirmButtonText: 'Ententido',
          confirmButtonColor: '#1e5a3f',

        });
      }
      this.spinnerService.stop(ref);
    }


  }

  actualizar(cuenta: Pagador){
  }
  openPagadorData(pagador:Pagador){
    this.dialog.open(PagadorComplianceComponent,{
      disableClose: true,
      data: {pagador}
      ,height: '680px',
      width: '1000px'
    }).afterClosed().subscribe(res=>{
      if(res === true){
        console.log('Se actualizó pagador');
        this.reloadAccountList.emit(true);
      }
    })
  }
}
