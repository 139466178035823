import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-validation',
  templateUrl: './payment-validation.component.html',
  styleUrls: ['./payment-validation.component.sass']
})
export class PaymentValidationComponent implements OnInit {

  constructor() { }
  @Input() valorATransferir: any | undefined;
  @Input() beneficiario: any | undefined;
  @Input() tipoPago: string | undefined;
  ngOnInit() {

  }

}
