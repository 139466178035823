import { ChangeDetectionStrategy } from '@angular/core';
import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ParameterService } from '../../services/parameter.service';
import { ParametrosEnum } from 'src/app/shared/enums/parameters.enum';
import Swal from 'sweetalert2';
import { SpinnerService } from 'src/app/shared/services/spinner.service';

@Component({
  selector: 'app-set-rates-parameters',
  templateUrl: './set-rates-parameters.component.html',
  styleUrls: ['./set-rates-parameters.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetRatesParametersComponent {

  valueSL: number = 0;
  valueLQ: number = 0;
  discount1: number = 0;
  discount2: number = 0;


  constructor(private parametersService: ParameterService, private spinner: SpinnerService) {
    this.parametersService.getParameter(ParametrosEnum.VALOR_SOLVENCIA).then((value) => {
      this.valueSL = value.valor;
      this.ratesForm.controls['valueSL'].setValue(value.valor);
    }
    );
    this.parametersService.getParameter(ParametrosEnum.VALOR_LIQUIDEZ).then((value) => {
      this.valueLQ = value.valor;
      this.ratesForm.controls['valueLQ'].setValue(value.valor);
    }
    );
    this.parametersService.getParameter(ParametrosEnum.DESCUENTO_1).then((value) => {
      this.discount1 = value.valor;
      this.ratesForm.controls['discount1'].setValue(value.valor);
    }
    );
    this.parametersService.getParameter(ParametrosEnum.DESCUENTO_2).then((value) => {
      this.discount2 = value.valor;
      this.ratesForm.controls['discount2'].setValue(value.valor);
    }
    );


   }
  ratesForm = new FormGroup({
    valueSL: new FormControl(),
    valueLQ: new FormControl(),
    discount1: new FormControl(),
    discount2: new FormControl(),
  });

  reset(){
    this.ratesForm.controls['valueSL'].setValue(this.valueSL);
    this.ratesForm.controls['valueLQ'].setValue(this.valueLQ);
    this.ratesForm.controls['discount1'].setValue(this.discount1);
    this.ratesForm.controls['discount2'].setValue(this.discount2);
  }

  showConfirm(){
    Swal.fire({
      title: '¿Está seguro de guardar los parámetros?',
      showCancelButton: true,
      confirmButtonText: `Guardar`,
      cancelButtonText: `Cancelar`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const ref = this.spinner.start();
        await this.setParameters();
        this.spinner.stop(ref);
        Swal.fire('Guardado', '', 'success');
      }
    });
  }
  async setParameters(){

    const valueSL = this.ratesForm.controls['valueSL'].value;
    const valueLQ = this.ratesForm.controls['valueLQ'].value;
    const discount1 = this.ratesForm.controls['discount1'].value;
    const discount2 = this.ratesForm.controls['discount2'].value;

    await Promise.all([
      this.parametersService.saveParameter(ParametrosEnum.VALOR_SOLVENCIA, valueSL),
      this.parametersService.saveParameter(ParametrosEnum.VALOR_LIQUIDEZ, valueLQ),
      this.parametersService.saveParameter(ParametrosEnum.DESCUENTO_1, discount1),
      this.parametersService.saveParameter(ParametrosEnum.DESCUENTO_2, discount2),
    ]);
    this.discount1 = discount1;
    this.discount2 = discount2;
    this.valueLQ = valueLQ;
    this.valueSL = valueSL;


  }
}
