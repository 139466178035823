<div class="m-4 w-full  overflow-x-auto " >
  <h1 class="m-4 text-2xl font-bold"> Lista de transacciones </h1>

  <mat-form-field class="p-4" style="width: 90%;">

    <input matInput type="text" (keyup)="doFilter($event)" placeholder="Filtrar datos">
    <mat-icon matPrefix class="my-icon">search</mat-icon>
  </mat-form-field>

  <div class="flex flex-row align-middle items-center">
    <button class="ml-4" [disabled]="seleccionados === 0" mat-raised-button color="primary" (click)="limpiarSeleccion()"  aria-label="Limpiar" title="Limpiar">
      Limpiar seleccion
    </button>
    <p class="m-4 text-gray-500"  >Seleccionadas: {{seleccionados}}</p>
  </div>

  <div class="flex overflow-x-auto">
    <table mat-table [dataSource]="dataSource" class="m-4" *ngIf="dataSource">
      <ng-container matColumnDef="seleccion">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions" style="background-color: #CEE9C2;">Seleccionar</th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <mat-checkbox (change)="change()" [disabled]="element.estado !== 'POR_PROCESAR'" [(ngModel)]="element.seleccionado" class="example-margin"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container
        *ngFor="let column of columns"
        [matColumnDef]="column.columnDef"
      >
        <th mat-header-cell *matHeaderCellDef style="background-color: #CEE9C2; text-align:center" >{{column.header}}</th>
        <td mat-cell *matCellDef="let row">{{column.cell(row)}}</td>
      </ng-container>
      <ng-container matColumnDef="acciones" stickyEnd>
        <th mat-header-cell *matHeaderCellDef aria-label="row actions" style="background-color: #CEE9C2; text-align:center">Acciones</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button color="accent" aria-label="Asignar" title="Asignar" (click)="openAssignDialog(element)">
            <mat-icon>assignment_add</mat-icon>
          </button>
        </td>
      </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
