<div class="m-4 w-full  overflow-x-auto " >
    <h1 class="m-4 text-2xl font-bold text-center">Transacciones retiro FIAT enviadas</h1>

    <div class="flex flex-row gap-5 align-middle mb-4 justify-center flex-wrap" style="max-width: 800px; margin: 0 auto;">
      <mat-form-field class="p-4">

        <input matInput type="text" (keyup)="doFilter($event)" placeholder="Filtrar datos">
        <mat-icon matPrefix class="my-icon">search</mat-icon>
      </mat-form-field>
    </div>

  <div class="paginator-container my-4 self-end">
    <mat-paginator #paginator class="self-left" (page)="onPageChange($event)" [length]="totalItems"
      [pageSize]="pageSize" [disabled]="false" [showFirstLastButtons]="true" [pageSizeOptions]="[5, 10, 25, 50, 100]"
      [hidePageSize]="false" [pageIndex]="currentPage-1" aria-label="Select page">
    </mat-paginator>
  </div>

  <div class="m-4 flex overflow-x-auto">
    <div class="table-container flex">
      <table mat-table [dataSource]="dataSource" class="m-4" *ngIf="dataSource">
        <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
          <th mat-header-cell *matHeaderCellDef class="bg-[#CEE9C2] text-center">{{column.header}}
          </th>
          <td mat-cell *matCellDef="let row" class="">{{column.cell(row)}}</td>
        </ng-container>
        <ng-container matColumnDef="acciones" stickyEnd>
          <th mat-header-cell *matHeaderCellDef aria-label="row actions"
            style="background-color: #CEE9C2; text-align:center">Acciones</th>
          <td mat-cell *matCellDef="let element">
            <div class="flex gap-2 justify-center">
              <button mat-raised-button color="primary" class="w-[60px]" aria-label="copiar" title="copiar" (click)="copyData(element)">
                <mat-icon>content_copy</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
  </div>
