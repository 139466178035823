<div class="p-6 space-y-4 md:space-y-6 sm:p-8">
  <div class="container flex flex-row items-center">
    <h1 class="text-xl font-bold">
      Actualizar Tasas
    </h1>
  </div>
  <form
    *ngIf="formCuenta"
    class="space-y-4 md:space-y-6"
    [formGroup]="formCuenta"
  >
  <div class="grid grid-cols-2 gap-4">
    <mat-form-field>
      <mat-label>Tasa CLP</mat-label>
      <input
        type="number"
        matInput
        formControlName="tasaCLP"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Porcentaje comisión</mat-label>
      <input
        type="number"
        matInput
        formControlName="porcentajeComision"
      />
    </mat-form-field>
  </div>
    <div class="grid grid-cols-2 gap-4">
      <mat-form-field>
        <mat-label>Tasa Banesco</mat-label>
        <input
          type="number"
          matInput
          formControlName="tasaBanesco"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Tasa Provincial</mat-label>
        <input
          type="number"
          matInput
          formControlName="tasaProvincial"
        />
      </mat-form-field>
    </div>
    <div class="grid grid-cols-2 gap-4">
      <mat-form-field>
        <mat-label>Tasa Mercantil</mat-label>
        <input
          type="number"
          matInput
          formControlName="tasaMercantil"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Tasa Venezuela</mat-label>
        <input
          type="number"
          matInput
          formControlName="tasaVenezuela"
        />
      </mat-form-field>
    </div>
    <div class="container flex flex-col items-center">
      <button
        (click)="actualizarTasa()"
        type="button"
        class="w-1/2 text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
      >
        Guardar
      </button>
    </div>
  </form>
</div>
