import { Component } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { CartolaService } from '../../services/cartola.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';

@Component({
  selector: 'app-charge-account',
  templateUrl: './charge-account.component.html',
  styleUrls: ['./charge-account.component.sass']
})
export class ChargeAccountComponent {
  data: any;

  constructor(private cartolaService: CartolaService, private clipboard: Clipboard, private spinner: SpinnerService) {
    this.loadData();
  }

  ngOnInit(): void {
  }

  async loadData(): Promise<void> {
    let spinnerRef = this.spinner.start();
    const accounts = await this.cartolaService.getEmisores();
    this.data = accounts;
    this.spinner.stop(spinnerRef);
  }
  
  copyData(item: any): void {
    const textToCopy = `${item.banco}\nCuenta Corriente\n${item.cuenta}\n${item.numeroCuenta}\nRut: ${this.formatRut(item.rutemisor)}`;

    this.clipboard.copy(textToCopy);

    console.log('Informacion de ',item.cuenta,' copiada');
  }

  formatRut(rut: string): string {
    if (!rut) return '';
    
    const rutDigits = rut.replace(/[^\d]/g, ''); 
    const rutLength = rutDigits.length;
  
    if (rutLength < 3) return rut;
  
    const lastDigit = rutDigits.charAt(rutLength - 1);
    const mainPart = rutDigits.substring(0, rutLength - 1);
  
    let formattedRut = mainPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  
    formattedRut = formattedRut + '-' + lastDigit;
  
    return formattedRut;
  }
}
