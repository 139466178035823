import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { CartolaService } from '../../services/cartola.service';
import { Subscription } from 'rxjs';
import { UserRoles } from 'src/app/shared/enums/roles-enum';
import { TransaccionService } from '../../services/transaccion.service';
import { TransactionUserDTO } from '../../interfaces/transaction-user.dto';
import { ManualTransactionService } from '../../services/manual-transaction.service';
import { ManualTransaction } from '../../interfaces/manual-transaction';
import { MatTableDataSource } from '@angular/material/table';
import { UtcToLocalTimeFormat } from 'src/app/shared/pipes/utc-converter.service';
import { UtcToLocalTimePipe } from 'src/app/shared/pipes/utc-to-local-time.pipe';
import { CurrencyPipe } from '@angular/common';
import { Sort } from '@angular/material/sort';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { MatPaginator } from '@angular/material/paginator';
import { SpinnerComponent } from 'src/app/shared/components/spinner/spinner.component';
import { EstadosTransferenciaManual } from 'src/app/shared/enums/estados-transferencia-manual.enum';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit, OnDestroy {
  view: 'historial' | 'lista manuales' = 'historial';
  viewInfo: boolean = false;
  manualTransactions: ManualTransaction[] = [];
  subscription: Subscription = new Subscription();
  userRole: UserRoles = UserRoles.aguacate_client;
  user: any;
  nombreUsuario: string = '';
  userEnum = UserRoles;
  estadoValidacion: number = -1;
  saldoActual = 0;
  listaTransacciones: TransactionUserDTO[] = [];
  dataSourceHistorial: MatTableDataSource<any> | undefined;
  public currentPage = 1;
  public pageSize = 25;
  public totalItems = 0;
  public totalPages = this.totalItems/this.pageSize;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private transaccionService: TransaccionService,
    private manualTransaccionService: ManualTransactionService,
    private datePipe: UtcToLocalTimePipe,
    private currencyPipe:CurrencyPipe,
    private spinner: SpinnerService
    ) {
    let spinnerRef = this.spinner.start();
    this.view = 'historial';
    this.subscription.add(
      this.store.select('auth').subscribe(async ({ user }: any) => {
        this.user = user;
        if(user && user.usuarioId ){
          const data = await this.transaccionService.getTransaccionCliente(this.currentPage, this.pageSize, user.usuarioId);
          this.listaTransacciones = data.query; 
          this.totalItems = +data.count;
          this.totalPages = Math.ceil (this.totalItems/this.pageSize);
          this.manualTransactions = await this.manualTransaccionService.getAllTransactionsByUser(user.usuarioId);
          this.manualTransactions= this.manualTransactions.filter(transaction => transaction.estado === EstadosTransferenciaManual.ABIERTA);
          this.dataSourceHistorial = new MatTableDataSource(this.manualTransactions);
        }
        this.validateManualTransactions();
      })                                      
    );
    this.spinner.stop(spinnerRef);
  }

  validateManualTransactions(){
    if(this.manualTransactions.length>0){
      this.viewInfo=true;
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onListaManualesClick(){
    this.view= 'lista manuales';
  }

  onHistorialClick(){
    this.view = 'historial';
  }

  onPageChange(event: any) {
    this.pageSize=event.pageSize;
    this.currentPage = event.pageIndex+1;
    this.loadTransactions();
  }

  async loadTransactions(){        
    if(this.user && this.user.usuarioId ){
      const ref = this.spinner.start();
      const data = await this.transaccionService.getTransaccionCliente(this.currentPage,this.pageSize, this.user.usuarioId);
      this.listaTransacciones = data.query; 
      this.totalItems = +data.count;
      this.totalPages = Math.ceil (this.totalItems/this.pageSize);
      this.spinner.stop(ref);
    }
  }

  sortDataHistory(sort: Sort) {
    const data = this.manualTransactions.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSourceHistorial = new MatTableDataSource(data);
    }

    this.manualTransactions = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'totalEnvio':
          return this.compare(a.totalEnvio, b.totalEnvio, isAsc);
        case 'totalDest':
          return this.compare(a.totalDest, b.totalDest, isAsc);
        case 'valorPagado':
          return this.compare(a.valorPagado, b.valorPagado, isAsc);
        case 'tasa':
          return this.compare(a.tasa, b.tasa, isAsc);
        case 'fechaCreacion':
          return this.compare(a.fechaCreacion.toString(), b.fechaCreacion.toString(), isAsc);
        case 'fechaFin':
          return this.compare(a.fechaFin.toString(), b.fechaFin.toString(), isAsc);
        case 'fechaActualizacion':
          return this.compare(a.fechaActualizacion.toString(), b.fechaActualizacion.toString(), isAsc);
        case 'estado':
          return this.compare(a.estado, b.estado, isAsc);
        default:
          return 0;
      }
    });
    this.dataSourceHistorial = new MatTableDataSource( this.manualTransactions);
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  displayedColumnsHistorial: string[] = [
    'totalEnvio',
    'monedaLocal',
    'totalDest',
    'monedaDestino',
    'valorPagado',
    'tasa',
    'walletTrx',
    'estado',
    'fechaCreacion',
    'fechaFin',
    'fechaActualizacion'
  ];

  columnsHistorial = [
    {
      columnDef: 'id',
      header: 'Id',
      cell: (element: any) => `${element.id}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'220px','min-width':'220px'},
      cellStyle: { 'text-align':'center','max-width':'220px','min-width':'220px'},
    },
    {
      columnDef: 'totalEnvio',
      header: 'Monto Promesa',
      cell: (element: any) => `${this.currencyPipe.transform(element.totalEnvio, 'USD', 'symbol-narrow', '1.2-2', 'en-US')}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'220px','min-width':'180px'},
      cellStyle: { 'text-align':'left', 'max-width':'220px','min-width':'180px'},
    },
    {
      columnDef: 'totalDest',
      header: 'Total Destino',
      cell: (element: any) => `${this.currencyPipe.transform(element.totalDest, 'USD', 'symbol-narrow', '1.2-2', 'en-US')}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'110px','min-width':'100px'},
      cellStyle: { 'text-align':'center','max-width':'110px','min-width':'100px'},
    },
    {
      columnDef: 'monedaLocal',
      header: 'Moneda Local',
      cell: (element: any) => `${element.monedaLocal}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'100px'},
      cellStyle: { 'text-align':'center','max-width':'100px'},
    },
    {
      columnDef: 'monedaDestino',
      header: 'Moneda Destino',
      cell: (element: any) => `${element.monedaDestino}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'100px'},
      cellStyle: { 'text-align':'center','max-width':'100px'},
    },
    {
      columnDef: 'valorPagado',
      header: 'Valor Pagado',
      cell: (element: any) => `${this.currencyPipe.transform(element.valorPagado, 'USD', 'symbol-narrow', '1.2-2', 'en-US')}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'160px','min-width':'140px'},
      cellStyle: { 'text-align':'left','max-width':'160px','min-width':'140px'},
    },
    {
      columnDef: 'tasa',
      header: 'Tasa',
      cell: (element: any) => `${element.tasa}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'80px','min-width':'60px'},
      cellStyle: { 'text-align':'center','max-width':'80px','min-width':'60px'},
    },
    {
      columnDef: 'walletTrx',
      header: 'Wallet',
      cell: (element: any) => `${element.walletTrx}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'300px','min-width':'260px'},
      cellStyle: { 'text-align':'left','max-width':'300px','min-width':'260px'},
    },
    {
      columnDef: 'estado',
      header: 'Estado',
      cell: (element: any) => {
        switch (element.estado) {
          case 'ABIERTA':
            return 'report';
          case 'CERRADA':
            return 'verified_user';
          default:
            return '';
        }
      },
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','min-width':'90px','max-width':'100px'},
      cellStyle: { 'text-align':'center','min-width':'90px','max-width':'100px', 'display': 'list-item'},
      iconColor: (element: any) => {
        switch (element.estado) {
          case 'ABIERTA':
            return '#EDD402';
          case 'CERRADA':
            return 'green';
          default:
            return '';
        }
      },
      val: (element: any) => `${element.estado}`,
    },
    {
      columnDef: 'fechaCreacion',
      header: 'Fecha de Creación',
      cell: (element: any) => `${this.datePipe.transform(element.fechaCreacion,UtcToLocalTimeFormat.SHORT)}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'100px','min-width':'90px'},
      cellStyle: { 'text-align':'center','max-width':'100px','min-width':'90px'},
    },
    {
      columnDef: 'fechaFin',
      header: 'Fecha de Cierre',
      cell: (element: any) => element.fechaFin?`${this.datePipe.transform(element.fechaFin,UtcToLocalTimeFormat.SHORT)}`:``,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'100px','min-width':'90px'},
      cellStyle: { 'text-align':'center','max-width':'100px','min-width':'90px'},
    },
    {
      columnDef: 'fechaActualizacion',
      header: 'Fecha de Actualizacion',
      cell: (element: any) => `${this.datePipe.transform(element.fechaActualizacion,UtcToLocalTimeFormat.SHORT)}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'150px','min-width':'130px'},
      cellStyle: { 'text-align':'center','max-width':'150px','min-width':'130px'},
    },
  ];

}
