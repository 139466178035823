<div class="flex flex-col text-center">
    <h1 class="text-xl font-bold mb-4">Tipo de cambio</h1>
    <div class="flex flex-col xl:flex-row items-center justify-evenly">
        <div class="zelle_value font-bold min-w-[350px] w-full">
            <h1>{{invert?'Venta USD a usuarios':'Comprar'}}</h1> <br>
            <p>
                <span>{{ zelle_buy_rate | number:'1.0-2'}} CLP = 1 USD</span>
            </p>
            <img *ngIf="!hasLoaded" src="assets/loader.svg" height="60" width="60" class="mt-3 mx-auto" alt="">
        </div>
        <div class="zelle_value font-bold min-w-[350px] w-full mt-4 ml-0 xl:mt-0 xl:ml-4" *ngIf="showBuy">
            <h1>{{invert?'Compra USD a usuarios':'Vender'}}</h1> <br>
            <p>
                <span>{{ zelle_sell_rate | number:'1.0-2'}} CLP = 1 USD</span>
            </p>
            <img *ngIf="!hasLoaded" src="assets/loader.svg" height="60" width="60" class="mt-3 mx-auto" alt="">
        </div>
    </div>
</div>
