<div class="mt-4 w-full flex flex-col justify-start items-start">
<h1 class="text-xl font-bold">Selecciona tu beneficiario</h1>
<br>
<mat-form-field appearance="fill" class="w-8/12">
  <mat-label>Buscar</mat-label>
  <mat-select [(value)]="selected" (valueChange)="onSeleccion(selected ?? $event)">
    <mat-option>Ninguno</mat-option>
    <mat-option *ngFor="let cuenta of listaCuentas" [value]="cuenta">{{cuenta.nombre}} {{cuenta.apellidos}} - {{cuenta.numeroCuenta}} {{cuenta.banco?.nombre}}</mat-option>
  </mat-select>
</mat-form-field>
</div>