 import { Component, Inject } from '@angular/core';
 import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
 import { MatTableDataSource } from '@angular/material/table';
 import { Subscription } from 'rxjs';
 import { CuentaTrabajo } from 'src/app/dashboard/interfaces/cuenta-trabajo.interface';
 import { Transaccion } from 'src/app/dashboard/interfaces/transaccion.interface';
 import { WorkingAccountService } from 'src/app/dashboard/services/working-account.service';
 import { columns, displayedColumns } from '../table-transactions-columns';
 import { SpinnerService } from 'src/app/shared/services/spinner.service';
 import { TransaccionService } from 'src/app/dashboard/services/transaccion.service';
 import { CognitoService } from 'src/app/auth/services/cognito.service';
 import { AssignTransactionsDTO } from 'src/app/dashboard/interfaces/assign-transactions.dto';
 import Swal from 'sweetalert2';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EditTransactionDTO } from 'src/app/dashboard/interfaces/edit-transaction.dto';

@Component({
  selector: 'app-edit-transaction-modal',
  templateUrl: './edit-transaction-modal.component.html',
  styleUrls: ['./edit-transaction-modal.component.sass']
})
export class EditTransactionModalComponent {
  datosForm: FormGroup | undefined;
  tiposID = ['CI', 'PASAPORTE', 'RUT'];
  transaccion: Transaccion;
  constructor(
    private auth: CognitoService,
    private readonly transactionService: TransaccionService,
    private fb: FormBuilder,
    private spinner: SpinnerService, public dialogRef: MatDialogRef<EditTransactionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any){
      this.transaccion = data;
      this.initForm();
  }

  initForm(){
    this.datosForm = this.fb.group({
      nombre: new FormControl(this.transaccion.cuentaBeneficiario.nombre, [Validators.required, Validators.maxLength(60)]),
      apellido: new FormControl(this.transaccion.cuentaBeneficiario.apellidos, [Validators.required,  Validators.maxLength(60)]),
      tipoID: new FormControl(this.transaccion.cuentaBeneficiario.tipoID, [Validators.required]),
      numeroID: new FormControl(this.transaccion.cuentaBeneficiario.numeroID, [Validators.required,  Validators.maxLength(20)]),
      numeroCuenta: new FormControl(this.transaccion.cuentaBeneficiario.numeroCuenta, [Validators.required, Validators.minLength(20), Validators.maxLength(20)]),
    });
  }

  async updateTransaction(){
    if(this.datosForm?.invalid || !this.transaccion.id)return;
    const editTransactionDTO: EditTransactionDTO = {
      ...this.datosForm?.value, transactionID: this.transaccion.id
    }
    const spinnerRef = this.spinner.start();
    try{
      const result =  await this.transactionService.editTransaction(editTransactionDTO);
      Swal.fire({
        icon: 'success',
        title: 'Transacción editada',
        text: 'la transacción se ha modificado exitosamente',
        showCancelButton: false,
        showDenyButton: false,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#1e5a3f',

      });
      this.dialogRef.close(true);
    }catch(error: any){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.error.mensaje,
        showCancelButton: false,
        showDenyButton: false,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#1e5a3f',

      });
      this.dialogRef.close(true);
    }

    this.spinner.stop(spinnerRef);
  }

  close(){
    this.dialogRef.close();
  }

}
