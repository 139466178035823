import { Component } from '@angular/core';
import { BinanceService } from '../../services/binance.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {NgIf, DatePipe} from '@angular/common';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { SetZelleRateComponent } from './set-zelle-rate/set-zelle-rate.component';
import { ParameterService } from '../../services/parameter.service';
import { ActionsService } from 'src/app/shared/services/actions.service';
import { ParametrosEnum } from 'src/app/shared/enums/parameters.enum';

@Component({
  selector: 'app-zelle-rate',
  templateUrl: './zelle-rate.component.html',
  styleUrls: ['./zelle-rate.component.sass']
})
export class ZelleRateComponent {
  tasa: any;
  public currentPage = 1;
  public pageSize = 25;
  public totalItems = 0;
  public totalPages = this.totalItems/this.pageSize;
  public zelleSellValue: number = 0;
  public zelleBuyValue: number = 0;
  public usdt_clp_tax: number = 0;
  public porCompZelle: number = 0;
  public descVentaZelle: number = 0;
  public spotPrice: number = 0;

  displayedColumns: string[] = [

    'fecha',
    'hora',
    'tasa',
    'tasa conv',
  ];

  columns = [
    {
      columnDef: 'fecha',
      header: 'Fecha',
      cell: (element: any) => `${this.datePipe.transform(element.createdAt, 'dd/MM/yyyy')}`,
    },
    {
      columnDef: 'hora',
      header: 'Hora',
      cell: (element: any) => ` ${this.datePipe.transform(element.createdAt, 'hh:mm a', 'America/Santiago')} `,
    },
    {
      columnDef: 'tasa',
      header: 'Tasa de Negociación USDT',
      cell: (element: any) => `${element.tasaUsdtClp ? parseFloat(element.tasaUsdtClp).toFixed(2) : ''} `,
    },
    {
      columnDef: 'tasa conv',
      header: 'Tasa de Negociación con Zelle',
      cell: (element: any) => `${element.tasaUsdtClp ? parseFloat((parseFloat(element.tasaUsdtClp)*this.porCompZelle).toString()).toFixed(2)  : ''} `,
    },
  ];
  dataSource: MatTableDataSource<any> | undefined;

  constructor(
    private datePipe: DatePipe,
    private tasasService: BinanceService,
    private dialog: MatDialog,
    private spinner: SpinnerService,
    private parameterService: ParameterService,
  ){
      this.initTasaValue();
  }

  async initTasaValue(): Promise<void>{
    const ref = this.spinner.start();
    this.tasa = await this.tasasService.getConversionData();
    const record = await this.tasasService.getConversionValueRecord(this.currentPage,this.pageSize);
    await this.getCurrentValues();
    this.dataSource = new MatTableDataSource(record.query);
    this.totalItems = +record.count;
    this.totalPages = Math.ceil (this.totalItems/this.pageSize);
    this.spinner.stop(ref);
  }

  async reload ():Promise<void>{
    this.currentPage = 1;
    this.initTasaValue();
  }

  onPageChange(event: any){
    console.log(event);
    this.pageSize=event.pageSize;
    this.currentPage = event.pageIndex+1;
    this.initTasaValue();
  }

  async setRates(){
    const dialogRef = this.dialog.open(SetZelleRateComponent, {  
      data: {
        zelleSellValue : this.zelleSellValue,
        zelleBuyValue : this.zelleBuyValue,
        porCompZelle : this.porCompZelle,
        descVentaZelle : this.descVentaZelle,
        spotPrice : this.spotPrice,
        usdtClpTax : this.usdt_clp_tax
      },
      disableClose: false,
      // maxWidth: '600px',
      minWidth: '300px',
      maxHeight: '600px'
    });

    dialogRef.afterClosed().subscribe(async result => {
      if(result && result == true ){
        this.initTasaValue();
      }
    })
  }

  async getCurrentValues(){
    const currentValues = await this.tasasService.getConversionData();
    this.usdt_clp_tax = currentValues.tasaUsdtClp;
    await this.parameterService.getParameter(ParametrosEnum.DESCUENTO_COM_ZELLE).then((value) => {
      this.descVentaZelle = value.valor;
    }
    );
    await this.parameterService.getParameter(ParametrosEnum.PRECIO_SPOT).then((value) => {
      this.spotPrice = value.valor;
    }
    );
    await this.parameterService.getParameter(ParametrosEnum.PORC_VENTA_ZELLE).then((value) => {
      this.porCompZelle = value.valor;
    }
    );

    this.zelleSellValue=this.usdt_clp_tax*this.porCompZelle;
    this.zelleBuyValue=this.spotPrice-this.descVentaZelle;
  }
}
