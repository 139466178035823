import { Component, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RutFormat, formatRut } from '@fdograph/rut-utilities';
import { BalanceAdjustmentService } from '../../services/balance-adjustment.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-manual-deposit',
  templateUrl: './manual-deposit.component.html',
  styleUrls: ['./manual-deposit.component.sass'],
})
export class ManualDepositComponent {
  form : FormGroup;
  tipo: string;
  userId: number;
  notZero=true;

  constructor(
    public dialogRef: MatDialogRef<ManualDepositComponent>,
    private fb: FormBuilder,
    public balanceService: BalanceAdjustmentService,
    private spinner: SpinnerService,

    @Inject(MAT_DIALOG_DATA) public data: any
  ){
    console.log(data);
    this.form = this.fb.group({
      value: ['', [ Validators.required, this.zeroValidator()]]
    });
    this.tipo = this.data.tipo;
    this.userId = this.data.userInfo.id;
    this.validateInput();
  }


  checkForErrorsIn(formControl: AbstractControl): string {
    if (formControl.hasError('required')) {
      return 'Campo requerido'
    }

    if (formControl.hasError('min') || formControl.hasError('max')) {
      return 'Value must be between 1980 and 2020';
    }

    if(formControl.hasError('maxValue')){
      return 'El valor no puede ser mayor al saldo actual';
    }
    return ''
  }

  validateInput(){
    if(this.tipo==='Quitar'){
      this.form.get('value')?.setValue(0);
    }
    if(this.tipo==='Agregar'){
      this.notZero= false;
    }
    if(this.form.get('value')?.value){
      this.notZero= true;
    }
  }

  onNoClick(){
    this.dialogRef.close(false)
  }

  formatDocument(){
    if(this.data.userInfo?.tipoId === 'RUT' ){
      return formatRut(this.data.userInfo.numeroId, RutFormat.DOTS_DASH);
    }
    return this.data.userInfo?.numeroId;
  }

  async showSuccess(){
    this.dialogRef.close(true)
    await Swal.fire('Movimiento creado','Movimiento creado exitosamente', 'success');

  }

  async showError(){
    this.dialogRef.close(false)
    await Swal.fire('Error','No se pudo crear el movimiento', 'error');
  }

  async adjustmentExecution(){
    if(this.form.invalid)return;

    if(this.data.tipo==="Quitar" && this.data.saldo===0){
    return;
    }
    const ref = this.spinner.start();
    try {
      if(this.data.tipo==="Agregar"){
        const body = {id: this.data.userInfo.usuarioId, valor: this.form.get('value')?.value}
        if(this.form.valid){
         await this.balanceService.addBalance(body);

        }
      }
      if(this.data.tipo==="Debitar"){
        const body = {id: this.data.userInfo.usuarioId, valor: this.form.get('value')?.value}
        if(this.form.valid){
          await this.balanceService.debitBalance(body);


        }
      }
      if(this.data.tipo==="Quitar"){
        const body = {id: this.data.userInfo.usuarioId, valor: 0}
        if(this.form.valid){
          await this.balanceService.debitAllBalance(body);

        }
    }
    this.spinner.stop(ref);
    this.showSuccess();

    } catch (error) {

        this.showError();
        this.spinner.stop(ref);
    }


    this.spinner.stop(ref);
  }

  maxValueValidator(maxValue: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = parseFloat(control.value);
      if(this.tipo==="Agregar"){
        return {};
      }
      if (!isNaN(value) && value > maxValue) {
        return { 'maxValue': { value: control.value } };
      }
      return null;
    };
  }

  zeroValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = parseFloat(control.value);
      if (this.tipo === "Agregar" && value === 0) {
        return { 'zeroValue': { value: control.value } };
      }
      return null;
    };
  }

}
