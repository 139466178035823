import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BinanceService {
  API_URL = environment.apiGatewayURL;

  constructor(private http: HttpClient) {}

  getBinanceRates(): Promise<any> {
    const serviceURL = `${this.API_URL}tasasBinance`;
    return firstValueFrom(this.http.get<any>(serviceURL));
  }

  getConversionData(): Promise<any> {
    const serviceURL = `${this.API_URL}conversion-value`;
    return firstValueFrom(this.http.get<any>(serviceURL));
  }

  getConversionValueRecord(currentPage: number, pageSize: number): Promise<any> {
    const serviceURL = `${this.API_URL}conversion-value-record?page=${currentPage}&pageSize=${pageSize}`;
    return firstValueFrom(this.http.get<any>(serviceURL));
  }

  getCurrentRates():any{
    const serviceURL = `${this.API_URL}conversionRate`;
    return firstValueFrom(this.http.get<any>(serviceURL));
  }

  updateRates(tasas: any, email: string, fuente: string): Promise<any>{
    const serviceURL = `${this.API_URL}conversionRate`;
    const body = {
      tasas,
      email, fuente
    }
    return firstValueFrom(this.http.put<any>(serviceURL, body));
  }

  updateRateCLPUSDT(tasaCLPUSDT: any, email: string): Promise<any>{
    const serviceURL = `${this.API_URL}update-usdt-value`;
    const body = {
      tasaCLPUSDT,
      email
    }
    return firstValueFrom(this.http.post<any>(serviceURL, body));
  }
}
