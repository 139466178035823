<div class="p-6 space-y-4 md:space-y-6 sm:p-8">
  <div class="container flex flex-col items-center">
    <h1 class="text-xl font-bold">
      Actualizar Tasa negociación USDT
    </h1>
  </div>
  <form
    *ngIf="formCuenta"
    class="space-y-4 md:space-y-6"
    [formGroup]="formCuenta"
  >
  <div class="grid grid-cols-1 gap-4">
    <mat-form-field>
      <mat-label>Tasa USDT</mat-label>
      <input
        type="text"
        appOnlynumber
        matInput
        formControlName="tasaCLPUSDT"
      />
      <mat-error *ngIf="formCuenta.get('tasaCLPUSDT')?.errors?.['greaterThanZero'] === true">Debe ser mayor que 0</mat-error>
      <mat-error *ngIf="formCuenta.get('tasaCLPUSDT')?.hasError('maxlength')">Debe tener menos de 9 caracteres</mat-error>
    </mat-form-field>

  </div>
    <div class="container flex flex-col items-center">
      <button
        (click)="actualizarTasas()"
        type="button"
        class="w-1/2 text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
      >
        Actualizar
      </button>
    </div>
  </form>
</div>
