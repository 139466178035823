<div
  class="px-16 py-10 rounded-3xl uploader-status flex flex-col items-center justify-center align-middle"
>
  <div class="py-6 text-xl font-semibold text-center">Rechazar usuario</div>
  <p>Selecciona el motivo de rechazo</p>

  <form
    class="grid grid-cols-1 gap-4 mt-5"
    [formGroup]="formCompliance"
    *ngIf="formCompliance"
  >
    <div class="grid grid-cols-1">
      <mat-form-field appearance="fill">
        <mat-label>Comentario compliance</mat-label>
        <mat-select
          formControlName="comentarioCompliance"
          name="comentarioCompliance"
        >
          <mat-option>SIN SELECCIONAR</mat-option>
          <mat-option
            *ngFor="let comentario of comentariosCompliance"
            [value]="comentario.comentarioID"
          >
            {{ comentario.descripcion | uppercase }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
  <div class="flex flex-row gap-4">

    <button class="mt-4" mat-raised-button  (click)="onNoClick()">
      <mat-icon> close</mat-icon>
      Cancelar
    </button>
    <button [disabled]="!formCompliance.valid"
      class="mt-4"
      mat-raised-button
      color="primary"
      (click)="rechazarUsuario()"
    >
      <mat-icon> check</mat-icon>
      Rechazar
    </button>
  </div>

</div>
