import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { UserService } from '../../services/user.service';
import { Usuario } from '../../interfaces/transaccion.interface';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Sort } from '@angular/material/sort';
import { BalanceSearchFilter } from 'src/app/shared/enums/balance-search-filter.enum';
import { MatDialog } from '@angular/material/dialog';
import { CreateFormDialogComponent } from '../create-transaction-dialog/create-transaction-dialog.component';
import { ManualTransactionFormService } from '../../services/manual-transaction-form.service';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import { ManualTransaction } from '../../interfaces/manual-transaction';
import { ManualTransactionService } from '../../services/manual-transaction.service';
import { CurrencyPipe } from '@angular/common';
import { UtcToLocalTimeFormat } from 'src/app/shared/pipes/utc-converter.service';
import { UtcToLocalTimePipe } from 'src/app/shared/pipes/utc-to-local-time.pipe';
import { ManualTransactionHistoryDialogComponent } from '../manual-transaction-history-dialog/manual-transaction-history-dialog.component';
import { RecipientCriptoService } from '../../services/recipient-cripto.service';
import { EstadosTransferencia } from 'src/app/shared/enums/estados-transferencia';

@Component({
  selector: 'app-manual-transactions',
  templateUrl: './manual-transactions.component.html',
  styleUrls: ['./manual-transactions.component.sass']
})
export class ManualTransactionsComponent implements OnInit {

  initialUsuarioId: number | undefined;
  operatorId: number | undefined;
  createForm: FormGroup;
  selectedFilter: string = BalanceSearchFilter.ALL;
  selectedDateFilter : 'Creacion' | 'Actualizacion' | 'Cierre' = 'Creacion';
  viewType: 'crear Transaccion' | 'historial Transacciones' | 'transacciones Pendientes' = 'crear Transaccion';
  emailOperador = '';
  filterDescriptions: { [key: string]: string } = {
    [BalanceSearchFilter.ALL]: 'Todos',
    [BalanceSearchFilter.DOCUMENT]: 'Documento',
    [BalanceSearchFilter.EMAIL]: 'Email',
    [BalanceSearchFilter.ID]: 'ID',
  };
  historialFilter: 'ABIERTAS' | 'CERRADAS' | 'TODAS' = 'TODAS';
  filters: string[] = [<string>BalanceSearchFilter.ALL, <string>BalanceSearchFilter.DOCUMENT, <string>BalanceSearchFilter.EMAIL, <string>BalanceSearchFilter.ID];
  searchValue: string = '';
  range : FormGroup;
  selectedRow: ManualTransaction | undefined;
  filtersDate: { [key: string]: string } = {
    'Creacion': 'Fecha de Creacion',
    'Actualizacion': 'Fecha de Actualizacion',
    'Cierre': 'Fecha de Cierre',
  };
  filtersDates: string[] = ['Creacion', 'Actualizacion', 'Cierre'];
  users: Usuario[] = [];
  transactions: ManualTransaction[] = [];
  dataSource: MatTableDataSource<any> | undefined;
  dataSourceHistorial: MatTableDataSource<any> | undefined;
  dataSourcePendientes: MatTableDataSource<any> | undefined;
  showForm: boolean = false;

  public currentPage = 1;
  public pageSize = 25;
  public totalItems = 0;
  public totalPages = this.totalItems/this.pageSize;

  constructor(
    private currencyPipe:CurrencyPipe,
    private auth: CognitoService,
    private dialog: MatDialog,
    private spinner: SpinnerService,
    private userService: UserService,
    private fb: FormBuilder,
    private manualTransactionFormService: ManualTransactionFormService,
    private manualTransactionService: ManualTransactionService,
    private datePipe: UtcToLocalTimePipe,
    private recipientCryptoService: RecipientCriptoService,
    ){
    this.createForm = this.manualTransactionFormService.getCreateForm();
    this.range = this.fb.group({
      start: [new Date(), [Validators.required]],
      end: [new Date(), [Validators.required]],
      fechaTypeSelected: ['', [Validators.required]],
    });
    this.initFormCuenta();
  }
  ngOnInit(): void {
    this.search();
  }
  async initFormCuenta() {
    const cognitoUser = await this.auth.getUser();
    this.operatorId = cognitoUser.attributes['email'];
  }

  onPageChange(event: any) {
    console.log(event);
    this.pageSize=event.pageSize;
    this.currentPage = event.pageIndex+1;
    if(this.viewType==="historial Transacciones"){
      this.searchTransactionsHistory();
    }
    if(this.viewType==="transacciones Pendientes"){
      this.searchTransactionsPending();
    }
  }

  getFilterDescription(value: string){
    return this.filterDescriptions[value];
  }
  @ViewChild(MatSort) sort: MatSort = new MatSort();

  async searchTransactionsHistory(){
    const ref = this.spinner.start();
    const data = await this.manualTransactionService.getAllTransactions(this.currentPage,this.pageSize, this.historialFilter, this.selectedDateFilter, this.range.get('start')?.value, this.range.get('end')?.value);
    this.transactions = data.data;
    this.dataSourceHistorial = new MatTableDataSource(this.transactions);
    this.totalItems = +data.count;
    this.totalPages = Math.ceil (this.totalItems/this.pageSize);
    this.spinner.stop(ref);
  }
  async searchTransactionsPending(){
    const ref = this.spinner.start();
    const data = await this.manualTransactionService.getAllTransactions(this.currentPage,this.pageSize, this.historialFilter, this.selectedDateFilter, this.range.get('start')?.value, this.range.get('end')?.value);
    this.transactions = data.data;
    this.dataSourceHistorial = new MatTableDataSource(this.transactions);
    this.totalItems = +data.count;
    this.totalPages = Math.ceil (this.totalItems/this.pageSize);
    this.spinner.stop(ref);
  }
  async search(){
    const ref = this.spinner.start();
    let searchQuery = this.searchValue;
    if(this.selectedFilter === BalanceSearchFilter.DOCUMENT){
      searchQuery = searchQuery.replaceAll('.', '').replaceAll('-', '');
    }
    this.users = await this.userService.searchUsers(this.searchValue || BalanceSearchFilter.ALL, this.selectedFilter.toUpperCase() );
    this.dataSource = new MatTableDataSource(this.users);
    this.spinner.stop(ref);
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  sortData(sort: Sort) {
    const data = this.users.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = new MatTableDataSource( data);
    }

    this.users = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'idUsuario':
          return this.compare(a.usuarioId, b.usuarioId, isAsc);
        case 'nombre':
          return this.compare(a.nombre, b.nombre, isAsc);
        case 'documento':
          return this.compare(a.numeroId, b.numeroId, isAsc);
        case 'email':
          return this.compare(a.email, b.email, isAsc);
        default:
          return 0;
      }
    });
    this.dataSource = new MatTableDataSource( this.users);
  }

  sortDataHistory(sort: Sort) {
    const data = this.transactions.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSourceHistorial = new MatTableDataSource( data);
    }

    this.transactions = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'usuarioId':
          return this.compare(Number.parseInt(a.usuarioId), Number.parseInt(b.usuarioId), isAsc);
        case 'totalEnvio':
          return this.compare(a.totalEnvio, b.totalEnvio, isAsc);
        case 'totalDest':
          return this.compare(a.totalDest, b.totalDest, isAsc);
        case 'valorPagado':
          return this.compare(a.valorPagado, b.valorPagado, isAsc);
        case 'tasa':
          return this.compare(a.tasa, b.tasa, isAsc);
        case 'fechaCreacion':
          return this.compare(a.fechaCreacion.toString(), b.fechaCreacion.toString(), isAsc);
        case 'fechaFin':
          return this.compare(a.fechaFin.toString(), b.fechaFin.toString(), isAsc);
        case 'fechaActualizacion':
          return this.compare(a.fechaActualizacion.toString(), b.fechaActualizacion.toString(), isAsc);
        case 'estado':
          return this.compare(a.estado, b.estado, isAsc);
        default:
          return 0;
      }
    });
    this.dataSourceHistorial = new MatTableDataSource( this.transactions);
  }

  sortDataPending(sort: Sort) {
    const data = this.transactions.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSourcePendientes = new MatTableDataSource(data);
    }

    this.transactions = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'usuarioId':
          return this.compare(Number.parseInt(a.usuarioId), Number.parseInt(b.usuarioId), isAsc);
        case 'totalEnvio':
          return this.compare(a.totalEnvio, b.totalEnvio, isAsc);
        case 'totalDest':
          return this.compare(a.totalDest, b.totalDest, isAsc);
        case 'valorPagado':
          return this.compare(a.valorPagado, b.valorPagado, isAsc);
        case 'tasa':
          return this.compare(a.tasa, b.tasa, isAsc);
        case 'fechaCreacion':
          return this.compare(a.fechaCreacion.toString(), b.fechaCreacion.toString(), isAsc);
        case 'fechaFin':
          return this.compare(a.fechaFin.toString(), b.fechaFin.toString(), isAsc);
        case 'fechaActualizacion':
          return this.compare(a.fechaActualizacion.toString(), b.fechaActualizacion.toString(), isAsc);
        case 'estado':
          return this.compare(a.estado, b.estado, isAsc);
        default:
          return 0;
      }
    });
    this.dataSourcePendientes = new MatTableDataSource( this.transactions);
  }

  onSubmit() {
    if (this.createForm?.valid) {
      const formData = this.createForm.value;
    }
    this.showForm = false;
  }

  openCreateFormDialog(usuarioId: number, nombre: string) {
    const dialogRef = this.dialog.open(CreateFormDialogComponent, {
      width: '650px',
      data: { usuarioId , operator:this.operatorId, nombre},
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('Transaccion cerrada');
    });
  }

  openHistoryFormDialog(id: number, walletTrxId: string) {
    const dialogRef = this.dialog.open(ManualTransactionHistoryDialogComponent, {
      width: '80%',
      data: { id, walletTrxId },
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('Historial cerrado');
    });
  }

  displayedColumns: string[] = [
    'idUsuario',
    'nombre',
    'documento',
    'email',
    'acciones'
  ];

  columns = [
    {
      columnDef: 'idUsuario',
      header: 'Id',
      cell: (element: any) => `${element.usuarioId}`,
      headerStyle: {'font-weight': 'bold','background-color': '#CEE9C2','max-width':'80px','min-width':'80px'},
      cellStyle: {'text-align':'center','max-width':'80px','min-width':'80px'},
      isSticky: true,
    },
    {
      columnDef: 'nombre',
      header: 'Nombre',
      cell: (element: any) => `${element.nombre} ${element.apellidos}`,
      headerStyle: {'font-weight': 'bold','background-color': '#CEE9C2','max-width':'220px','min-width':'220px'},
      cellStyle: { 'text-align':'left','max-width':'220px','min-width':'220px'},
    },
    {
      columnDef: 'documento',
      header: 'Documento',
      cell: (element: any) => `${element.numeroId}`,
      headerStyle: {'font-weight': 'bold','background-color': '#CEE9C2','max-width':'220px','min-width':'220px'},
      cellStyle: { 'text-align':'left','max-width':'220px','min-width':'220px'},
    },
    {
      columnDef: 'email',
      header: 'Email',
      cell: (element: any) => `${element.email}`,
      headerStyle: {'font-weight': 'bold','background-color': '#CEE9C2','max-width':'350px','min-width':'300px'},
      cellStyle: { 'text-align':'left','max-width':'350px','min-width':'300px'},
    },
  ];
  displayedColumnsHistorial: string[] = [
    'estado',
    'nombre',
    'usuarioId',
    'operatorId',
    'totalEnvio',
    'totalDest',
    'monedaLocal',
    'monedaDestino',
    'valorPagado',
    'tasa',
    'walletTrx',
    'fechaCreacion',
    'fechaFin',
    'fechaActualizacion',
    'id',
    'acciones'
  ];

  columnsHistorial = [
    {
      columnDef: 'id',
      header: 'Id',
      cell: (element: any) => `${element.id}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'220px','min-width':'220px'},
      cellStyle: { 'text-align':'center','max-width':'220px','min-width':'220px'},
      isSticky: true,
    },
    {
      columnDef: 'nombre',
      header: 'Nombre',
      cell: (element: any) => `${this.users.find(user => user.usuarioId === element.usuarioId)?.nombre} ${this.users.find(user => user.usuarioId === element.usuarioId)?.apellidos}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'220px','min-width':'220px'},
      cellStyle: { 'text-align':'left','max-width':'220px','min-width':'220px'},
      isSticky: true,
    },
    {
      columnDef: 'usuarioId',
      header: 'Usuario Id',
      cell: (element: any) => `${element.usuarioId}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'100px','min-width':'90px'},
      cellStyle: { 'text-align':'center','max-width':'100px','min-width':'90px'},
    },
    {
      columnDef: 'operatorId',
      header: 'Operador Id',
      cell: (element: any) => `${element.operatorId}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'220px','min-width':'210px'},
      cellStyle: { 'text-align':'left','max-width':'220px','min-width':'210px'},
    },
    {
      columnDef: 'totalEnvio',
      header: 'Monto Promesa',
      cell: (element: any) => `${this.currencyPipe.transform(element.totalEnvio, 'USD', 'symbol-narrow', '1.2-2', 'en-US')}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'220px','min-width':'180px'},
      cellStyle: { 'text-align':'left', 'max-width':'220px','min-width':'180px'},
    },
    {
      columnDef: 'totalDest',
      header: 'Total Destino',
      cell: (element: any) => `${this.currencyPipe.transform(element.totalDest, 'USD', 'symbol-narrow', '1.2-2', 'en-US')}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'110px','min-width':'100px'},
      cellStyle: { 'text-align':'center','max-width':'110px','min-width':'100px'},
    },
    {
      columnDef: 'monedaLocal',
      header: 'Moneda Local',
      cell: (element: any) => `${element.monedaLocal}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'100px'},
      cellStyle: { 'text-align':'center','max-width':'100px'},
    },
    {
      columnDef: 'monedaDestino',
      header: 'Moneda Destino',
      cell: (element: any) => `${element.monedaDestino}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'100px'},
      cellStyle: { 'text-align':'center','max-width':'100px'},
    },
    {
      columnDef: 'valorPagado',
      header: 'Valor Pagado',
      cell: (element: any) => `${this.currencyPipe.transform(element.valorPagado, 'USD', 'symbol-narrow', '1.2-2', 'en-US')}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'160px','min-width':'140px'},
      cellStyle: { 'text-align':'left','max-width':'160px','min-width':'140px'},
    },
    {
      columnDef: 'tasa',
      header: 'Tasa',
      cell: (element: any) => `${element.tasa}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'80px','min-width':'60px'},
      cellStyle: { 'text-align':'center','max-width':'80px','min-width':'60px'},
    },
    {
      columnDef: 'walletTrx',
      header: 'Wallet',
      cell: (element: any) => `${element.walletTrx}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'300px','min-width':'260px'},
      cellStyle: { 'text-align':'left','max-width':'300px','min-width':'260px'},
    },
    {
      columnDef: 'estado',
      header: 'Estado',
      cell: (element: any) => {
        switch (element.estado) {
          case 'ABIERTA':
            return 'Abierta';
          case 'CERRADA':
            return 'Cerrada';
          default:
            return '';
        }
      },
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','min-width':'100px','max-width':'110px'},
      cellStyle: { 'text-align':'center','min-width':'100px','max-width':'110px'},
      iconColor: (element: any) => {
        switch (element.estado) {
          case 'ABIERTA':
            return 'green';
          case 'CERRADA':
            return 'red';
          default:
            return '';
        }
      },
      val: (element: any) => `${element.estado}`,
    },
    {
      columnDef: 'fechaCreacion',
      header: 'Fecha de Creación',
      cell: (element: any) => `${this.datePipe.transform(element.fechaCreacion,UtcToLocalTimeFormat.SHORT)}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'100px','min-width':'90px'},
      cellStyle: { 'text-align':'center','max-width':'100px','min-width':'90px'},
    },
    {
      columnDef: 'fechaFin',
      header: 'Fecha de Cierre',
      cell: (element: any) => element.fechaFin?`${this.datePipe.transform(element.fechaFin,UtcToLocalTimeFormat.SHORT)}`:``,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'100px','min-width':'90px'},
      cellStyle: { 'text-align':'center','max-width':'100px','min-width':'90px'},
    },
    {
      columnDef: 'fechaActualizacion',
      header: 'Fecha de Actualizacion',
      cell: (element: any) => `${this.datePipe.transform(element.fechaActualizacion,UtcToLocalTimeFormat.SHORT)}`,
      headerStyle: { 'text-align':'center', 'font-weight': 'bold','max-width':'150px','min-width':'130px'},
      cellStyle: { 'text-align':'center','max-width':'150px','min-width':'130px'},
    },
  ];

  onCrearTransaccionClick() {
    this.viewType = 'crear Transaccion';
  }

  isColumnSticky(columnDef: string): boolean {
    return columnDef === 'usuarioId' || columnDef === 'email';
  }

  onHistorialTransaccionesClick() {
    this.currentPage = 1;
    this.searchTransactionsHistory();
    const ref = this.spinner.start();
    this.dataSourceHistorial = new MatTableDataSource(this.transactions);
    this.viewType = 'historial Transacciones';
    this.spinner.stop(ref);
  }

  onTransaccionesPendientesClick() {
    this.currentPage = 1;
    const ref = this.spinner.start();
    this.searchTransactionsPending();
    this.filterAndSetDataSourcePending('ABIERTA');
    this.dataSourcePendientes = new MatTableDataSource(this.transactions);
    this.viewType = 'transacciones Pendientes';
    this.spinner.stop(ref);
  }

  onOrdenesAbiertasClick(){
    this.historialFilter = 'ABIERTAS';
    this.filterAndSetDataSource('ABIERTA');
  }

  onOrdenesCerradasClick(){
    this.historialFilter = 'CERRADAS';
    this.filterAndSetDataSource('CERRADA');
  }

  onOrdenesTodasClick(){
    this.historialFilter = 'TODAS';
    this.dataSourceHistorial = new MatTableDataSource(this.transactions);
  }

  onDateFilterChange(){
    this.updateDateTypeFilter();
    this.filterAndSetDataSourceByDate(
      this.selectedDateFilter,
      this.range.value.start? this.range.value.start : new Date(),
      this.range.value.end?this.range.value.end:new Date()
      );
  }

  updateDateTypeFilter(){
    switch (this.range.get('fechaTypeSelected')?.value) {
      case 'Creacion':
        return this.selectedDateFilter = 'Creacion';
      case 'Cierre':
        return this.selectedDateFilter = 'Cierre';
      case 'Actualizacion':
        return this.selectedDateFilter = 'Actualizacion';
      default:
        return this.selectedDateFilter = 'Creacion';
    }
  }

  filterTransactionsByStatus(transactions: ManualTransaction[], status: string): ManualTransaction[] {
    return transactions.filter(transaction => transaction.estado === status);
  }

  filterTransactionsByDate(transactions: ManualTransaction[], date: string, start: Date, end: Date): ManualTransaction[] {
    switch (date) {
      case 'Creacion':
        return transactions.filter(
          transaction => this.stripTime(new Date(transaction?.fechaCreacion)) >= start
          && this.stripTime(new Date(transaction?.fechaCreacion)) <= end
        );
      case 'Cierre':
        return transactions.filter(
          transaction => this.stripTime(new Date(transaction?.fechaFin)) >= start
          && this.stripTime(new Date(transaction?.fechaFin)) <= end
        );
      case 'Actualizacion':
        return transactions.filter(
          transaction => this.stripTime(new Date(transaction?.fechaActualizacion)) >= start
          && this.stripTime(new Date(transaction?.fechaActualizacion)) <= end
        );
      default:
        return this.transactions;
    }
  }

  stripTime(date: Date): Date {
    if (!date) {
      return date;
  }
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  filterAndSetDataSource(status: string) {
    const filteredTransactions = this.filterTransactionsByStatus(this.transactions, status);
    this.dataSourceHistorial = new MatTableDataSource(filteredTransactions);
    this.dataSourceHistorial.sort = this.sort;
  }

  filterAndSetDataSourcePending(status: string) {
    const filteredTransactions = this.filterTransactionsByStatus(this.transactions, status);
    this.dataSourcePendientes = new MatTableDataSource(filteredTransactions);
    this.dataSourcePendientes.sort = this.sort;
  }

  filterAndSetDataSourceByDate(date: string, start: Date, end: Date) {
    const filteredTransactions = this.filterTransactionsByDate(this.transactions, date, new Date(start.toISOString()), new Date(end.toISOString()));
    this.dataSourceHistorial = new MatTableDataSource(filteredTransactions);
  }

  updateManualTransactionStatus(row: ManualTransaction, estado: string){
    this.selectedRow = row;
    this.selectedRow.estado = estado;
    this.manualTransactionService.createManualTransaction(this.selectedRow);
  }

  async RemoveUnnecesaryTransaction(row: ManualTransaction){
    const trxManual = row;
    const transactionsAssociated = await this.recipientCryptoService.getTransactionCriptoManualTransactions(trxManual.id)
    const ref = this.spinner.start();
    let authData = await this.auth.getUser();
    this.emailOperador = authData.attributes.email;
    if(row.estado === 'CERRADA'&& row.valorPagado === 0){
      if(this.ValidateTransactionsByStatus(transactionsAssociated)){
        trxManual.deletedBy=this.emailOperador;
        trxManual.deleteDate= new Date();
        await this.manualTransactionService.createManualTransaction(trxManual);
      }
    }else{
      console.log("Operacion Invalida");
    }
    this.spinner.stop(ref);
    this.searchTransactionsHistory();
  }

  ValidateTransactionsByStatus(transactions: any){
    for (const transaction of transactions) {
      if(transaction.estado === EstadosTransferencia.ENVIADA||transaction.estado === EstadosTransferencia.ESPERANDO_DEPOSITO){
        return false
      }
    }
    return true
  }

  ValidateTransaction(row: ManualTransaction):Boolean{
    if(row.estado === 'CERRADA'&& row.valorPagado === 0){
      return false;
    }
    return true;
  }

  getTitle(): string {
    switch (this.viewType) {
      case 'crear Transaccion':
        return 'Crear transacción manual de USDT';
      case 'historial Transacciones':
        return 'Historial de transacciones';
      case 'transacciones Pendientes':
        return 'Transacciones pendientes';
      default:
        return '';
    }
  }
}
