
<mat-stepper (selectionChange)="onStepChange($event)" #stepper class="pt-4 py-4" [orientation]="isVertical ? 'vertical': 'horizontal'" [linear]="true">
  <h2>Horario de operaciones Zelle: 9:00 - 17:00 </h2>
  <mat-step [completed]="stepPass2 && (clpCurrency !=0 && zelleCurrency != 0)">
      <ng-template matStepLabel>Calcula tu cambio</ng-template>
      <div class="w-full md:w-1/2 mx-auto mt-10">
        <app-calculator-zelle-cal
        #calculatorComponent
        (clpCurrencyEmit)="assignClp($event)"
        (zelleCurrencyEmit)="assignZelle($event)"
        (changeStep)="nextStep($event)"
        [zelle_buy_rate]="zelleSellValue"
        [zelle_sell_rate]="zelleBuyValue"
        [saldoActual]="saldoActual"
        [sobregiroDisponible]="sobregiroDisponible"
        [saldoContable]="saldoContable"
        [lineaCreditoBloqueada]="lineaCreditoBloqueada"
        [lineaCredito]="lineaCredito">
      </app-calculator-zelle-cal>
      </div>
    </mat-step>
    <mat-step [completed]="stepPass3 && beneficiaryComponent!.beneficiarioEscogido != undefined">
      <ng-template matStepLabel>Elige tu beneficiario</ng-template>
      <app-beneficiary-zelle
        #beneficiarioComponent
        (changeStep)="nextStep($event)"
        (beneficiarioOutput)="assignBeneficiario($event)"
        (goBack)="backStep()"></app-beneficiary-zelle>
    </mat-step>
    <mat-step [completed]="stepPass4">
      <ng-template matStepLabel>Resumen de tu orden</ng-template>
      <app-summary-order-zelle
        [clpCurrency]="clpCurrency"
        [zelleCurrency]="zelleCurrency"
        [usdt_clp_tax_zelle]="zelleSellValue"
        [beneficiario]="beneficiario"
        [valorCambioId]="valorCambioId"
        (goBack)="backStep()"
        ></app-summary-order-zelle>
    </mat-step>
  </mat-stepper>
