<div class="p-4 flex flex-col items-center overflow-hidden">
    <h1 class="font-bold text-center my-4">Confirme creación</h1>
    <div class="flex flex-col mb-4 mx-4">
      <span>¿Está seguro que desea crear la transacción?</span>
    </div>
    <div class="flex flex-col mx-4 max-w-[100%]">
      <div class="flex-col md:flex-row">
        <span class=""><b>Cliente: </b> </span>
        <span class="md:mx-1"> {{this.data.nombre}}</span>
      </div>
      <div class="flex-col md:flex-row">
        <span class=""><b>Id: </b> </span>
        <span class="md:mx-1"> {{this.data.userid}}</span>
      </div>
      <div class="flex-col break-words md:flex-row overflow-hidden">
        <span class=""><b>Wallet: </b> </span>
        <span class="break-words md:mx-1"> {{this.data.formData.walletTrx}}</span>
      </div>
      <div class="flex-col md:flex-row">
        <span class=""><b>Tasa: </b> </span>
        <span class="md:mx-1"> {{this.data.formData.tasa | number:'1.2-2'}}</span>
      </div>
      <div class="flex-col md:flex-row">
        <span class=""><b>Monto promesa: </b> </span>
        <span class="md:mx-1"> {{this.data.formData.totalEnvio | currency:'USD':'symbol':'1.2-2'}} {{ this.data.formData.monedaLocal}}</span>
      </div>
    </div>
    <div mat-dialog-actions class="place-content-center">
      <button mat-raised-button color="primary" class="m-4 shadow max-h-md" [mat-dialog-close]="true">Si</button>
      <button mat-raised-button color="secondary" class="m-4 shadow max-h-md" [mat-dialog-close]="false">No</button>
    </div>
</div>