import { Component,EventEmitter,Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BeneficiarioZelle } from 'src/app/dashboard/interfaces/beneficiario-zelle.interface';
import { TransaccionService } from 'src/app/dashboard/services/transaccion.service';
import { ErrorTypes } from 'src/app/shared/enums/error-types.enum';
import { TipoPago } from 'src/app/shared/enums/tipo-pago.enum';
import { ActionsService } from 'src/app/shared/services/actions.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-summary-order-zelle',
  templateUrl: './summary-order-zelle.component.html',
  styleUrls: ['./summary-order-zelle.component.sass']
})
export class SummaryOrderZelleComponent {
  constructor(
    private spinnerService: SpinnerService,
    private transaccionService: TransaccionService,
    private router: Router,
    private actionService: ActionsService
    ){}

  @Input()
  public clpCurrency: number = 0;

  @Input()
  public zelleCurrency: number = 0;

  @Input()
  public usdt_clp_tax_zelle: number = 0;

  @Input()
  public valorCambioId: number = 0;

  @Input()
  public beneficiario: BeneficiarioZelle | undefined;

  @Output()
  public goBack = new EventEmitter<any>();


  async confirmarEnvio(){
    const ref = this.spinnerService.start();

    const transaccionDTO ={
      "paisOrigen":'CL',
      "userId":this.beneficiario?.usuarioId,
      "totalMonedaOrigen": + this.clpCurrency,
      "totalMonedaDestino":this.zelleCurrency,
      "monedaOrigen":'CLP',
      "monedaDestino":'USD',
      "paisDestino":'ZELLE',
      "formaPago":TipoPago.WALLET,
      "diferencia":null,
      "tasa": this.usdt_clp_tax_zelle,
      "beneficiarioZelleId":this.beneficiario?.id,
      "tasaCambioId":null,
      "valorCambioId":this.valorCambioId!,
      "tipoTransaccion": 'ZELLE'
    }

    try{
      const resultadoTransaccion = await this.transaccionService.saveTransaccion(transaccionDTO);
      this.spinnerService.stop(ref);
      Swal.fire('Transaccion creada', `Se ha creado la transaccion con éxito`, 'success' );
      this.router.navigate(['/wallet/client/zelle-home']);

    }catch(error: any){
      console.log(error);
      if(error.status === 400 && error.error.type === ErrorTypes.TASAS_ACTUALIZADAS){
        this.spinnerService.stop(ref);
        this.actionService.emitirAccionTasaUSDT();
      }else{
        Swal.fire('Error al enviar dinero','Ha ocurrido un error al realizar la transferencia', 'warning').then(()=>{
          this.spinnerService.stop(ref);
          this.router.navigate(['/wallet/client/zelle-home']);
        });
      }

    }
  }

  goToBack(): void {
    this.goBack.emit();
  }
}
