import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import { environment } from 'src/environments/environment';
import { UpdateContactData } from '../interfaces/update-contact-data';
import { firstValueFrom } from 'rxjs';
import { Pagador } from '../interfaces/pagador';
import { UserRoles } from 'src/app/shared/enums/roles-enum';

@Injectable({
  providedIn: 'root'
})
export class PagadorService {

  API_URL = environment.apiGatewayURL;
  constructor(private http: HttpClient, private cognito: CognitoService) { }
  async createPagador(body: any){
    const userLogged = await this.cognito.getUser();
    let userBody = {...body, username: userLogged.username};
    if(userLogged.attributes['custom:user-group']!=UserRoles.aguacate_operator){
      userBody = {...body, email: userLogged.attributes.email};
    }
    // const userBody = {...body, email: userLogged.attributes.email, username: userLogged.username};
    const serviceURL = `${this.API_URL}pagador/create`
    console.log(userBody);
    return firstValueFrom(this.http.post(serviceURL, userBody))
    .catch((e) => {
     console.log(e);
    });;
  }

  //TODO separar logica de edicion de imagenes de la edicion de pagador
  async editarPagador(body: any){
    console.log(body);
    console.log('editar');
    const userLogged = await this.cognito.getUser();
    const userBody = {...body, email: userLogged.attributes.email, username: userLogged.username};
    console.log(userBody);
    const serviceURL = `${this.API_URL}pagador/edit`



    return this.http.post(serviceURL, userBody) .toPromise()
    .catch((e) => {
     console.log(e);
    });;
  }

  getUserData(email: string){
    const serviceURL = `${this.API_URL}user/${email}`;
    return this.http.get(serviceURL);
  }

  getPagadoresByUser(usuarioId: number): Promise<Pagador[]>{
    const serviceURL = `${this.API_URL}pagador/by-user/${usuarioId}`
    return firstValueFrom(this.http.get<Pagador[]>(serviceURL));
  }

  getPagador(pagadorId: string): Promise<Pagador>{
    const serviceURL = `${this.API_URL}pagador/by-id/${pagadorId}`
    return firstValueFrom(this.http.get<Pagador>(serviceURL));
  }

  updateUserData(payload: UpdateContactData){
    const serviceURL = `${this.API_URL}userContactData`;
    return this.http.put(serviceURL, payload);
  }

  inactivatePagador(pagadorId: string){
    const serviceURL = `${this.API_URL}pagador/inactivate`;
    return firstValueFrom(this.http.post(serviceURL, {pagadorId}));
  }

  updateUserCompliance(pagadorId: string, emailOperator: string, compliance: any){
    const serviceURL = `${this.API_URL}compliance-pagador`;
    const payload = {
      pagadorId, emailOperator, compliance
    }
    return this.http.put(serviceURL, payload)
  }

  updateUserChecks(pagadorId: string, emailOperator: string, compliance: any){
    const serviceURL = `${this.API_URL}checks-pagador`;
    const payload = {
      pagadorId, emailOperator, compliance
    }
    return this.http.put(serviceURL, payload)
  }

  checkPayerExist(rut:string){
    const serviceURL = `${this.API_URL}pagador/check-payer/${rut}`;
    return firstValueFrom(this.http.get(serviceURL));
  }
}
