import * as moment from "moment-timezone";
import { CurrencyPipe } from '@angular/common';

export function getColumns(currencyPipe: CurrencyPipe) {
  return [
    {
      columnDef: 'id',
      header: 'ID',
      cell: (element: any) => `${element.id}`,
    },
    {
      columnDef: 'estado',
      header: 'Estado',
      cell: (element: any) => `${element.estado}`,
    },
    {
      columnDef: 'createdAt',
      header: 'Fecha creación',
      cell: (element: any) => `${element.createdAt ? moment(new Date(element.createdAt), 'America/Santiago').format('lll'): ''}`,
    },
    {
      columnDef: 'fechaTermino',
      header: 'Fecha cierre',
      cell: (element: any) => `${element.fechaTermino ? moment(new Date(element.fechaTermino), 'America/Santiago').format('lll'): ''}`,
    },
    {
      columnDef: 'userId',
      header: 'Nombre usuario',
      cell: (element: any) => `${element.nombreUsuario} ${element.apellidosUsuario}`,
    },
    {
      columnDef: 'cuentaRetiroId',
      header: 'Alias cuenta retiro',
      cell: (element: any) => `${element.alias}`,
    },
  
    {
      columnDef: 'valorRetiro',
      header: 'Monto retiro',
      cell: (element: any) => currencyPipe.transform(element.totalMonedaOrigen, '$'),
    },
  
    {
      columnDef: 'bancoDestino',
      header: 'Banco destino',
      cell: (element: any) => `${element.bancoNombre}`,
    },
    {
      columnDef: 'cuentaDestino',
      header: 'Cuenta destino',
      cell: (element: any) => `${element.tipoCuentaNombre} ${element.numeroCuenta}`,
    },
    {
      columnDef: 'identificacion',
      header: 'Identificación',
      cell: (element: any) => `${element.tipoIdentificacion} ${element.numeroId}`,
    },
    {
      columnDef: 'operador',
      header: 'Operador',
      cell: (element: any) => `${element.operador?element.operador:'N/A'}`,
    }
    
    
  ]

}

export const  columns = [
  {
    columnDef: 'id',
    header: 'ID',
    cell: (element: any) => `${element.id}`,
  },
  {
    columnDef: 'createdAt',
    header: 'Fecha creación',
    cell: (element: any) => `${element.createdAt ? moment(new Date(element.createdAt), 'America/Santiago').format('lll'): ''}`,
  },
  {
    columnDef: 'fechaTermino',
    header: 'Fecha cierra',
    cell: (element: any) => `${element.fechaTermino ? moment(new Date(element.fechaTermino), 'America/Santiago').format('lll'): ''}`,
  },  
  {
    columnDef: 'userId',
    header: 'Nombre usuario',
    cell: (element: any) => `${element.nombreUsuario} ${element.apellidosUsuario}`,
  },
  {
    columnDef: 'cuentaRetiroId',
    header: 'Alias cuenta retiro',
    cell: (element: any) => `${element.alias}`,
  },

  {
    columnDef: 'valorRetiro',
    header: 'Monto origen',
    cell: (element: any) => `${element.totalMonedaOrigen}`,
  },

  {
    columnDef: 'bancoDestino',
    header: 'Banco destino',
    cell: (element: any) => `${element.bancoNombre}`,
  },
  {
    columnDef: 'cuentaDestino',
    header: 'Cuenta destino',
    cell: (element: any) => `${element.tipoCuentaNombre} ${element.numeroCuenta}`,
  },
  {
    columnDef: 'identificacion',
    header: 'Identificación',
    cell: (element: any) => `${element.tipoIdentificacion} ${element.numeroId}`,
  },
  {
    columnDef: 'operador',
    header: 'Operador',
    cell: (element: any) => `${element.operador?element.operador:'N/A'}`,
  },
  {
    columnDef: 'estado',
    header: 'Estado',
    cell: (element: any) => `${element.estado}`,
  }

];

export const  displayedColumns: string[] = [
  'id',
  'createdAt',
  'fechaTermino',
  'estado',
  'userId',
  'cuentaRetiroId',
  'valorRetiro',
  'bancoDestino',
  'cuentaDestino',
  'identificacion',
  'operador',
  'acciones',

];
