import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Transaccion } from 'src/app/dashboard/interfaces/transaccion.interface';
import { DialogCambioComponent } from '../../dialog-cambio/dialog-cambio.component';
import { TransactionUserDTO } from 'src/app/dashboard/interfaces/transaction-user.dto';

@Component({
  selector: 'app-transaction-card',
  templateUrl: './transaction-card.component.html',
  styleUrls: ['./transaction-card.component.css']
})
export class TransactionCardComponent implements OnInit {
  @Input() transaccion: TransactionUserDTO | undefined;
  constructor(public dialog: MatDialog) {
   }

  public beneficiario:string | null = ''
  public status:string = ''

  ngOnInit() {
    this.status = this.transaccion!.estadoActual;
    if(this.transaccion?.motivo === 'FONDEO WALLET'){
      this.beneficiario = this.transaccion.pagador === null ? this.transaccion.remitente : this.transaccion.pagador;
      if(this.transaccion?.estadoActual === 'OPERACION EXITOSA'){
        this.status = 'ENVIADA'
      }
    }else{
      this.beneficiario = this.transaccion!.beneficiario;
    }
    if(this.transaccion?.motivo==='AJUSTE DE BALANCE'){
      this.status = 'ENVIADA'
    }
    if(this.transaccion?.motivo === 'CRIPTOMONEDA' && this.transaccion?.beneficiario === null){
      if(this.transaccion.walletTRC20){
        this.beneficiario = "Transferencia a Wallet: " + this.transaccion.walletTRC20;
      } else {
        this.beneficiario = "Transferencia a Wallet"
      }
    }
  }

  openDialog() {
    this.transaccion!.beneficiario = this.beneficiario!
    this.dialog.open(DialogCambioComponent, {
      data: this.transaccion
    });
  }

}
