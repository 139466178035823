import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'customNumberPipe'
})
export class CustomNumberPipePipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: any, digits?: string): unknown {
    return this.decimalPipe.transform(value, digits, 'en-US');
  }

}
