import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ManualTransactionFormService {
  private createForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.createForm = this.fb.group({
      usuarioId: [null, [Validators.required]],
      totalEnvio: [null, [Validators.required]],
      totalDest: [null, [Validators.required]],
      monedaLocal: [null, [Validators.required]],
      monedaDestino: [null, [Validators.required]],
      tasa: [null, [Validators.required]],
      walletTrx: [null, [Validators.required]],
    });
  }

  getCreateForm(): FormGroup {
    return this.createForm;
  }

  setCreateFormValues(values: any): void {
    this.createForm.setValue(values);
  }
}
