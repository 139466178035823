import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { CuentaBeneficiario } from 'src/app/dashboard/interfaces/cuenta-beneficiario.interface';

@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountListComponent implements OnInit, OnDestroy {
  usuarioID: number | undefined;
  @Output() reloadAccountList: EventEmitter<any> = new EventEmitter();
  @Output() actualizarCuenta: EventEmitter<CuentaBeneficiario> = new EventEmitter();
  @Output() seleccion: EventEmitter<CuentaBeneficiario> = new EventEmitter();
  subscriptions: Subscription = new Subscription();
  @Input() listaCuentas: CuentaBeneficiario[] = [];

  @Input() seleccionable: boolean = false;
  cuentaSeleccionada: CuentaBeneficiario | undefined;
  constructor(

  ) {
   // this.initFormCuenta();
  }


  ngOnInit(): void {

  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  eliminarCuenta(){
    this.reloadAccountList.emit();
  }

  actualizar(cuenta: CuentaBeneficiario){
    this.actualizarCuenta.emit(cuenta);
  }

  seleccionar(cuenta: any){
    if(!this.seleccionable)return;
    if(this.cuentaSeleccionada && this.cuentaSeleccionada?.cuentaBeneficiarioID !== cuenta.cuentaBeneficiarioID){
      this.cuentaSeleccionada.seleccionado=false;
    }
    cuenta['seleccionado'] = cuenta['seleccionado']? !cuenta['seleccionado'] : true;
    if(cuenta.seleccionado){
      this.cuentaSeleccionada = cuenta;
      this.seleccion.emit(cuenta);
    }else {
      this.seleccion.emit(undefined);
      this.cuentaSeleccionada = undefined;
    }
  }
}
