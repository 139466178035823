<h1 class="text-2xl font-bold text-center m-4">Tasa de negociación Zelle</h1>

<div *ngIf="tasa" >
    <div class="p-5">
        <app-zelle-change [zelle_buy_rate]="zelleSellValue" [zelle_sell_rate]="zelleBuyValue" [invert]="true" class="zelleChange"></app-zelle-change>
    </div>
  <button mat-raised-button color="primary" class="mx-8" (click)="setRates()" >Actualizar tasas</button>
</div>

<div class="p-5">
  <h1 class="text-xl text-center mt-8">Historial de la tasa de negociación </h1>
  <button mat-raised-button color="primary" class="mx-8 mt-4" (click)="reload()" >Refrescar</button>
  <div class="items-center w-full mt-4">
    <mat-paginator #paginator
               class="demo-paginator self-left"
               (page)="onPageChange($event)"
               [length]="totalItems"
               [pageSize]="pageSize"
               [disabled]="false"
               [showFirstLastButtons]="true"
               [pageSizeOptions]="[5, 10, 25, 50, 100]"
               [hidePageSize]="false"
               [pageIndex]="currentPage-1"
               aria-label="Select page">
  </mat-paginator>
  </div>
  <div class="flex overflow-x-auto">
    <table mat-table [dataSource]="dataSource" class="m-4 w-5/6" *ngIf="dataSource">

      <ng-container
        *ngFor="let column of columns"
        [matColumnDef]="column.columnDef"
      >
        <th mat-header-cell *matHeaderCellDef style="background-color: #CEE9C2; text-align:center" >{{column.header}}</th>
        <td mat-cell *matCellDef="let row"  style="text-align:center">{{column.cell(row)}}</td>
      </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
