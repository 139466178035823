import { Component, HostListener, ViewChild } from '@angular/core';
import { CartolaService } from '../../services/cartola.service';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { ActionsService } from 'src/app/shared/services/actions.service';

import Swal from 'sweetalert2';
import { HoursOperationService } from '../../services/hours-operation.service';
import { Router } from '@angular/router';
import { CalculatorWithdrawComponent } from './components/calculator-withdraw/calculator-withdraw.component';
import { WithdrawAccountsStepComponent } from './components/withdraw-accounts-step/withdraw-accounts-step.component';
import { CuentaRetiro } from '../../interfaces/cuenta-retiro.interface';
import { WithdrawAccountService } from '../../services/withdraw-account.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';

@Component({
  selector: 'app-withdraw-balance',
  templateUrl: './withdraw-balance.component.html',
  styleUrls: ['./withdraw-balance.component.sass']
})
export class WithdrawBalanceComponent {
  isVertical= false;
  hasAccounts=false;
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    if (window.innerWidth <= 768) {
      this.isVertical = true;
    } else {
      this.isVertical = false;
    }
  }
  constructor(
    private cartolaService: CartolaService,
    private store: Store<AppState>,
    private actionService: ActionsService,
    private hourOperations: HoursOperationService,
    private router: Router,
    private withdrawAccountService: WithdrawAccountService,
    private spinner: SpinnerService
  ){
    const ref = this.spinner.start();
    this.validateAccounts();
    this.spinner.stop(ref);
  }


  @ViewChild('stepper') stepper: MatStepper | undefined;
  @ViewChild('beneficiarioComponent') withdrawAccountsStepComponent: WithdrawAccountsStepComponent | undefined;
  @ViewChild('calculatorComponent') calculatorComponent: CalculatorWithdrawComponent | undefined;

  public subscription: Subscription = new Subscription();
  public user: any;
  public saldoActual = 0;
  public hasLoaded:boolean =  false;
  public usdt_clp_tax: number = 0;
  public stepPass2: boolean = false;
  public stepPass3: boolean = false;
  public stepPass4: boolean = false;
  public montoRetiro: number = 0;
  public accountSelected: CuentaRetiro | undefined;
  public valorCambioId: number = 0;
  public userId: number | undefined= 0;
  estadoOperaciones = true;
  estadoStopOperacion = true;
  subscriptions: Subscription = new Subscription();

  ngOnInit(): void {
    this.onWindowResize();
    this.subscription.add(
      this.actionService.subjectTasaUSDT.subscribe(res=>{
        if(this.stepper && this.stepper.selectedIndex !== 0){
          Swal.fire('Tasas actualizadas', 'Se han actualizado las tasas, será regresado al primer paso', 'warning').then(()=>{});
          this.stepper.selectedIndex = 0;
        }
      })
    )
    this.subscription.add(
      this.store.select('auth').subscribe(({ user }: any) => {
        this.user = user;
        this.userId = user?.usuarioId;
        this.obtenerSaldo();
      })
    );
    // this.validarEstado();
  }

  async validateAccounts(){
    let accounts = await this.withdrawAccountService.getWithdrawAccounts();
    if(accounts.length<=0){
      this.hasAccounts=false;
       Swal.fire(
        { confirmButtonColor: '#1e5a3f',
        icon: 'info',
        title: 'Atención',
        text:'Requieres crear una cuenta de retiro para realizar esta acción',
        showCancelButton: false,
      }
      );
      this.router.navigate(['/wallet/client/withdraw-accounts']);
    }else{
      this.hasAccounts=true;
    }

  }
  async validarEstado(){
    this.estadoOperaciones = await this.hourOperations.getEstadoOperacion('USDT');
    this.estadoStopOperacion = await this.hourOperations.getEstadoOperacion('USDT_STOP');
    console.log(this.estadoStopOperacion);
    const date = new Date().getDay();
    const message = [6,7].includes(date) ? 'Hemos cerrado operaciones, muchas gracias por preferirnos, Nos vemos el Lunes a las 9:00 am' :
    'Hemos cerrado operaciones, muchas gracias por preferirnos, Nos vemos Mañana a las 9:00 am'
    console.log(date);
    if(!this.estadoOperaciones){
      Swal.fire(
        { confirmButtonColor: '#1e5a3f',
        title: 'Operación cerrada',
        text:message,
      }
      );
      this.router.navigate(['/wallet']);
    }
    if(!this.estadoStopOperacion){
      Swal.fire(
        { confirmButtonColor: '#1e5a3f',
        title: 'Operación Pausada',
        text:'Volvemos en 10 min',
      }
      );
      this.router.navigate(['/wallet']);
    }
  }

  async obtenerSaldo(){
    if(this.user?.usuarioId){
      const {saldo} = await this.cartolaService.getSaldoCliente(this.user.usuarioId);
      this.saldoActual = saldo;
    }
  }

  nextStep( step:number ):void {
    switch( step ){
      case 2:{
        this.stepPass2 = true
        break
      }
      case 3:{
        this.stepPass3 = true
        break
      }
    }
    setTimeout(() => {
      if(this.stepper){
        this.stepper.next();
      }
    }, 300);
  }

  backStep(): void {
    setTimeout(() => {
      if(this.stepper){
        this.stepper.previous();
      }
    }, 300);
  }

  assignMontoRetiro( amount:number ){
    this.montoRetiro = amount;
    if(this.montoRetiro == 0) this.stepPass2 = false;
  }

  assignAccount( account:CuentaRetiro ){
    this.accountSelected = account;
  }

  onStepChange(event: StepperSelectionEvent): void{
    const newIndex = event.selectedIndex;
    // this.accountSelected!.clearInput();
    // this.beneficiaryComponent!.beneficiarioEscogido = undefined;
    // this.beneficiaryComponent!.listaBeneficiariosFiltrados = this.beneficiaryComponent!.listaBeneficiarios;
    this.stepPass3 = false;
  }
}
