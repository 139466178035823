<div class="overflow-y-auto my-6 p-4">
    <div class="p-4 flex flex-col items-center overflow-hidden">
        <h1 class="font-bold text-center my-4">Asignación de Transacción</h1>
        
        <div class="flex flex-col mb-4 mx-4">
          <span><b>Datos de la transacción</b></span>
        </div>
        
        <div class="flex flex-col mx-4 max-w-[100%]">
            <div *ngFor="let field of formFields" class="flex-col md:flex-row">
                <span class=""><b>{{ formTitles[field.name] }}: </b></span>
                <span class="md:mx-1">{{ field.value }}</span>
            </div>

            <div class="flex-col md:flex-row">
                <form [formGroup]="datosForm" *ngIf="datosForm" (ngSubmit)="submitForm()">
                    <div class="flex-col md:flex-row">
                        <span class=""><b>ID del Usuario: </b></span>
                        <input type="text" formControlName="userId" />
                    </div>
                    <span *ngIf="datosForm.get('userId')?.hasError('onlyNumbers')" class="text-sm text-red-600">
                        El ID de usuario debe contener solo números
                    </span><br *ngIf="datosForm.get('userId')?.hasError('onlyNumbers')">
                    <span *ngIf="datosForm.get('userId')?.hasError('required')" class="text-sm text-red-600">
                        Ingrese un ID de usuario válido
                    </span><br *ngIf="datosForm.get('userId')?.hasError('required')">
                    <button
                        type="submit"
                        mat-raised-button
                        color="primary"
                        [disabled]="datosForm!.invalid"
                        class="mt-4 place-self-center"
                      >
                        Enviar datos
                      </button>
                </form>
            </div>
        </div>

    <div>
        <button class="close"  mat-stroked-button (click)="cancelar()">Cerrar </button>
    </div>
</div>