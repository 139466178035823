import { Component, OnInit, ViewChild } from '@angular/core';
import { TransaccionService } from '../../services/transaccion.service';
import { Transaccion } from '../../interfaces/transaccion.interface';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { getColumns, displayedColumns } from './table-transactions-columns';
import { CurrencyPipe } from '@angular/common';
import { Clipboard } from '@angular/cdk/clipboard';
import Swal from 'sweetalert2';
import { MatPaginator } from '@angular/material/paginator';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { RejectTransactionWithdrawModalComponent } from '../withdraw-trx-list/reject-transaction-withdraw-modal/reject-transaction-withdraw-modal.component';

@Component({
  selector: 'app-withdraw-trx-sent',
  templateUrl: './withdraw-trx-sent.component.html',
  styleUrls: ['./withdraw-trx-sent.component.css']
})
export class WithdrawTrxSentComponent implements OnInit {
  transacciones: any[] = [];
  seleccionados: number = 0;
  dataSource: MatTableDataSource<any> | undefined;
  subscription: Subscription = new Subscription();
  columns = getColumns(this.currencyPipe);
  displayedColumns = displayedColumns;
  emailOperador = '';
  public currentPage = 1;
  public pageSize = 25;
  public totalItems = 0;
  public totalPages = this.totalItems / this.pageSize;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator | null = null;
  constructor(
    private transactionService: TransaccionService,
    private currencyPipe: CurrencyPipe,
    private clipboard: Clipboard,
    private spinner: SpinnerService,
    private dialog: MatDialog,
  ) { }
  async ngOnInit() {
    await this.loadTransactions();
  }

  async loadTransactions() {
    const ref = this.spinner.start();

    const data = await this.transactionService.getAllWithdrawTransactions('ENVIADA,RECHAZADA', this.currentPage, this.pageSize);

    this.transacciones = data.query;
    this.dataSource = new MatTableDataSource(this.transacciones);
    this.totalItems = +data.count;
    this.totalPages = Math.ceil(this.totalItems / this.pageSize);
    this.spinner.stop(ref);
  }

  onPageChange(event: any) {
    console.log(event);
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex + 1;
    this.loadTransactions();
  }

  openRejectTransactionModal(row: any) {
    const dialogRef = this.dialog.open(RejectTransactionWithdrawModalComponent, {
      data: row,
      maxHeight: '70%',
      maxWidth: '826px',
      minWidth: '800px',
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === true) {
        this.loadTransactions();
      }
    });
  }

  copyData(data: any) {
    const id = data.id || '';
    const fechaCreacion = data.createdAt || '';
    const nombreUsuario = data.nombreUsuario + ' ' + data.apellidosUsuario || '';
    const aliasCuentaRetiro = data.alias || '';
    const montoRetiro = data.totalMonedaDestino || '';
    const bancoDestino = data.bancoNombre || '';
    const cuentaDestino = data.tipoCuentaNombre + ' ' + data.numeroCuenta || '';
    const identificacion = data.tipoIdentificacion + ' ' + data.numeroId || '';

    this.clipboard.copy(`Id: ${id}\n Fecha de Creacion: ${fechaCreacion}\n Nombre: ${nombreUsuario}\n Alias Cuenta Retiro: ${aliasCuentaRetiro}\n Monto Retiro: ${montoRetiro}\n Banco Destino: ${bancoDestino}\n Cuenta Destino: ${cuentaDestino}\n Identificacion: ${identificacion}`);
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    })

    Toast.fire({
      icon: 'success',
      title: 'Transferencia copiada al portapapeles'
    })
  }

  change() {
    this.seleccionados =
      this.dataSource?.data.filter((value) => value.seleccionado).length || 0;

  }

  limpiarSeleccion() {
    const data =
      this.dataSource?.data.map((value) => {
        return { ...value, seleccionado: false };
      }) || [];

    if (this.dataSource && this.dataSource.data) {
      this.dataSource.data = data;
    }
    this.change();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (this.dataSource) {
      this.dataSource.filter = filterValue.trim().toUpperCase();
    }
  }

  public doFilter = (event: any) => {
    const element = event.currentTarget as HTMLInputElement;
    const value = element.value;
    if (this.dataSource)
      this.dataSource.filter = value.trim().toLocaleLowerCase();
  };
  //TPDP

  returnSelectedTransactions(row: Transaccion) {
    const selected =
      this.dataSource?.data?.filter((x) => x.seleccionado && x.id !== row.id) ||
      [];
    selected.push(row);
    return selected;
  }

  disableButton(email: string) {
    if (this.emailOperador !== email) return true;
    return false;
  }
}
