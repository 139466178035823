import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { map, exhaustMap, catchError } from 'rxjs/operators';
import { UserService } from '../dashboard/services/user.service';
import * as authActions from './auth.actions';
 
@Injectable()
export class AuthEffects {
 
  loadUser$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.loadUserWithEmail),
    exhaustMap((action) => this.userService.getUserData(action.email)
      .pipe(
        map(({user}: any) => authActions.setUser({user : user })),
        catchError( async (error) => authActions.unsetUser())
      ))
    )
  );

  loadUserWithRole$ = createEffect(() => this.actions$.pipe(
    ofType(authActions.loadUser),
    exhaustMap((action) => this.userService.getUserData(action.email)
      .pipe(
        map(({user}: any) => authActions.setUserAndRole({user : user, role: action.role })),
        catchError( async (error) => authActions.unsetUser())
      ))
    )
  );
 
  constructor(
    private actions$: Actions,
    private userService: UserService
  ) {}
}