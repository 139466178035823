import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { CuentaTrabajo } from 'src/app/dashboard/interfaces/cuenta-trabajo.interface';
import { Transaccion } from 'src/app/dashboard/interfaces/transaccion.interface';
import { WorkingAccountService } from 'src/app/dashboard/services/working-account.service';
import { columns, displayedColumns } from '../table-transactions-columns';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { TransaccionService } from 'src/app/dashboard/services/transaccion.service';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import { AssignTransactionsDTO } from 'src/app/dashboard/interfaces/assign-transactions.dto';
import Swal from 'sweetalert2';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RejectTransactionDTO } from 'src/app/dashboard/interfaces/reject-transaction.dto';

@Component({
  selector: 'app-reject-transaction-modal',
  templateUrl: './reject-transaction-modal.component.html',
  styleUrls: ['./reject-transaction-modal.component.sass']
})
export class RejectTransactionModalComponent {
  datosForm: FormGroup | undefined;
  motivosRechazo = ['DNI incorrecto', 'Cuenta incorrecta'];
  private transaction: Transaccion | undefined | any;
  constructor(
    private auth: CognitoService,
    private readonly transactionService: TransaccionService,
    private fb: FormBuilder,
    private spinner: SpinnerService, private workingAccountService: WorkingAccountService, public dialogRef: MatDialogRef<RejectTransactionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any ){
     this.transaction = data;
     this.initForm();
  }

  initForm(){
    this.datosForm = this.fb.group({
      motivo: new FormControl('', [Validators.required]),
      comentario: new FormControl('', [Validators.maxLength(100)]),
    });
  }

  async rejectTransaction(){
    if(this.datosForm?.invalid)return;
    let datos: RejectTransactionDTO = {...this.datosForm?.value, transactionID: this.transaction.id || this.transaction.idTransferencia};
    const spinnerRef = this.spinner.start();
    try{
      const result =  await this.transactionService.rejectTransaction(datos);
      Swal.fire({
        icon: 'success',
        title: 'Transacción rechazada',
        text: 'la transacción se ha rechazado exitosamente',
        showCancelButton: false,
        showDenyButton: false,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#1e5a3f',

      });
      this.dialogRef.close(true);
    }catch(error: any){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.error.mensaje,
        showCancelButton: false,
        showDenyButton: false,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#1e5a3f',

      });
      this.dialogRef.close(true);
    }

    this.spinner.stop(spinnerRef);
  }

  close(){
    this.dialogRef.close();
  }

  }

