<div class="w-[800px] px-16 py-10  rounded-3xl uploader-status flex flex-col items-center justify-center align-middle">

  <h2 class="text-2xl font-bold self-start">Marcar operación como transferencia enviada
  </h2>

  <h3 class="text-lg font-bold mt-8  self-start">Id transacción: {{transaction?.id}}</h3>
  <h3 class="text-lg font-bold  self-start">Beneficiario: {{transaction?.cuentaBeneficiario?.nombre}} {{transaction?.cuentaBeneficiario?.apellidos}}</h3>
  <h3 class="text-lg font-bold  self-start">Banco: {{transaction?.cuentaBeneficiario?.banco?.nombre}} </h3>
  <h3 class="text-lg font-bold  self-start">Cuenta: {{transaction?.cuentaBeneficiario?.tipoCuenta?.nombre}} {{transaction?.cuentaBeneficiario?.numeroCuenta}}</h3>
  <h3 class="text-lg font-bold  self-start">Monto: {{transaction?.totalMonedaDestino | currency:'':''}}  {{transaction?.monedaDestino }}</h3>
  <h3 class="text-lg font-bold  self-start" *ngIf="transaction?.precio_compra">Precio Compra: {{transaction?.precio_compra | currency: ''}} </h3>
    <form [formGroup]="datosForm" class="w-full my-4" *ngIf="datosForm">
      <div class="grid grid-cols-1 gap-4">
        <mat-form-field class="col-span-2">
          <mat-label>Referencia:</mat-label>
          <input
            type="text"
            matInput
            formControlName="referencia"
            placeholder="Opcional"
          />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Comprobante (opcional)</mat-label>
          <div class="fileUploadContainer" [ngStyle]="{'margin-top' : datosForm.get('photo')!.value ? '5px' :  '20px'}">
            <ng-container *ngIf="datosForm.get('photo')!.value">
              <img [src]="datosForm.get('photo')!.value" />
              <button class="deleteButton" mat-icon-button (click)="fileInput.value = ''; datosForm.get('photo')?.setValue(null);">
                <mat-icon>close</mat-icon>
              </button>
            </ng-container>

            <div *ngIf="!datosForm.get('photo')!.value" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
              <mat-icon style="opacity: 60%;">file_upload</mat-icon>
              <button mat-raised-button color="primary" style="width:100%; opacity: 80%;">Adjuntar</button>
              <small style="margin: 20px">Arrastrar y soltar</small>
            </div>

            <input #fileInput class="fileInput" type="file" multiple="false" accept="image/*"
              (change)="setFileData($event)" />
          </div>
          <input matInput formControlName="photo" readonly [hidden]="true " />
        </mat-form-field>
      </div>

    </form>

  <div class="flex flex-row justify-center gap-10 w-full mt-6">
    <button type="button" (click)="acceptTransaction()" [disabled]="datosForm?.invalid" mat-raised-button color="primary" class="mt-4 w-1/2  text-lg rounded-lg px-5 py-6 text-center"
    >
    Pagado
  </button>
    <button type="button" mat-raised-button color="basic" (click)="close()"
      class=" w-1/2 mt-4  text-lg rounded-lg px-5 py-6 text-center">
      Cancelar
    </button>
  </div>

</div>
