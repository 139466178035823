import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AddBeneficiarioCripto } from 'src/app/dashboard/interfaces/add-beneficiario-cripto.interface';
import { RecipientCriptoService } from 'src/app/dashboard/services/recipient-cripto.service';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';


@Component({
  selector: 'app-add-account-cripto',
  templateUrl: './add-account-cripto.component.html',
  styleUrls: ['./add-account-cripto.component.sass']
})
export class AddAccountCriptoComponent {


  formCuenta: FormGroup | undefined;
  subscriptions: Subscription = new Subscription();

  usuarioID: number | undefined;

  myInputValue: string = '';

  constructor(
    public dialogRef: MatDialogRef<AddAccountCriptoComponent>,
    public recipientcriptoservice: RecipientCriptoService,
    private store: Store<AppState>,
  ){}

  cancel(){
    this.dialogRef.close(false);
  }

  soloLetras(event: KeyboardEvent){
    if (event.key >= '0' && event.key <= '9') {
      event.preventDefault();
    }
  }

  soloNumeros(event: KeyboardEvent) {
    const char = event.key;
    if (!char.match(/[0-9]/)) {
      event.preventDefault();
    }
  }
}
