<div class="w-[800px] px-16 py-10  rounded-3xl uploader-status flex flex-col items-center justify-center align-middle">

  <h2 class="text-2xl font-bold self-start">Marcar operación como transferencia enviada
  </h2>

  <h3 class="text-lg font-bold mt-8  self-start">ID Transferencia: {{ transaction?.idTransferencia }}</h3>
  <h3 class="text-lg font-bold  self-start">Remitente: {{ transaction?.remitente }}</h3>
  <h3 class="text-lg font-bold  self-start">Beneficiaro: {{ transaction?.beneficiario }}</h3>
  <h3 class="text-lg font-bold  self-start">Tasa de negociación: {{ transaction?.tn }}</h3>
  <h3 class="text-lg font-bold  self-start">Monto CLP: {{ transaction?.montoCLP | currency:'':'' }}</h3>
  <h3 class="text-lg font-bold  self-start">Monto USDT: {{ transaction?.montoUSDT | currency:'':'' }}</h3>
    <form [formGroup]="datosForm" class="w-full my-4" *ngIf="datosForm">
      <div class="grid grid-cols-1 gap-4">
        <mat-form-field class="col-span-2">
          <mat-label>Referencia:</mat-label>
          <input
            type="text"
            matInput
            formControlName="referencia"
            placeholder="Opcional"
          />
        </mat-form-field>
      </div>

    </form>

  <div class="flex flex-row justify-center gap-10 w-full mt-6">
    <button type="button" mat-raised-button color="primary" (click)="acceptTransaction()" [disabled]="datosForm?.invalid" class="mt-4 w-1/2  text-lg rounded-lg px-5 py-6 text-center"
    >
    Pagado
  </button>
    <button type="button" mat-raised-button color="basic" (click)="close()"
      class=" w-1/2 mt-4  text-lg rounded-lg px-5 py-6 text-center">
      Cancelar
    </button>
  </div>

</div>
