import { Component,Output,EventEmitter,Input } from '@angular/core';
import { ActionsService } from 'src/app/shared/services/actions.service';

@Component({
  selector: 'app-calculator-crypto',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.sass']
})
export class CalculatorCryptoComponent {

  public minError = false;
  public clpCurrency: number = 0
  public usdtCurrency: number = 0;
  private minValue: number = 50;
  @Input()
  public usdt_clp_tax:number = 0;

  @Input()
  public saldoActual:number = 0;

  @Input()
  public sobregiroDisponible :number|undefined=0;

  @Input()
  public saldoContable :number|undefined= 0;

  @Input()
  public lineaCreditoBloqueada:boolean|undefined=false;

  @Input()
  public lineaCredito:number|undefined=0;

  @Output()
  public changeStep = new EventEmitter<number>();

  @Output()
  public clpCurrencyEmit = new EventEmitter<number>();

  @Output()
  public usdCurrencyEmit = new EventEmitter<number>();

  constructor(
    private actionService: ActionsService,
  ){
    this.actionService.subjectTasaUSDT.subscribe(
      (res)=>{
        // setTimeout(()=> this.transformCurrency('clp'), 1000)
      }
    )
  }

  get valorClp(): string {
    return this.clpCurrency === 0 ? '' : this.clpCurrency.toLocaleString('en-US');
  }

  set valorClp(val: string) {
    if (val === '') {
      this.clpCurrency = 0;
    } else {
      this.clpCurrency = parseFloat(val.replace(/,/g, ''));
    }
  }

  get valorUsdt(): string{
    return this.usdtCurrency === 0 ? '' : this.usdtCurrency.toLocaleString('en-US');
  }

  set valorUsdt(val: string) {

    if (val === '') {
      this.usdtCurrency = 0;
    } else {
      this.usdtCurrency = parseFloat(val.replace(/,/g, ''));
    }

  }

  emitChangeStep( ): void{
    if(this.minError)return;
    this.changeStep.emit( 2 );
    this.clpCurrencyEmit.emit( this.clpCurrency );
    this.usdCurrencyEmit.emit( this.usdtCurrency );
  }

  emitAmountChange(): void{
    this.clpCurrencyEmit.emit( this.clpCurrency );
  }

  maxValAvailableCLP( ): void{
    if(this.lineaCredito != null && this.lineaCredito>0 && !this.lineaCreditoBloqueada){
      if(this.saldoContable != null && this.saldoContable > 0){
        this.valorClp=this.saldoContable?.toString() ?? '';
        this.transformCurrency('clp');
      }
    }else{
      if(this.saldoActual>0){
        this.valorClp=this.saldoActual.toString();
        this.transformCurrency('clp');
      }
    }
  }

  clearValAvailableCLP( ): void{
      this.valorClp="0";
      this.transformCurrency('clp');
  }

  transformCurrency( currency: string ): void {
    this.minError=false;
    if(currency === 'clp'){
      this.usdtCurrency = this.clpCurrency / this.usdt_clp_tax;
    }
    if(currency === 'usdt'){
      this.clpCurrency = this.usdtCurrency*this.usdt_clp_tax;
    }
    if(this.usdtCurrency < this.minValue){
      this.minError = true;
    }
    if(this.minError)return;
    this.clpCurrencyEmit.emit( this.clpCurrency )
    this.usdCurrencyEmit.emit( this.usdtCurrency )
  }

  soloNumeros(event: KeyboardEvent) {
    const char = event.key;
    if (!char.match(/[0-9]/)) {
      event.preventDefault();
    }
  }
}
