<div class="m-4 flex flex-col justify-center" >
  <h1 class="m-4 text-xl font-bold text-center"> Estatus cajero </h1>


  <div class="w-full flex flex-row-reverse pr-4">
    <button  mat-raised-button color="primary" (click)="loadData()"> Recargar
      <mat-icon>
        refresh
      </mat-icon>
    </button>
  </div>

  <div class="flex overflow-x-auto">
    <table mat-table [dataSource]="dataSource" class="m-4" *ngIf="dataSource">

      <ng-container
        *ngFor="let column of columns"
        [matColumnDef]="column.columnDef"
      >
        <th mat-header-cell *matHeaderCellDef style="background-color: #CEE9C2; text-align:left;font-weight: bold;" >{{column.header}}</th>
        <td mat-cell *matCellDef="let row"  style="text-align:left">{{column.cell(row)}}</td>
      </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
