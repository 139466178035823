import { Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { TransaccionService } from '../../services/transaccion.service';
import { Transaccion } from '../../interfaces/transaccion.interface';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { getColumns, displayedColumns } from './table-transactions-columns';
import { CurrencyPipe } from '@angular/common';
import { RejectTransactionWithdrawModalComponent } from './reject-transaction-withdraw-modal/reject-transaction-withdraw-modal.component';
import { SendTransactionWithdrawModalComponent } from './send-transaction-withdraw-modal/send-transaction-withdraw-modal.component';
import { Clipboard } from '@angular/cdk/clipboard';
import Swal from 'sweetalert2';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { AddWithdrawAccountsComponent } from '../add-withdraw-accounts/add-withdraw-accounts.component';

@Injectable()
export class CustomPaginatorIntl extends MatPaginatorIntl {
  override itemsPerPageLabel = 'Elementos por página';
  override nextPageLabel = 'Siguiente';
  override previousPageLabel = 'Anterior';
  override firstPageLabel = "Primera página";
  override lastPageLabel = "Última página"

  override getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 de ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} de ${length}`;
  };

}
@Component({
  selector: 'app-withdraw-trx-list',
  templateUrl: './withdraw-trx-list.component.html',
  styleUrls: ['./withdraw-trx-list.component.css']
})
export class WithdrawTrxListComponent implements OnInit {
  transacciones: any[] = [];
  seleccionados: number = 0;
  dataSource: MatTableDataSource<any> | undefined;
  subscription: Subscription = new Subscription();
  columns = getColumns(this.currencyPipe);
  displayedColumns = displayedColumns;
  emailOperador = '';
  public currentPage = 1;
  public pageSize = 25;
  public totalItems = 0;
  public totalPages = this.totalItems / this.pageSize;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator | null = null;
  constructor(
    private transactionService: TransaccionService,
    private currencyPipe: CurrencyPipe,
    private clipboard: Clipboard,
    private spinner: SpinnerService,
    private dialog: MatDialog,
  ) { }
  async ngOnInit() {
    await this.loadTransactions();
  }

  async loadTransactions() {
    const ref = this.spinner.start();

    const data = await this.transactionService.getAllWithdrawTransactions('POR_PROCESAR', this.currentPage, this.pageSize);

    this.transacciones = data.query;
    this.dataSource = new MatTableDataSource(this.transacciones);
    this.totalItems = +data.count;
    this.totalPages = Math.ceil(this.totalItems / this.pageSize);
    this.spinner.stop(ref);
  }

  onPageChange(event: any) {
    console.log(event);
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex + 1;
    this.loadTransactions();
  }

  openRejectTransactionModal(row: any) {
    const dialogRef = this.dialog.open(RejectTransactionWithdrawModalComponent, {
      data: row,
      maxHeight: '70%',
      maxWidth: '826px',
      minWidth: '800px',
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === true) {
        this.loadTransactions();
      }
    });
  }

  copyData(data: any) {
    const id = data.id || '';
    const fechaCreacion = data.createdAt || '';
    const nombreUsuario = data.nombreUsuario + ' ' + data.apellidosUsuario || '';
    const aliasCuentaRetiro = data.alias || '';
    const montoRetiro = data.totalMonedaDestino || '';
    const bancoDestino = data.bancoNombre || '';
    const cuentaDestino = data.tipoCuentaNombre + ' ' + data.numeroCuenta || '';
    const identificacion = data.tipoIdentificacion + ' ' + data.numeroId || '';

    this.clipboard.copy(`Id: ${id}\n Fecha de Creacion: ${fechaCreacion}\n Nombre: ${nombreUsuario}\n Alias Cuenta Retiro: ${aliasCuentaRetiro}\n Monto Retiro: ${montoRetiro}\n Banco Destino: ${bancoDestino}\n Cuenta Destino: ${cuentaDestino}\n Identificacion: ${identificacion}`);
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    })

    Toast.fire({
      icon: 'success',
      title: 'Transferencia copiada al portapapeles'
    })
  }
  openSendTransactionModal(row: any) {

    const dialogRef = this.dialog.open(SendTransactionWithdrawModalComponent, {
      data: row,
      maxHeight: '70%',
      maxWidth: '826px',
      minWidth: '800px',
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === true) {
        this.loadTransactions();
      }
      // if(result == true && this.usuarioID){
      //   this.listaCuentas = await this.accountRecipientService.getByUser(this.usuarioID);
      // }
    });
  }

  openEditTrxModal(row: any) {
    console.log(row);
    const rowMapped = {
      alias: row.alias,
      codigoPais: row.paisOrigen,
      tipoId: row.tipoIdentificacion,
      numeroId: row.numeroId,
      banco: row.bancoNombre,
      bancoId: row.bancoId,
      tipoCuentaId: row.tipoCuentaId,
      numeroCuenta: row.numeroCuenta,
      id: row.cuentaRetiroId
    };
    console.log(rowMapped);
    const dialogRef = this.dialog.open(AddWithdrawAccountsComponent, {
      data: {type: 'UPDATE', cuenta: rowMapped, userId: row.usuarioId},
      maxHeight: '70%',
      maxWidth: '826px',
      minWidth: '800px',
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result === true) {
        this.loadTransactions();
      }
      // if(result == true && this.usuarioID){
      //   this.listaCuentas = await this.accountRecipientService.getByUser(this.usuarioID);
      // }
    });
  }

  private transaccionesIguales(transacciones1: Transaccion[], transacciones2: Transaccion[]): boolean {
    if (transacciones1.length !== transacciones2.length) {
      return false; // Si las longitudes son diferentes, las listas no son iguales
    }
    const transacciones1Ids = transacciones1.map((t) => t.id);
    const transacciones2Ids = transacciones2.map((t) => t.id);

    // Compara los identificadores de las transacciones
    for (const id of transacciones1Ids) {
      if (!transacciones2Ids.includes(id)) {
        return false; // Si un identificador en transacciones1 no está en transacciones2, no son iguales
      }
    }
    return true; // Si todas las comparaciones fueron iguales, las listas son iguales
  }

  change() {
    this.seleccionados =
      this.dataSource?.data.filter((value) => value.seleccionado).length || 0;

  }

  limpiarSeleccion() {
    const data =
      this.dataSource?.data.map((value) => {
        return { ...value, seleccionado: false };
      }) || [];

    if (this.dataSource && this.dataSource.data) {
      this.dataSource.data = data;
    }
    this.change();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (this.dataSource) {
      this.dataSource.filter = filterValue.trim().toUpperCase();
    }
  }

  public doFilter = (event: any) => {
    const element = event.currentTarget as HTMLInputElement;
    const value = element.value;
    if (this.dataSource)
      this.dataSource.filter = value.trim().toLocaleLowerCase();
  };
  //TPDP

  returnSelectedTransactions(row: Transaccion) {
    const selected =
      this.dataSource?.data?.filter((x) => x.seleccionado && x.id !== row.id) ||
      [];
    selected.push(row);
    return selected;
  }

  disableButton(email: string) {
    if (this.emailOperador !== email) return true;
    return false;
  }
}
