import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AutomaticRateService {

  API_URL = environment.apiGatewayURL;
  constructor(private http: HttpClient) { }

  getAutomaticRate(){
    const serviceURL = `${this.API_URL}usdt-rate-preview`;
    return firstValueFrom(this.http.get<any>(serviceURL));
  }

  getUsdtRateForUser(): Promise<any>{
    const serviceURL = `${this.API_URL}general-types/usdtRate`;
    return firstValueFrom(this.http.get<any>(serviceURL));
  }
}
