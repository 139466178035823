import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { CuentaBeneficiario } from 'src/app/dashboard/interfaces/cuenta-beneficiario.interface';
import { AccountRecipientService } from 'src/app/dashboard/services/account-recipient.service';

@Component({
  selector: 'app-recipient-select',
  templateUrl: './recipient-select.component.html',
  styleUrls: ['./recipient-select.component.css']
})
export class RecipientSelectComponent implements OnInit {

  subscriptions: Subscription = new Subscription();
  usuarioID: number | undefined;
  listaCuentas: CuentaBeneficiario[] = [];
  selected: CuentaBeneficiario | undefined;
  @Output() seleccion: EventEmitter<CuentaBeneficiario> = new EventEmitter();
  constructor(
    private store: Store<AppState>,
    private accountRecipientService: AccountRecipientService
  ) {
    this.initFormCuenta();
  }



  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initFormCuenta() {
    this.subscriptions.add(
      this.store.select('auth').subscribe(async ({ user }) => {
        this.usuarioID = user?.usuarioId;
        if(this.usuarioID){
          this.listaCuentas = await this.accountRecipientService.getByUser(this.usuarioID);
        }
      })
    );

  }
  ngOnInit() {}


  onSeleccion(cuenta: CuentaBeneficiario){
    this.seleccion.emit(cuenta);
  }
}
