<div class="px-16 py-10  rounded-3xl uploader-status flex flex-col items-center justify-center align-middle">
<h2 style="text-align: center;" class="text-lg font-bold">Asignación Roles de usuario</h2>

<form class="w-full flex flex-col" style="margin: 20px; padding: 10px;">
  <mat-form-field appearance="fill" >
    <mat-label>Tipo de usuario</mat-label>
    <mat-select [(ngModel)]="segmentationId"   name="segmentationId">
      <mat-option [value]=1>Retail</mat-option>
      <mat-option [value]=2>Mayorista</mat-option>
      <mat-option [value]=3>Super Mayorista</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Puntos spot</mat-label>
    <input matInput appOnlynumber type="number" placeholder="Ej: 0.1" name="spotPoints"  [(ngModel)]="spotPoints" [disabled]="segmentationId == 1">
    <mat-hint *ngIf="segmentationId==1" class="text-sm text-red-900">* No se le asignan puntos spot al usuario Retail</mat-hint>
    <mat-hint class="text-sm text-red-900" *ngIf="+segmentationId!==1 && +spotPoints === 0">* Debe ingresar puntos spot para este tipo de usuario </mat-hint>
  </mat-form-field>
</form>
<button color="primary" class="w-1/2 py-2.5" mat-flat-button [disabled]="!segmentationId || (+segmentationId!==1 && +spotPoints === 0)"  (click)="editSegmentation()">Asignar</button>

</div>
