<div class="p-4 flex flex-col md:p-14 gap-[1rem]">
  <ng-container *ngIf="userRole === userEnum.aguacate_client">
    <ng-container *ngIf="estadoValidacion === 2">
      <div>
        <h2 class="text-2xl font-bold">Hola, {{user.nombre}} {{user.apellidos}}</h2>
        <app-rates></app-rates>
        <p class="text-lg font-bold italic mb-4 mx-4">Saldo Actual</p>
        <h2 class="mb-2 font-bold">${{ saldoActual | currency:'':'' }} CLP</h2>
        <div *ngIf="lineaCredito!>0&&!lineaCreditoBloqueada" ngClass="mx-auto">
          <p class="text-lg font-bold italic mb-4 mx-4">Saldo Contable</p>
          <h2 class="mb-2 font-bold">${{ saldoContable | currency:'':'' }} CLP</h2>
        </div>
        <div *ngIf="lineaCredito!>0&&!lineaCreditoBloqueada" ngClass="mx-auto">
          <p class="text-lg font-bold italic mb-4 mx-4">Sobregiro Disponible</p>
          <h2 class="mb-2 font-bold">${{ sobregiroDisponible | currency:'':'' }} CLP</h2>
        </div>
        <mat-error *ngIf="lineaCreditoBloqueada">Su linea de credito se encuentra actualmente bloqueada</mat-error>
        <div class="w-full flex flex-row justify-between mx-4 pr-5 gap-3 my-4 ">
          <button mat-raised-button color="primary" class="w-full md:w-1/3 py-5"
            routerLink="/wallet/client/rechargeAccount">Recargar saldo</button>

          <button mat-raised-button color="primary" class="w-full md:w-1/3 py-5 mr-4" (click)="enviarDinero()" [disabled]="lineaCreditoBloqueada">Envía
            dinero</button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="estadoValidacion===0">
      <button mat-raised-button color="primary" class="w-full md:w-1/3 py-5"
        routerLink="/wallet/client/user-validation">Verifica tu cuenta</button>
      <span class="px-6 py-4 bg-aguacate-green/25 rounded-lg  font-semibold">Para procesar tus envíos es necesario que
        nos
        facilites algunos datos personales, esto con el objetivo de hacer tus envíos mas seguros y verificar tu
        identidad.
      </span>
    </ng-container>

    <ng-container *ngIf="estadoValidacion===1">
      <span class="px-6 py-4 bg-aguacate-green/25 rounded-lg  font-semibold">Tu información está siendo validada por
        nuestro equipo, se te notificará a tu correo electrónico cuando el proceso finalice
      </span>
    </ng-container>

  </ng-container>
</div>