<div class="px-16 py-10 uploader-status flex flex-col items-center justify-center align-middle">


<mat-progress-spinner
class="example-margin"
[color]="'#1C593D'"
mode="indeterminate"
>
</mat-progress-spinner>
<div class="pt-9 text-lg font-semibold">{{data}}</div>
</div>
