<h1 class="text-xl font-bold mt-5 ">Tasa del día</h1>

<div *ngIf="currentValues && currentRates">
  <h1 class="text-lg  my-7">
    Mínimo {{ currentValues.limiteInferior | customNumberPipe }} BS
  </h1>

  <div class="grid grid-cols-2 xl:grid-cols-4 sm:grid-cols-2 gap-4">
    <app-rate-value-card [bank]="'Banesco'" [imgUrl]="'assets/img/bancos/banesco.png'"
    [rateDestination]="currentRates.minimo.tasaBanesco"></app-rate-value-card>
    <app-rate-value-card [bank]="'Mercantil'" [imgUrl]="'assets/img/bancos/mercantil.png'"
    [rateDestination]="currentRates.minimo.tasaMercantil"></app-rate-value-card>
    <app-rate-value-card [bank]="'Provincial'" [imgUrl]="'assets/img/bancos/provincial.png'"
    [rateDestination]="currentRates.minimo.tasaProvincial"></app-rate-value-card>
    <app-rate-value-card [bank]="'Venezuela'" [imgUrl]="'assets/img/bancos/venezuela.png'"
    [rateDestination]="currentRates.minimo.tasaVenezuela"></app-rate-value-card>
  </div>

  <h1 class="text-lg  my-7">
    Mínimo {{ currentValues.limiteSuperior | currency:'':'' }} BS
  </h1>
  <div class="grid grid-cols-2 xl:grid-cols-4 sm:grid-cols-2 gap-4 ">
    <app-rate-value-card [bank]="'Banesco'" [imgUrl]="'assets/img/bancos/banesco.png'"
    [rateDestination]="currentRates.maximo.tasaBanesco"></app-rate-value-card>
    <app-rate-value-card [bank]="'Mercantil'" [imgUrl]="'assets/img/bancos/mercantil.png'"
    [rateDestination]="currentRates.maximo.tasaMercantil"></app-rate-value-card>
    <app-rate-value-card [bank]="'Provincial'" [imgUrl]="'assets/img/bancos/provincial.png'"
    [rateDestination]="currentRates.maximo.tasaProvincial"></app-rate-value-card>
    <app-rate-value-card [bank]="'Venezuela'" [imgUrl]="'assets/img/bancos/venezuela.png'"
    [rateDestination]="currentRates.maximo.tasaVenezuela"></app-rate-value-card>
  </div>
</div>
