import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { RecipientZelleService } from 'src/app/dashboard/services/recipient-zelle.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { BeneficiarioZelle } from 'src/app/dashboard/interfaces/beneficiario-zelle.interface';
import Swal from 'sweetalert2';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Banco } from 'src/app/dashboard/interfaces/banco';
import { BanksService } from 'src/app/dashboard/services/banks.service';

@Component({
  selector: 'app-add-beneficiary-zelle',
  templateUrl: './add-beneficiary-zelle.component.html',
  styleUrls: ['./add-beneficiary-zelle.component.sass']
})
export class AddBeneficiaryZelleComponent implements OnInit{
  beneficiarioForm?: FormGroup | undefined;
  subscriptions: Subscription = new Subscription();
  bancos: Banco[] = [];

  usuarioID: number | undefined;

  myInputValue: string = '';
  esUpdate = false;


  constructor(
    private fb: FormBuilder,
    public recipientcriptoservice: RecipientZelleService,
    private spinner: SpinnerService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AddBeneficiaryZelleComponent>,
    public router: Router,
    private bankService: BanksService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

    this.esUpdate =  this.data.type === 'UPDATE';
    console.log(this.data.beneficiario);
    this.initFormCuenta();
  }

  async ngOnInit(): Promise<void> {

  }

  async initFormCuenta() {
    this.beneficiarioForm = this.fb.group({
      bancoDestino: new FormControl (this.esUpdate ? this.data.beneficiario.bancoDestino : '', [Validators.required]),
      tipoBeneficiario: new FormControl (this.esUpdate ? this.data.beneficiario.esEmpresa? 1 : 0 : '', [Validators.required]),
      nombreApellido: new FormControl (this.esUpdate ? this.data.beneficiario.nombre: '', [Validators.required]),
      identificador: new FormControl (this.esUpdate ? this.data.beneficiario.identificador : '', [this.identifierValidator]),
      numeroCuenta: new FormControl (this.esUpdate ? this.data.beneficiario.numeroCuenta : '', []),
    });

    this.beneficiarioForm.get('bancoDestino')?.valueChanges.subscribe((value) => {
      console.log(value);
      if(value !== 'ZELLE'){
        this.beneficiarioForm?.get('identificador')?.clearValidators();
        this.beneficiarioForm?.get('identificador')?.updateValueAndValidity();;
        this.beneficiarioForm?.get('numeroCuenta')?.setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(12)]);
      }
      else{
        this.beneficiarioForm?.get('numeroCuenta')?.clearValidators();
      }
      this.beneficiarioForm?.get('numeroCuenta')?.updateValueAndValidity();

    });
    this.bancos = await  this.bankService.getBanksByCountry('US');
    if(this.esUpdate){

      const selectedBank = this.bancos.find(banco => banco.bancoID === this.data.beneficiario.bancoId);
    this.beneficiarioForm.get('bancoDestino')?.setValue(selectedBank?.codigoBanco);
    }
  }
  cancel(){
    this.dialogRef.close(false);
  }

  async guardarCuentaZelle() {
    let mensajeExito = 'Beneficiario Zelle registrado con éxito';
    console.log(this.bancos);
    console.log(this.beneficiarioForm?.value);
    const bancoId = this.bancos.find(banco => banco.codigoBanco === this.beneficiarioForm?.get('bancoDestino')?.value)?.bancoID;
    const numeroCuenta = this.beneficiarioForm?.get('bancoDestino')?.value === 'BOFA' ? this.beneficiarioForm?.get('numeroCuenta')?.value : null;
    if (this.beneficiarioForm?.invalid) return;
    const formValue = this.beneficiarioForm?.value;
    const cuenta: BeneficiarioZelle = {
      ...formValue,
      esEmpresa: formValue.tipoBeneficiario,
      nombre: formValue.nombreApellido,
      usuarioId: this.data.userID,
      bancoId: bancoId,
      numeroCuenta: numeroCuenta,


    };
    if(this.esUpdate){
       cuenta['id'] = this.data.beneficiario.id;
        mensajeExito ='Nueva Cuenta de beneficiario';
    }
    const ref = this.spinner.start();
    try {
      const resultado = await this.recipientcriptoservice.saveRecipientAccount(
        cuenta
      );
      console.log(resultado);

      this.snackBar.open(mensajeExito, 'Ok', {duration: 3000});
      this.dialogRef.close(true);
      if(!this.esUpdate){
        this.router.navigate(['/wallet/client/send-zelle']);
      }

    } catch (error: any) {
      if(error.status === 400){
        Swal.fire({
          icon: 'warning',
          title: error.error.title,
          text: error.error.message,
          showCancelButton: false,
          showDenyButton: false,
          confirmButtonText: 'Entendido',
          confirmButtonColor: '#1e5a3f',

        });
      }
      console.log(error);
    }
    this.spinner.stop(ref);
  }

  onSubmit(): void {

  }

  soloLetras(event: KeyboardEvent){
    if (event.key >= '0' && event.key <= '9') {
      event.preventDefault();
    }
  }

  resetIdentificador() {
    this.beneficiarioForm?.get('identificador')?.setValue('');
  }

  getPlaceholder() {
    const tipoBeneficiario = this.beneficiarioForm?.get('tipoBeneficiario')?.value;
    return tipoBeneficiario === '1' ? 'Nombre Empresa' : 'Nombres y Apellidos';
  }

  identifier(event: KeyboardEvent) {
    const char = event.key;
    if (!char.match(/^[0-9a-zA-Z@._-]$/)) {
      event.preventDefault();
    }
  }

  identifierValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value?.trim();
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    const phonePattern = /^[0-9]{10,}$/;
    if (!value) {
      return null;
    }
    if (/[a-zA-Z@._-]/.test(value)) {
      return emailPattern.test(value) ? null : { invalidEmail: true };
    } else {
      return phonePattern.test(value) ? null : { invalidPhone: true, minLength: true };
    }
  }

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const control = this.beneficiarioForm?.get('identificador');
    if (control) {
      control.markAsTouched();
      control.markAsDirty();
      control.setValue(input.value);
    }
  }

}
