import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe, NgIf } from '@angular/common';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxMatFileInputModule } from '@angular-material-components/file-input';
import { SucessDialogComponent } from './components/sucess-dialog/sucess-dialog.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatStepperModule } from '@angular/material/stepper';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { OnlynumberDirective } from './directives/onlynumber.directive';
import { CustomNumberPipePipe } from './pipes/custom-number-pipe.pipe';
import { UtcToLocalTimePipe } from './pipes/utc-to-local-time.pipe';
import { UtcConverterService } from './pipes/utc-converter.service';
import { MatInputCommifiedDirective } from './directives/mat-input-commified.directive';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginatorIntl } from '../dashboard/components/compliance-list/compliance-list.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ConfirmationDialogComponent } from '../dashboard/components/confirmation-dialog-component/confirmation-dialog-component.component';



@NgModule({
  declarations: [SpinnerComponent, SucessDialogComponent, OnlynumberDirective, CustomNumberPipePipe, UtcToLocalTimePipe, MatInputCommifiedDirective],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule, ReactiveFormsModule,
    CarouselModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMatFileInputModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatTableModule,
    MatTabsModule,
    MatCardModule,
    MatCheckboxModule,
    MatStepperModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatSortModule,
    MatPaginatorModule,
    MatTooltipModule
  ],
  exports: [
    CarouselModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMatFileInputModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatTableModule,
    MatTabsModule,
    MatCardModule,
    MatCheckboxModule,
    MatStepperModule,
    MatExpansionModule,
    MatRadioModule,
    MatAutocompleteModule,
    OnlynumberDirective,
    CustomNumberPipePipe,
    UtcToLocalTimePipe,
    MatInputCommifiedDirective,
    MatButtonToggleModule,
    MatSortModule,
    MatPaginatorModule,
    MatTooltipModule
  ],
  providers: [
    DecimalPipe,
    UtcToLocalTimePipe,
    UtcConverterService,
    { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    // { provide: MdDialogRef, useValue: {} }, --> deprecated
    {
      provide: MatDialogRef,
      useValue: {}
    },
  ],
})
export class SharedModule { }
