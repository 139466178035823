import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import { Transaccion } from 'src/app/dashboard/interfaces/transaccion.interface';
import { TransaccionService } from 'src/app/dashboard/services/transaccion.service';
import { EditTransactionModalComponent } from '../../assigned-transactions/edit-transaction-modal/edit-transaction-modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { EditTransactionDTO } from 'src/app/dashboard/interfaces/edit-transaction.dto';
import Swal from 'sweetalert2';
import { AddBeneficiarioCripto } from 'src/app/dashboard/interfaces/add-beneficiario-cripto.interface';
import { RecipientCriptoService } from 'src/app/dashboard/services/recipient-cripto.service';


@Component({
  selector: 'app-edit-zelle-transaction',
  templateUrl: './edit-zelle-transaction.component.html',
  styleUrls: ['./edit-zelle-transaction.component.sass']
})
export class EditZelleTransactionComponent {
  datosForm: FormGroup | undefined;
  tiposID = ['CI', 'PASAPORTE', 'RUT'];
  transaccion: any;
  constructor(
    private auth: CognitoService,
    private readonly transactionService: TransaccionService,
    private fb: FormBuilder,
    private recipientcriptoservice: RecipientCriptoService,
    private spinner: SpinnerService, public dialogRef: MatDialogRef<EditZelleTransactionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any){
      console.log(data);
      this.transaccion = data;
      this.initForm();
  }

  initForm(){
    this.datosForm = this.fb.group({
      nombre: new FormControl( this.transaccion.nombre, [Validators.required, Validators.maxLength(40)]),
      apellido: new FormControl(  this.transaccion.apellidos  , [Validators.required, Validators.maxLength(40)]),
      recepcion: new FormControl('TRC20'),
      medioRecepcion: new FormControl(  this.transaccion.medioRecepcion, [Validators.required, Validators.maxLength(20)]),
    })
  }



  async guardarBeneficiario(){

    if (this.datosForm?.invalid){
      return
    }
    const formValue = this.datosForm?.value;
    const cuentaBeneficiary: AddBeneficiarioCripto = {
      ...formValue,
      medioCriptoId: 1,
      id: this.data.idBeneficiario
    }
    let swalConfig = {}
      swalConfig = {
        icon: 'success',
        title: 'Transacción actualizada',
        text: 'Su transacción fue actualizada con éxito',
        showCancelButton: false,
        showDenyButton: false,
        confirmButtonText: 'Entendido',
        confirmButtonColor: '#1e5a3f',
      };

    const ref = this.spinner.start();
    try{
      const resultado = await this.recipientcriptoservice.saveRecipientAccount(
        cuentaBeneficiary
      );
      Swal.fire(swalConfig);
      this.dialogRef.close(true);
    }catch (error){
    }
    this.spinner.stop(ref);
  }


  cancel(){
    this.dialogRef.close();
  }

  close(){
    this.dialogRef.close();
  }
}

