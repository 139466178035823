<div class="m-8">
  <div class="flex flex-row flex-wrap justify-between items-center">
    <h1 class="text-xl font-bold">Cuentas de trabajo</h1>
    <button mat-button (click)="openCreateDialog()">
      <mat-icon color="primary" class="add-icon">add_box</mat-icon>
      <span>Agregar cuenta de trabajo</span>
    </button>
  </div>
</div>
<app-working-account-list [listaCuentas]="listaCuentas" (seleccion)="onSeleccion($event)" (reloadAccountList)="reloadAccount()"[seleccionable]="seleccionable" (actualizarCuenta)="openCreateDialog($event)"></app-working-account-list>
