<div class="m-8">
  <div class="flex flex-row justify-between items-center">
    <h1 class="text-xl font-bold">Beneficiarios criptomoneda</h1>
    <button mat-button (click)="openCreateDialog()">
      <mat-icon color="primary" class="add-icon">add_box</mat-icon>
      <span>Agregar Beneficiario</span>
    </button>
  </div>
</div>
<app-recipient-cripto-list [listaCuentas]="listaCuentas" (seleccion)="onSeleccion($event)" (reloadAccountList)="reloadAccount()"[seleccionable]="seleccionable" (actualizarCuenta)="openCreateDialog($event)"></app-recipient-cripto-list>
