import * as moment from "moment-timezone";
import { CurrencyPipe } from '@angular/common';

export function getColumns(currencyPipe: CurrencyPipe) {
  return [
    {
      columnDef: 'idTransferencia',
      header: 'ID',
      cell: (element: any) => `${element.idTransferencia}`,
    },
    {
      columnDef: 'fechaInicio',
      header: 'Fecha inicio',
      cell: (element: any) => `${element.fechaInicio ? moment(new Date(element.fechaInicio), 'America/Santiago').format('lll'): ''}`,
    },
    {
      columnDef: 'fechaTermino',
      header: 'Fecha de termino',
      cell: (element: any) => `${element.fechaTermino  ?  moment(new Date(element.fechaTermino), 'America/Santiago').format('lll') : 'No aplica'}`,
    },

    {
      columnDef: 'remitente',
      header: 'Remitente',
      cell: (element: any) => `${element.remitente}`,
    },
    {
      columnDef: 'beneficiario',
      header: 'Beneficiaro',
      cell: (element: any) => `${element.beneficiario ? element.beneficiario : 'No aplica'}`,
    },
    {
      columnDef: 'tn',
      header: 'Tasa de negociación',
      cell: (element: any) => currencyPipe.transform(element.tn, '$'),
    },
    {
      columnDef: 'montoCLP',
      header: 'Monto CLP',
      cell: (element: any) => currencyPipe.transform(element.montoCLP, '$'),
    },
    {
      columnDef: 'montoUSDT',
      header: 'Monto USD',
      cell: (element: any) => currencyPipe.transform(element.montoUSDT, '$'),
    },
    {
      columnDef: 'banco',
      header: 'Banco',
      cell: (element: any) => element.banco,
    },
    {
      columnDef: 'identificador',
      header: 'Direccion',
      cell: (element: any) => `${element.codigoBanco === 'BOFA' ? element.numeroCuenta : element.direccionZelle}`,
    },
    {
      columnDef: 'estadoActual',
      header: 'EstadoActual',
      cell: (element: any) => `${element.estadoActual.replaceAll('_',' ')}`,
    },
  ]

}


export const  displayedColumns: string[] = [
  'idTransferencia',
  'fechaInicio',
  'fechaTermino',
  'remitente',
  'beneficiario',
  'tn',
  'montoCLP',
  'montoUSDT',
  'banco',
  'identificador',
  'estadoActual',
  'acciones',
];
