import { Component } from '@angular/core';
import { BinanceService } from '../../services/binance.service';
import { MatDialog } from '@angular/material/dialog';
import { SetUsdtRateComponent } from './set-usdt-rate/set-usdt-rate.component';
import { MatTableDataSource } from '@angular/material/table';
import {NgIf, DatePipe} from '@angular/common';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
@Component({
  selector: 'app-cripto-rate',
  templateUrl: './cripto-rate.component.html',
  styleUrls: ['./cripto-rate.component.sass']
})
export class CriptoRateComponent {
  tasa: any;
  public currentPage = 1;
  public pageSize = 25;
  public totalItems = 0;
  public totalPages = this.totalItems/this.pageSize;

  displayedColumns: string[] = [

    'fecha',
    'hora',
    'tasa',
  ];

  columns = [
    {
      columnDef: 'fecha',
      header: 'Fecha',
      cell: (element: any) => `${this.datePipe.transform(element.createdAt, 'dd/MM/yyyy')}`,
    },
    {
      columnDef: 'hora',
      header: 'Hora',
      cell: (element: any) => ` ${this.datePipe.transform(element.createdAt, 'hh:mm a', 'America/Santiago')} `,
    },
    {
      columnDef: 'tasa',
      header: 'Tasa de Negociación',
      cell: (element: any) => `${element.tasaUsdtClp ? parseFloat(element.tasaUsdtClp).toFixed(2) : ''} `,
    },
  ];
  dataSource: MatTableDataSource<any> | undefined;

  constructor(private datePipe: DatePipe, private tasasService: BinanceService, private dialog: MatDialog, private spinner: SpinnerService){
      this.initTasaValue();
  }

  async initTasaValue(): Promise<void>{
    const ref = this.spinner.start();
    this.tasa = await this.tasasService.getConversionData();
    const record = await this.tasasService.getConversionValueRecord(this.currentPage,this.pageSize);
    this.dataSource = new MatTableDataSource(record.query);
    this.totalItems = +record.count;
    this.totalPages = Math.ceil (this.totalItems/this.pageSize);
    this.spinner.stop(ref);
  }

  async reload ():Promise<void>{
    this.currentPage = 1;
    this.initTasaValue();
  }

  onPageChange(event: any){
    console.log(event);
    this.pageSize=event.pageSize;
    this.currentPage = event.pageIndex+1;
    this.initTasaValue();
  }

  async setRates(){
    this.dialog.open(SetUsdtRateComponent, {  }).afterClosed().subscribe(res=>{
      if(res && res ===true){
        this.initTasaValue();
      }
    })
  }
}
