import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-sucess-dialog',
  templateUrl: './sucess-dialog.component.html',
  styleUrls: ['./sucess-dialog.component.sass']
})
export class SucessDialogComponent {
  constructor(public dialogRef: MatDialogRef<SucessDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: { message: string, button: string }) {}
}
