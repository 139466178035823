import { Component, OnInit, Input } from '@angular/core';
import { DataTransferencia } from 'src/app/dashboard/interfaces/lista-transferencia.interface';
import { MultipleExchangeService } from 'src/app/dashboard/services/multiple-exchange.service';

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.css'],
})
export class OrdersListComponent implements OnInit {
  constructor(private multipleExchangeService: MultipleExchangeService) {
    this.multipleExchangeService.currentData$.subscribe(res=>{
      this.listaTransferencia = res;
    })
   }
  listaTransferencia: DataTransferencia[] =[];
  displayedColumns: string[] = ['nombre', 'banco', 'cuenta', 'monto_origen', 'monto_destino', 'opciones'];

  deleteElement(index:number){
    this.multipleExchangeService.deleteElement(index);
  }

  ngOnDestroy(): void {
  }

  ngOnInit() {
  }
  
  clear() {
    this.multipleExchangeService.clear();
  }

}
