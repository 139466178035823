
<mat-table class="elements-table mat-elevation px-6 py-4  bg-aguacate-bg rounded-lg " [dataSource]="listaTransferencia"  >
  
  <ng-container matColumnDef="nombre">
      <mat-header-cell *matHeaderCellDef>Nombre del beneficiario</mat-header-cell>
      <mat-cell *matCellDef="let element">{{element.beneficiario.nombre}} {{element.beneficiario.apellidos}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="banco">
      <mat-header-cell *matHeaderCellDef>Banco </mat-header-cell>
      <mat-cell class="description-cell"
                *matCellDef="let element">{{element.beneficiario.banco.nombre}}</mat-cell>

  </ng-container>

  <ng-container matColumnDef="cuenta">
      <mat-header-cell *matHeaderCellDef>No de Cuenta</mat-header-cell>
      <mat-cell class="duration-cell"
                *matCellDef="let element">{{element.beneficiario.numeroCuenta }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="monto_origen">
    <mat-header-cell *matHeaderCellDef>Monto Origen</mat-header-cell>
    <mat-cell class="duration-cell"
              *matCellDef="let element">{{element.cambio.envia |  number : '1.2-2' }} CLP</mat-cell>
</ng-container>
<ng-container matColumnDef="monto_destino">
  <mat-header-cell *matHeaderCellDef>Monto destino</mat-header-cell>
  <mat-cell class="duration-cell"
            *matCellDef="let element">{{element.cambio.recibe |  number : '1.2-2' }} BS</mat-cell>
</ng-container>
<ng-container matColumnDef="opciones">
  <mat-header-cell *matHeaderCellDef>Opciones</mat-header-cell>
  <mat-cell class="duration-cell"
            *matCellDef="let element; let i = index">
            <button mat-icon-button color="accent" aria-label="editar" (click)="deleteElement(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-cell>

</ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

</mat-table>
