<div class="m-4 flex flex-col justify-center  overflow-x-auto" >
  <h1 class="m-4 text-xl font-bold text-center"> Balance cajero </h1>

<div class="w-full flex flex-row justify-around align-middle pr-4">

  <div>
    <mat-form-field class="w-full md:w-96 self-center mr-4">
      <mat-label>Cuenta</mat-label>
      <mat-select
        required
        [(ngModel)]="cuentaSeleccionada"

      >
        <mat-option>Selecione cuenta</mat-option>
        <mat-option
          *ngFor="let cuenta of cuentasTrabajo"
          [value]="cuenta.cuenta_trabajo_id"
        >
          {{ cuenta.nombre  | titlecase }}  {{ cuenta.apellidos  | titlecase }} {{ cuenta.banco?.nombre}} {{cuenta.numeroCuenta}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="mr-4">
      <mat-label>Ingresa un rango de fechas</mat-label>
      <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date">
        <input matEndDate formControlName="end" placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>

      <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
      <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Fecha fin invalida</mat-error>
    </mat-form-field>
    <button  mat-raised-button color="primary" (click)="loadData()"> Buscar
      <mat-icon>
        search
      </mat-icon>
    </button>
  </div>

</div>
<div class="table-container flex overflow-auto">
  <table mat-table [dataSource]="dataSource" class="m-4" *ngIf="dataSource">

    <ng-container
      *ngFor="let column of columns"
      [matColumnDef]="column.columnDef"
    >
      <th mat-header-cell *matHeaderCellDef style="background-color: #CEE9C2;font-weight: bold; text-align:center" >{{column.header}}</th>
      <td mat-cell *matCellDef="let row"  style="text-align:center">{{column.cell(row)}}</td>
    </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
</div>
