import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-rate',
  templateUrl: './admin-rate.component.html',
  styleUrls: ['./admin-rate.component.sass']
})
export class AdminRateComponent {

}
