import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { CuentaRetiro } from 'src/app/dashboard/interfaces/cuenta-retiro.interface';
import { CuentaTrabajo } from 'src/app/dashboard/interfaces/cuenta-trabajo.interface';

@Component({
  selector: 'app-withdraw-account-list',
  templateUrl: './withdraw-account-list.component.html',
  styleUrls: ['./withdraw-account-list.component.sass']
})
export class WithdrawAccountListComponent {
  usuarioID: number | undefined;
  @Output() reloadAccountList: EventEmitter<any> = new EventEmitter();
  @Output() actualizarCuenta: EventEmitter<CuentaRetiro> = new EventEmitter();
  @Output() seleccion: EventEmitter<CuentaRetiro> = new EventEmitter();
  subscriptions: Subscription = new Subscription();
  @Input() listaCuentas: CuentaRetiro[] = [];

  @Input() seleccionable: boolean = false;
  cuentaSeleccionada: CuentaRetiro | undefined;
  constructor(

  ) {
  }


  ngOnInit(): void {

  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  eliminarCuenta(){
    this.reloadAccountList.emit();
  }

  actualizar(cuenta: CuentaRetiro){
    this.actualizarCuenta.emit(cuenta);
  }
}
