export enum ParametrosEnum{
  ACTUALIZACION_USDT = 'ACTUALIZACION_USDT',
  VALOR_SOLVENCIA = 'VALOR_SOLVENCIA',
  VALOR_LIQUIDEZ = 'VALOR_LIQUIDEZ',
  VALOR_SOLVENCIA_FIAT = 'VALOR_SOLVENCIA_FIAT',
  VALOR_LIQUIDEZ_FIAT = 'VALOR_LIQUIDEZ_FIAT',
  DESCUENTO_1 = 'DESCUENTO_1',
  DESCUENTO_2 = 'DESCUENTO_2',
  ACTUALIZACION_VES  = 'ACTUALIZACION_VES',
  CORREOS_AUT = 'CORREOS_AUT',
  PRECIO_SPOT = 'PRECIO_SPOT',
  DESCUENTO_FIAT = 'DESCUENTO_FIAT',
  PORC_VENTA_ZELLE = 'PORC_VENTA_ZELLE',
  DESCUENTO_COM_ZELLE = 'DESCUENTO_COM_ZELLE'
}
