<div class="px-6 py-4 w-full bg-aguacate-bg rounded-lg " [class.bg-aguacate-highlight]="cuenta?.seleccionado">
    <div class="flex flex-row justify-between items-center">
      <div>
        <b>Nombre:</b> {{cuenta?.nombre}} <br>
        <b>Tipo de Beneficiario:</b> {{cuenta?.esEmpresa? 'Empresa':'Individuo'}} <br>
        <b>Banco:</b> {{cuenta?.banco?.nombre}} <br>
        <ng-container *ngIf="cuenta?.banco?.codigoBanco === 'ZELLE'; else bofa">

          <b>Identificador:</b> {{cuenta?.identificador}}  <br>
        </ng-container>
        <ng-template  #bofa >

          <b>Numero de cuenta:</b> {{cuenta?.numeroCuenta}} <br>
          <b>Email/Telefono:</b> {{cuenta?.identificador}}  <br>
        </ng-template>

      </div>
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="verCuenta()">
          <mat-icon>search</mat-icon>
          <span>Ver</span>
        </button>
        <button mat-menu-item (click)="actualizar()">
          <mat-icon>edit</mat-icon>
          <span>Editar</span>
        </button>
        <button mat-menu-item (click)="inactivarCuenta()">
          <mat-icon>delete</mat-icon>
          <span>Eliminar</span>
        </button>
      </mat-menu>
    </div>
  </div>
