import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';1


@Component({
  selector: 'app-dialog-beneficiario-cripto',
  templateUrl: './dialog-beneficiario-cripto.component.html',
  styleUrls: ['./dialog-beneficiario-cripto.component.sass']
})
export class DialogBeneficiarioCriptoComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogBeneficiarioCriptoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ){
    console.log(data);
  }

  onNoClick(){
    this.dialogRef.close()
  }

}
