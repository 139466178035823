<div class="w-[800px] px-16 py-10  rounded-3xl uploader-status flex flex-col items-center justify-center align-middle">

  <h2 class="text-2xl font-bold self-start">Motivo del rechazo de la transferencia
  </h2>
    <form [formGroup]="datosForm" class="w-full my-4" *ngIf="datosForm">
      <div class="grid grid-cols-1 gap-4">
        <mat-form-field>
          <mat-label>Motivo del rechazo:</mat-label>
          <mat-select required formControlName="motivo">
            <mat-option>Motivo del rechazo</mat-option>
            <mat-option *ngFor="let motivo of motivosRechazo" [value]="motivo">
              {{ motivo }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="grid grid-cols-1 gap-4">
        <mat-form-field class="col-span-2">
          <mat-label>Descripción del rechazo:</mat-label>
          <textarea
          maxlength="100"
          #input
            matInput
            formControlName="comentario"
            placeholder="Opcional"
          ></textarea>
          <mat-hint align="end">{{input.value.length}}/100</mat-hint>
        </mat-form-field>
      </div>

    </form>

  <div class="flex flex-row justify-center gap-10 w-full mt-6">
    <button type="button" (click)="rejectTransaction()" [disabled]="datosForm?.invalid" mat-raised-button color="primary" class="mt-4 w-1/2  text-lg rounded-lg px-5 py-6 text-center"
    >
    Rechazado
  </button>
    <button type="button" mat-raised-button color="basic" (click)="close()"
      class=" w-1/2 mt-4  text-lg rounded-lg px-5 py-6 text-center">
      Cancelar
    </button>
  </div>

</div>
