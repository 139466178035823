export function isObjectEmpty(objectName: any) {
  return Object.keys(objectName).length === 0;
}

export function getQueryString(object: any) {
  let queryString = '';

  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      if (queryString.length > 0) {
        queryString += '&';
      }
      queryString += `${encodeURIComponent(key)}=${encodeURIComponent(
        object[key]
      )}`;
    }
  }
  return queryString;
}

export function filterObjKeys(object: any){
  const filteredObject = Object.keys(object).reduce((result: { [key: string]: any }, key) => {
    const value = object[key];
    if (value !== null && value !== undefined && value !== '') {
      result[key] = value;
    }
    return result;
  }, {});
  return filteredObject;
}
