<button class="close"  mat-stroked-button (click)="close()" >Cerrar </button>
<br>
<mat-tab-group class="m-4">
  <mat-tab label="Datos Generales">
    <mat-card *ngIf="pagador" class="mt-4 w-full">
      <mat-card-content>
        <div class="grid grid-cols-3 gap-4">
          <div>
            <span class="font-semibold"> Nombre: <br /></span>
            {{ pagador.nombre }}
          </div>
          <div *ngIf="pagador.tipoUsuarioId===0">
            <span class="font-semibold"> Apellidos: <br /></span>
            {{ pagador.apellidos }}
          </div>
          <div>
            <span class="font-semibold"> Documento: <br /></span>
            {{ pagador.tipoId }} {{ pagador.numeroId }}
          </div>
          <div *ngIf="pagador.tipoUsuarioId===0">
            <span class="font-semibold"> Fecha de nacimiento: <br /></span>
            {{ pagador.fechaNacimiento }}
          </div>
          <div>
            <span class="font-semibold"> Teléfono: <br /></span>
            {{ pagador.prefijoTelefono }} {{ pagador.telefono }}
          </div>
          <div *ngIf="pagador.tipoUsuarioId===0">
            <span class="font-semibold"> Nacionalidad: <br /></span>
            {{ pagador.nacionalidad }}
          </div>

          <div *ngIf="pagador.tipoUsuarioId===0">
            <span class="font-semibold"> País residencia: <br /></span>
            {{ pagador.paisResidencia }}
          </div>
          <div *ngIf="pagador.tipoUsuarioId===0">
            <span class="font-semibold"> Profesion: <br /></span>
            {{ pagador.profesion }}
          </div>
        </div>

        <div class="mt-4 w-full flex flex-col items-center">
          <ng-container *ngIf="mostarFrontal">
            <h2 class="font-bold text-lg">Documento identidad frontal:</h2>
            <img class="sm:w-4/6 md:w-1/2" [src]="fotoIdFrontal" />
          </ng-container>
          <ng-container *ngIf="!mostarFrontal">
            <h2 class="font-bold text-lg">Documento identidad posterior:</h2>
            <img class="sm:w-4/6 md:w-1/2" [src]="fotoIdPosterior" />
          </ng-container>
          <div>
            <button
              class="mt-4"
              mat-raised-button
              color="primary"
              (click)="mostarFrontal = !mostarFrontal"
            >
              <mat-icon> rotate_right</mat-icon>
              Girar
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-tab>
  <mat-tab label="KYC">
    <mat-card *ngIf="pagador" class="mt-4">


     <mat-card-content>

        <div>
          <form
            class="grid grid-cols-2 gap-4"
            [formGroup]="formCompliance"
            *ngIf="formCompliance"
          >
            <div class="grid grid-cols-1">
              <div  >
                <span class="font-semibold"> Estado Compliance: <br /></span>
                {{pagador.estadoCompliance.descripcion}}
              </div>
              <div class="flex flex-row gap-4">
                <button
                class="mt-4"
                mat-raised-button
                color="primary"
                (click)="aproveCompliance()" [disabled]="pagador.estadoComplianceID === 3"
              >
                <mat-icon> check</mat-icon>
                Aprobar
              </button>
              <button
              class="mt-4"
              mat-raised-button
              color="warn"
              (click)="rejectUser()"
              [disabled]="pagador.estadoComplianceID === 1"
            >
              <mat-icon> cancel</mat-icon>
              Rechazar
            </button>
            <button
            class="mt-4"
            mat-raised-button
            color="warn"  [disabled]="pagador.estadoComplianceID === 2"
            (click)="backlistUser()"
          >
            <mat-icon> error</mat-icon>
            Black list
          </button>
              </div>
            </div>
            <div>
              <p>
                <mat-checkbox formControlName="politicamenteExpuesto"
                  >PEP</mat-checkbox
                >
              </p>
              <p><mat-checkbox formControlName="esRTP">RTP</mat-checkbox></p>
              <p>
                <mat-checkbox formControlName="esListaONU"
                  >LISTA ONU</mat-checkbox
                >
              </p>
              <mat-hint class="text-sm" *ngIf="pagador.fuenteComplianceID === 2">
                *Información traída de REGCHEQ
              </mat-hint>
              <div>
                <button
                class="mt-4"
                mat-raised-button
                color="primary"
                (click)="guardarCompliance()"

              >
                <mat-icon> check</mat-icon>
                Guardar
              </button>
              </div>

            </div>
          </form>


        </div>
      </mat-card-content>
    </mat-card>

    <div></div>
  </mat-tab>
  <mat-tab label="Datos Residenciales"  *ngIf="pagador?.tipoUsuarioId===0">
    <mat-card *ngIf="pagador" class="mt-4">
      <mat-card-content>
        <div class="grid grid-cols-1 gap-4">
          <div>
            <span class="font-semibold"> País residencia: <br /></span>
            {{ pagador.paisResidencia }}
          </div>
          <div>
            <span class="font-semibold"> Ciudad de residencia: <br /></span>
            {{ pagador.ciudadResidencia }}
          </div>
          <div>
            <span class="font-semibold"> Dirección: <br /></span>
            {{ pagador.direccion }}
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <div></div>
  </mat-tab>
  <mat-tab label="Datos Empresariales"  *ngIf="pagador?.tipoUsuarioId===1">
  <mat-card-content>
    
    <div class="datos grid grid-cols-3 gap-4">
      <div>
        <span class="font-semibold"> Razon Social: <br /></span>
        {{ pagador?.razonSocial }}
      </div>
      <div>
        <span class="font-semibold"> Rut: <br /></span>
        {{ pagador?.rutEmpresa }}
      </div>
      <div>
        <span class="font-semibold"> Nombre Comercial: <br /></span>
        {{ pagador?.nombreComercial }}
      </div>
      <div>
        <span class="font-semibold"> Domicilio Fiscal: <br /></span>
        {{ pagador?.domicilioFiscal }}
      </div>
    </div>

    <div class="documentos">
      <mat-card *ngIf="pagador" class="mt-4">
        <div class="mt-4 w-full flex flex-col items-center">
          <ng-container *ngIf="pagador.estatuto">
            <button mat-raised-button color="primary" (click)="downloadPDF(estatuto)" class="my-4">Descargar Estatuto</button>
          </ng-container>
          <ng-container *ngIf="pagador.e_rut">
            <button mat-raised-button color="primary" (click)="downloadPDF(erut)" class="my-4">Descargar Rut</button>
          </ng-container>
          <ng-container *ngIf="pagador.documentoId">
            <button mat-raised-button color="primary" (click)="downloadPDF(documentoIdTributario)" class="my-4">Descargar Documento ID Tributario</button>
          </ng-container>
        </div>
      </mat-card>  
    </div>

  </mat-card-content>
  </mat-tab>
</mat-tab-group>
