<div class="px-16 py-10  rounded-3xl uploader-status flex flex-col items-center justify-center align-middle">
  <h2 style="text-align: center;" class="text-lg font-bold">Asignación Puntos Spot</h2>

  <form class="w-full flex flex-col" style="margin: 20px; padding: 10px;">

    <mat-form-field appearance="fill">
      <mat-label>Puntos spot</mat-label>
      <input matInput appOnlynumber type="number" step="0.1" placeholder="Ej: 0.1" name="spotPoints"  [(ngModel)]="spotPoints">
    </mat-form-field>
  </form>
  <button color="primary" class="w-1/2 py-2.5" mat-flat-button (click)="editSegmentation()">Asignar</button>

  </div>
