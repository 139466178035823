import { Component } from '@angular/core';
import { CognitoService, IUser } from 'src/app/auth/services/cognito.service';
import { BinanceService } from '../../services/binance.service';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent {
  loading: boolean;
  user: IUser;

  constructor(private cognitoService: CognitoService) {
    this.loading = false;
    this.user = {} as IUser;

  }

  public ngOnInit(): void {
    this.cognitoService.getSession().then((session: any) => {
      console.log(session);
    })
    this.cognitoService.getUser()
    .then((user: any) => {
      this.user = user.attributes;
    });
  }

  public update(): void {
    this.loading = true;

    this.cognitoService.updateUser(this.user)
    .then(() => {
      this.loading = false;
    }).catch(() => {
      this.loading = false;
    });
  }
}
