import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CuentaBeneficiario } from '../interfaces/cuenta-beneficiario.interface';

@Injectable({
  providedIn: 'root'
})
export class AccountRecipientService {
  API_URL = environment.apiGatewayURL;

  constructor(private http: HttpClient) {}

  saveRecipientAccount(recipientAccount: CuentaBeneficiario): Promise<CuentaBeneficiario> {
    const serviceURL = `${this.API_URL}recipient/fiat/account-recipient`;
    return firstValueFrom(this.http.post<CuentaBeneficiario>(serviceURL,recipientAccount));
  }
  getByUser(userId: number): Promise<CuentaBeneficiario[]>{
    const serviceURL = `${this.API_URL}recipient/fiat/account-recipient/${userId}`;
    return firstValueFrom(this.http.get<CuentaBeneficiario[]>(serviceURL));
  }
  inactivateAccount(accountId: number): Promise<CuentaBeneficiario>{
    const serviceURL = `${this.API_URL}recipient/fiat/inactivate-recipient-account`;
    return firstValueFrom(this.http.post<CuentaBeneficiario>(serviceURL, {cuentaId: accountId}));
  }

}
