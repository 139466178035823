<div class="wrapper">
  <owl-carousel-o [options]="customOptions">

    <ng-container *ngFor="let slide of photoList">
      <ng-template class="slide" carouselSlide [id]="slide.id">
        <img [src]="slide.download_url" [alt]="slide.id" [title]="slide.id">
      </ng-template>
    </ng-container>

  </owl-carousel-o>
</div>
