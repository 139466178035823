import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { ErrorTypes } from 'src/app/shared/enums/error-types.enum';
import { ActionsService } from 'src/app/shared/services/actions.service';
import { MatStepper } from '@angular/material/stepper';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { CreditLineService } from '../../services/credit-line.service';

@Component({
  selector: 'app-credit-line-dialog',
  templateUrl: './credit-line-dialog.component.html',
  styleUrls: ['./credit-line-dialog.component.sass']
})
export class CreditLineDialogComponent {
  createForm: FormGroup | undefined = undefined;
  monto=0;
  @ViewChild('stepper') stepper: MatStepper | undefined;
  constructor(
    private actionService: ActionsService,
    private dialogRef: MatDialogRef<CreditLineDialogComponent>,
    private spinnerService: SpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private lineaCreditoService: CreditLineService,
  ) {
    console.log(data);
    if(data.lineaCredito){
      this.monto=data.lineaCredito?.lineaCredito;
    }
    this.createForm = data.createForm || this.fb.group({
      lineaCredito: new FormControl('', [Validators.required]),
    });
    this.createForm?.get('usuarioId')?.disabled;
    this.createForm?.get('totalDest')?.disabled;

  }

  onSubmit() {
    this.recordLineaCredito();
  }

  async recordLineaCredito(){
    const ref = this.spinnerService.start();   
    try{
      const resultadoLineaCredito = await this.lineaCreditoService.saveCreditLine(this.data.user,this.createForm?.get('lineaCredito')?.value);
      this.spinnerService.stop(ref);
      Swal.fire('Línea de Credito Creada', `Se ha creado la línea de credito con éxito`, 'success' );
      this.dialogRef.close();
    }catch(error: any){
      if(error.status === 400 && error.error.type === ErrorTypes.ERROR_CREANDO){
        this.spinnerService.stop(ref);
          if(this.stepper){
            this.actionService.emitirAccionTasaFiat();
            this.stepper.reset();
          }
          this.dialogRef.close();
      }else{
        Swal.fire('Error al crear linea de credito',error.error.title, 'warning').then(()=>{
          this.spinnerService.stop(ref);
          this.dialogRef.close();
        });
      }

    }
  }

  closeDialog(){
    this.dialogRef.close();
  }
}
