import * as moment from "moment-timezone";
import { CurrencyPipe } from '@angular/common';

export function getColumns(currencyPipe: CurrencyPipe) {
  return [
    {
      columnDef: 'id',
      header: 'ID',
      cell: (element: any) => `${element.id}`,
    },
    {
      columnDef: 'createdAt',
      header: 'Fecha creación',
      cell: (element: any) => `${element.createdAt ? moment(new Date(element.createdAt), 'America/Santiago').format('lll'): ''}`,
    },
    
    {
      columnDef: 'userId',
      header: 'Nombre usuario',
      cell: (element: any) => `${element.nombreUsuario} ${element.apellidosUsuario}`,
    },
    {
      columnDef: 'cuentaRetiroId',
      header: 'Alias cuenta retiro',
      cell: (element: any) => `${element.alias}`,
    },
  
    {
      columnDef: 'valorRetiro',
      header: 'Monto retiro',
      cell: (element: any) => currencyPipe.transform(element.totalMonedaOrigen, '$'),
    },
  
    {
      columnDef: 'bancoDestino',
      header: 'Banco destino',
      cell: (element: any) => `${element.bancoNombre}`,
    },
    {
      columnDef: 'cuentaDestino',
      header: 'Cuenta destino',
      cell: (element: any) => `${element.tipoCuentaNombre} ${element.numeroCuenta}`,
    },
    {
      columnDef: 'identificacion',
      header: 'Identificación',
      cell: (element: any) => `${element.tipoIdentificacion} ${element.numeroId}`,
    }
    
  ]

}

export const  columns = [
  {
    columnDef: 'id',
    header: 'ID',
    cell: (element: any) => `${element.id}`,
  },
  {
    columnDef: 'createdAt',
    header: 'Fecha creación',
    cell: (element: any) => `${element.createdAt ? moment(new Date(element.createdAt), 'America/Santiago').format('lll'): ''}`,
  },
  
  {
    columnDef: 'userId',
    header: 'Nombre usuario',
    cell: (element: any) => `${element.nombreUsuario} ${element.apellidosUsuario}`,
  },
  {
    columnDef: 'cuentaRetiroId',
    header: 'Alias cuenta retiro',
    cell: (element: any) => `${element.alias}`,
  },

  {
    columnDef: 'valorRetiro',
    header: 'Monto origen',
    cell: (element: any) => `${element.totalMonedaOrigen}`,
  },

  {
    columnDef: 'bancoDestino',
    header: 'Banco destino',
    cell: (element: any) => `${element.bancoNombre}`,
  },
  {
    columnDef: 'cuentaDestino',
    header: 'Cuenta destino',
    cell: (element: any) => `${element.tipoCuentaNombre} ${element.numeroCuenta}`,
  },
  {
    columnDef: 'identificacion',
    header: 'Identificación',
    cell: (element: any) => `${element.tipoIdentificacion} ${element.numeroId}`,
  }

];

export const  displayedColumns: string[] = [
  'id',
  'createdAt',
  'userId',
  'cuentaRetiroId',
  'valorRetiro',
  'bancoDestino',
  'cuentaDestino',
  'identificacion',
  'acciones',

];
