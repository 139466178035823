<div class="p-6 space-y-4 md:space-y-6 sm:p-8">
    <div class="container flex flex-col items-center">
      <h1 class="text-xl font-bold">
        Editar transaccion # {{data.idTransferencia}}
      </h1>
    </div>
    <form
      [formGroup]="datosForm "
      (ngSubmit)="guardarBeneficiario()"
      class="space-y-4 md:space-y-6"
      *ngIf="datosForm">
      <div class="grid grid-cols-1 gap-4">
        <input
          formControlName="nombre"
          type="text"
          pattern="[ a-zA-Z]*"
          placeholder="Nombres">
  
          <mat-error *ngIf="this.datosForm.get('nombre')!.hasError('required')">
            ¡Este campo es obligatorio!
         </mat-error>
          <mat-error *ngIf=" this.datosForm.get('nombre')!.hasError('maxlength')">
            Los caracteres no pueden ser mayor a 40
         </mat-error>
      </div>
      <div class="grid grid-cols-1 gap-4">
        <input
          formControlName="apellido"
          type="text"
          pattern="[ a-zA-Z]*"
          placeholder="Apellidos"
        >
          <mat-error *ngIf="this.datosForm.get('apellido')!.hasError('required')">
            ¡Este campo es obligatorio!
         </mat-error>
          <mat-error *ngIf=" this.datosForm.get('apellido')!.hasError('maxlength')">
            Los caracteres no pueden ser mayor a 40
         </mat-error>
      </div>
      <div class="grid grid-cols-1 gap-4">
        <select formControlName="recepcion">
          <option value="TRC20">RED Tron (TRC 20)</option>
        </select>
      </div>
      <div class="grid grid-cols-1 gap-4">
        <input
          type="text"
          formControlName="medioRecepcion"
          placeholder="Wallet TRX">
  
          <mat-error *ngIf=" this.datosForm.get('medioRecepcion')!.hasError('required')">
            ¡Este campo es obligatorio!
         </mat-error>
         <mat-error *ngIf=" this.datosForm.get('medioRecepcion')!.hasError('maxlength')">
          La longitud no puede ser mayor a 20
       </mat-error>
      </div>
      <div class="container flex flex-row gap-4 items-center justify-center">
        <button mat-raised-button type="button" (click)="cancel()">Cancelar</button>
        <button mat-raised-button type="submit" color="primary">Guardar</button>
      </div>
    </form>
  </div>
  
  