import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ComentarioCompliance } from 'src/app/dashboard/interfaces/comentario-compliance';
import { UserService } from 'src/app/dashboard/services/user.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-compliance-dialog',
  templateUrl: './compliance-dialog.component.html',
  styleUrls: ['./compliance-dialog.component.sass']
})
export class ComplianceDialogComponent {
  formCompliance: FormGroup;
  comentariosCompliance: ComentarioCompliance[] = [];
  constructor(
  public dialogRef: MatDialogRef<ComplianceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any, private fb: FormBuilder,   private userService: UserService,  private spinner: SpinnerService,
    private dialog: MatDialog) {
      this.comentariosCompliance = data.comentariosCompliance;
      this.comentariosCompliance = this.comentariosCompliance.filter(res=> res.comentarioID !==0)
      this.formCompliance = this.fb.group({
        comentarioCompliance: new FormControl(
          null,
          [Validators.required]
        ),
    })
  }

    onNoClick(){
      this.dialogRef.close()
    }

    rechazarUsuario(){
      if(this.formCompliance.get('comentarioCompliance')?.invalid)return;

      const ref = this.spinner.start();
      this.userService
      .updateUserCompliance(
        this.data.user.email,
        this.data.userEmail,
       {comentarioCompliance: this.formCompliance.get('comentarioCompliance')?.value,
       estadoCompliance: 1}
      )
      .subscribe(
        (res) => {
          Swal.fire({
            icon: 'success',
            title: 'Compliance actualizado',
            text: 'Se rechazado el usuario',
            showCancelButton: false,
            showDenyButton: false,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: '#1e5a3f',

          });
          this.spinner.stop(ref);
          this.dialogRef.close(true);
        },
        (err) => {
          this.spinner.stop(ref);
          this.dialogRef.close(false);

        }


      );
    }
}
