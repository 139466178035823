import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Country, Pais } from '../interfaces/country.interface';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  API_URL = environment.apiGatewayURL;
  constructor(private http: HttpClient) {}
  getCountryList() {
    return this.http.get<Country[]>('assets/data/world.json');
  }

  getActiveCountries(): Promise<Pais[]> {
    const serviceURL = `${this.API_URL}general-types/countries`;
    return firstValueFrom(this.http.get<Pais[]>(serviceURL));
  }
}
