import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { DialogBeneficiaryZelleComponent } from '../../dialog-beneficiary-zelle/dialog-beneficiary-zelle.component';
import { RecipientZelleService } from 'src/app/dashboard/services/recipient-zelle.service';
import { BeneficiarioZelle } from 'src/app/dashboard/interfaces/beneficiario-zelle.interface';

@Component({
  selector: 'app-recipient-zelle-card',
  templateUrl: './recipient-zelle-card.component.html',
  styleUrls: ['./recipient-zelle-card.component.sass']
})
export class RecipientZelleCardComponent implements OnInit {
  @Input() cuenta: BeneficiarioZelle | undefined;
  @Output() deleteAccount: EventEmitter<any> = new EventEmitter();
  @Output() actualizarCuenta: EventEmitter<BeneficiarioZelle> = new EventEmitter();
  subscriptions: Subscription = new Subscription();
  public user: any;

  constructor(
    private accountRecipientService: RecipientZelleService,
    private spinner: SpinnerService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private store: Store<AppState>) { }

  ngOnInit() {
    this.subscriptions.add(
      this.store.select('auth').subscribe(({ user }: any) => {
        this.user = user;
      })
    );
  }


  async verCuenta(){
    this.dialog.open(DialogBeneficiaryZelleComponent, {
      data: this.cuenta,
      disableClose: true,
    });
  }


  async inactivarCuenta(){
    if( !(this.cuenta && this.cuenta.id))return;
    const resullt = await Swal.fire({
      title: '¿Está seguro de que desea desvincular esta cuenta?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: `No`,
      confirmButtonColor: '#1e5a3f',
    });
    if(resullt.isDenied || !resullt.isConfirmed){
      return;
    }
    const spinnerRef  = this.spinner.start();
    try {
      console.log(this.cuenta.id);
      await this.accountRecipientService.inactivateAccount(this.cuenta.id);
      this.snackBar.open('Cuenta eliminada correctamente', 'Ok', {
        duration: 3000
      });
      this.deleteAccount.emit();
    } catch (error) {
      console.log(error);
    }
    this.spinner.stop(spinnerRef);

  }

  actualizar(){
    this.actualizarCuenta.emit(this.cuenta);
  }
}
