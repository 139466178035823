import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TipoCuenta } from '../interfaces/tipo-cuenta.interface';

@Injectable({
  providedIn: 'root',
})
export class AccountTypeService {
  API_URL = environment.apiGatewayURL;

  constructor(private http: HttpClient) {}

  getAccountTypes(countryCode: string): Promise<TipoCuenta[]> {
    const serviceURL = `${this.API_URL}general-types/account-types/${countryCode}`;
    return firstValueFrom(this.http.get<TipoCuenta[]>(serviceURL));
  }
}
