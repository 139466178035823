import { Component, Inject} from '@angular/core';
import { UserService } from '../../services/user.service';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import {} from '@aws-amplify/ui-angular';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { S3 } from 'aws-sdk';
import { VariablesComplianceService } from '../../services/variables-compliance.service';
import { ComentarioCompliance } from '../../interfaces/comentario-compliance';
import { EstadoCompliance } from '../../interfaces/estado-compliance';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { PagadorService } from '../../services/pagador.service';
import { Pagador } from '../../interfaces/pagador';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { RejectCompliancePagadorComponent } from './reject-compliance-pagador/reject-compliance-pagador.component';

export interface Image {
  key: string;
  url: string;
}
@Component({
  selector: 'app-pagador-compliance',
  templateUrl: './pagador-compliance.component.html',
  styleUrls: ['./pagador-compliance.component.sass']
})
export class PagadorComplianceComponent {
  images: Image[] = [];
  updateParent= false;
  id: string = '';
  user: any;
  userEmail: string = '';
  fotoIdFrontal: string = '';
  fotoIdPosterior: string = '';
  estatuto: string = '';
  erut: string = '';
  documentoIdTributario: string = '';
  mostarFrontal: boolean = true;
  comentariosCompliance: ComentarioCompliance[] = [];
  estadosCompliance: EstadoCompliance[] = [];
  formCompliance: FormGroup | undefined;
  estadoComplianceBase: Object = {};
  pagador: Pagador | undefined;  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: SpinnerService,
    private fb: FormBuilder,
    private varCompliance: VariablesComplianceService,
    private http: HttpClient,
    private cognito: CognitoService,
    private userService: UserService,
    private pagadorService: PagadorService,
    private dialog: MatDialog,
    private reference: MatDialogRef<PagadorComplianceComponent>,
  ) {
    this.pagador = data.pagador;
    console.log(this.data);
    this.loadUserEmail();
    this.getVariablesCompliance();
    this.loadPagador();
  }
  
  async refreshPagador(){
    this.pagador = await this.pagadorService.getPagador(this.pagador?.pagadorId ?? '');
  }

  loadPagador() {
    console.log(this.pagador);
    this.getIdImages();
    this.formCompliance = this.fb.group({
      estadoCompliance: new FormControl(this.pagador?.estadoComplianceID, [
        Validators.required,
      ]),
      comentarioCompliance: new FormControl(
        this.pagador?.comentarioComplianceID,
        [Validators.required]
      ),
      esListaONU: new FormControl(
        {
          value: this.pagador?.esListaONU,
          disabled: this.pagador?.fuenteComplianceID === 2,
        },
        [Validators.required]
      ),
      politicamenteExpuesto: new FormControl(
        {
          value: this.pagador?.esPoliticamenteExpuesto,
          disabled: this.pagador?.fuenteComplianceID === 2,
        },
        [Validators.required]
      ),
      esRTP: new FormControl(
        {
          value: this.pagador?.esRTP,
          disabled: this.pagador?.fuenteComplianceID === 2,
        },
        [Validators.required]
      ),
    });
  }
  async loadUserEmail() {
    const userInSession = await this.cognito.getUser();
    this.userEmail = userInSession.attributes.email;
    console.log(this.userEmail);
  }

  async getVariablesCompliance() {
    [this.comentariosCompliance, this.estadosCompliance] = await Promise.all([
      this.varCompliance.getComentariosCompliance(),
      this.varCompliance.getEstadosCompliance(),
    ]);
    console.log(this.comentariosCompliance);
    console.log(this.estadosCompliance);
  }

  close(){
    this.reference.close(this.updateParent);
  }

  async getIdImages() {
    const s3 = new S3({
      accessKeyId: environment.s3.accessKeyId,
      secretAccessKey: environment.s3.secretAccessKey,
    });
    if(this.pagador?.fotoIdfrontal){
      const paramsFrontal = {
        Bucket: environment.s3.bucket,
        Key: this.pagador?.fotoIdfrontal,
        Expires: 3600,
      };
      const signedURLFrontal = s3.getSignedUrl('getObject', paramsFrontal);
      this.http
      .get(signedURLFrontal, { responseType: 'blob' })
      .subscribe((response) => {
        const reader = new FileReader();
        reader.readAsDataURL(response);
        reader.onloadend = () => {
          this.fotoIdFrontal = reader.result as string;
        };
      });
    }
    if(this.pagador?.fotoIdPosterior){
      const paramsPosterior = {
        Bucket: environment.s3.bucket,
        Key: this.pagador?.fotoIdPosterior,
        Expires: 3600,
      };
      const signedURLPosterior = s3.getSignedUrl('getObject', paramsPosterior);
      this.http
      .get(signedURLPosterior, { responseType: 'blob' })
      .subscribe((response) => {
        const reader = new FileReader();
        reader.readAsDataURL(response);
        reader.onloadend = () => {
          this.fotoIdPosterior = reader.result as string;
        };
      });
    }

    if(this.pagador?.estatuto){
      const paramsEstatuto = {
        Bucket: environment.s3.bucket,
        Key: this.pagador?.estatuto,
        Expires: 3600,
      };
      this.estatuto = s3.getSignedUrl('getObject', paramsEstatuto);
    }
    
    if(this.pagador?.e_rut){
      const paramsERut = {
        Bucket: environment.s3.bucket,
        Key: this.pagador?.e_rut,
        Expires: 3600,
      };
      this.erut = s3.getSignedUrl('getObject', paramsERut);
    }
    
    if(this.pagador?.documentoId){
      const paramsDocumentoTributario = {
        Bucket: environment.s3.bucket,
        Key: this.pagador?.documentoId,
        Expires: 3600,
      };
      this.documentoIdTributario = s3.getSignedUrl('getObject', paramsDocumentoTributario);    
    }
  }
  async ngOnInit() {}

  async fileCheckType(file: File) {
    let isValid = false;
    const isPNG = this.check([0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a]);
    const isJPEG = this.check([0xff, 0xd8, 0xff]);
    const buffers: Buffer = await this.readBuffer(file, 0, 8);
    const uint8Array = new Uint8Array(buffers);
    if (isPNG(uint8Array)) {
      isValid = true;
    }
    if (isJPEG(uint8Array)) {
      isValid = true;
    }
    return isValid;
  }

  readBuffer(file: File, start = 0, end = 2): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file.slice(start, end));
    });
  }

  check(headers: any) {
    return (buffers: any, options = { offset: 0 }) =>
      headers.every(
        (header: any, index: any) => header === buffers[options.offset + index]
      );
  }

  downloadPDF(url: string) {
    window.open(url, '_blank');
  }

  guardarCompliance() {
    if (this.formCompliance?.invalid || !this.formCompliance?.touched) {
      alert('agrega algo');
      return;
    }
    const ref = this.spinner.start();
    this.pagadorService
      .updateUserChecks(
        this.pagador?.pagadorId ?? '',
        this.userEmail,
        this.formCompliance.value
      )
      .subscribe(
        (res) => {
        //  this.loadUser();
          this.spinner.stop(ref);
          this.refreshPagador();
          this.updateParent = true;
        },
        (err) => this.spinner.stop(ref)
      );
  }

  aproveCompliance(){
    const ref = this.spinner.start();
    this.pagadorService
    .updateUserCompliance(
      this.pagador?.pagadorId ?? '',
      this.userEmail,
     {comentarioCompliance: 0,
     estadoCompliance: 3}
    )
    .subscribe(
      (res) => {
       // this.loadUser();
        Swal.fire({
          icon: 'success',
          title: 'Compliance actualizado',
          text: 'Se ha aprobado el pagador con éxito',
          showCancelButton: false,
          showDenyButton: false,
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#1e5a3f',

        });
        this.spinner.stop(ref);
        this.updateParent = true;
        this.refreshPagador();
      },
      (err) => this.spinner.stop(ref)
    );
  }

  backlistUser(){
    const ref = this.spinner.start();
    this.pagadorService
    .updateUserCompliance(
      this.pagador?.pagadorId ?? '',
      this.userEmail,
     {comentarioCompliance: 0,
     estadoCompliance: 2}
    )
    .subscribe(
      (res) => {
        //this.loadUser();
        Swal.fire({
          icon: 'success',
          title: 'Compliance actualizado',
          text: 'Se ha marcado el usuario como blacklist',
          showCancelButton: false,
          showDenyButton: false,
          confirmButtonText: 'Aceptar',
          confirmButtonColor: '#1e5a3f',

        });
        this.updateParent = true;
        this.spinner.stop(ref);
        this.refreshPagador();
      },
      (err) => this.spinner.stop(ref)
    );
  }
  rejectUser(){
   this.dialog.open(RejectCompliancePagadorComponent, {
    data: {
      userEmail: this.userEmail,
      pagadorId: this.pagador?.pagadorId,
      comentariosCompliance: this.comentariosCompliance
    }
   }).afterClosed().subscribe(res=> {
    if(res === true){
      this.updateParent = true;
      this.refreshPagador();
     // this.loadUser();
    }
   })
  }

}
