<div class="w-full p-5 md:p-0 md:w-1/2 mx-auto mt-10">
  <h1 class="text-2xl font-bold text-center"> Enviar criptomonedas </h1>
  <br>
  <app-usdt-change [usdt_clp_tax]="usdt_clp_tax" class="usdtChange"></app-usdt-change>
  <div class="flex flex-row w-full">
    <div class="flex flex-col xl:flex-row place-items-center balance_container w-full px-4">
      <div class="mx-auto">
      <p class="font-bold">Saldo Actual</p>
      <h2 [ngStyle]="{ display: hasLoaded ? '' : 'none' }" class="mb-2 font-bold">${{ saldoActual | currency:'':'' }} CLP</h2>
      <img *ngIf="!hasLoaded" src="assets/loader.svg" height="60" width="60" class="mb-3 mx-auto" alt="">
      </div>
      <div *ngIf="lineaCredito!>0&&!lineaCreditoBloqueada"ngClass="mx-auto">
      <p class="font-bold">Saldo Contable</p>
      <h2 [ngStyle]="{ display: hasLoaded ? '' : 'none' }" class="mb-2 font-bold">${{ saldoContable | currency:'':'' }} CLP</h2>
      <img *ngIf="!hasLoaded" src="assets/loader.svg" height="60" width="60" class="mb-3 mx-auto" alt="">
      </div>
      <div *ngIf="lineaCredito!>0&&!lineaCreditoBloqueada"ngClass="mx-auto">
        <p class="font-bold">Sobregiro Disponible</p>
        <h2 [ngStyle]="{ display: hasLoaded ? '' : 'none' }" class="mb-2 font-bold">${{ sobregiroDisponible | currency:'':'' }} CLP</h2>
        <img *ngIf="!hasLoaded" src="assets/loader.svg" height="60" width="60" class="mb-3 mx-auto" alt="">
      </div>
      <mat-error *ngIf="lineaCreditoBloqueada">Su linea de credito se encuentra actualmente bloqueada</mat-error>
    </div>
  </div>
  <div class="flex flex-row justify-between items-center place-content-center gap-4 mx-5 mt-8">
    <button mat-raised-button color="primary" class="p-5 text-base  py-7 w-1/2" routerLink="/wallet/client/rechargeAccount">
      Recargar Saldo
    </button>
    <button  mat-raised-button color="primary" routerLink="/wallet/client/send-cripto" class=" w-1/2 p-5 py-7 text-base">
      Comprar Criptomoneda
    </button>
  </div>

</div>
