<div class="w-[800px] px-16 py-10  rounded-3xl uploader-status flex flex-col items-center justify-center align-middle">

  <h2 class="text-2xl font-bold self-start">Marcar operación como transferencia enviada
  </h2>

  <h3 class="text-lg font-bold mt-8  self-start">Id transacción: {{transaction?.id}}</h3>
  <h3 class="text-lg font-bold  self-start">Usuario: {{transaction?.nombreUsuario}} {{transaction?.apellidosUsuario}}</h3>
  <h3 class="text-lg font-bold  self-start">Email: {{transaction?.emailUsuario}}</h3>
  <h3 class="text-lg font-bold  self-start">RUT: {{transaction?.numeroId}}</h3>
  <h3 class="text-lg font-bold  self-start">Banco: {{transaction?.bancoNombre}} </h3>
  <h3 class="text-lg font-bold  self-start">Cuenta: {{transaction?.tipoCuentaNombre}} {{transaction?.numeroCuenta}}</h3>
  <h3 class="text-lg font-bold  self-start">Monto: {{transaction?.totalMonedaDestino | currency:'':''}}  {{transaction?.monedaDestino | currency:'':''}}</h3>
    <form [formGroup]="datosForm" class="w-full my-4" *ngIf="datosForm">
      <div class="grid grid-cols-1 gap-4">
        <mat-form-field class="col-span-2">
          <mat-label>Referencia:</mat-label>
          <input
            type="text"
            matInput
            formControlName="referencia"
            placeholder="Opcional"
          />
        </mat-form-field>
      </div>

    </form>

  <div class="flex flex-row justify-center gap-10 w-full mt-6">
    <button type="button" (click)="acceptTransaction()" [disabled]="datosForm?.invalid" mat-raised-button color="primary" class="mt-4 w-1/2  text-lg rounded-lg px-5 py-6 text-center"
    >
    Pagado
  </button>
    <button type="button" mat-raised-button color="basic" (click)="close()"
      class=" w-1/2 mt-4  text-lg rounded-lg px-5 py-6 text-center">
      Cancelar
    </button>
  </div>

</div>
