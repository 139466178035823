<div class="m-8">
    <div class="flex flex-col justify-between items-center bg-red">
      <div class="mb-10">
        <h1 class="text-xl font-bold">Elige Cuenta de retiro</h1>
      </div>
      <div class="w-full grid grid-cols-4 gap-4">
        <input class="inputSearch w-full col-span-4 md:col-span-3" (keyup)="buscar($event)" [(ngModel)]="searchTxt" placeholder="Buscar" type="text">
        <button mat-button class="w-full col-span-4 md:col-span-1 py-4" (click)="openCreateDialog()">
          <mat-icon color="primary" class="add-icon w-auto ">add_box</mat-icon>
          <span>Agregar Cuenta</span>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="accountsListFiltered && accountsListFiltered.length > 0" class="flex flex-col gap-4 p-4">
      <div *ngFor="let account of accountsListFiltered" class="px-6 py-4 w-full bg-aguacate-bg rounded-lg " (click)="elegir(account)" [class.bg-aguacate-highlight]="accountSelected === account">
        <div class="flex flex-row justify-between items-center">
            <div>
                <b>Titular</b> - {{ account.alias }}<br>
                <b>Banco</b> - {{ account.banco }}<br>
                <b>Tipo de cuenta</b> - {{ account.tipoCuenta }}<br>
                <b>Numero de cuenta</b> - {{ account.numeroCuenta }}<br>
            </div>
        </div>
      </div>
  </div>
  <div class="flex flex-row gap-4 justify-center items-center my-7">
    <button
      mat-raised-button color="primary"
      class="w-40 md:w-30 mt-10 p-5 text-lg"
      type="button"
      (click)="goToBack()">
      Volver
      </button>
     <button
      type="button"
      mat-raised-button
      color="primary"
      class="mt-10 p-5 text-lg"
      (click)="emitChangeStep()"
      [disabled]="accountSelected == undefined"
    >
     Continuar
    </button>
  </div>
  