<mat-stepper #stepper class="pt-4 py-4" [linear]="true" [orientation]="isVertical ? 'vertical': 'horizontal'">
  <mat-step [completed]="envioData && envioData.envia !=0"  >
    <ng-template matStepLabel>Calcula tu cambio</ng-template>
    <div class="flex flex-row justify-center items-center w-full">
      <div class="bg-aguacate-bg rounded-lg flex flex-col justify-center items-center w-full md:w-8/12">
        <app-calculator (continuarEmitter)="dataCalculadora($event)" class="w-full"></app-calculator>

        <button
        mat-raised-button color="primary"
        class="w-40 md:w-80 mb-10 my-4"
        type="button"
        (click)="continuarCalculadora()"
         >
        Continuar
        </button>
      </div>
    </div>
  </mat-step>
  <mat-step [completed]="cuentaBeneficiario!== undefined && validPayer" >
    <ng-template matStepLabel>Elige beneficiario</ng-template>
    <app-account-recipient [seleccionable]="true" (seleccion)="onCuentaSeleccionada($event)" ></app-account-recipient>
    <div class=" flex flex-row gap-4 justify-center items-center my-7">
      <button
      type="button"
      matStepperPrevious
      class=" text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
    >
     Regresar
    </button>
       <button
        type="button"
        (click)="selectBeneficiarioStep()"
        class=" text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
      >
       Continuar
      </button>
  </div>
  </mat-step>
  <mat-step [completed]="medioPago" >
    <ng-template matStepLabel>Realiza tu pago</ng-template>
    <app-payment-instruction [valorATransferir]="envioData" (medioPagoSeleccionado)="onMedioPagoSeleccionado($event)"></app-payment-instruction>
    <div class="flex flex-row gap-4 justify-center items-center my-7">
      <button
      type="button"
      matStepperPrevious
      class=" text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
    >
     Regresar
    </button>
       <button
        type="button"
        matStepperNext
        class=" text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
      >
       Continuar
      </button>
  </div>
  </mat-step>
  <mat-step >
    <ng-template matStepLabel>Validación</ng-template>
    <app-payment-validation *ngIf="envioData" [beneficiario]="cuentaBeneficiario" [valorATransferir]="envioData" [tipoPago]="medioPago"></app-payment-validation>
    <div class="flex flex-row gap-4 justify-center items-center my-7">
      <button
      type="button"
      matStepperPrevious
      class=" text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
    >
     Regresar
    </button>
       <button
        type="button"
        (click)="confirmarEnvio()"
        class=" text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
      >
       Realizar envío
      </button>
  </div>
  </mat-step>
</mat-stepper>
