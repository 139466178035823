<div
  class="overflow-y-hidden my-10 h-auto flex flex-row justify-around items-center content-center gap-4 md:mx-4"
  *ngIf="email"
>
  <div
    class="w-11/12 md:w-5/6 xl:w-4/6 h-auto bg-slate-200 rounded-lg shadow xs:p-0"
  >
    <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
      <div class="container flex flex-col items-center">
        <h1 *ngIf="!isCompany" class="text-2xl font-bold">Datos personales</h1>
        <h1 *ngIf="isCompany" class="text-2xl font-bold">Datos Empresa</h1>
      </div>
      <form
        class="space-y-4 md:space-y-6"
        [formGroup]="datosForm"
        *ngIf="!isCompany"
      >
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input
              type="text"
              pattern="[ a-zA-Z]*"
              matInput
              formControlName="nombre"
              placeholder="Ingresa tu nombre"
            />
            <mat-error
              *ngIf="this.datosForm.get('nombre')!.hasError('pattern')"
            >
              Sólo caracteres alfábeticos
            </mat-error>
            <mat-error
              *ngIf="this.datosForm.get('nombre')!.hasError('maxlength')"
            >
              Máximo 40 caracteres
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Apellidos</mat-label>
            <input
              type="text"
              pattern="[ a-zA-Z]*"
              matInput
              formControlName="apellido"
              placeholder="Ingresa tu apellido"
            />
            <mat-error
              *ngIf="this.datosForm.get('apellido')!.hasError('pattern')"
            >
              Sólo caracteres alfábeticos
            </mat-error>
            <mat-error
              *ngIf="this.datosForm.get('apellido')!.hasError('maxlength')"
            >
              Máximo 40 caracteres
            </mat-error>
          </mat-form-field>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <mat-form-field>
            <mat-label>Tipo documento</mat-label>
            <mat-select required formControlName="tipoId">
              <mat-option>Tipo documento</mat-option>
              <mat-option *ngFor="let tipoID of tiposID" [value]="tipoID">
                {{ tipoID }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Numero identificación</mat-label>
            <input
              (blur)="validateRut()"
              type="text"
              matInput
              formControlName="numeroId"
              placeholder="Ingresa tu documento"
            />
            <div
              *ngIf="
                this.datosForm.controls['numeroId'].invalid &&
                (this.datosForm.controls['numeroId'].dirty ||
                  this.datosForm.controls['numeroId'].touched)
              "
              class="error-file alert"
            >
              <mat-error
                *ngIf="
                  this.datosForm.get('numeroId')!.errors!['incorrectFormat'] ===
                  true
                "
              >
                Ingrese un rut válido
              </mat-error>
              <mat-error
                *ngIf="this.datosForm.get('numeroId')!.hasError('maxlength')"
              >
                Máximo 15 caracteres
              </mat-error>
            </div>
          </mat-form-field>
        </div>
        <div class="grid grid-cols-1 gap-4">
          <mat-form-field>
            <mat-label>Nacionalidad</mat-label>
            <input
              type="text"
              placeholder="Selecciona un país"
              matInput
              formControlName="nacionalidad"
              required
              [matAutocomplete]="autoPaisNacionalidad"
              (blur)="checkPais('nacionalidad')"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #autoPaisNacionalidad="matAutocomplete"
            >
              <mat-option
                *ngFor="let pais of optionsNacionalidad | async"
                [value]="pais.NAME"
                class="flex flex-row"
              >
                <div [class]="'bg-' + pais.ALPHA2.toLowerCase()"></div>
                <span> {{ pais.NAME | titlecase }} </span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
          <mat-form-field class="col-span-2 sm:col-span-1">
            <mat-label>Código</mat-label>
            <input
              type="text"
              placeholder="Selecciona uno"
              aria-label="Number"
              matInput
              formControlName="prefijoTelefono"
              [matAutocomplete]="auto"
              (blur)="checkPrefijo()"
            />
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
              <mat-option
                *ngFor="let pais of optionsPrefijoTelefono | async"
                [value]="pais.DIAL"
                class="flex flex-row"
              >
                <div [class]="'bg-' + pais.ALPHA2.toLowerCase()"></div>
                <span> {{ pais.DIAL }} - {{ pais.NAME | titlecase }} </span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field class="col-span-2">
            <mat-label>Teléfono</mat-label>
            <input
              type="text"
              matInput
              appOnlynumber
              formControlName="telefono"
              placeholder="Ingresa tu Teléfono"
            />
          </mat-form-field>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
          <mat-form-field class="col-span-2 sm:col-span-1">
            <mat-label>Sexo</mat-label>
            <mat-select required formControlName="sexo">
              <mat-option>Selecciona una opción</mat-option>
              <mat-option
                *ngFor="let sexo of sexos"
                [value]="sexo"
                class="flex flex-row"
              >
                <span> {{ sexo }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="col-span-2" appearance="fill">
            <mat-label>Selecciona tu fecha</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              [max]="maxDate"
              formControlName="fechaNacimiento"
            />
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="grid grid-cols-1 gap-4">
          <mat-form-field>
            <mat-label>País de residencia</mat-label>
            <input
              type="text"
              placeholder="Selecciona un país"
              aria-label="Number"
              matInput
              formControlName="paisResidencia"
              [matAutocomplete]="autoPais"
              (blur)="checkPais('paisResidencia')"
            />
            <mat-autocomplete autoActiveFirstOption #autoPais="matAutocomplete">
              <mat-option
                *ngFor="let pais of optionsPaisResidencia | async"
                [value]="pais.NAME"
                class="flex flex-row"
              >
                <div [class]="'bg-' + pais.ALPHA2.toLowerCase()"></div>
                <span> {{ pais.NAME | titlecase }} </span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="grid grid-cols-1 gap-4">
          <mat-form-field class="col-span-2">
            <mat-label>Ciudad de residencia</mat-label>
            <input
              type="text"
              matInput
              formControlName="ciudadResidencia"
              placeholder="Ej. Santiago"
            />
          </mat-form-field>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <mat-form-field class="col-span-1">
            <mat-label>Dirección</mat-label>
            <input
              type="text"
              matInput
              formControlName="direccion"
              placeholder="Ej. Santiago"
            />
            <mat-error
              *ngIf="this.datosForm.get('direccion')!.hasError('maxlength')"
            >
              Máximo 60 caracteres
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-span-1">
            <mat-label>Profesión</mat-label>
            <input
              type="text"
              matInput
              formControlName="profesion"
              placeholder="Ej. Ingeniero"
            />
            <mat-error
              *ngIf="this.datosForm.get('direccion')!.hasError('maxlength')"
            >
              Máximo 30 caracteres
            </mat-error>
          </mat-form-field>
        </div>
        <div class="grid grid-cols-1 gap-4">
          <mat-form-field class="w-full">
            <ngx-mat-file-input
              class="w-full"
              formControlName="documentoFrontal"
              [multiple]="false"
              [accept]="'image/*'"
              color="primary"
              placeholder="Adjunte imagen frontal de su documento"
            >
            </ngx-mat-file-input>

            <div
              *ngIf="
                this.datosForm.controls['documentoFrontal'].invalid &&
                (this.datosForm.controls['documentoFrontal'].dirty ||
                  this.datosForm.controls['documentoFrontal'].touched)
              "
              class="error-file alert"
            >
              <mat-error
                class="error-file"
                *ngIf="
                  this.datosForm.get('documentoFrontal')!.errors!['maxSize']
                "
              >
                Máximo 5 MB
              </mat-error>
              <mat-error
                class="error-file"
                *ngIf="
                  this.datosForm.get('documentoFrontal')!.errors![
                    'incorrectFormat'
                  ]
                "
              >
                Únicamente formatos JPG o PNG
              </mat-error>
            </div>
          </mat-form-field>
        </div>
        <div class="grid grid-cols-1 gap-4">
          <mat-form-field appearance="fill" class="w-full">
            <ngx-mat-file-input
              [multiple]="false"
              formControlName="documentoPosterior"
              [accept]="'image/*'"
              color="primary"
              placeholder="Adjunte imagen posterior de su documento"
            >
            </ngx-mat-file-input>
            <div
              *ngIf="
                this.datosForm.controls['documentoPosterior'].invalid &&
                (this.datosForm.controls['documentoPosterior'].dirty ||
                  this.datosForm.controls['documentoPosterior'].touched)
              "
              class="error-file alert"
            >
              <mat-error
                class="error-file"
                *ngIf="
                  this.datosForm.get('documentoPosterior')!.errors!['maxSize']
                "
              >
                Máximo 5 MB
              </mat-error>
              <mat-error
                class="error-file"
                *ngIf="
                  this.datosForm.get('documentoPosterior')!.errors![
                    'incorrectFormat'
                  ]
                "
              >
                Únicamente formatos JPG o PNG
              </mat-error>
            </div>
          </mat-form-field>
        </div>
        <div class="grid grid-cols-1">
          <mat-slide-toggle formControlName="politicamenteExpuesto"
            >¿Es usted una persona expuesta politicamente?</mat-slide-toggle
          >
        </div>

        <div class="container flex flex-col items-center">
          <button
            type="click"
            (click)="enviarDatos()"
            class="w-1/2 text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            Enviar datos
          </button>
        </div>
      </form>
      <!-- Registro empresa -->
      <form
        class="space-y-4 md:space-y-6"
        [formGroup]="datosForm"
        *ngIf="isCompany"
      >
        <h2 class="text-xl font-bold">Representante Legal</h2>
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input
              type="text"
              pattern="[ a-zA-Z]*"
              matInput
              formControlName="nombre"
              placeholder="Ingresa tu nombre"
            />
            <mat-error
              *ngIf="this.datosForm.get('nombre')!.hasError('pattern')"
            >
              Sólo caracteres alfábeticos
            </mat-error>
            <mat-error
              *ngIf="this.datosForm.get('nombre')!.hasError('maxlength')"
            >
              Máximo 40 caracteres
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Apellidos</mat-label>
            <input
              type="text"
              pattern="[ a-zA-Z]*"
              matInput
              formControlName="apellido"
              placeholder="Ingresa tu apellido"
            />
            <mat-error
              *ngIf="this.datosForm.get('apellido')!.hasError('pattern')"
            >
              Sólo caracteres alfábeticos
            </mat-error>
            <mat-error
              *ngIf="this.datosForm.get('apellido')!.hasError('maxlength')"
            >
              Máximo 40 caracteres
            </mat-error>
          </mat-form-field>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <mat-form-field>
            <mat-label>Tipo documento</mat-label>
            <mat-select required formControlName="tipoId">
              <mat-option>Tipo documento</mat-option>
              <mat-option *ngFor="let tipoID of tiposID" [value]="tipoID">
                {{ tipoID }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Numero identificación</mat-label>
            <input
              (blur)="validateRut()"
              type="text"
              matInput
              formControlName="numeroId"
              placeholder="Ingresa tu documento"
            />
            <div
              *ngIf="
                this.datosForm.controls['numeroId'].invalid &&
                (this.datosForm.controls['numeroId'].dirty ||
                  this.datosForm.controls['numeroId'].touched)
              "
              class="error-file alert"
            >
              <mat-error
                *ngIf="
                  this.datosForm.get('numeroId')!.errors!['incorrectFormat'] ===
                  true
                "
              >
                Ingrese un rut válido
              </mat-error>
              <mat-error
                *ngIf="this.datosForm.get('numeroId')!.hasError('maxlength')"
              >
                Máximo 15 caracteres
              </mat-error>
            </div>
          </mat-form-field>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
          <mat-form-field class="col-span-2 sm:col-span-1">
            <mat-label>Código</mat-label>
            <input
              type="text"
              placeholder="Selecciona uno"
              aria-label="Number"
              matInput
              formControlName="prefijoTelefono"
              [matAutocomplete]="auto"
              (blur)="checkPrefijo()"
            />
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
              <mat-option
                *ngFor="let pais of optionsPrefijoTelefono | async"
                [value]="pais.DIAL"
                class="flex flex-row"
              >
                <div [class]="'bg-' + pais.ALPHA2.toLowerCase()"></div>
                <span> {{ pais.DIAL }} - {{ pais.NAME | titlecase }} </span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field class="col-span-2">
            <mat-label>Teléfono</mat-label>
            <input
              type="text"
              matInput
              appOnlynumber
              formControlName="telefono"
              placeholder="Ingresa tu Teléfono"
            />
          </mat-form-field>
        </div>
        <div class="grid grid-cols-1 gap-4">
          <mat-form-field class="w-full">
            <ngx-mat-file-input
              class="w-full"
              formControlName="documentoFrontal"
              [multiple]="false"
              [accept]="'image/*'"
              color="primary"
              placeholder="Adjunte imagen frontal de su documento"
            >
            </ngx-mat-file-input>

            <div
              *ngIf="
                this.datosForm.controls['documentoFrontal'].invalid &&
                (this.datosForm.controls['documentoFrontal'].dirty ||
                  this.datosForm.controls['documentoFrontal'].touched)
              "
              class="error-file alert"
            >
              <mat-error
                class="error-file"
                *ngIf="
                  this.datosForm.get('documentoFrontal')!.errors!['maxSize']
                "
              >
                Máximo 5 MB
              </mat-error>
              <mat-error
                class="error-file"
                *ngIf="
                  this.datosForm.get('documentoFrontal')!.errors![
                    'incorrectFormat'
                  ]
                "
              >
                Únicamente formatos JPG o PNG
              </mat-error>
            </div>
          </mat-form-field>
        </div>
        <div class="grid grid-cols-1 gap-4">
          <mat-form-field appearance="fill" class="w-full">
            <ngx-mat-file-input
              [multiple]="false"
              formControlName="documentoPosterior"
              [accept]="'image/*'"
              color="primary"
              placeholder="Adjunte imagen posterior de su documento"
            >
            </ngx-mat-file-input>
            <div
              *ngIf="
                this.datosForm.controls['documentoPosterior'].invalid &&
                (this.datosForm.controls['documentoPosterior'].dirty ||
                  this.datosForm.controls['documentoPosterior'].touched)
              "
              class="error-file alert"
            >
              <mat-error
                class="error-file"
                *ngIf="
                  this.datosForm.get('documentoPosterior')!.errors!['maxSize']
                "
              >
                Máximo 5 MB
              </mat-error>
              <mat-error
                class="error-file"
                *ngIf="
                  this.datosForm.get('documentoPosterior')!.errors![
                    'incorrectFormat'
                  ]
                "
              >
                Únicamente formatos JPG o PNG
              </mat-error>
            </div>
          </mat-form-field>
        </div>
        <h2 class="text-xl font-bold">Empresa</h2>
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <mat-form-field>
            <mat-label>Nombre o Razón Social</mat-label>
            <input
              type="text"
              matInput
              formControlName="razonSocial"
              placeholder="Ingresa la razón social"
            />
            <mat-error
              *ngIf="this.datosForm.get('razonSocial')!.hasError('maxlength')"
            >
              Máximo 40 caracteres
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Nombre comercial</mat-label>
            <input
              type="text"
              matInput
              formControlName="nombreComercial"
              placeholder="Ingresa el nombre comercial"
            />

            <mat-error
              *ngIf="
                this.datosForm.get('nombreComercial')!.hasError('maxlength')
              "
            >
              Máximo 40 caracteres
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Rut Empresa</mat-label>
            <input
              (blur)="validateRutEmpresa()"
              type="text"
              matInput
              formControlName="rutEmpresa"
              placeholder="Ingresa tu documento"
            />
            <div
              *ngIf="
                this.datosForm.controls['rutEmpresa'].invalid &&
                (this.datosForm.controls['rutEmpresa'].dirty ||
                  this.datosForm.controls['rutEmpresa'].touched)
              "
              class="error-file alert"
            >
              <mat-error
                *ngIf="
                  this.datosForm.get('rutEmpresa')!.errors!['incorrectFormat'] ===
                  true
                "
              >
                Ingrese un rut válido
              </mat-error>
              <mat-error
                *ngIf="this.datosForm.get('rutEmpresa')!.hasError('maxlength')"
              >
                Máximo 15 caracteres
              </mat-error>
            </div>
          </mat-form-field>
          <mat-form-field class="col-span-1">
            <mat-label>Domicilio fiscal</mat-label>
            <input
              type="text"
              matInput
              formControlName="domicilioFiscal"
              placeholder="Ej. Santiago"
            />
            <mat-error
              *ngIf="
                this.datosForm.get('domicilioFiscal')!.hasError('maxlength')
              "
            >
              Máximo 60 caracteres
            </mat-error>
          </mat-form-field>
        </div>

        <div class="grid grid-cols-1 gap-4">
          <mat-form-field appearance="fill" class="w-full">
            <ngx-mat-file-input
              [multiple]="false"
              formControlName="documentoIdTributario"
              [accept]="'image/*, .pdf'"
              color="primary"
              placeholder="Adjunte Documento de Identificación Tributario"
            >
            </ngx-mat-file-input>
            <div
              *ngIf="
                this.datosForm.controls['documentoIdTributario'].invalid &&
                (this.datosForm.controls['documentoIdTributario'].dirty ||
                  this.datosForm.controls['documentoIdTributario'].touched)
              "
              class="error-file alert"
            >
              <mat-error
                class="error-file"
                *ngIf="
                  this.datosForm.get('documentoIdTributario')!.errors!['maxSize']
                "
              >
                Máximo 5 MB
              </mat-error>
              <mat-error
                class="error-file"
                *ngIf="
                  this.datosForm.get('documentoIdTributario')!.errors![
                    'incorrectFormat'
                  ]
                "
              >
                Únicamente formatos JPG, PNG o PDF
              </mat-error>
            </div>
          </mat-form-field>
        </div>
        <div class="grid grid-cols-1 gap-4">
          <mat-form-field appearance="fill" class="w-full">
            <ngx-mat-file-input
              [multiple]="false"
              formControlName="estatuto"
              [accept]="'image/*, .pdf'"
              color="primary"
              placeholder="Adjunte Estatuto constitutivo de la empresa"
            >
            </ngx-mat-file-input>
            <div
              *ngIf="
                this.datosForm.controls['estatuto'].invalid &&
                (this.datosForm.controls['estatuto'].dirty ||
                  this.datosForm.controls['estatuto'].touched)
              "
              class="error-file alert"
            >
              <mat-error
                class="error-file"
                *ngIf="
                  this.datosForm.get('estatuto')!.errors!['maxSize']
                "
              >
                Máximo 5 MB
              </mat-error>
              <mat-error
                class="error-file"
                *ngIf="
                  this.datosForm.get('estatuto')!.errors![
                    'incorrectFormat'
                  ]
                "
              >
                Únicamente formatos JPG, PNG o PDF
              </mat-error>
            </div>
          </mat-form-field>
        </div>

        <div class="container flex flex-col items-center">
          <button
            type="click"
            (click)="enviarDatos()"
            class="w-1/2 text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            Enviar datos
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
