import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TransaccionService } from '../../services/transaccion.service';
import Swal from 'sweetalert2';
import { SpinnerService } from 'src/app/shared/services/spinner.service';

@Component({
  selector: 'app-assign-trx-dialog',
  templateUrl: './assign-trx-dialog.component.html',
  styleUrls: ['./assign-trx-dialog.component.sass']
})
export class AssignTrxDialogComponent {
  datosForm: FormGroup | undefined;
  formTitles: { [key: string]: string } = {
    userId: 'User ID',
    cartolaId: 'Cartola ID',
    bancoOrigen: 'Banco de Origen',
    codigoTransferencia: 'Codigo de Transferencia',
    createdAt: 'Fecha de Creacion',
    cuentaCorriente: 'Cuenta Corriente',
    docFiscalId: 'Documento Fiscal Id',
    fechaContable: 'Fecha Contable',
    fechaEmision: 'Fecha de Emision',
    fechaInserta: 'Fecha Inserta',
    fechaRecepcion: 'Fecha de Recepcion',
    hora: 'Hora',
    moSaldoAbono: 'Monto de Abono',
    moSaldoCargo: 'Monto de Cargo',
    nombreOrigen: 'Nombre de Origen',
    numeroCuentaOrigen: 'Numero de Cuenta Origen',
    pagadorId: 'Pagador ID',
    rutOrigen: 'Rut de Origen',
    saldo: 'Saldo',
    tipoCuentaOrigen:'Tipo de Cuenta Origen',
    tipoDocFiscal: 'Tipo de Documento Fiscal',
    trazaErrorDoc: 'Error de Trazabilidad',
    updatedAt:'Fecha de Actualizacion',
  };

  formFields: { name: string; value: any}[] = [
    { name: 'userId', value: null},
    { name: 'cartolaId', value: null},
    { name: 'bancoOrigen', value: null},
    { name: 'codigoTransferencia', value: null},
    { name: 'cuentaCorriente', value: null},
    { name: 'docFiscalId', value: null},
    { name: 'hora', value: null},
    { name: 'moSaldoAbono', value: null},
    { name: 'moSaldoCargo', value: null},
    { name: 'nombreOrigen', value: null},
    { name: 'numeroCuentaOrigen', value: null},
    { name: 'pagadorId', value: null},
    { name: 'rutOrigen', value: null},
    { name: 'saldo', value: null},
    { name: 'tipoCuentaOrigen', value: null},
    { name: 'tipoDocFiscal', value: null},
    { name: 'trazaErrorDoc', value: null},
    { name: 'updatedAt', value: null},
    { name: 'createdAt', value: null},   
  ];

  dates: string="updatedAt createdAt fechaContable fechaEmision fechaInserta"
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AssignTrxDialogComponent>,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private transaccionService: TransaccionService,
    private spinner: SpinnerService
  ) {
    const ref = this.spinner.start();
    this.initializeForm();
    this.populateFormFields();
    this.spinner.stop(ref)
  }

  initializeForm(){
    this.datosForm = this.fb.group({
      userId: new FormControl(null, [Validators.required, this.onlyNumbersValidator()]),
      cartolaId: new FormControl(this.data.transaction.cartolaId, [Validators.required]),
      bancoOrigen: new FormControl(this.data.transaction.bancoOrigen),
      codigoTransferencia: new FormControl(this.data.transaction.codigoTransferencia),
      cuentaCorriente: new FormControl(this.data.transaction.cuentaCorriente),
      docFiscalId: new FormControl(this.data.transaction.docFiscalId),
      hora: new FormControl(this.data.transaction.hora),
      moSaldoAbono: new FormControl(this.data.transaction.moSaldoAbono),
      moSaldoCargo: new FormControl(this.data.transaction.moSaldoCargo),
      nombreOrigen: new FormControl(this.data.transaction.nombreOrigen),
      numeroCuentaOrigen: new FormControl(this.data.transaction.numeroCuentaOrigen),
      pagadorId: new FormControl(this.data.transaction.pagadorId),
      rutOrigen: new FormControl(this.data.transaction.rutOrigen),
      saldo: new FormControl(this.data.transaction.saldo),
      tipoCuentaOrigen: new FormControl(this.data.transaction.tipoCuentaOrigen),
      tipoDocFiscal: new FormControl(this.data.transaction.tipoDocFiscal),
      trazaErrorDoc: new FormControl(this.data.transaction.trazaErrorDoc),
      fechaRecepcion: new FormControl(this.datePipe.transform(this.data.transaction.fechaRecepcion, 'yyyy-MM-dd HH:mm:ss'))
    }); 
  }
  populateFormFields(): void {
    this.formFields = []; 
    Object.keys(this.datosForm!.controls).forEach(controlName => {
      if (controlName !== 'userId') {
        const control = this.datosForm!.get(controlName);
        if (control && control.value !== null) {
          this.formFields.push({ name: controlName, value: control.value});
        }
      }
    });
  }
  cancelar(){
    this.dialogRef.close(false);
    return;
  }
  submitForm(){
    const ref = this.spinner.start();
    const cartola = this.data.transaction;
    cartola.usuarioId = this.datosForm?.get('userId')?.value;
    console.log(cartola);
    this.transaccionService
      .assignUnassignedTrx(cartola)
      .then((res) => {
        if (res) {
          Swal.fire({
            icon: 'success',
            title: 'Transacción Asignada',
            text: 'Se ha asignado la transacción con éxito',
            showCancelButton: false,
            showDenyButton: false,
            confirmButtonText: 'Entendido',
            confirmButtonColor: '#1e5a3f',
          });
          this.spinner.stop(ref);
          this.dialogRef.close(true);
          return true;
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Ha ocurrido un error',
            text: 'No se ha podido asignar la transacción',
            showCancelButton: false,
            showDenyButton: false,
            confirmButtonText: 'Ententido',
            confirmButtonColor: '#1e5a3f',
          });
          this.dialogRef.close(true);
          return false;
        }
      })
      .catch((err) => {
        this.spinner.stop(ref);
      });
      
  }
  onlyNumbersValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valid = /^\d+$/.test(control.value);
      return valid ? null : { onlyNumbers: true };
    };
  }
}
