
import { Component, Input,Output, OnDestroy, OnInit, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { CartolaService } from 'src/app/dashboard/services/cartola.service';
import { MultipleExchangeService } from 'src/app/dashboard/services/multiple-exchange.service';
import { TipoPago } from 'src/app/shared/enums/tipo-pago.enum';

@Component({
  selector: 'app-payment-instructions-multiple',
  templateUrl: './payment-instructions-multiple.component.html',
  styleUrls: ['./payment-instructions-multiple.component.sass']
})
export class PaymentInstructionsMultipleComponent implements OnInit, OnDestroy {
  value= `Banco de credito e inversiones (BCI)
  Cuenta corriente
  Parra Aldazoro spa
  27780767
  Rut: 76.661.819-7`;
  valueSecondAccount = `
  Banco de credito e inversiones (BCI)
  Cuenta corriente
  Aguacate Investments SPA
  46590234
  Rut 77258223-4`;
  @Input() valorATransferir: any | undefined;
  @Output() medioPagoSeleccionado: EventEmitter<string> = new EventEmitter();
  @Input() multiple: boolean | undefined = true;
  subscriptions: Subscription = new Subscription();
  usuarioID: number | undefined;
  medioPago: string | undefined = undefined;
  mediosPago: string[] = [TipoPago.TRANSFERENCIA, TipoPago.WALLET];
  valueToPay: number = 0;
  saldoUsuario= 0;
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  selectType(){
    this.medioPagoSeleccionado.emit(this.medioPago);
  }
  initFormPago() {
    this.subscriptions.add(
      this.store.select('auth').subscribe(async ({ user }) => {
        this.usuarioID = user?.usuarioId;
        if(this.usuarioID){
          this.saldoUsuario = (await this.cartolaService.getSaldoCliente(String(this.usuarioID))).balancePermitido;
          this.medioPagoSeleccionado.emit(this.medioPago);
        }
      })
    );
  }
 

  constructor(private cartolaService: CartolaService,    private store: Store<AppState>, private multipleExchange: MultipleExchangeService
    ) {
      console.log("items a calcular" ,this.multipleExchange.getItems());
      console.log(this.valorATransferir);
      this.initFormPago();
      this.multipleExchange.currentData$.subscribe(res=>{
        this.calcAmount(res);
      })
     }
  calcAmount(data: any[]){
    this.valueToPay= 0;
    data.forEach(x=>{
      this.valueToPay+= +x.cambio.envia;
    })
  }
  ngOnInit() {

  }

}
