  import { Component, OnInit, ViewChild, Injectable, AfterViewInit   } from '@angular/core';
  import { TransaccionService } from '../../services/transaccion.service';
  import { Transaccion } from '../../interfaces/transaccion.interface';
  import { MatTableDataSource } from '@angular/material/table';
  import { Subscription } from 'rxjs';
  import { FormBuilder, FormGroup , Validators } from '@angular/forms';
  import { getColumns } from './table-transactions-columns';
  import { ActionsService } from 'src/app/shared/services/actions.service';
  import { CartolaService } from '../../services/cartola.service';
  import { CurrencyPipe } from '@angular/common';
  import { MatPaginator } from '@angular/material/paginator';
  import { MatPaginatorIntl } from '@angular/material/paginator';
  import { filterObjKeys } from 'src/app/shared/utils/misc.util';
  import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { AssignTrxDialogComponent } from '../assign-trx-dialog/assign-trx-dialog.component';
import { MatDialog } from '@angular/material/dialog';


  @Injectable()
  export class CustomPaginatorIntl extends MatPaginatorIntl {
    override itemsPerPageLabel = 'Elementos por página';
    override nextPageLabel = 'Siguiente';
    override previousPageLabel = 'Anterior';
    override firstPageLabel="Primera página";
    override lastPageLabel="Última página"

    override getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 de ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
          Math.min(startIndex + pageSize, length) :
          startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} de ${length}`;
    };

  }

  @Component({
    selector: 'app-unassigned-transactions',
    templateUrl: './unassigned-transactions.component.html',
    styleUrls: ['./unassigned-transactions.component.sass']
  })
  export class UnassignedTransactionsComponent implements AfterViewInit, OnInit{
    transacciones: any[] = [];
    seleccionados: number = 0;
    dataSource: MatTableDataSource<any> | undefined;
    subscription: Subscription = new Subscription();
    columns = getColumns(this.currencyPipe);
    public currentPage = 1;
    public pageSize = 25;
    public totalItems = 0;
    public totalPages = this.totalItems/this.pageSize;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator | null = null;
    displayedColumns: string[] = [
      'codigoTransferencia',
      'bancoOrigen',
      'tipoCuentaOrigen',
      'numeroCuentaOrigen',
      'numeroCuentaDestino',
      'rutOrigen',
      'nombreOrigen',
      'fechaContable',
      'hora',
      'moSaldoCargo',
      'moSaldoAbono',
      'acciones'

    ];
    emailOperador = '';
    cambios: any = {};
    isBinance: boolean = false;
    filterForm!: FormGroup;
    hours: string[] = [];
    endHours: string[] = [];
    constructor(
      private transactionService: TransaccionService,
      private actionService: ActionsService,
      private cartolaService: CartolaService,
      private currencyPipe:CurrencyPipe,
      private fb: FormBuilder,
      private spinner: SpinnerService,
      private dialog: MatDialog
    ) {
    }
    async ngOnInit() {
    this.initializeFilter();
    await this.loadTransactions();
    this.subscription.add(this.actionService.subjectAssignedTransaction.subscribe(async res=> {
      if(res){
      this.loadTransactions();
      }
    }));
    this.hours = Array.from({ length: 24 }, (_, i) => i < 10 ? `0${i}:00` : `${i}:00`);
  }

    async loadTransactions() {
      if (this.validateFilter()){
        const ref = this.spinner.start();
        const filterValue = this.filterForm.value;
        console.log(filterValue);
        filterValue.startDate = this.formatLocalDate(new Date(this.filterForm.get('startDate')!.value));
        filterValue.endDate = this.formatLocalDate(new Date(this.filterForm.get('endDate')!.value));
        
        const query = filterObjKeys(this.filterForm.value);
        const data = await this.cartolaService.getTransaccionesRezagadas(query, this.currentPage,this.pageSize);
        this.transacciones = data.query;
        this.dataSource = new MatTableDataSource(this.transacciones);
        this.totalItems = +data.count;
        this.totalPages = Math.ceil (this.totalItems/this.pageSize);
        this.spinner.stop(ref);
      }
    }

    formatLocalDate(date: Date): string {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }

    onPageChange(event: any){
      console.log(event);
      this.pageSize=event.pageSize;
      this.currentPage = event.pageIndex+1;
      this.loadTransactions();
    }

    ngAfterViewInit() {
      if (this.paginator && this.dataSource) {
        this.dataSource.paginator = this.paginator;
      }
    }

    initializeFilter() {
      this.filterForm = this.fb.group({
        startTime: [],
        endTime: [],
        destAcc: [],
        rut: [],
        originAcc: [],
        name: [],
        startDate: [new Date(),[Validators.required]],
        endDate: [new Date(),[Validators.required]],
      });
    }
    validateFilter(){
      if (this.filterForm.get('endDate')?.value == undefined || this.filterForm.get('startDate')?.value == undefined) {
        return false;
      } 
      if (this.filterForm.get('startTime')?.value != undefined && this.filterForm.get('endTime')?.value == undefined) {
        return false;
      }
      if (this.filterForm.get('endTime')?.value != undefined && this.filterForm.get('startTime')?.value == undefined) {
        return false;
      }
        return true;
    }
    async setPurchaseValue(transactionId: number, id: number){
      if(!transactionId || !id)return;

      const value = (<HTMLInputElement>document.getElementById(`valorCompra${id}`)).value;
      console.log(value);
      await this.transactionService.setPurchaseValue({
        transactionId, value
      });

    }

    async guardarSeleccion(){
      const cambioFormateados = Object.keys(this.cambios).map( (key) => {
        return { id  : +key,
        checked: this.cambios[key]};
    });
    console.log(this.cambios);
    const body = {
      idTransaction: cambioFormateados,
      operatorEmail: this.emailOperador
    };
    await this.transactionService.changeAssignationStatus(body);
    await this.loadTransactions();
    }

    change(event: any, id: number) {
      this.cambios[id] = event.checked;
      console.log(this.cambios);
    }

    checkEmailOperador(email:string){
      if(!email)return false;
      if(this.emailOperador !== email)return true;
      return false;
    }

    updateEndTimeOptions() {
      const startHour = this.filterForm.get('startTime')?.value as string;
      const startIndex = this.hours.indexOf(startHour);
      if (startHour !== null) {
        this.endHours = [...this.hours.slice(startIndex + 1), startHour]; 
      } else {
          this.endHours = this.hours;
      }
      if (startHour === '23:00'){
        this.endHours = [...this.hours.slice(startIndex + 1), '23:00']; 

      }
    
      this.filterForm.get('endTime')?.setValue(null);
    } 

    disableButton(email:string){
      if(this.emailOperador !== email)return true;
      return false;
    }

    limpiarSeleccion() {
      const data =
        this.dataSource?.data.map((value) => {
          return { ...value, seleccionado: false };
        }) || [];

      if (this.dataSource && this.dataSource.data) {
        this.dataSource.data = data;
      }
    }

    ngOnDestroy(): void {
      this.subscription.unsubscribe();
    }

    applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      if (this.dataSource) {
        this.dataSource.filter = filterValue.trim().toUpperCase();
      }
    }

    public doFilter = (event: any) => {
      const element = event.currentTarget as HTMLInputElement;
      const value = element.value;
      if (this.dataSource)
        this.dataSource.filter = value.trim().toLocaleLowerCase();
    };

    returnSelectedTransactions(row: Transaccion) {
      const selected =
        this.dataSource?.data?.filter((x) => x.seleccionado && x.id !== row.id) ||
        [];
      selected.push(row);
      return selected;
    }

    assignDialog(transaction: any){
      this.dialog.open(AssignTrxDialogComponent, {
        data: {transaction},
        maxWidth: '600px',
        maxHeight:'100vh',
        width: '90%',
      }).afterClosed().subscribe(res=> {
        if(res === true){
          this.loadTransactions();
        }
      });
    }

    clearFilters() {
      this.initializeFilter();
    }
  }
