export const environment = {
  firebase: {
    projectId: 'aguacate-wallet-qa',
    appId: '1:455461068324:web:703b6f15fa5312448ec2b1',
    storageBucket: 'aguacate-wallet-qa.appspot.com',
    apiKey: 'AIzaSyB532JuLd5JDhWc43qPlWPA-ZcXRuJflgc',
    authDomain: 'aguacate-wallet-qa.firebaseapp.com',
    messagingSenderId: '455461068324',
    measurementId: 'G-CMJ0LWH3C2',
    applicationServerKey : 'BP4XiQR6lR20NC2R23Cj1MuvpurOVlF9AP7BOk7GXhHCXXMJQuhWt2Q6-k-ES9J2YvLPXdhVM1r8hrCQQRxXCSI'
  },
  production:true,
  apiGatewayURL: 'https://w3eswmitz1.execute-api.us-east-1.amazonaws.com/prod/',
  cognito: {
    userPoolId: 'us-east-1_qVPVhyS5j',
    userPoolWebClientId: '7g6p2q4hask10oi8ocnfgmqo3d',
    mandatorySignIn: false,
    region: 'us-east-1',
    identityPoolId: 'us-east-1:c1d135a7-4200-4303-ba7a-790a2928b3ab'

  },
  s3: {
    accessKeyId: 'AKIAS6UF422PKR3BNKNM',
    secretAccessKey: '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',
    bucket: 'aguacatewalletdata',
    region: 'us-east-1',
    identityPoolId: 'us-east-1:e88d2a0b-195f-4ad3-b44b-70bd63c94ec4'
}
};
