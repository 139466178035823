import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AcceptTransactionDTO } from 'src/app/dashboard/interfaces/accept-transaction.dto';
import { TransferenciasUSDT } from 'src/app/dashboard/interfaces/transferencia-usdt.interface';
import { TransaccionService } from 'src/app/dashboard/services/transaccion.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-send-transactiona-cripto-modal',
  templateUrl: './send-transactiona-cripto-modal.component.html',
  styleUrls: ['./send-transactiona-cripto-modal.component.sass']
})
export class SendTransactionaCriptoModalComponent {

  transaction: TransferenciasUSDT | undefined;
  datosForm: FormGroup | undefined;

  constructor(
    private spinner: SpinnerService,
    private readonly transactionService: TransaccionService,
    public dialogRef: MatDialogRef<SendTransactionaCriptoModalComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any ){
    this.transaction = data;
    this.initForm();
  }


  initForm(){
    this.datosForm = this.fb.group({
      referencia: new FormControl(''),
    });
  }

  close(){
    this.dialogRef.close();
  }

  async acceptTransaction(){
    if(this.datosForm?.invalid || !this.transaction?.idTransferencia)return;
    const spinnerRef = this.spinner.start();

    const payload: AcceptTransactionDTO = {
      transactionID: this.transaction?.idTransferencia,
      referencia: this.datosForm?.get('referencia')?.value
    }
    try{
      const result =  await this.transactionService.acceptTransaction(payload);
      Swal.fire({
        icon: 'success',
        title: 'Transacción pagada',
        text: 'la transacción se ha pagado exitosamente',
        showCancelButton: false,
        showDenyButton: false,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#1e5a3f',

      });
      this.dialogRef.close(true);
    }catch(error: any){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.error.mensaje,
        showCancelButton: false,
        showDenyButton: false,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#1e5a3f',

      });
      this.dialogRef.close(true);
    }

    this.spinner.stop(spinnerRef);
  }



}
