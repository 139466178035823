<div class="m-8">
  <div class="flex flex-col md:flex-row justify-between items-center">
    <h1 class="text-xl font-bold">Beneficiarios</h1>
    <button class="mt-4 md:mt-0 p-7" mat-raised-button (click)="openCreateDialog()">
      <mat-icon color="primary"  class="add-icon transform scale-75">add_box</mat-icon>
      <span>Agregar Beneficiario</span>
    </button>
  </div>
</div>
<app-account-list [listaCuentas]="listaCuentas" (seleccion)="onSeleccion($event)" (reloadAccountList)="reloadAccount()"[seleccionable]="seleccionable" (actualizarCuenta)="openCreateDialog($event)"></app-account-list>
