<h1 class="text-2xl font-bold mx-14 my-7">Tasas de binance</h1>

<div *ngIf="variablesCambio && currentRates">
  <h1 class="text-xl font-bold mx-14 my-7">
    Tasa USD-CLP {{ currentRates.tasaCLP | number : "1.2-2" }}
  </h1>
  <h1 class="text-xl font-bold mx-14 my-7">
    Porcentaje comisión: {{ currentRates.comision | number : "1.2-5" }}
  </h1>
  <button
    (click)="setRates()"
    type="button"
    class="ml-14 mb-4 text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
  >
    Actualizar tasas
  </button>
  <button
    aria-label="Copiar"
    title="copiar"
    (click)="copyMessageToClipboard()"
    type="button"
    class="ml-14 mb-4 text-aguacate-green bg-white border-y-aguacate-highlight border-r-[1px] hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
    *ngIf="showCopyToClipboard"
  >
    <mat-icon class="font-medium rounded-lg text-sm text-center">content_copy</mat-icon>
  </button>

  <h1 class="text-xl font-bold mx-14 my-7">
    Mínimo {{ variablesCambio.limiteInferior }} BS
  </h1>
  <div class="grid xl:grid-cols-4 sm:grid-cols-2 gap-4 mx-14">
    <app-rate-value-card
      [bank]="'Banesco'"
      [rateOrigin]="tasas.banesco.precio"
      [imgUrl]="'assets/img/bancos/banesco.png'"
      [rateDestination]="tasasMin.banesco"
    ></app-rate-value-card>
    <app-rate-value-card
      [bank]="'Mercantil'"
      [rateOrigin]="tasas.mercantil.precio"
      [imgUrl]="'assets/img/bancos/mercantil.png'"
      [rateDestination]="tasasMin.mercantil"
    ></app-rate-value-card>
    <app-rate-value-card
      [bank]="'Provincial'"
      [rateOrigin]="tasas.provincial.precio"
      [imgUrl]="'assets/img/bancos/provincial.png'"
      [rateDestination]="tasasMin.provincial"
    ></app-rate-value-card>
    <app-rate-value-card *ngIf="tasas.bankVenezuela"
      [bank]="'Venezuela'"
      [rateOrigin]="tasas.bankVenezuela.precio"
      [imgUrl]="'assets/img/bancos/venezuela.png'"
      [rateDestination]="tasasMin.venezuela"
    ></app-rate-value-card>
  </div>

  <h1 class="text-xl font-bold mx-14 my-7">
    Mínimo {{ variablesCambio.limiteSuperior }} BS
  </h1>
  <div class="grid xl:grid-cols-4 sm:grid-cols-2 gap-4 mx-14">
    <app-rate-value-card
      [bank]="'Banesco'"
      [rateOrigin]="tasas.banesco.precio"
      [imgUrl]="'assets/img/bancos/banesco.png'"
      [rateDestination]="tasasMax.banesco"
    ></app-rate-value-card>
    <app-rate-value-card
      [bank]="'Mercantil'"
      [rateOrigin]="tasas.mercantil.precio"
      [imgUrl]="'assets/img/bancos/mercantil.png'"
      [rateDestination]="tasasMax.mercantil"
    ></app-rate-value-card>
    <app-rate-value-card
      [bank]="'Provincial'"
      [rateOrigin]="tasas.provincial.precio"
      [imgUrl]="'assets/img/bancos/provincial.png'"
      [rateDestination]="tasasMax.provincial"
    ></app-rate-value-card>
    <app-rate-value-card  *ngIf="tasas.bankVenezuela"
      [bank]="'Venezuela'"
      [rateOrigin]="tasas.bankVenezuela.precio"
      [imgUrl]="'assets/img/bancos/venezuela.png'"
      [rateDestination]="tasasMax.venezuela"
    ></app-rate-value-card>
  </div>
</div>
