import { Component } from '@angular/core';
import { CognitoService } from './auth/services/cognito.service';
import { getMessaging, getToken, onMessage } from '@angular/fire/messaging';
import { environment } from 'src/environments/environment';
import { FirebaseApp } from '@angular/fire/app';
import { ActionsService } from './shared/services/actions.service';
import { TiposMensaje } from './shared/enums/tipos-mensaje.enum';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { Idle } from '@ng-idle/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent {
  title = 'aguacate-wallet';

  token = '';
  constructor(
    private _idle: Idle,
    private afApp: FirebaseApp,
    private cognitoService: CognitoService,
    private actionsService: ActionsService  ) {

    this.requestPermission();
  }

  message: any = null;

  listen() {
    const messaging = getMessaging(this.afApp);
    onMessage(messaging, (payload: any) => {
      this.manageNotification(payload.data.action);
    });
  }

  requestPermission() {
    const messaging = getMessaging(this.afApp);
    getToken(messaging, { vapidKey: environment.firebase.applicationServerKey })
      .then((currentToken) => {
        if (currentToken) {
          this.token = currentToken;
          console.log(currentToken);
        } else {
          console.log(
            'No registration token available. Request permission to generate one.'
          );
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  }

  showMessage(title: string, iconName: SweetAlertIcon = 'success') {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 6000,
      timerProgressBar: true,
    });

    Toast.fire({
      icon: iconName,
      title: title,
    });
  }

  manageNotification(action: TiposMensaje) {
    if (action === TiposMensaje.RECARGAR_SALDO) {
      this.actionsService.emitirAccionRecargarSaldo();
      this.showMessage('Saldo abonado a tu cuenta');
    }
    if (action === TiposMensaje.ACTUALIZAR_TRANSACCIONES_ASIGNADAS) {
      this.actionsService.emitirRecargarTransaccionesAsignadas();
      this.showMessage('Transacciones seleccionadas por otro operador', 'info');
    }
    if (action === TiposMensaje.ACTUALIZAR_TASA_FIAT) {
      this.actionsService.emitirAccionTasaFiat();
    }
    if(action === TiposMensaje.ACTUALIZAR_USDT){
      this.actionsService.emitirAccionTasaUSDT();
    }
  }

  public ngOnInit(): void {

     this.listen();


  }


}
