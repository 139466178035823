import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Banco } from 'src/app/dashboard/interfaces/banco';
import { Pais } from 'src/app/dashboard/interfaces/country.interface';
import { TipoCuenta } from 'src/app/dashboard/interfaces/tipo-cuenta.interface';
import { CountriesService } from 'src/app/dashboard/services/countries.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AddBeneficiarioCripto } from 'src/app/dashboard/interfaces/add-beneficiario-cripto.interface';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { RecipientCriptoService } from 'src/app/dashboard/services/recipient-cripto.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-add-beneficiary',
  templateUrl: './add-beneficiary.component.html',
  styleUrls: ['./add-beneficiary.component.sass']
})
export class AddBeneficiaryComponent implements OnInit {

  formCuenta!: FormGroup;
  esUpdate = false;

  constructor(
    public dialogRef: MatDialogRef<AddBeneficiaryComponent>,
    private fb: FormBuilder,
    private spinner: SpinnerService,
    private recipientcriptoservice: RecipientCriptoService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ){
    this.esUpdate =  this.data.type === 'UPDATE';
  }

  tiposID = ['CI', 'PASAPORTE', 'RUT'];
  bancos: Banco[] = [];
  tiposCuentas: TipoCuenta[] = [];
  submit:boolean = false;

  async ngOnInit(): Promise<void> {
    const ref = this.spinner.start();
    this.initForm(ref);
  }

  async initForm(ref: any) {
    if(this.esUpdate){
      this.formCuenta = this.fb.group({
        nombre: new FormControl( this.esUpdate ? this.data.beneficiario.beneficiario_nombre : '', [Validators.required, Validators.maxLength(40)]),
        apellido: new FormControl( this.esUpdate ? this.data.beneficiario.beneficiario_apellido : '' , [Validators.required, Validators.maxLength(40)]),
        medioRecepcion: new FormControl( this.esUpdate ? this.data.beneficiario.beneficiario_medio_recepcion : '' , [Validators.required, Validators.minLength(34)]),
      })
    }else{
      this.formCuenta = this.fb.group({
        nombre: new FormControl('', [Validators.required, Validators.maxLength(40)]),
        apellido: new FormControl('', [Validators.required, Validators.maxLength(40)]),
        medioRecepcion: new FormControl('', [Validators.required, Validators.minLength(34), Validators.maxLength(34)]),
        recepcion: new FormControl('TRC20', [Validators.required, Validators.maxLength(20)]),
      })
    }
    this.spinner.stop(ref);
  }

  soloAlfabetico(event: KeyboardEvent) {
    const teclasPermitidas = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];
    if (teclasPermitidas.indexOf(event.key) !== -1) {
      return;  // permite la acción por defecto
    }
    const patron = /^[a-zA-Z\s]+$/;
    if (!patron.test(event.key)) {
      event.preventDefault();  // previene la introducción del caracter
    }
  }

  soloNumeros(event: KeyboardEvent) {
    const char = event.key;
    if (!char.match(/[0-9]/)) {
      event.preventDefault();
    }
  }

  async guardarBeneficiario(){
    this.submit = true;
    if (this.formCuenta?.invalid){
      return
    }
    const formValue = this.formCuenta?.value;
    const cuentaBeneficiary: AddBeneficiarioCripto = {
      ...formValue,
      medioCriptoId: 1,
      usuarioId: this.data.userID,
    }
    let swalConfig = {}
    if(this.esUpdate){
      cuentaBeneficiary['id'] = this.data.beneficiario.beneficiario_id;
      swalConfig = {
        icon: 'success',
        title: 'Beneficiario actualizado',
        text: 'Su Beneficiario fue actualizado con éxito',
        showCancelButton: false,
        showDenyButton: false,
        confirmButtonText: 'Entendido',
        confirmButtonColor: '#1e5a3f',
      };
    }else{
      swalConfig = {
        icon: 'success',
        title: 'Beneficiario creado',
        text: 'Su Beneficiario fue registrado con éxito',
        showCancelButton: false,
        showDenyButton: false,
        confirmButtonText: 'Entendido',
        confirmButtonColor: '#1e5a3f',
      };
    }
    const ref = this.spinner.start();
    try{
      const resultado = await this.recipientcriptoservice.saveRecipientAccount(
        cuentaBeneficiary
      );
      Swal.fire(swalConfig);
      this.dialogRef.close(true);
    }catch (error){
    }
    this.spinner.stop(ref);
  }

  cancel(){
    this.dialogRef.close(false);
  }

}
