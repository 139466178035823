<div class="md:w-[600px] p-10 md:p-4 rounded-3xl grid grid-cols-1 justify-center max-h-[65%]">

    <div class="w-full">
      <h2 class="font-bold text-center">Marcar operación como transferencia enviada</h2>
      <h4 class="mt-8">ID Transferencia: {{ transaction?.idTransferencia }}</h4>
      <h4 class="">Remitente: {{ transaction?.remitente }}</h4>
      <h4 class="">Beneficiaro: {{ transaction?.beneficiario }}</h4>
      <h4 class="">Tasa de negociación: {{ transaction?.tn | currency:'':'' }}</h4>
      <h4 class="">Monto CLP: {{ transaction?.montoCLP | currency:'':'' }}</h4>
      <h4 class="">Monto USDT: {{ transaction?.montoUSDT | currency:'':'' }}</h4>
    </div>
      <div class="w-full">
        <form [formGroup]="datosForm" class="my-4" *ngIf="datosForm">
          <div class="grid grid-cols-1 gap-4">
            <mat-form-field class="col-span-1">
              <mat-label>Referencia:</mat-label>
              <input
                type="text"
                matInput
                formControlName="referencia"
                placeholder="Opcional"
              />
            </mat-form-field>
          </div>
          <div class="grid grid-cols-1 gap-4">
          <mat-form-field class="col-span-1" >
              <mat-label>Cuenta</mat-label>
              <mat-select
                required
                formControlName="cuentaSeleccionada"
                [(ngModel)]="cuentaSeleccionada"
              >
                <mat-option [disabled]="">Selecione cuenta</mat-option>
                <mat-option
                  *ngFor="let cuenta of cuentasTrabajo"
                  [value]="cuenta.cuenta_trabajo_id"
                >
                  {{ cuenta.nombre  | titlecase }}  {{ cuenta.apellidos  | titlecase }} {{ cuenta.banco?.nombre}} {{cuenta.numeroCuenta}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
    
        </form>
    
      <div class="flex flex-col place-content-center md:flex-row mt-6 place-items-center gap-2">
        <button type="button" mat-raised-button color="primary" (click)="acceptTransaction()" [disabled]="datosForm?.invalid" class="mt-4 w-1/2 md:w-full text-lg rounded-lg px-5 py-6 text-center"
        >
        Pagado
      </button>
        <button type="button" mat-raised-button color="basic" (click)="close()"
          class=" w-1/2 md:w-full mt-4  text-lg rounded-lg px-5 py-6 text-center">
          Cancelar
        </button>
      </div>
      </div>
  
  </div>
  