import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';
import { ManualTransaction } from '../interfaces/manual-transaction';

@Injectable({
  providedIn: 'root'
})
export class ManualTransactionService {
  API_URL = environment.apiGatewayURL;
  constructor(private http: HttpClient, private cognito: CognitoService) { }

  async createManualTransaction(body: any){
    const serviceURL = `${this.API_URL}manual-trx`
    console.log('creando transaccion')
    console.log(body)
    return firstValueFrom(this.http.post(serviceURL, body));
  }

  async getAllTransactions(currentPage: number, pageSize: number, status: string, filterDateType: string, start:Date , end: Date): Promise<any>{
    const serviceURL = `${this.API_URL}manual-trx?page=${currentPage}&pageSize=${pageSize}&status=${status}&filterDateType=${filterDateType}&start=${start}&end=${end}`;
    return firstValueFrom(this.http.get<any[]>(serviceURL));
  }

  async getAllTransactionsByUser(userId: number): Promise<ManualTransaction[]>{
    const serviceURL = `${this.API_URL}manual-trx/${userId}`;
    return firstValueFrom(this.http.get<ManualTransaction[]>(serviceURL));
  }

}
