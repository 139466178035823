import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegisteredDevicesService {

  API_URL = environment.apiGatewayURL;

  constructor(private http: HttpClient) {}

  saveDevice(email: string, token: string, rol: string){
    const payload = {email, token, rol};
    const serviceURL = `${this.API_URL}save-connected-device`;
    return firstValueFrom(this.http.post(serviceURL, payload));
  }

  deleteDevice(email: string, token: string){
    const payload = {email, token};
    const serviceURL = `${this.API_URL}delete-connected-device`;
    return firstValueFrom(this.http.post(serviceURL, payload));
  }
}
