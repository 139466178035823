export enum ParameterTypeEnum {
    ACTUALIZACION_USDT = 'ACTUALIZACION_USDT',
    ACTUALIZACION_VES = 'ACTUALIZACION_VES',
    CORREOS_AUTORIZADOS ='CORREOS_AUT'
}

export enum ParameterValueEnum{
  MANUAL = 'MANUAL',
  AUTOMATICO = 'AUTOMATICO'
}
