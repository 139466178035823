import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Monedas } from 'src/app/shared/enums/monedas';
import { ManualTransactionService } from '../../services/manual-transaction.service';
import { EstadosTransferenciaManual } from 'src/app/shared/enums/estados-transferencia-manual.enum';
import Swal from 'sweetalert2';
import { ErrorTypes } from 'src/app/shared/enums/error-types.enum';
import { ActionsService } from 'src/app/shared/services/actions.service';
import { MatStepper } from '@angular/material/stepper';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog-component/confirmation-dialog-component.component';

@Component({
  selector: 'app-create-transaction-dialog',
  templateUrl: './create-transaction-dialog.component.html',
  styleUrls: ['./create-transaction-dialog.component.sass']
})
export class CreateFormDialogComponent {
  createForm: FormGroup | undefined = undefined;

  selectedCurrenLocal: { [key: string]: string } = {
    [Monedas.CL]: 'CLP',
  };
  selectedCurren: { [key: string]: string } = {
    [Monedas.CL]: 'CLP',
    [Monedas.USDT]: 'USDT'
  };
  filtersLocal: string[] = [<string>Monedas.CL];
  filters: string[] = [<string>Monedas.CL, <string>Monedas.USDT];
  @ViewChild('stepper') stepper: MatStepper | undefined;
  constructor(
    private actionService: ActionsService,
    private dialogRef: MatDialogRef<CreateFormDialogComponent>,
    private manualTransactionService:ManualTransactionService,
    private spinnerService: SpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {
    console.log(data);
    this.createForm = data.createForm || this.fb.group({
      usuarioId: new FormControl({value: data.usuarioId, disabled:true}, [Validators.required]),
      operatorId: new FormControl(data.operator, [Validators.required]),
      totalEnvio: new FormControl(null, [Validators.required]),
      totalDest: new FormControl({value: null, disabled: true}, [Validators.required]),
      monedaLocal: new FormControl(Monedas.CL, [Validators.required]),
      monedaDestino: new FormControl(Monedas.USDT, [Validators.required]),
      tasa: new FormControl(null, [Validators.required]),
      walletTrx: new FormControl(null, [Validators.required]),
    });
    this.createForm?.get('usuarioId')?.disabled;
    this.createForm?.get('totalDest')?.disabled;

  }

  onSubmit() {

    if (this.createForm?.valid) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '450px',
        data: { formData: this.createForm.value, userid: this.data.usuarioId, nombre: this.data.nombre}
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          const formData = this.createForm?.value;
          console.log('Form data submitted:', formData);
          this.recordTrx();
        } else {
          this.closeDialog();
        }
      });
    }
  }

  async recordTrx(){
    const ref = this.spinnerService.start();
    const transaccionDTO ={
    "usuarioId":this.data.usuarioId,
    "operatorId":this.data.operator,
    "totalEnvio": this.createForm?.get('totalEnvio')?.value,
    "totalDest":this.createForm?.get('totalDest')?.value,
    "monedaLocal":this.createForm?.get('monedaLocal')?.value,
    "monedaDestino":this.createForm?.get('monedaDestino')?.value,
    "valorPagado":0,
    "tasa":this.createForm?.get('tasa')?.value,
    "walletTrx":this.createForm?.get('walletTrx')?.value,
    "estado":EstadosTransferenciaManual.ABIERTA,
    }
    console.log(transaccionDTO);
    try{
      const resultadoTransaccion = await this.manualTransactionService.createManualTransaction(transaccionDTO);
      this.spinnerService.stop(ref);
      Swal.fire('Transaccion creada', `Se ha creado la transaccion con éxito`, 'success' );
      // this.router.navigate(['/wallet']);
      this.dialogRef.close();
    }catch(error: any){
      if(error.status === 400 && error.error.type === ErrorTypes.ERROR_CREANDO){
        this.spinnerService.stop(ref);
          if(this.stepper){
            this.actionService.emitirAccionTasaFiat();
            this.stepper.reset();
          }
          this.dialogRef.close();
      }else{
        Swal.fire('Error al enviar dinero',error.error.title, 'warning').then(()=>{
          this.spinnerService.stop(ref);
          // this.router.navigate(['/wallet']);
          this.dialogRef.close();
        });
      }

    }
  }

  closeDialog(){
    this.dialogRef.close();
  }
  loadDestino(){
    if(this.createForm?.value.tasa&&this.createForm?.value.totalEnvio){
      this.createForm.get("totalDest")?.setValue((+this.createForm?.get('totalEnvio')?.value/+this.createForm?.get('tasa')?.value));
    }
  }
  loadDestinoOnTasaChange(){
    if(this.createForm?.value.tasa&&this.createForm?.value.totalEnvio){
      this.createForm.get("totalDest")?.setValue((+this.createForm?.get('totalEnvio')?.value/+this.createForm?.get('tasa')?.value));
    }
  }
}
