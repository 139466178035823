import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Transaccion } from '../../interfaces/transaccion.interface';
import { TransactionUserDTO } from '../../interfaces/transaction-user.dto';

@Component({
  selector: 'app-dialog-cambio',
  templateUrl: './dialog-cambio.component.html',
  styleUrls: ['./dialog-cambio.component.sass']
})
export class DialogCambioComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogCambioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TransactionUserDTO) {}

    onNoClick(){
      this.dialogRef.close()
    }
}
