import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree, createUrlTreeFromSnapshot } from '@angular/router';
import { Observable, from, map } from 'rxjs';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import { UserRoles } from '../enums/roles-enum';

@Injectable({
  providedIn: 'root'
})
export class OperatorGuard implements CanActivate, CanActivateChild {
  constructor(private cognitoService: CognitoService, private router: Router){

  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      console.log("Calling canActivate");
    return from(this.cognitoService.getUser()).pipe(map((userInfo: any)=>{
      console.log(userInfo);
      return (userInfo?.attributes && userInfo?.attributes['custom:user-group'] === UserRoles.aguacate_operator) ? true : createUrlTreeFromSnapshot(route, ['/auth/login']);
    }));

}
}
