<div
  class="sm:w-[480px] md:w-[600px] overflow-hidden flex flex-col items-center"
>
  <p class="text-center m-8"><b>Ajuste Manual</b></p>
  <p class="text-center m-4">{{ this.tipo }} Saldo</p>
  <div class="w-3/5 content-center">
    <h2>
      <b>Nombre:</b> {{ this.data.userInfo?.nombre }}
      {{ this.data.userInfo?.apellidos }}
    </h2>
    <h2><b>Id:</b> {{ this.data.userInfo?.usuarioId }}</h2>
    <h2>
      <b>Documento:</b> {{ this.data.userInfo?.tipoId }} -
      {{ formatDocument() }}
    </h2>
    <h2><b>Email:</b> {{ this.data.userInfo?.email }}</h2>
    <h2><b>Saldo:</b> {{ this.data.saldo | currency : "" }}</h2>
    <form [formGroup]="form" class="grid grid-cols-1 my-8">
      <mat-form-field *ngIf="!this.tipo.includes('Quitar')">
        <mat-label>Monto:</mat-label>
        <input
          matInput
          matInputCommified
          placeholder="Monto"
          formControlName="value"
          appOnlynumber
          class="adjustment"
          required
        />
        <span matTextPrefix>$&nbsp;</span>
        <mat-error *ngIf="form.controls['value'].hasError('required')">
          El monto es requerido
        </mat-error>

        <mat-error *ngIf="form.controls['value'].hasError('pattern')">
          El monto debe ser un número
        </mat-error>
        <mat-error *ngIf="form.controls['value'].hasError('min')">
          El monto debe ser mayor a 0
        </mat-error>
        <mat-error *ngIf="form.controls['value'].hasError('max')">
          El monto debe ser menor o igual a {{ this.data.saldo | currency : "" }}
        </mat-error>
      </mat-form-field>

      <div class="flex flex-row justify-evenly my-4">
        <button
          mat-raised-button
          color="primary"
          (click)="adjustmentExecution()"
          class=""
        >
          Guardar
        </button>
        <button
          mat-raised-button
          color="secondary"
          (click)="onNoClick()"
          class=""
        >
          Cancelar
        </button>
      </div>
    </form>
  </div>
</div>
