import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ComplianceService {
  API_URL = environment.apiGatewayURL;
  constructor(private http: HttpClient) {}

  getUserComplianceList() {
    const serviceURL = `${this.API_URL}users-compliance`;
    return this.http.get(serviceURL);
  }
}
