<div class="w-full flex flex-col  justify-center items-center" *ngIf="valorATransferir ">
  <h2 class="font-bold text-xl my-5">Medio de pago</h2>
  <div class="px-6 py-4 w-full md:w-8/12 bg-aguacate-bg rounded-lg " >
    <div class="flex flex-col justify-between items-start">
      <label class="radio-group-label font-bold">Selecciona tu medio de pago</label>
      <!-- <p class="mt-4" *ngIf="valorATransferir">Cantidad a transferir <span class="font-bold">{{valorATransferir.envia | number }} CLP</span> </p> -->

<mat-radio-group (change)="selectType()"
  aria-labelledby="radio-group-label"
  class="radio-group"
  [(ngModel)]="medioPago">
  <!-- <mat-radio-button class="radio-button"  value="TRANSFERENCIA">
    Transferencia electrónica
  </mat-radio-button> -->

  <mat-radio-button class="radio-button"  value="WALLET" [disabled]="saldoUsuario < valorATransferir.envia ">
    Saldo de la wallet: <span class="font-bold">  {{saldoUsuario  | number }} CLP </span>
  </mat-radio-button>
</mat-radio-group>

<!-- <ng-container  *ngIf="medioPago === 'TRANSFERENCIA'">
  <ng-container *ngTemplateOutlet="datosTransferencia" >
  </ng-container>
</ng-container> -->
<!-- <ng-template #datosTransferencia>
  <div class="w-full">
    <h1 class="font-extrabold text-l">Transferencia electrónica</h1>
    <p class="mt-4" *ngIf="valorATransferir">Cantidad a transferir {{valorATransferir.envia | number }} CLP</p>
    <span class="mt-4 bg-[#F3FFE4] w-full lg:w-2/5 pl-5 pt-1 pb-1 pr-8 rounded-lg font-bold  flex flex-row justify-between"><span>Cantidad a transferir </span> <span>{{valorATransferir.envia | number}} CLP</span></span>
    <p class="mt-4">Realizar el depósito a la siguiente cuenta:</p>

    <div class="w-full flex flex-row  gap-8">
      <div>
        <p class="mt-5">
          Banco de credito e inversiones (BCI) <br>
          Cuenta corriente <br>
          Parra Aldazoro spa <br>
          27780767 <br>
          Rut: 76.661.819-7 <br>

          </p>
          <button mat-button class="mt-4" [cdkCopyToClipboard]="value"><mat-icon> content_copy </mat-icon> Copiar datos</button>
      </div>

    </div>
  </div>

</ng-template> -->

  </div>
</div>
<span class="mt-4 flex flex-row gap-2 text-sm"><div class="icon-alert pr-5"></div> Recuerda que el pago debe estar a tu nombre, si el pago proviene de un tercero, no sera válido</span>
</div>
