import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import { environment } from 'src/environments/environment';
import { UpdateContactData } from '../interfaces/update-contact-data';
import { firstValueFrom } from 'rxjs';
import { Usuario } from '../interfaces/transaccion.interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  API_URL = environment.apiGatewayURL;
  private userMoment: any;
  constructor(private http: HttpClient, private cognito: CognitoService) { }
  async sendUserVerification(body: any){
    const userBody = {...body.user};
    const serviceURL = `${this.API_URL}user`
    return firstValueFrom(this.http.post(serviceURL, userBody));
  }
  getUserData(email: string){
    const serviceURL = `${this.API_URL}user/${email}`;
    return this.http.get(serviceURL);
  }

  checkUserEmail(email: string){
    const serviceURL =  `${this.API_URL}check-user-email/${email}`;
    return firstValueFrom(this.http.get(serviceURL,  {headers: {'skip': 'true'}}));
  }

  checkUserValidity(email: string){
    const serviceURL =  `${this.API_URL}check-user-validity/${email}`;
    return firstValueFrom(this.http.get(serviceURL, {headers: {'skip': 'true'}}));
  }

  updateUserData(payload: UpdateContactData){
    const serviceURL = `${this.API_URL}userContactData`;
    return this.http.put(serviceURL, payload);
  }

  updateUserCompliance(email: string, emailOperator: string, compliance: any){
    const serviceURL = `${this.API_URL}compliance-usuario`;
    const payload = {
      email, emailOperator, compliance
    }
    return this.http.put(serviceURL, payload)
  }

  updateUserChecks(email: string, emailOperator: string, compliance: any){
    const serviceURL = `${this.API_URL}checks-usuario`;
    const payload = {
      email, emailOperator, compliance
    }
    return this.http.put(serviceURL, payload)
  }

  searchUsers(searchValue: string, type: string): Promise<Usuario[]>{
    const serviceURL = `${this.API_URL}user/${searchValue}?type=${type}`;
    return firstValueFrom(this.http.get<Usuario[]>(serviceURL));

  }

  updateUserSegmentation(ids: number[], segmentationId: number | undefined, usdtRate: number| undefined, minAmount: number | undefined){
    const serviceURL = `${this.API_URL}general-types/update-user-type`;
    const payload = {
      ids, userType: segmentationId, spotPoints: usdtRate, minAmount
    }
    return firstValueFrom(this.http.post(serviceURL, payload));
  }

  increaseSpotPoints(ids: number[], spotPoints: number){
    const serviceURL = `${this.API_URL}general-types/increase-spot-points`;
    const payload = {
      ids, spotPoints
    }
    return firstValueFrom(this.http.post(serviceURL, payload));
  }

}
