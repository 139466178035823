import { ActionReducerMap } from '@ngrx/store';
import * as fromAuth from './auth/auth.reducer';
import * as fromDashboard from './dashboard/dashboard.reducer';
// import * as fromIngresoEgreso from './ingreso-egreso/ingreso-egreso.reducer';


export interface AppState {
    auth: fromAuth.AuthState;
    envio: fromDashboard.DashboardState
    // ingresoEgreso: fromIngresoEgreso.IngresoEgresoState;
}

export const appReducers: ActionReducerMap<AppState> = {
    auth: fromAuth.authReducer,
    envio: fromDashboard.dashboardReducer

};



