import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ParameterService {
  API_URL = environment.apiGatewayURL;
  constructor(private http: HttpClient, private cognito: CognitoService) { }

  getParameter(name: string){
    const serviceURL = `${this.API_URL}parametro/${name}`;
    return firstValueFrom(this.http.get<any>(serviceURL));
  }
  saveParameter(descripcion:string, valor: string){
    const serviceURL = `${this.API_URL}parametro/${name}`;
    return firstValueFrom(this.http.post<any>(serviceURL, {descripcion, valor}));
  }

}
