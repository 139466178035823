import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CuentaBeneficiario } from '../interfaces/cuenta-beneficiario.interface';
import { LineaCredito } from '../interfaces/credit-line.interface';


@Injectable({
  providedIn: 'root'
})
export class CreditLineService {

  API_URL = environment.apiGatewayURL;

  constructor(private http: HttpClient) {}

  getByUser(userId: string): Promise<LineaCredito>{
    const serviceURL = `${this.API_URL}balance/credit-line/${userId}`;
    return firstValueFrom(this.http.get<LineaCredito>(serviceURL));
  }

  saveCreditLine(userId: number, creditLineAmount: number): Promise<any>{
    const serviceURL = `${this.API_URL}balance/credit-line`;
    const body = {
      userId,
      creditLineAmount
    }
    return firstValueFrom(this.http.post<any>(serviceURL, body));
  }

  inactivateCreditLine(userId: string): Promise<CuentaBeneficiario> {
    const serviceURL = `${this.API_URL}/balance/credit-line/unlink`;
    const body = {
      userId,
    }
    return firstValueFrom(this.http.post<any>(serviceURL, body));
  }

}
