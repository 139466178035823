import { createReducer, on } from '@ngrx/store';
import * as fromDashboard from './dashboard.actions';
import { CuentaBeneficiario } from './interfaces/cuenta-beneficiario.interface';


export interface DashboardState {
    envioSimple: IEnvioSimple | null;
}

export interface IEnvioSimple{
  cuentaBeneficiario?: CuentaBeneficiario | null,
  origen?: string | null,
  destino?: string | null,
  dineroOrigen?: number | null,
  dineroDestino?: number | null,
  fechaInicio?: Date | null
}

const estadoInicial: DashboardState = {
    envioSimple: null
};

export const dashboardReducer = createReducer(
    estadoInicial,
    on(fromDashboard.setRecipient, (state, {beneficiario})=>({
        ...state,
        envioSimple: { ...state.envioSimple,
        cuentaBeneficiario: beneficiario
        },
    })),
    on(fromDashboard.setExchangeData, (state, {origen, destino, dineroOrigen, dineroDestino})=>({
        ...state,
        envioSimple: { ...state.envioSimple,
          origen, destino, dineroDestino, dineroOrigen
          },
    })),
    on(fromDashboard.unsetRecipient, (state)=>({
       ...state,
       envioSimple: {...state.envioSimple, cuentaBeneficiario: null}
    })),
    on(fromDashboard.unsetExchangeData, (state)=>({
      ...state,
      envioSimple: null,
  })),
  on(fromDashboard.setInitDate, (state)=>({
    ...state,
    envioSimple: {...state.envioSimple, fechaInicio: new Date()},
})),
)

