import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-logout-countdown',
  templateUrl: './logout-countdown.component.html',
  styleUrls: ['./logout-countdown.component.sass']
})
export class LogoutCountdownComponent {
  constructor(public dialogRef: MatDialogRef<LogoutCountdownComponent>){

  }

  close(value : boolean){
    this.dialogRef.close(value)
  }
}
