<div class="m-4 w-full">
    <h1 class="m-4 text-2xl font-bold text-center">Transacciones pendientes Zelle</h1>
    <mat-form-field class="p-4" style="width: 90%;">
      <input matInput type="text" placeholder="Filtrar datos"  [(ngModel)]="filterValue" (ngModelChange)="applyFilter()">
      <mat-icon matPrefix class="my-icon">search</mat-icon>
    </mat-form-field>
    <button mat-raised-button color="primary" class="m-4" (click)="reload()" >Refrescar</button>
    <div class="items-center w-full my-4">
      <mat-paginator #paginator
                 class="demo-paginator self-left"
                 (page)="onPageChange($event)"
                 [length]="totalItems"
                 [pageSize]="pageSize"
                 [disabled]="false"
                 [showFirstLastButtons]="true"
                 [pageSizeOptions]="[5, 10, 25, 50, 100]"
                 [hidePageSize]="false"
                 [pageIndex]="currentPage-1"
                 aria-label="Select page">
    </mat-paginator>
    </div>
    <div class="flex overflow-x-auto">
      <table mat-table [dataSource]="transactionsZelle" class="mat-elevation-z8">
        <ng-container
          *ngFor="let column of columns"
          [matColumnDef]="column.columnDef"
        >
          <th mat-header-cell *matHeaderCellDef style="background-color: #CEE9C2;font-weight: bold; text-align: center;">{{column.header}}</th>
          <td mat-cell *matCellDef="let row">{{column.cell(row)}}</td>
        </ng-container>

        <ng-container matColumnDef="acciones">
          <th mat-header-cell *matHeaderCellDef aria-label="row actions" style="background-color: #CEE9C2;font-weight: bold; text-align: center;">Acciones</th>
          <td mat-cell *matCellDef="let element" >
            <div class="flex flex-row gap-3">
              <button mat-raised-button color="primary" aria-label="copiar" title="copiar" (click)="copy(element)">
                <mat-icon>content_copy</mat-icon>
              </button>
              <button class="content-center text-center" mat-raised-button color="primary" aria-label="Pagar" title="Pagar" [disabled]="element.estadoActual !== 'POR_PROCESAR'" (click)="openSendTransactionModal(element)">
                <mat-icon>done
                </mat-icon>
              </button>
              <!-- <button mat-raised-button   aria-label="Editar" title="Editar" (click)="openEditModal(element)" [disabled]="element.estadoActual !== 'POR_PROCESAR'">
                <mat-icon>edit</mat-icon>
              </button> -->
              <button mat-raised-button color="warn" aria-label="Rechazar" title="Rechazar" [disabled]="element.estadoActual !== 'POR_PROCESAR'" (click)="openRejectTransactionModal(element)">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
