import { Component } from '@angular/core';
import { WorkingAccountService } from '../../services/working-account.service';
import { MatTableDataSource } from '@angular/material/table';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { CurrencyPipe } from '@angular/common';
@Component({
  selector: 'app-status-cajero',
  templateUrl: './status-cajero.component.html',
  styleUrls: ['./status-cajero.component.sass']
})
export class StatusCajeroComponent {

  statusCajero:any[] = [];
  countryCode = ['VE', 'US'];

  displayedColumns: string[] = [

    'cajero',
    'total',
    'necesarioOB',
    'necesarioMB',
    'fiatOB',
  ];
  columns = [
    {
      columnDef: 'cajero',
      header: 'Cajero',
      cell: (element: any) => `${element.cuenta.nombre} ${element.cuenta.apellidos} - ${element.cuenta.banco.nombre}`,
    },
    {
      columnDef: 'total',
      header: 'Total',
      cell: (element: any) => `${this.currencyPipe.transform(element.total, '$')}`,
    },
    {
      columnDef: 'fiatOB',
      header: 'Fiat OB',
      cell: (element: any) => `${ this.currencyPipe.transform( element.fiatOb, '$')}`,
    },
    {
      columnDef: 'necesarioOB',
      header: 'Necesario OB',
      cell: (element: any) => `${this.currencyPipe.transform(element.necesarioOb, '$')}`,
    },
    {
      columnDef: 'necesarioMB',
      header: 'Necesario MB',
      cell: (element: any) => `${this.currencyPipe.transform(element.necesarioMb, '$')}`,
    },



  ];
  dataSource: MatTableDataSource<any> | undefined;

  constructor(private workingAccountService: WorkingAccountService, private spinner: SpinnerService, private currencyPipe:CurrencyPipe){
    this.loadData()
  }
  async loadData(){
    const ref = this.spinner.start();
    this.statusCajero = await this.workingAccountService.getStatusCajero(this.countryCode);
    this.dataSource = new MatTableDataSource(this.statusCajero);
    this.spinner.stop(ref);

  }
}
