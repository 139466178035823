import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CuentaRetiro } from 'src/app/dashboard/interfaces/cuenta-retiro.interface';
import { WithdrawAccountService } from 'src/app/dashboard/services/withdraw-account.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';

@Component({
  selector: 'app-withdraw-account-card',
  templateUrl: './withdraw-account-card.component.html',
  styleUrls: ['./withdraw-account-card.component.sass']
})
export class WithdrawAccountCardComponent {
  @Input() cuenta: CuentaRetiro | undefined;
  @Output() deleteAccount: EventEmitter<any> = new EventEmitter();
  @Output() actualizarCuenta: EventEmitter<CuentaRetiro> = new EventEmitter();
  constructor(private withdrawAccountService: WithdrawAccountService, private spinner: SpinnerService, private snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  async inactivarCuenta(){
    if(!this.cuenta)return;
    const spinnerRef  = this.spinner.start();
    try {
      await this.withdrawAccountService.inactivateAccount(this.cuenta.id);
      this.snackBar.open('Cuenta eliminada correctamente', 'Ok', {
        duration: 3000
      });
      this.deleteAccount.emit();
    } catch (error) {
      console.log(error);
    }
    this.spinner.stop(spinnerRef);

  }

  actualizar(){
    this.actualizarCuenta.emit(this.cuenta);
  }
}
