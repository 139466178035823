import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { SpinnerService } from '../services/spinner.service';
import { firstValueFrom, take } from 'rxjs';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ValidateLoginService {

  constructor(private store: Store<AppState>, private spinner: SpinnerService, private router: Router) { }

  validateLogin(){
    const ref = this.spinner.start();
    setTimeout(async ()=>{
      const store =await firstValueFrom(this.store.select('auth').pipe(take(1)));
      console.log(store);
      if( store.isLogged && store.user && this.isNotNullOrEmpty(store.user) && store.user.estadoComplianceID === 3){
        this.spinner.stop(ref);
      }else{
        this.spinner.stop(ref);
        Swal.fire({
          icon: 'warning',
          title: 'Completa tu validación',
          text: 'Para poder acceder a esta funcionalidad debes completar tu proceso de validación, si ya está en proceso por favor espera a que finalice',
          showCancelButton: false,
          showDenyButton: false,
          confirmButtonText: 'Entendido',
          confirmButtonColor: '#1e5a3f',

        });
        this.router.navigate(['/wallet/home']);

      }
    }, 3000)

  }
  isNotNullOrEmpty(object: Object) {
    return object && Object.keys(object).length > 0;
  }
}
