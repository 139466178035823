<div class="m-4 w-full  overflow-x-auto ">
    <h1 class="m-4 text-2xl font-bold"> Transacciones Ves Completadas </h1>

    <mat-form-field class="p-4" style="width: 90%;">

        <input matInput type="text" (keyup)="doFilter($event)" placeholder="Filtrar datos">
        <mat-icon matPrefix class="my-icon">search</mat-icon>
    </mat-form-field>

    <div class="m-4 flex flex-col md:flex-row gap-4">
        <mat-form-field class="w-[250px]">
            <mat-label>Ingresa un rango de fechas</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="Start date">
                <input matEndDate formControlName="end" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Fecha fin invalida</mat-error>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="loadTransactions()" class="h-[56px]  md:w-[100px]">
            Buscar
            <mat-icon> search </mat-icon>
        </button>
    </div>

    <div class="m-4 flex flex-col md:flex-row gap-4">
        <mat-paginator #paginator class="demo-paginator self-left" (page)="onPageChange($event)" [length]="totalItems"
            [pageSize]="pageSize" [disabled]="false" [showFirstLastButtons]="true"
            [pageSizeOptions]="[5, 10, 25, 50, 100]" [hidePageSize]="false" [pageIndex]="currentPage-1"
            aria-label="Select page">
        </mat-paginator>
    </div>

    <div class="flex overflow-x-auto max-w-[90%]">

        <table mat-table [dataSource]="dataSource" class="m-4" *ngIf="dataSource">
            <ng-container matColumnDef="seleccion">
                <th mat-header-cell *matHeaderCellDef aria-label="row actions" style="background-color: #CEE9C2;">
                    Seleccionar</th>
                <td mat-cell *matCellDef="let element" class="text-center">
                    <mat-checkbox (change)="change()" [disabled]="element.estado !== 'POR_PROCESAR'"
                        [(ngModel)]="element.seleccionado" class="example-margin"></mat-checkbox>
                </td>
            </ng-container>
            <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
                <th mat-header-cell *matHeaderCellDef style="background-color: #CEE9C2; text-align:center">
                    {{column.header}}</th>
                <td mat-cell *matCellDef="let row">{{column.cell(row)}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>