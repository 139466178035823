import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, interval } from 'rxjs';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import { BinanceService } from 'src/app/dashboard/services/binance.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import Swal from 'sweetalert2';
import { SetValuesComponent } from '../manual-rate/set-values/set-values.component';

@Component({
  selector: 'app-binance-rate',
  templateUrl: './binance-rate.component.html',
  styleUrls: ['./binance-rate.component.sass'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class BinanceRateComponent implements OnDestroy {
  tasasMin: any = {
    banesco: 0,
    venezuela: 0,
    mercantil: 0,
    provincial: 0,
  };
  tasasMax: any = {
    banesco: 0,
    venezuela: 0,
    mercantil: 0,
    provincial: 0,
  };
  variablesCambio: any;
  timer = interval(60000);
  subscriptions: Subscription = new Subscription();
  email = '';
  tasas: any;
  currentRates: any;
  currentValues: any;
  @Input() showCopyToClipboard = false;

  constructor(private spinner: SpinnerService, private binanceService: BinanceService, private auth: CognitoService, private dialog: MatDialog ) {
   this.initDatos();
  }

  async initDatos(){
    this.getDatosUsuario();
    await this.getCurrentRates();
    await this.getCurrentValues();
    this.calcularTasas();
    this.subscriptions.add(
      this.timer.subscribe(()=>{
        this.calcularTasas();
      })
    )
  }
  async getCurrentRates(){
    this.currentRates = await this.binanceService.getCurrentRates();
  }
  async getCurrentValues(){
    this.currentValues = await this.binanceService.getConversionData();
  }

  copyMessageToClipboard(){
    const message = this.generateMessage();
    const listener = (e: ClipboardEvent) => {
      e.clipboardData!.setData('text/plain', message);
      e.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }

  generateMessage(): string {
    return `ACT🏃🏻🔥🇻🇪
**Minimo 15,000 Bs**
BANESCO \t${this.tasasMax.banesco.toFixed(5)}
PROVINCIAL \t${this.tasasMax.provincial.toFixed(5)}
MERCANTIL\t${this.tasasMax.mercantil.toFixed(5)}
VENEZUELA\t${this.tasasMax.venezuela.toFixed(5)}

**Minimo 10,000 Bs**
BANESCO \t${this.tasasMin.banesco.toFixed(5)}
PROVINCIAL \t${this.tasasMin.provincial.toFixed(5)}
MERCANTIL\t${this.tasasMin.mercantil.toFixed(5)}
VENEZUELA\t${this.tasasMin.venezuela.toFixed(5)}

Favor enviar datos bancarios sin puntos ni guiones

**Disclaimer: al enviar pedido el cliente acepta que su pago puede demorar, no es permitido devolución ni cambio de tasa**`;
}

  ngOnDestroy(): void {
   this.subscriptions.unsubscribe();
  }

  async getDatosUsuario(){
    const cognitoUser = await this.auth.getUser();
    this.email = cognitoUser.attributes['email'];
  }


  async calcularTasas() {
    this.tasas = await this.binanceService.getBinanceRates();
    const valoresCambio = await this.binanceService.getConversionData();
    this.variablesCambio= valoresCambio;


    this.tasasMin.banesco = this.calculoTasaMin(
      this.currentRates.tasaCLP,
      +this.tasas.banesco.precio,
      +this.currentRates.comision   ,
      +valoresCambio.descuento
    );
    if(this.tasas.bankVenezuela)
    this.tasasMin.venezuela = this.calculoTasaMin(
      this.currentRates.tasaCLP,
      +this.tasas.bankVenezuela.precio,
      +this.currentRates.comision   ,
      +valoresCambio.descuento
    );
    this.tasasMin.mercantil = this.calculoTasaMin(
      this.currentRates.tasaCLP,
      +this.tasas.mercantil.precio,
      +this.currentRates.comision   ,
      +valoresCambio.descuento
    );
    this.tasasMin.provincial = this.calculoTasaMin(
      this.currentRates.tasaCLP,
      +this.tasas.provincial.precio,
      +this.currentRates.comision   ,
      +valoresCambio.descuento
    );

    //TASA MAX
    this.tasasMax.banesco = this.calculoTasaMax(
      this.currentRates.tasaCLP,
      +this.tasas.banesco.precio,
      +this.currentRates.comision
    );
    if(this.tasas.bankVenezuela)
    this.tasasMax.venezuela = this.calculoTasaMax(
      this.currentRates.tasaCLP,
      +this.tasas.bankVenezuela.precio,
      +this.currentRates.comision   );
    this.tasasMax.mercantil = this.calculoTasaMax(
      this.currentRates.tasaCLP,
      +this.tasas.mercantil.precio,
      +this.currentRates.comision   );
    this.tasasMax.provincial = this.calculoTasaMax(
      this.currentRates.tasaCLP,
      +this.tasas.provincial.precio,
      +this.currentRates.comision    );
  }

  calculoTasaMax(clpValor: number, valorCompraVES: number, comision: number) {
    const usdt_1 = 10000 / +clpValor;
    const porcentaje_1 = (usdt_1 * comision) / 100;
    const usdt_2 = usdt_1 - porcentaje_1;
    const total_ves = usdt_2 * valorCompraVES;
    return (total_ves / 10000);
  }



  async setRates(){
    const data= {
      tasaMercantil: this.tasas.mercantil.precio,
      tasaVenezuela: this.tasas.bankVenezuela.precio,
      tasaProvincial: this.tasas.provincial.precio,
      tasaBanesco: this.tasas.banesco.precio,
      tasaCLP: this.currentRates.tasaCLP,
      porcentajeComision: this.currentRates.comision,
      fuente: 'BINANCE'
    }
    this.dialog.open(SetValuesComponent, { data }).afterClosed().subscribe(res=>{
      if(res && res ===true){
        this.getCurrentRates();
        this.getCurrentValues();
        this.calcularTasas();
      }
    })
  }
  calculoTasaMin(
    clpValor: number,
    valorCompraVES: number,
    comision: number,
    descuento: number
  ) {
    const usdt_1 = 10000 / +clpValor;
    const porcentaje_1 = (usdt_1 * comision) / 100;
    const usdt_2 = usdt_1 - porcentaje_1;
    const total_ves = usdt_2 * valorCompraVES;
    return (total_ves / 10000) - Number(descuento);
  }
}
