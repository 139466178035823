
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CuentaBeneficiario } from '../interfaces/cuenta-beneficiario.interface';
import { DataTransferencia } from '../interfaces/lista-transferencia.interface';

@Injectable({
  providedIn: 'root'
})
export class MultipleExchangeService {
  private exchangeData$ = new BehaviorSubject<any | null>(null);
  private resetCalculator$ =  new BehaviorSubject<any | null>(null);
  public transactionList: DataTransferencia[] = []
  public valorATransferir = 0;
  currentData$ = this.exchangeData$.asObservable();
  currentStatusCalculator$ = this.resetCalculator$.asObservable();
  constructor() {}

  addToList(data: DataTransferencia) {
    this.transactionList.push(data);
    this.valorATransferir+= data.cambio.envia;

    console.log(this.valorATransferir);
    console.log(this.transactionList);
    this.exchangeData$.next([...this.transactionList]);
  }

  getItems() {
    return this.transactionList;
  }

  deleteElement(index:number){
    const valor = this.transactionList[index];
    this.transactionList.splice(index,1);
    this.valorATransferir -= valor.cambio.envia;
    this.exchangeData$.next([...this.transactionList]);
  }

  setExchangeData(data: any) {
    this.exchangeData$.next(data);
  }
  resetCalculator(data: any) {
    this.resetCalculator$.next(data);
  }

  clear(){
    this.transactionList = [];
    this.exchangeData$.next([]);

  }
}
