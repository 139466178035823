import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { BinanceService } from '../../services/binance.service';
import { CartolaService } from '../../services/cartola.service';
import { ActionsService } from 'src/app/shared/services/actions.service';

@Component({
  selector: 'app-cripto-home',
  templateUrl: './cripto-home.component.html',
  styleUrls: ['./cripto-home.component.sass']
})
export class CriptoHomeComponent implements OnInit, OnDestroy{

  constructor(
    private cartolaService: CartolaService,
    private store: Store<AppState>,
    private binanceService: BinanceService,
    private actionService: ActionsService
  ){}
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @ViewChild('stepper') stepper: MatStepper | undefined;
  private intervalToRefreshValue = interval(60000);
  public subscription: Subscription = new Subscription();
  public user: any;
  public saldoActual = 0;
  public hasLoaded:boolean =  false;
  public usdt_clp_tax: number = 0;
  public sobregiroDisponible :number|undefined=0;
  public saldoContable :number|undefined= 0;
  public lineaCreditoBloqueada:boolean|undefined=false;
  public lineaCredito:number|undefined=0;

  ngOnInit(): void {
    this.subscription.add(
      this.intervalToRefreshValue.subscribe(()=>this.getCurrentValues())
    );
    this.subscription.add(
      this.actionService.subjectTasaUSDT.subscribe(res=>{
        this.getCurrentValues();
      })
    )
    this.subscription.add(
      this.store.select('auth').subscribe(({ user }: any) => {
        this.user = user;
        this.obtenerSaldo();
      })
    );
    this.getCurrentValues();
  }

  async obtenerSaldo(){
    if(this.user?.usuarioId){
      const {saldo,lineaCreditoId,balancePermitido,lineaBloqueada,sobregiro} = await this.cartolaService.getSaldoCliente(this.user.usuarioId);
      this.saldoActual = saldo;
      this.sobregiroDisponible=sobregiro;
      this.saldoContable = balancePermitido;
      this.lineaCreditoBloqueada=lineaBloqueada;
      this.lineaCredito=lineaCreditoId;
      this.hasLoaded = true;
    }
  }

  async getCurrentValues(){
    const currentValues = await this.binanceService.getConversionData();
    this.usdt_clp_tax = currentValues.tasaUsdtClp;
  }






}
