<div class="m-4 overflow-x-auto items-center">
  <h1 class="m-4 text-2xl font-bold text-center"> Transacciones rezagadas </h1>
  <form [formGroup]="filterForm"  *ngIf="filterForm">
    <div class="flex flex-wrap gap-5 m-4 justify-around" style="max-width: 90%; margin: 0 auto;">
      <div class="flex flex-row flex-wrap gap-2 justify-center">
        <div class="w-[250px] md:w-[280px] item">
          <mat-form-field class="search-date w-full" >
          <mat-label>Ingresa un rango de fechas</mat-label>
          <mat-date-range-input [rangePicker]="datePicker">
            <input matStartDate formControlName="startDate" placeholder="Fecha inicial">
            <input matEndDate formControlName="endDate" placeholder="Fecha final">
          </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-date-range-picker #datePicker></mat-date-range-picker>
            <div *ngIf="(filterForm.get('endDate')?.value==null&&filterForm.get('startDate')!=null)||(filterForm.get('startDate')?.value==null&&filterForm.get('endDate')!=null)" class="text-red-500 mt-2">
              Porfavor seleccionar fecha inicial y fecha final.
            </div>
          </mat-form-field>

        </div>
        <div class="w-[195px] md:w-[250px] item">
          <mat-form-field class="w-full">
            <mat-label>Ingresa hora inicial</mat-label>
            <mat-select formControlName="startTime" placeholder="Hora inicial" (change)="updateEndTimeOptions()">
              <mat-option *ngFor="let hour of hours" [value]="hour">{{ hour }}</mat-option>
            </mat-select>
            <div *ngIf="(filterForm.get('startTime')?.value==undefined&&filterForm.get('endTime')?.value!=undefined)" class="text-red-500 mt-2">
              Porfavor ingresar hora inicial.
            </div>
          </mat-form-field>
        </div>
        <div class="w-[195px] md:w-[250px] item">
          <mat-form-field class="w-full">
            <mat-label>Ingresa hora final</mat-label>
            <mat-select formControlName="endTime" placeholder="Hora final" class="text-gray-300">
              <ng-container *ngFor="let hour of hours; let i = index">
                <mat-option *ngIf="i >= hours.indexOf(filterForm.get('startTime')?.value || '')" [value]="hour">{{ hour }}</mat-option>
              </ng-container>
            </mat-select>
            <div *ngIf="(filterForm.get('endTime')?.value==undefined&&filterForm.get('startTime')?.value!=undefined)" class="text-red-500 mt-2">
              Porfavor ingresar hora final.
            </div>
          </mat-form-field>
        </div>
      </div>
      <div class="flex flex-wrap gap-2 justify-center">
        <div class="w-[195px] md:w-[250px] item">
          <mat-form-field class="w-full">
            <mat-label>Cuenta Destino</mat-label>
            <input matInput formControlName="destAcc" placeholder="Cuenta Destino">
          </mat-form-field>
        </div>
        <div class="w-[195px] md:w-[250px] item">
          <mat-form-field class="w-full">
            <mat-label>RUT</mat-label>
            <input matInput formControlName="rut" placeholder="RUT">
          </mat-form-field>
        </div>
      </div>
      <div class="flex flex-wrap gap-2 justify-center">
        <div class="w-[150px] md:w-[250px] item">
          <mat-form-field class="w-full">
            <mat-label>Cuenta Origen</mat-label>
            <input matInput formControlName="originAcc" placeholder="Cuenta Origen">
          </mat-form-field>
        </div>
        <div class="w-[150px] md:w-[250px] item">
          <mat-form-field class="w-full">
            <mat-label>Nombre Origen</mat-label>
            <input matInput formControlName="name" placeholder="Nombre Origen">
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>


  <div class="flex justify-center gap-5">
    <button type="button" mat-raised-button color="primary" class="px-4 py-2 rounded items-center" (click)="loadTransactions()">Filtro</button>
    <button type="button" mat-raised-button color="primary" class="px-4 py-2 rounded items-center" (click)="clearFilters()">Limpiar Filtros</button>
  </div>
  <div class="m-4">
    <div class="paginator-container my-4">
      <mat-paginator #paginator
               class="demo-paginator self-left"
               (page)="onPageChange($event)"
               [length]="totalItems"
               [pageSize]="pageSize"
               [disabled]="false"
               [showFirstLastButtons]="true"
               [pageSizeOptions]="[5, 10, 25, 50, 100]"
               [hidePageSize]="false"
               [pageIndex]="currentPage-1"
               aria-label="Select page">
      </mat-paginator>
    </div>
    <div class="table-container flex overflow-x-auto">
      <table mat-table [dataSource]="dataSource" *ngIf="dataSource">
        <ng-container
          *ngFor="let column of columns"
          [matColumnDef]="column.columnDef"
        >
          <mat-header-cell
          *matHeaderCellDef
          style="background-color: #CEE9C2;font-weight: bold;"
          [ngStyle]="column.headerStyle"
          >
            {{column.header}}
          </mat-header-cell>

          <mat-cell
          *matCellDef="let row"
          [ngStyle]="column.cellStyle"
          >
            <ng-container>
              {{ column.cell(row) }}
            </ng-container>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="acciones" stickyEnd>
          <mat-header-cell *matHeaderCellDef aria-label="row actions" style="background-color: #CEE9C2; font-weight: bold;">Acciones</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-icon-button color="accent" aria-label="Asignar" title="Asignar" *ngIf="element.moSaldoAbono>0" (click)="assignDialog(element)">
              <mat-icon>assignment_add</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </table>
    </div>
  </div>
</div>
