import { Component,EventEmitter,Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BeneficiarioCriptomoneda } from 'src/app/dashboard/interfaces/beneficiario-criptomoneda.interface';
import { CuentaRetiro } from 'src/app/dashboard/interfaces/cuenta-retiro.interface';
import { TransaccionService } from 'src/app/dashboard/services/transaccion.service';
import { ErrorTypes } from 'src/app/shared/enums/error-types.enum';
import { TipoPago } from 'src/app/shared/enums/tipo-pago.enum';
import { ActionsService } from 'src/app/shared/services/actions.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-withdraw-summary-order',
  templateUrl: './withdraw-summary-order.component.html',
  styleUrls: ['./withdraw-summary-order.component.sass']
})
export class WithdrawSummaryOrderComponent {
  constructor(
    private spinnerService: SpinnerService,
    private transaccionService: TransaccionService,
    private router: Router,
    private actionService: ActionsService
    ){
      
    }

  @Input()
  public montoRetiro: number = 0;

  @Input()
  public account: CuentaRetiro | undefined;

  @Input()
  public usuarioId: number | undefined;

  @Output()
  public goBack = new EventEmitter<any>();


  async confirmarEnvio(){
    const ref = this.spinnerService.start();

    const transaccionDTO ={
      "paisOrigen":'CL',
      "userId":this.usuarioId,
      "totalMonedaOrigen": +this.montoRetiro,
      "totalMonedaDestino":+this.montoRetiro,
      "monedaOrigen":'CLP',
      "monedaDestino":'CLP',
      "paisDestino":'CL',
      "formaPago":TipoPago.WALLET,
      "diferencia":null,
      "tasa": null,
      "beneficiarioCriptoId":null,
      "tasaCambioId":null,
      "valorCambioId":null,
      "tipoTransaccion": 'RETIRO',
      "cuentaRetiroId":this.account?.id,
    }
    console.log(transaccionDTO);
    try{
      const resultadoTransaccion = await this.transaccionService.saveTransaccion(transaccionDTO);
      this.spinnerService.stop(ref);
      Swal.fire('Transaccion creada', `Se ha creado la transaccion con éxito`, 'success' );
      this.router.navigate(['/wallet/home']);

    }catch(error: any){
      console.log(error);
      Swal.fire('Error al retirar dinero','Ha ocurrido un error al realizar la transferencia', 'warning').then(()=>{
      this.spinnerService.stop(ref);
      this.router.navigate(['/wallet/client/withdraw']);
      });
    }
  }

  goToBack(): void {
    this.goBack.emit();
  }
}
