import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import { CognitoService } from 'src/app/auth/services/cognito.service';
import { BinanceService } from 'src/app/dashboard/services/binance.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-set-values',
  templateUrl: './set-values.component.html',
  styleUrls: ['./set-values.component.sass']
})
export class SetValuesComponent {
  formCuenta: FormGroup | undefined;

  subscriptions: Subscription = new Subscription();
  esUpdate = false;
  email: string  = '';
  constructor(
    private fb: FormBuilder,
    private auth: CognitoService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private binanceService: BinanceService,
    private dialogRef: MatDialogRef<SetValuesComponent>,
    private spinner: SpinnerService,
  ) {
    this.initFormCuenta();
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async initFormCuenta() {
    const cognitoUser = await this.auth.getUser();
    this.email = cognitoUser.attributes['email'];
    this.formCuenta = this.fb.group({
      tasaBanesco: new FormControl(  this.data.tasaBanesco , [Validators.required, Validators.min(0)]),
      tasaProvincial: new FormControl( this.data.tasaProvincial , [Validators.required, Validators.min(0)]),
      tasaVenezuela: new FormControl( this.data.tasaVenezuela , [Validators.required, Validators.min(0)]),
      tasaMercantil: new FormControl( this.data.tasaMercantil , [Validators.required, Validators.min(0)]),
      tasaCLP:new FormControl( this.data.tasaCLP , [Validators.required, Validators.min(0)]),
      porcentajeComision:new FormControl( this.data.porcentajeComision , [Validators.required, Validators.min(0.7)]),
    });
  }

  async actualizarTasa(){
    if(this.formCuenta?.invalid)return;
    const tasas = this.formCuenta?.value;
    tasas['valorBanesco']= tasas.tasaBanesco;
    tasas['valorProvincial']= tasas.tasaProvincial;
    tasas['valorMercantil']= tasas.tasaMercantil;
    tasas['valorVenezuela']= tasas.tasaVenezuela;
    let ref = this.spinner.start();
    tasas.tasaBanesco = this.calculoTasaMax(tasas.tasaCLP, tasas.valorBanesco, tasas.porcentajeComision);
    tasas.tasaProvincial = this.calculoTasaMax(tasas.tasaCLP, tasas.valorProvincial, tasas.porcentajeComision);
    tasas.tasaMercantil = this.calculoTasaMax(tasas.tasaCLP, tasas.valorMercantil, tasas.porcentajeComision);
    tasas.tasaVenezuela = this.calculoTasaMax(tasas.tasaCLP, tasas.valorVenezuela, tasas.porcentajeComision);

    try{
      await this.binanceService.updateRates(tasas, this.email, this.data.fuente);
      this.spinner.stop(ref);
      Swal.fire('Tasa actualizada','Se han actualizado los valores', 'success').then(()=>{
        this.dialogRef.close(true);
      });
    }catch(error){
      this.spinner.stop(ref);
      Swal.fire('Error','No se ha podido actualizar la tasa', 'error').then(()=>{
        this.dialogRef.close(false);
      });
    }


  }


  calculoTasaMax(clpValor: number, valorCompraVES: number, comision: number) {
    const usdt_1 = 10000 / +clpValor;
    const porcentaje_1 = (usdt_1 * comision) / 100;
    const usdt_2 = usdt_1 - porcentaje_1;
    const total_ves = usdt_2 * valorCompraVES;
    return (total_ves / 10000);
  }

  confirmarEnvio(){

  }

}
