<div class="p-6 space-y-4 md:space-y-6 sm:p-8">
  <div class="flex justify-end">
    <button (click)="cancel()" class="button_close text-white p-1"><mat-icon>close</mat-icon></button>
  </div>
  <div class="container flex flex-col items-center">
    <h1 class="text-xl font-bold">
      Registrar
    </h1>
  </div>
  <form
  class="space-y-4 md:space-y-6">
    <div class="grid grid-cols-1 gap-4">
      <input
      type="text"
      pattern="[a-zA-Z]*"
      placeholder="Nombre y apellido"
      (keypress)="soloLetras($event)">
    </div>
    <div class="grid grid-cols-1 gap-4">
      <select name="select">
        <option value="1" disabled selected>Tron (TRC 20)</option>
      </select>
    </div>
    <div class="grid grid-cols-1 gap-4">
      <input (keypress)="soloNumeros($event)" type="text" placeholder="USDT Dirección  de depósito">
    </div>
    <div class="container flex flex-row gap-4 items-center justify-center">
      <button mat-raised-button type="submit" color="primary">Agregar Beneficiario</button>
    </div>
  </form>
</div>

