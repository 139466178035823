import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree, createUrlTreeFromSnapshot } from '@angular/router';
import { Observable, from, map } from 'rxjs';
import { CognitoService } from 'src/app/auth/services/cognito.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private cognitoService: CognitoService, private router: Router){

  }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      console.log("Calling canActivate");
      this.cognitoService.getAuthUser().then(res=>{
        console.log(res)
      })
      this.cognitoService.getSession().then(res=>{
        console.log(res);
      })
    return from(this.cognitoService.getUser()).pipe(map((userInfo: any)=>{
      console.log(userInfo);
      return (userInfo?.attributes?.email) ? true : createUrlTreeFromSnapshot(route, ['/auth/login']);
    }));

}
}
