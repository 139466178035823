import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { AuthEffects } from './auth.effects';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { RequestPasswordResetComponent } from './components/request-password-reset/request-password-reset.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { AuthComponent } from './pages/auth/auth.component';
import { MailValidationComponent } from './components/mail-validation/mail-validation.component';
const routes: Routes = [
  {
    path: 'auth',
    component: AuthComponent,

    children: [
      { path: 'login', component: SignInComponent },
      { path: 'signup', component: SignUpComponent },
      { path: 'complete-password-reset', component: PasswordResetComponent },
      { path: 'request-password-reset', component: RequestPasswordResetComponent },
      { path: 'confirm-mail-verification/:email/:code', component: MailValidationComponent },
      {path: '**', redirectTo: 'login', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
