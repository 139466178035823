import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { CuentaTrabajo } from '../../interfaces/cuenta-trabajo.interface';
import { WorkingAccountService } from '../../services/working-account.service';
import { AddWithdrawAccountsComponent } from '../add-withdraw-accounts/add-withdraw-accounts.component';
import { CuentaRetiro } from '../../interfaces/cuenta-retiro.interface';
import { WithdrawAccountService } from '../../services/withdraw-account.service';

@Component({
  selector: 'app-withdraw-accounts',
  templateUrl: './withdraw-accounts.component.html',
  styleUrls: ['./withdraw-accounts.component.sass']
})
export class WithdrawAccountsComponent {
  subscriptions: Subscription = new Subscription();
  usuarioID: number | undefined;
  listaCuentas: CuentaRetiro[] = [];
  @Input() seleccionable = false;
  @Output() seleccion: EventEmitter<CuentaRetiro> = new EventEmitter();
  constructor(
    private store: Store<AppState>,
    private withdrawAccountService: WithdrawAccountService,
    private dialog: MatDialog
  ) {
    this.initFormCuenta();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async initFormCuenta() {
    this.listaCuentas = await this.withdrawAccountService.getWithdrawAccounts();
    console.log(this.listaCuentas);
  }
  ngOnInit() {}

  async reloadAccount(){
    this.listaCuentas = await this.withdrawAccountService.getWithdrawAccounts();
  }

  openCreateDialog(cuenta?: CuentaRetiro):void{
    const dialogRef = this.dialog.open(AddWithdrawAccountsComponent, {
      data: {
        type: cuenta? 'UPDATE': 'CREATE',
        cuenta
      },
      height: '70%'
    });
    dialogRef.afterClosed().subscribe(async result => {
      if(result == true){
        await this.reloadAccount();
      }
    })
  }

  onSeleccion(cuenta: CuentaRetiro){
    this.seleccion.emit(cuenta);
  }
}
