<app-usdt-change [usdt_clp_tax]="usdt_clp_tax" class="usdtChange"></app-usdt-change>
<div class="flex flex-row justify-between items-center mx-5">
  <div class="balance_container">
    <h2 [ngStyle]="{ display: hasLoaded ? '' : 'none' }" class="mb-3 font-bold">${{ saldoActual | number }} CLP$</h2>
    <img *ngIf="!hasLoaded" src="assets/loader.svg" height="60" width="60" class="mt-3 mx-auto" alt="">
    <p class="font-bold">Saldo Actual</p>
  </div>
</div>
<div class="flex flex-row justify-between items-center mx-5 mt-8">
  <button mat-raised-button color="primary" class="p-5 text-lg" routerLink="/wallet/client/rechargeAccount">
    Recargar Saldo
  </button>
  <button (click)="emitChangeStep()" mat-raised-button color="primary" class="p-5 text-lg">
    Comprar Criptomoneda
  </button>
</div>
