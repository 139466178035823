<div class="h-screen flex flex-row justify-around items-center content-center gap-4	md:mx-4">
  <div class=" w-11/12 md:w-1/2 xl:w-2/5  h-auto  bg-aguacate-bg rounded-lg shadow  xs:p-0">
    <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
      <div class="container flex flex-col items-left">
        <h1 class="font-medium text-2xl">Ingresa tu nueva contraseña</h1>
        <br>
        <p class="font-medium">Debe tener al menos ocho caracteres, contener caracteres especiales,
          minúsculas y mayúsculas, números y símbolos.</p>
      </div>
      <form class="space-y-4 md:space-y-6" [formGroup]="passwordForm" (ngSubmit)="onSubmit(passwordForm)">
        <div>
          <label for="password" class="block mb-2 text-sm font-medium text-gray-900">Contraseña</label>
          <input type="password" name="password" id="password" placeholder="••••••••" formControlName="password"
            class="bg-aguacate-green-50 border border-aguacate-green-300 text-primary-900 sm:text-sm rounded-lg focus:ring-aguacate-green-600 focus:border-aguacate-green-600 block w-full p-2.5"
            required="" />
          <div
            *ngIf="passwordForm.get('password')?.invalid && (passwordForm.get('password')?.dirty || passwordForm.get('password')?.touched)">
            <span *ngIf="passwordForm.get('password')?.hasError('required')" class="text-sm text-red-600"> Ingresa la
              contraseña </span>
            <span *ngIf="passwordForm.get('password')?.hasError('pattern')" class="text-sm text-red-600"> La contraseña
              debe contener:
              <ol>
                <li>Mínimo 8 caracteres</li>
                <li>Mínimo un numero</li>
                <li>Mínimo una letra mayuscula y una minuscula</li>
                <li>Mínimo un caracter especial ej: ^ $ * . [ ] {{'{ }' }} ( ) ? " ! @ # % & / \ , > < ' : ; | _ ~ ` = + - </li>
                </ol> </span>
            </div>

        </div>
        <div>
          <label for="passwordVerification" class="block mb-2 text-sm font-medium text-gray-900" >Confirmación de contraseña</label>
          <input type="password" name="passwordVerification" id="passwordVerification" placeholder="••••••••" formControlName="passwordVerification"
            class="bg-aguacate-green-50 border border-aguacate-green-300 text-primary-900 sm:text-sm rounded-lg focus:ring-aguacate-green-600 focus:border-aguacate-green-600 block w-full p-2.5"
            required="" />
            <div *ngIf="passwordMatchError">
              <span class="text-sm text-red-600"> Las contraseñas no coinciden </span>
            </div>

        </div>
        <div class="container flex flex-col items-center">
          <button type="submit" [disabled]="passwordForm.invalid"
            class="w-1/2 text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
            Reiniciar contraseña
          </button>
          <span *ngIf="passwordResetError" class="text-sm text-red-600 mt-4"> Credenciales incorrectas </span>
        </div>
      </form>
    </div>
  </div>
</div>
