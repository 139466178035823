import { Component,OnInit,Input } from '@angular/core';
import { BinanceService } from 'src/app/dashboard/services/binance.service';

@Component({
  selector: 'app-usdt-change',
  templateUrl: './usdt-change.component.html',
  styleUrls: ['./usdt-change.component.sass']
})
export class UsdtChangeComponent implements OnInit {

  @Input()
  public usdt_clp_tax: number = 0;
  public hasLoaded:boolean =  true;

  constructor(){}

  ngOnInit(): void {}

}
