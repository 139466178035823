import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-withdraw-balance-view',
  templateUrl: './withdraw-balance-view.component.html',
  styleUrls: ['./withdraw-balance-view.component.sass']
})
export class WithdrawBalanceViewComponent implements OnInit {
  @Input()
  public actualBalance: number = 0;
  public hasLoaded:boolean =  true;

  constructor(){}

  ngOnInit(): void {}
}
