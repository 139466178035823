import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecipientCriptoService {
  API_URL = environment.apiGatewayURL;

  constructor(private http: HttpClient) {}

  saveRecipientAccount(recipientAccount: any): Promise<any> {
    const serviceURL = `${this.API_URL}recipient/usdt/beneficiario-cripto`;
    return firstValueFrom(this.http.post<any>(serviceURL,recipientAccount));
  }
  getByUser(userId: number): Promise<any[]>{
    const serviceURL = `${this.API_URL}recipient/usdt/beneficiario-cripto/${userId}`;
    return firstValueFrom(this.http.get<any[]>(serviceURL));
  }
  inactivateAccount(accountId: number): Promise<any>{
    const serviceURL = `${this.API_URL}recipient/usdt/inactivate-beneficiario-cripto/${accountId}`;
    const body = { cuentaId: accountId };
    return firstValueFrom(this.http.put<any>(serviceURL, {}));
  }

  getTransactionCripto(currentPage: number, pageSize: number): Promise<any>{
    const serviceURL = `${this.API_URL}transaccion/transaccionUSDT?page=${currentPage}&pageSize=${pageSize}`;
    return firstValueFrom(this.http.get<any[]>(serviceURL));
  }

  getTransactionCriptoManualTransactions(id: string): Promise<any>{
    const serviceURL = `${this.API_URL}transaccion/manual-related?id=${id}`;
    return firstValueFrom(this.http.get<any[]>(serviceURL));
  }

}
