<div style="display: flex; justify-content: center; ">
    <div class="w-full md:w-9/12 mt-4">
        <h1 class="font-semibold text-xl text-center mb-4">Modificar parámetros para tasa fiat</h1>
        <form [formGroup]="ratesFiatForm" style="display: flex; flex-direction: column;">
          <mat-form-field>
            <mat-label >Valor Solvencia (ValorSL)</mat-label>
            <input matInput formControlName="valorSolvenciaFiat" appOnlynumber matInputCommified type="text" placeholder="Valor SL">
            <span matTextPrefix>Bs  &nbsp; &nbsp; </span>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Valor Liquidez (ValorLQ)</mat-label>
            <input matInput formControlName="valorLiquidezFiat"  appOnlynumber matInputCommified type="text" placeholder="Valor LQ">
            <span matTextPrefix>USDT  &nbsp;</span>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Valor Descuento (Bs)</mat-label>
            <input matInput formControlName="valorDescuentoFiat"  appOnlynumber matInputCommified type="text" placeholder="Valor descuento">
            <span matTextPrefix>Bs  &nbsp;</span>
          </mat-form-field>
          <div class="flex flex-row justify-center gap-10">
            <button mat-raised-button color="primary" (click)="showConfirm()">Actualizar</button>
            <button mat-raised-button  (click)="reset()">Restablecer</button>
          </div>
        </form>
    </div>
</div>
<div class="flex flex-col w-full justify-center items-center">
    <app-binance-rate  [showCopyToClipboard]="true" class="w-full md:w-9/12"></app-binance-rate>
    <button mat-raised-button  (click)="reloadData()" class="my-4">Refrescar</button>
</div>