import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getQueryString } from 'src/app/shared/utils/misc.util';

@Injectable({
  providedIn: 'root'
})
export class CartolaService {
  API_URL = environment.apiGatewayURL;

  constructor(private http: HttpClient) {}

  getSaldoCliente(usuarioId: string): Promise<{saldo: number, lineaCreditoId: number | undefined, balancePermitido: number, lineaBloqueada: boolean | undefined, sobregiro: number | undefined}>{
    const serviceURL = `${this.API_URL}saldo/${usuarioId}`;
    return firstValueFrom(this.http.get<{saldo: number, lineaCreditoId: number | undefined, balancePermitido: number, lineaBloqueada: boolean | undefined, sobregiro: number | undefined}>(serviceURL));
  }
  getTransaccionesCliente(usuarioId: string): Promise<any[]>{
    const serviceURL = `${this.API_URL}depositos/${usuarioId}`;
    return firstValueFrom(this.http.get<any[]>(serviceURL));
  }

  getTransaccionesRezagadas(query: any, currentPage: number, pageSize: number): Promise<any>{
    const queryString = getQueryString(query);
    const serviceURL = `${this.API_URL}unassigned-transaction?${queryString}&page=${currentPage}&pageSize=${pageSize}`;
    return firstValueFrom(this.http.get<any>(serviceURL));
  }

  getBillingRecords(startDate: string, endDate: string, rutEmisor: string): Promise<any[]>{
    const serviceURL = `${this.API_URL}transaccion/billing-record?startDate=${startDate}&endDate=${endDate}&rutEmisor=${rutEmisor}`;
    return firstValueFrom(this.http.get<any[]>(serviceURL));
  }

  getEmisores(): Promise<any[]>{
    const serviceURL = `${this.API_URL}general-types/emisores`;
    return firstValueFrom(this.http.get<any[]>(serviceURL));
  }

  setEstadoEmisor(id: number, status: boolean): any{
    const serviceURL = `${this.API_URL}general-types/emisores`;
    return firstValueFrom(this.http.post<any[]>(serviceURL, {id: id, status: status}));
  }
}
