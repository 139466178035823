import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { BeneficiarioZelle } from 'src/app/dashboard/interfaces/beneficiario-zelle.interface';

@Component({
  selector: 'app-recipient-zelle-list',
  templateUrl: './recipient-zelle-list.component.html',
  styleUrls: ['./recipient-zelle-list.component.sass']
})
export class RecipientZelleListComponent implements OnInit, OnDestroy {
  usuarioID: number | undefined;
  @Output() reloadAccountList: EventEmitter<any> = new EventEmitter();
  @Output() actualizarCuenta: EventEmitter<BeneficiarioZelle> = new EventEmitter();
  @Output() seleccion: EventEmitter<BeneficiarioZelle> = new EventEmitter();
  subscriptions: Subscription = new Subscription();
  @Input() listaCuentas: BeneficiarioZelle[] = [];

  @Input() seleccionable: boolean = false;
  cuentaSeleccionada: BeneficiarioZelle | undefined;
  constructor()
  {
  }


  ngOnInit(): void {

  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  eliminarCuenta(){
    this.reloadAccountList.emit();
  }

  actualizar(cuenta: BeneficiarioZelle){
    this.actualizarCuenta.emit(cuenta);
  }

  seleccionar(cuenta: any){
    if(!this.seleccionable)return;
    if(this.cuentaSeleccionada && this.cuentaSeleccionada?.id !== cuenta.beneficiario_id){
      this.cuentaSeleccionada.seleccionado=false;
    }
    cuenta['seleccionado'] = cuenta['seleccionado']? !cuenta['seleccionado'] : true;
    console.log(cuenta['seleccionado'])
    if(cuenta.seleccionado){
      this.cuentaSeleccionada = cuenta;
      this.seleccion.emit(cuenta);
    }else {
      this.seleccion.emit(undefined);
      this.cuentaSeleccionada = undefined;
    }
  }
}
