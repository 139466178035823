<div class="h-screen flex flex-row justify-around items-center content-center gap-4	md:mx-4">
  <div class=" w-11/12 md:w-1/2 xl:w-2/5  h-auto  bg-white rounded-lg shadow  xs:p-0" >
    <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
      <div class="container flex flex-col items-center">
       <h1 class="font-medium"> Código de Verificación </h1>
       <p> Ingresa el código que te enviamos a tu correo electrónico </p>
      </div>

        <div class="space-y-4 md:space-y-6 " >

          <input type="text" id="code" name="code" #code="ngModel" [(ngModel)]="confirmationCode"
            class="bg-aguacate-green-50 border border-aguacate-green-300 text-primary-900 sm:text-sm rounded-lg focus:ring-aguacate-green-600 focus:border-aguacate-green-600 block w-full p-2.5"
            placeholder="XXXXXX" required="true" />
            <div *ngIf="confirmationCode &&  verificationError && verificationError.name === 'CodeMismatchException'">
              <span class="text-sm text-red-600">Código incorrecto </span>
            </div>
            <div *ngIf="confirmationCode &&  verificationError && verificationError.name === 'ExpiredCodeException'">
              <span class="text-sm text-red-600">Código expirado, pide el código de nuevo </span>
            </div>
            <div *ngIf="confirmationCode &&  verificationError && verificationError.name !== 'CodeMismatchException'">
              <span class="text-sm text-red-600">Error al validar código </span>
            </div>
        </div>

        <div class="container flex flex-col items-center">
          <button type="button" (click)="confirmSignUp()" [disabled]="! confirmationCode"
            class="w-1/2 text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
            Verificar código
          </button>

        </div>
    </div>
  </div>
</div>
