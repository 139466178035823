import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/dashboard/services/user.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-segmentation',
  styleUrls: ['./dialog-segmentation.component.css'],
  templateUrl: './dialog-segmentation.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DialogSegmentationComponent implements OnInit {
  spotPoints: number =0;
  segmentationId : number = 1;

  constructor( private dialogRef: MatDialogRef<DialogSegmentationComponent>,
    @Inject(MAT_DIALOG_DATA) private data : any[], private userService: UserService, private spinner: SpinnerService) {
      console.log(data);

     }
  ngOnInit(): void {
    this.segmentationId= 1;
  }
     async editSegmentation(){
      const ref = this.spinner.start();
      const ids = this.data.map((user: any) => user.usuarioId);
      try {
        await this.userService.updateUserSegmentation(ids,this.segmentationId, this.spotPoints, undefined);
        this.spinner.stop(ref);
        Swal.fire('Éxito', 'Segmentación actualizada', 'success');
        this.dialogRef.close(true);
      } catch (error) {
        this.spinner.stop(ref);
        Swal.fire('Error', 'Ocurrió un error al actualizar la segmentación', 'error');
      }

     }
 }
