import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/app.reducer';
import { CuentaBeneficiario } from 'src/app/dashboard/interfaces/cuenta-beneficiario.interface';
import { DataCambio, DataTransferencia } from 'src/app/dashboard/interfaces/lista-transferencia.interface';
import { SendMultipleDTO } from 'src/app/dashboard/interfaces/send-multiple.dto';
import { CartolaService } from 'src/app/dashboard/services/cartola.service';
import { MultipleExchangeService } from 'src/app/dashboard/services/multiple-exchange.service';
import { TransaccionService } from 'src/app/dashboard/services/transaccion.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-multiple-money-order',
  templateUrl: './multiple-money-order.component.html',
  styleUrls: ['./multiple-money-order.component.css'],
})
export class MultipleMoneyOrderComponent implements OnInit, OnDestroy{

  cuentaBeneficiario: CuentaBeneficiario | undefined;
  listaTransferencias: DataTransferencia[] = []
  infoCalculadora: any | undefined ;
  subscriptions: Subscription = new Subscription();
  usuarioID: number | undefined;
  saldoUsuario= 0;
  valueToPay=0;
  constructor(
    private router: Router,
    private transaccionService: TransaccionService,
    private cartolaService: CartolaService,
    private spinnerService: SpinnerService,
    private store: Store<AppState>,
    public multipleExchangeService: MultipleExchangeService
    ) {

    }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    // Swal.fire('','Esta opción está deshabilitada', 'warning').then(()=>{
    //   this.multipleExchangeService.clear();
    //   this.router.navigate(['/wallet/client/remittance']);
    // });
    this.initDataUsuario();
  }

  initDataUsuario() {
    this.subscriptions.add(
      this.store.select('auth').subscribe(async ({ user }) => {
        this.usuarioID = user?.usuarioId;
      })
    );
  }

  async validateUserBalance(){
    if(this.usuarioID){
      this.saldoUsuario = (await this.cartolaService.getSaldoCliente(String(this.usuarioID))).balancePermitido;
    }
  }

  continuar(stepper: MatStepper){
    stepper.next();
  }

  addDataCalculator(data: any){
    this.infoCalculadora = data;
  }
  
  addTransferencia(){

    if( !(this.cuentaBeneficiario && this.infoCalculadora) ){
      return;
    }
    if(this.cuentaBeneficiario.bancoID !== this.infoCalculadora.banco.bancoID){
      Swal.fire(
        { confirmButtonColor: '#1e5a3f',
        title: 'Revisa tus datos',
        text:'El banco seleccionado en la calculadora y el del beneficiario deben ser el mismo',
      }
      );
      return;
    }
    this.multipleExchangeService.addToList({
      beneficiario: this.cuentaBeneficiario,
      cambio: this.infoCalculadora
    });
    this.multipleExchangeService.resetCalculator(true);

  }

  onCuentaSeleccionada(cuenta: CuentaBeneficiario){
    this.cuentaBeneficiario = cuenta;

  }
  async confirmarEnvio(){
    const ref = this.spinnerService.start();
    await this.validateUserBalance();
    const items = this.multipleExchangeService.getItems();
    this.calcAmount(items);
    console.log(this.valueToPay);
    console.log(this.saldoUsuario);
    if(this.valueToPay> this.saldoUsuario){
      Swal.fire('No tiene saldo suficiente', 'No tiene saldo suficiente para realizar todos los envíos, por favor valide su información', 'error')
      this.spinnerService.stop(ref);
      return;
    }

    const transactions = items.map(x=>{
      const transaccionDTO ={
        "paisOrigen":'CL', "userId":this.usuarioID,
        "totalMonedaOrigen": +x.cambio.envia,"totalMonedaDestino":+x.cambio.recibe,"monedaOrigen":'CLP',
        "monedaDestino":'VES',"paisDestino":'VE',"formaPago":'WALLET',"diferencia":null,
        "tasa":x.cambio.tasa,"cuentaBeneficiarioId":x.beneficiario.cuentaBeneficiarioID,"tasaCambioId":x.cambio.tasaId,"valorCambioId":x.cambio.valueId,
        }
        return transaccionDTO;
    })

    await this.executeTransactions(transactions).then(results=>{
      console.log(results);
      Swal.fire('Envío creado','Gracias por confiar en nosotros, estaremos validando su pago', 'success').then(()=>{
        this.multipleExchangeService.clear();
        this.router.navigate(['/wallet/client/remittance']);
      });
    }).catch(errors=>{
      console.log(errors);
    }).finally(()=>{
      this.spinnerService.stop(ref);
    });
  }

  async  executeTransactions(transacciones: any[]): Promise<any[]> {
    const promesas = transacciones.map(transaccion => this.transaccionService.saveTransaccion(transaccion));
    return Promise.all(promesas);
  }
  calcAmount(data: any[]){
    this.valueToPay= 0;
    for (const iterator of data) {
      this.valueToPay+= iterator.cambio.envia;
    }
  }

  cancel(){

  }

}
