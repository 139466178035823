import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators'
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/utils/custom.validator';
import { CognitoService } from '../../services/cognito.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.sass']
})
//http://localhost:4200/complete-password-reset?code=664045&email=alejopar2012@gmail.com
export class PasswordResetComponent implements OnInit, OnDestroy {
  code: string= '';
  email: string = '';
  passwordForm: FormGroup;
  passwordResetError = false;
  passwordResetErrorMessage = '';
  private readonly COGNITO_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\"!@#%&\/\\,><\':;|_~`=+\-])[a-zA-Z0-9\^$*.\[\]{}\(\)?\"!@#%&\/\\,><\':;|_~`=+\-]{8,98}$/;
  subscription: Subscription = new Subscription();
  constructor(private dialogService: DialogService, private route: ActivatedRoute, private router: Router, private fb: FormBuilder, private cognitoService: CognitoService, private spinnerService: SpinnerService){
    this.passwordForm = this.fb.group({
      password: ['', [Validators.required, Validators.pattern(this.COGNITO_PATTERN)]],
      passwordVerification: ['', [Validators.required]]
    }, {validators: [CustomValidators.MatchValidator('password', 'passwordVerification')]})
  }
  get passwordMatchError() {
    var mismatch =  (
      this.passwordForm.getError('mismatch') &&
      (this.passwordForm.get('passwordVerification')?.touched || this.passwordForm.get('passwordVerification')?.dirty)
    );
    return mismatch;
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  onSubmit(form: FormGroup){
    const password = form.get('password')?.value;
    const spinnerRef = this.spinnerService.start();
    this.passwordResetError = false;
    this.cognitoService.completeResetPassword(this.email, this.code, password).then(res=> {
      this.spinnerService.stop(spinnerRef);
      this.subscription.add( this.dialogService.start({button: 'Cerrar', message: 'Se cambió la contraseña de manera exitosa' }).afterClosed().subscribe(res=>{
        if(res) this.router.navigate(['auth/login']);
      }))

    }).catch(err=> {
      this.spinnerService.stop(spinnerRef);
      this.passwordResetError = true;
      if(err.code === 'ExpiredCodeException'){
        this.passwordResetErrorMessage = 'El link de reestablecimiento de contraseña ha expirado, por favor solicita otro'
      }
      if(err.code === 'LimitExceededException'){
        this.passwordResetErrorMessage = 'Has superado el limite de intentos para el reestablecimiento de contraseña, espera un momento antes de realizar el proceso de nuevo'
      }
      if(err.code ==='CodeMismatchException'){
        this.passwordResetErrorMessage = 'Link de reestablecimiento de contraseña incorrecto';
      }
    });
  }
  ngOnInit(): void {
    this.subscription.add(
    this.route.queryParams
    .subscribe(params => {
      this.code = params['code'];
      this.email = params['email'];
      if( !this.code || !this.email){
        this.router.navigate(['auth/login']);
      }
    }))}


  }

