<div class=" flex flex-row justify-around items-center content-center gap-4	md:mx-4">
  <div class=" w-11/12 md:w-1/2 xl:w-2/5 " >
    <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
      <div class="container flex flex-col items-center">
       <h1 class="text-xl font-bold"> Se cerrará la sesión por inactividad
       </h1>
       <p> Hemos detectado que llevas más de 20 minutos inactivo, se procederá a cerrar la sesión</p>
      </div>

        <div class="space-y-4 md:space-y-6 " >



        <div class="container flex flex-row gap-4 items-center">
          <button type="button" (click)="close(true)"
          class="w-1/2 text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
          Cancelar
        </button>
        <button type="button" (click)="close(false)"
        class="w-1/2 text-white bg-aguacate-green hover:bg-aguacate-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
        Entendido
      </button>
        </div>
    </div>
  </div>
</div>
